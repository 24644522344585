import React from 'react';
import { EditFleetContainer } from 'container';

class EditFleet extends React.PureComponent {

    render() {
        return(
            <EditFleetContainer
                {...this.props}
            />
        )
    }

}


export default (EditFleet);