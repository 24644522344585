import React from 'react';
import { AuctionDetailsContainer } from 'container';

class AuctionDetails extends React.PureComponent {

    render() {
        return(
            <AuctionDetailsContainer
                {...this.props}
            />
        )
    }

}

export default (AuctionDetails);