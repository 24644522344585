
import React, { useState } from 'react';
import './FAQpage.css';

const FAQpageItem = ({ title, content, isOpen, onClick }) => {
  return (
    <div className="faq-item">
      <div className="faq-title" onClick={onClick}>
        <h2>{title}</h2>
        <span>{isOpen ? '-' : '+'}</span>
      </div>
      {isOpen && <div className="faq-content">{content}</div>}
    </div>
  );
};

const FAQ = ({ items }) => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <div className='faq-header'><h1>Frequently Asked Questions</h1></div>
    <div className="faq-wrap">
      {items.map((item, index) => (
        <FAQpageItem
          key={index}
          title={item.title}
          content={item.content}
          isOpen={openIndex === index}
          onClick={() => handleClick(index)}
        />
      ))}
    </div>
    </div>
  );
};

export default FAQ;
