import React from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import {
  Button,
  ButtonGroup,
  FormWrapper,
  FormHeader,
  FormBody,
  Input,
  FormGroup,
} from "components";
import { Tools } from "common";

var validation = {
  material_category: "required",
  material_detail: "required",
  weight: "required",
  fleet_category: "required",
};

class CreateConsignmentStepThree extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: [],
      materialCategoryError: false,
      materialDetailError: false,
      weightError: false,
      fleetCategoryError: false,
    };
  }

  UNSAFE_componentWillMount() {
    var data = localStorage.getItem("@createConsignment");
    if (data) {
      data = JSON.parse(data);
      this.setState({
        data: {
          ...this.state.data,
          ...data,
          pickup_date: new Date(data.pickup_date),
        },
      });
    }
  }

  async componentDidMount() {
    const {
      fetchMaterialCategories,
      fetchFleetCategories,
      clearMaterialCategories,
      clearFleetCategories,
      token,
    } = this.props;
    clearMaterialCategories();
    clearFleetCategories();
    fetchMaterialCategories(token);
    fetchFleetCategories(token);
  }

  onChange = (target, value) => {
    this.setState({
      send: false,
      data: {
        ...this.state.data,
        [target]: value,
      },
    });
  };

  validation = async () => {
    var validate = {};
    validate = await Tools.validation(validation, this.state.data);

    this.setState({
      valid: validate.valid,
      errors: validate.errors,
    });

    return validate.valid;
  };

  onNext = async () => {
    const valid = await this.validation();
    this.setState({
      send: true,
    });

    if (valid) {
      const { data } = this.state;
      var consignment = localStorage.getItem("@createConsignment");
      if (consignment) {
        consignment = JSON.parse(consignment);
        consignment = {
          ...consignment,
          ...data,
        };
      } else {
        consignment = {
          ...data,
        };
      }
      this.saveLocally(consignment);
      localStorage.setItem("@createConsignment", JSON.stringify(consignment));

      this.props.onNext();
    }
  };

  saveLocally = (local) => {
    const { local_consignment, setLocalConsingment } = this.props;
    var local_consignments = local_consignment || [];

    const { data } = this.state;
    var selected_consignments = local_consignments.find(
      (item) => item.id === local.id
    );

    if (selected_consignments) {
      selected_consignments = {
        ...selected_consignments,
        ...data,
      };
      var index = local_consignments
        .map(function (e) {
          return e.id;
        })
        .indexOf(local.id);

      local_consignments[index] = selected_consignments;

      setLocalConsingment(local_consignments);
    }

    return true;
  };

  onBack = () => {
    this.props.onPrevious();
  };

  onBlurValidationHandler = (target) => {
    if (target === "materialCategory") {
      if (!Tools.isNotEmpty(this.state.data.material_category)) {
        this.setState({
          ...this.state,
          materialCategoryError: true,
        });
      } else {
        this.setState({
          ...this.state,
          materialCategoryError: false,
        });
      }
    }
    if (target === "materialDetail") {
      if (!Tools.isNotEmpty(this.state.data.material_detail)) {
        this.setState({
          ...this.state,
          materialDetailError: true,
        });
      } else {
        this.setState({
          ...this.state,
          materialDetailError: false,
        });
      }
    }
    if (target === "weight") {
      if (!Tools.isNotEmpty(this.state.data.weight)) {
        this.setState({
          ...this.state,
          weightError: true,
        });
      } else {
        this.setState({
          ...this.state,
          weightError: false,
        });
      }
    }
    if (target === "fleetCategory") {
      if (!Tools.isNotEmpty(this.state.data.fleet_category)) {
        this.setState({
          ...this.state,
          fleetCategoryError: true,
        });
      } else {
        this.setState({
          ...this.state,
          fleetCategoryError: false,
        });
      }
    }
  };

  render() {
    const { fleet_categories, material_categories } = this.props;
    const { data } = this.state;
    const { innerWidth } = window;

    var materials = [];
    material_categories.map((item) => {
      materials.push({ label: item.name, value: item.name, id: item.id });
      return true;
    });

    var fleets = [];
    fleet_categories.map((item) => {
      fleets.push({ label: item.name, value: item.name, id: item.id });
      return true;
    });

    return (
      <div
        style={
          innerWidth > 768
            ? { marginTop: 50, marginBottom: 50 }
            : { marginTop: 15, marginBottom: 20 }
        }
      >
        <FormWrapper>
          <FormHeader title="material" />
          <FormBody>
            <Input
              type="select"
              label="Material Categories"
              options={materials}
              placeholder="Please type here"
              style={{ marginBottom: 20 }}
              value={data.material_category}
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  materialCategoryError: false,
                });
                this.onChange("material_category", e);
              }}
              caption={
                this.state.materialCategoryError && "This field is required"
              }
              // onBlur={() => this.onBlurValidationHandler("materialCategory")}
              isError={
                (this.state.send &&
                  !this.state.valid &&
                  this.state.errors.includes("material_category")) ||
                this.state.materialCategoryError
              }
              isRequired={true}
            />
            <Input
              type="textarea"
              label="Material Detail"
              placeholder="Please provide complete details of the material (if a volumetric item please provide it's LxBxH)"
              style={{ marginBottom: 20 }}
              value={data.material_detail}
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  materialDetailError: false,
                });
                this.onChange("material_detail", e.target.value);
              }}
              caption={
                this.state.materialDetailError && "This field is required"
              }
              onBlur={() => this.onBlurValidationHandler("materialDetail")}
              isError={
                this.state.send &&
                !this.state.valid &&
                this.state.errors.includes("material_detail")
              }
              isRequired={true}
            />
            <FormGroup>
              <Col lg={4} md={4} sm={12} xs={12}>
                <Input
                  type="number"
                  min="0"
                  label="Weight (In Kg)"
                  placeholder="Please type here"
                  style={{ marginBottom: 20 }}
                  value={data.weight}
                  onChange={(e) => {
                    this.setState({
                      ...this.state,
                      weightError: false,
                    });
                    this.onChange("weight", e.target.value);
                  }}
                  caption={this.state.weightError && "This field is required"}
                  onBlur={() => this.onBlurValidationHandler("weight")}
                  isError={
                    (this.state.send &&
                      !this.state.valid &&
                      this.state.errors.includes("weight")) ||
                    this.state.weightError
                  }
                  isRequired={true}
                />
              </Col>
              <Col lg={8} md={8} sm={12} xs={12}>
                <Input
                  type="select"
                  label="Transportation Mode"
                  options={fleets}
                  placeholder="Please type here"
                  style={{ marginBottom: 20 }}
                  value={data.fleet_category}
                  onChange={(e) => {
                    this.setState({
                      ...this.state,
                      fleetCategoryError: false,
                    });
                    this.onChange("fleet_category", e);
                  }}
                  caption={
                    this.state.fleetCategoryError && "This field is required"
                  }
                  // onBlur={() => this.onBlurValidationHandler("fleetCategory")}
                  isError={
                    (this.state.send &&
                      !this.state.valid &&
                      this.state.errors.includes("fleet_category")) ||
                    this.state.fleetCategoryError
                  }
                  isRequired={true}
                />
              </Col>
            </FormGroup>

            <ButtonGroup position="right">
              <Button
                buttonText="Back"
                type="default"
                size="sm"
                onClick={() => this.onBack()}
              />
              <Button
                buttonText="Save"
                type="success"
                size="sm"
                onClick={() => this.onNext()}
              />
            </ButtonGroup>
          </FormBody>
        </FormWrapper>
      </div>
    );
  }
}

const mapsStateToProps = ({
  authenticated,
  material_categories,
  fleet_categories,
  consignment,
}) => {
  return {
    token: authenticated.token,
    material_categories: material_categories.lists,
    fleet_categories: fleet_categories.lists,
    local_consignment: consignment.local_data,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const {
    actions: fleet_categories_actions,
  } = require("redux/FleetCategoriesRedux");
  const {
    actions: material_categories_actions,
  } = require("redux/MaterialCategoriesRedux");

  return {
    ...ownProps,
    ...stateProps,
    fetchFleetCategories: async (token) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await fleet_categories_actions.fetchFleetCategories(
        dispatch,
        token
      );
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    clearFleetCategories: () => dispatch({ type: "CLEAR_FLEET_CATEGORIES" }),
    fetchMaterialCategories: async (token) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await material_categories_actions.fetchMaterialCategories(
        dispatch,
        token
      );
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    clearMaterialCategories: () =>
      dispatch({ type: "CLEAR_MATERIAL_CATEGORIES" }),
    toastPending: (body) => {
      dispatch({ type: "TOAST_PENDING", ...body });
    },
    setLocalConsingment: (items) =>
      dispatch({ type: "SET_LOCAL_CONSIGNMENT", items }),
  };
};

export default connect(
  mapsStateToProps,
  undefined,
  mergeProps
)(CreateConsignmentStepThree);
