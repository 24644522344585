import { Tools, Images, Languages } from "common";
import {
  Button,
  Input,
  SocialAuthentication,
  VerifyEmail,
  VerifyMobileNumber,
} from "components";
import React from "react";
// import jwt_decode from "jwt-decode";
import { Row, Col } from "react-bootstrap";
import { FirebaseAPI } from "services";
import { connect } from "react-redux";

var validation = {
  user: {
    firstname: "required",
    lastname: "required",
    email: "email",
    password: "password",
  },
  partner: {
    resubmit: {
      account_type: "required",
      name: "required",
      email: "email",
      mobile_prefix: "required",
      mobile_number: "required",
    },
    normal: {
      account_type: "required",
      name: "required",
      email: "email",
      password: "password",
      mobile_prefix: "required",
      mobile_number: "required",
      mobile_number_verified: "valid",
    },
  },
};

class RegisterStepOne extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      selectedOption: [],
      send: false,
      valid: false,
      data: {},
      partner_data: {},
      errors: [],
      firstNameError: false,
      firstNameErrorMessage: '',
      lastNameError: false,
      lastNameErrorMessage: '',
      emailError: false,
      passwordError: false,
      passwordErrorMessage: '',
      registerProfileError: false,
      partnerNameError: false,
      partnerNameErrorMessage: "",
      partnerEmailError: false,
      partnerPasswordError: false,
    };
  }

  componentDidMount() {
    const {user, history } = this.props;
    console.log("user", user);
    this.setState({
      partner_data: {
        email: user.email,
        mobile_number: user.mobile_number,
        mobile_prefix: user.mobile_prefix,
      },
    });
    this.props.fetchFinish();

    if (
      Tools.getParamFromURL(history, "role") === "logistic_partner" ||
      this.props.isResubmit
    ) {
      this.setState({
        selectedOption: { value: "partner", label: "Saytum Service Provider" },
      });
    } else {
      this.setState({
        selectedOption: { value: "user", label: "Saytum User" },
      });
    }

    // setTimeout(() => {
    //   let dataNew = localStorage.getItem("@registrationData");
    //   if (dataNew) {
    //     dataNew = JSON.parse(dataNew);
    //     if (this.state.selectedOption.value === "user") {
    //       this.setState({
    //         data: {
    //           ...this.state.data,
    //           ...dataNew,
    //         },
    //       });
    //     } else { 
    //       this.setState({
    //         partner_data: {
    //           ...this.state.partner_data,
    //           ...dataNew,
    //         },
    //       });
    //     }
    //   }
    // }, 2000)

  }

  onBlurValidationHandler = (target) => {

    const format = /[ -/:-@[-`{-~0-9]/;
    // const format = /[^0-9 -/:-@[-`{-~]/;
    const passLength = (this.state.data.password).length;

    if (target === "firstName") {
      console.log({target}, this.state.data.firstname);
      if (!Tools.isNotEmpty(this.state.data.firstname)) {
        this.setState({
          ...this.state,
          firstNameError: true,
          firstNameErrorMessage: 'This field is required!',
        });
      } else if(format.test(this.state.data.firstname)) {
        this.setState({
          ...this.state,
          firstNameError: true,
          firstNameErrorMessage: 'Special characters and Numbers are not allowed!'
        });
      } else {
        this.setState({
          ...this.state,
          firstNameError: false,
          firstNameErrorMessage: '',
        });
      }
    }
    
    if (target === "lastName") {
      if (!Tools.isNotEmpty(this.state.data.lastname)) {
        this.setState({
          ...this.state,
          lastNameError: true,
          lastNameErrorMessage: 'This field is required!'
        });
      } else if(format.test(this.state.data.lastname)) {
        this.setState({
          ...this.state,
          lastNameError: true,
          lastNameErrorMessage: 'Special characters and Numbers are not allowed!'
        });
      } else {
        this.setState({
          ...this.state,
          lastNameError: false,
          lastNameErrorMessage: ''
        });
      }
    }
    if (target === "email") {
      if (!Tools.isEmailValid(this.state.data.email)) {
        this.setState({
          ...this.state,
          emailError: true,
        });
      } else {
        this.setState({
          ...this.state,
          emailError: false,
        });
      }
    }
    if (target === "password") {
      if (passLength < 6 || passLength > 12) {
        this.setState({
          ...this.state,
          passwordError: true,
          passwordErrorMessage: "Password Length should be between 6-12"
        })
      }
      else if (!Tools.isPasswordValid(this.state.data.password) || /\s/g.test(this.state.data.password)) {
        this.setState({
          ...this.state,
          passwordError: true,
          passwordErrorMessage: (
            <div>
              <p>
                Password must contain:
                <p>1.One Uppercase Alphabet. <br/>
                2.One Lowercase Alphabet. <br/>
                3.One Numeric Value. <br/>
                4.One Special Character.</p>
              </p>
            </div>
          ),
        });
      } else {
        this.setState({
          ...this.state,
          passwordError: false,
        });
      }
    }
    if (target === "registerProfile") {
      if (!Tools.isNotEmpty(this.state.partner_data.account_type)) {
        this.setState({
          ...this.state,
          registerProfileError: true,
        });
      } else {
        this.setState({
          ...this.state,
          registerProfileError: false,
        });
      }
    }
    if (target === "partnerName") {
      if (!Tools.isNotEmpty(this.state.partner_data.name)) {
        this.setState({
          ...this.state,
          partnerNameError: true,
          partnerNameErrorMessage:"This is required field"
        });
      } else 
      if (!Tools.isNameValid(this.state.partner_data.name)) {
        this.setState({
          ...this.state,
          partnerNameError: true,
          partnerNameErrorMessage: "Special characters and Numbers are not allowed!",
        });
      } else {
        this.setState({
          ...this.state,
          partnerNameError: false,
        });
      }
    }
    if (target === "partnerEmail") {
      if (!Tools.isEmailValid(this.state.partner_data.email)) {
        this.setState({
          ...this.state,
          partnerEmailError: true,
        });
      } else {
        this.setState({
          ...this.state,
          partnerEmailError: false,
        });
      }
    }
    if (target === "partnerPassword") {
      if (!Tools.isPasswordValid(this.state.partner_data.password)) {
        this.setState({
          ...this.state,
          partnerPasswordError: true,
        });
      } else {
        this.setState({
          ...this.state,
          partnerPasswordError: false,
        });
      }
    }
  };

  onChange = (target, value, account = "user") => {
    if (account === "user") {
      if(target === "firstname")
      {
        var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]0-9/;
        const testString = format.test(value);
        if(testString === true)
        {
          console.log('===>>>');
          this.setState({

            firstNameError: false,
          });
        }
      }
      this.setState({
        data: {
          ...this.state.data,
          [target]: value,
        },
        send: false,
      });
    } else {
      this.setState({
        partner_data: {
          ...this.state.partner_data,
          [target]: value,
        },
        send: false,
      });
    }
  };

  onNext = async () => {
    var valid = await this.validation();
    if (valid) {
      var data =
        this.state.selectedOption.value === "user"
          ? this.state.data
          : this.state.partner_data;
      // console.log("onNext",data);
      data = {
        ...data,
        accountType: this.state.selectedOption,
      };
      if (!this.props.isResubmit) {
        var isUnique = await this.isAccountUnique(data);
        if (isUnique) {
          localStorage.setItem("@registrationData", JSON.stringify(data));
          this.props.onNext();
        }
      } else {
        localStorage.setItem("@registrationData", JSON.stringify(data));
        this.props.onNext();
      }
    }
  };

  handleSocialRegister = async (provider, params) => {
    params = { ...params, accountType: { label: "user", value: "user" } };
    // console.log("params", params);
    // const resDecode = jwt_decode(params.credential);
    // console.log("paramsdecode" , resDecode)
    if (provider === "google") {
      params = await {
        ...params,
        firstname: params.credDecode.given_name,
        lastname: params.credDecode.family_name,
        email: params.credDecode.email,
        provider: "google",
        file: params.credDecode.picture,
        accessToken: params.cred,
      };
    } else {
      params = await {
        ...params,
        firstname: params.name.split(" ")[0],
        lastname: params.name.split(" ")[1],
        email: params.email,
        provider: "facebook",
        file: params.picture.data.url,
        accessToken: params.accessToken,
      };
    }
    var isUnique = await this.isAccountUnique(params);
    if (isUnique) {
      this.props.onSocialRegister(params);
    }
  };

  validation = () => {
    const { selectedOption, data, partner_data } = this.state;
    // console.log("partner data", partner_data);
    // console.log("VALUE", selectedOption.value);
    var validate = {};
    if (selectedOption.value === "user") {
      validate = Tools.validation(validation.user, data);
    } else if (selectedOption.value === "partner") {
      const {
        mobile_number_verified,
        mobile_number_change,
        email_change,
        email_verified,
      } = partner_data;
      // console.log("partner" , partner_data)
      if (this.props.isResubmit) {
        validate = Tools.validation(validation.partner.resubmit, partner_data);
      } else {
        validate = Tools.validation(validation.partner.normal, partner_data);

        if (this.props.type === "join-partner" || this.props.isResubmit) {
          validate.valid = validate.valid && email_verified;

          if (!validate) {
            validate.errors.push("email_verified");
          }
        }
      }

      if (mobile_number_change) {
        validate.valid = validate.valid && mobile_number_verified;
        if (!validate) {
          validate.errors.push("mobile_number_verified");
        }
      }

      if (email_change) {
        validate.valid = validate.valid && email_verified;
        if (!validate) {
          validate.errors.push("email_verified");
        }
      }
    }

    this.setState({
      valid: validate.valid,
      errors: validate.errors,
      send: true,
    });

    return validate.valid;
  };

  isAccountUnique = async (body) => {
    const { fetchPending, fetchFinish, toastPending } = this.props;
    fetchPending();
    var param = { email: body.email, role: body.accountType.value };
    var is_unique_account = await FirebaseAPI.callFunctions(
      "isUniqueAccount",
      param
    );
    if (is_unique_account.status_code === 200) {
      fetchFinish();
      return true;
    }
    var message = is_unique_account.message;
    if (is_unique_account.status === "app/account-registered") {
      message = Languages.registration.credintial_registered("Email ID");
    }
    toastPending({
      message: message,
      toastType: is_unique_account.status_code,
    });
    fetchFinish();
    return false;
  };

  render() {
    const { history } = this.props;
    var role = Tools.getParamFromURL(history, "role");

    const renderUserForm = () => {
      const { data } = this.state;
      return (
        <>
          <Input
            type="text"
            label="First Name"
            iconPrefix={Images.user}
            placeholder="Please type here"
            style={{ margin: 0, marginBottom: 25 }}
            value={data.firstname}
            onChange={(e) => {
              this.setState({
                ...this.state,
                firstNameError: false,
              });
              this.onChange("firstname", e.target.value);
            }}
            caption={
              this.state.firstNameError && this.state.firstNameErrorMessage
            }
            onBlur={() => this.onBlurValidationHandler("firstName")}
            isError={
              (this.state.send &&
                !this.state.valid &&
                this.state.errors.includes("firstname")) ||
              this.state.firstNameError
            }
            isRequired={true}
          />
          <Input
            type="text"
            label="Last Name"
            iconPrefix={Images.user}
            placeholder="Please type here"
            style={{ margin: 0, marginBottom: 25 }}
            value={data.lastname}
            onChange={(e) => {
              this.setState({
                ...this.state,
                lastNameError: false,
              });
              this.onChange("lastname", e.target.value);
            }}
            caption={
              this.state.lastNameError && this.state.lastNameErrorMessage
            }
            onBlur={() => this.onBlurValidationHandler("lastName")}
            isError={
              (this.state.send &&
                !this.state.valid &&
                this.state.errors.includes("lastname")) ||
              this.state.lastNameError
            }
            isRequired={true}
          />
          <Input
            type="email"
            label="Email"
            iconPrefix={Images.form_email}
            placeholder="Please type here"
            style={{ margin: 0, marginBottom: 25 }}
            value={this.state.data.email}
            onChange={(e) => {
              this.setState({
                ...this.state,
                emailError: false,
              });
              this.onChange("email", e.target.value);
            }}
            onBlur={() => this.onBlurValidationHandler("email")}
            caption={this.state.emailError && "Provide correct email format"}
            isError={
              (this.state.send &&
                !this.state.valid &&
                this.state.errors.includes("email")) ||
              this.state.emailError
            }
            isRequired={true}
          />
          <Input
            type={!this.state.showPassword ? "password" : "text"}
            label="Password"
            iconPrefix={Images.password}
            iconSuffix={
              !this.state.showPassword ? Images.eye_close : Images.eye
            }
            clickableIcon={["suffix"]}
            onIconClick={(position) => {
              if (position === "suffix") {
                this.setState({ showPassword: !this.state.showPassword });
              }
            }}
            placeholder="Example:- Saytum@123"
            style={{ margin: 0, marginBottom: 25 }}
            value={this.state.data.password}
            onChange={(e) => {
              this.setState({
                ...this.state,
                passwordError: false,
              });
              this.onChange("password", e.target.value);
            }}
            onBlur={() => this.onBlurValidationHandler("password")}
            caption={this.state.passwordError && this.state.passwordErrorMessage}
            isError={
              (this.state.send &&
                !this.state.valid &&
                this.state.errors.includes("password")) ||
              this.state.passwordError
            }
            isRequired={true}
          />
        </>
      );
    };

    const renderPartnerForm = () => {
      const options = [
        { value: "personal", label: "Personal" },
        { value: "company", label: "Company" },
      ];

      const { partner_data } = this.state;

        return (
          <>
            <Input
              type="select"
              options={options}
              style={{ margin: 0, marginBottom: 25 }}
              label="Register Your Profile as"
              value={partner_data.account_type}
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  registerProfileError: false,
                });
                this.onChange("account_type", e, "partner");
              }}
              // onBlur={() => this.onBlurValidationHandler("registerProfile")}
              caption={this.registerProfileError && "This field is required"}
              isError={
                (this.state.send &&
                  !this.state.valid &&
                  this.state.errors.includes("account_type")) ||
                this.registerProfileError
              }
              isRequired={true}
            />
            <Input
              type="text"
              label="Name"
              iconPrefix={Images.user}
              placeholder="Enter your personal/company name"
              style={{ margin: 0, marginBottom: 25 }}
              value={partner_data.name}
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  partnerNameError: false,
                });
                this.onChange("name", e.target.value, "partner");
              }}
              onBlur={() => this.onBlurValidationHandler("partnerName")}
              caption={
                this.state.partnerNameError &&
                this.state.partnerNameErrorMessage
              }
              isError={
                (this.state.send &&
                  !this.state.valid &&
                  this.state.errors.includes("name")) ||
                this.state.partnerNameError
              }
              isRequired={true}
            />

            {this.props.type === "edit-profile" ? (
              <VerifyEmail
                onVerified={(data) => {
                  this.setState({
                    send: false,
                    partner_data: {
                      ...this.state.partner_data,
                      email: data.email,
                      email_verified: true,
                    },
                  });
                }}
                isChanged={(param) => {
                  this.setState({
                    send: false,
                    partner_data: {
                      ...this.state.partner_data,
                      email_change: param,
                      email_verified: !param ? true : false,
                    },
                  });
                }}
                type={this.props.type}
                isVerified={this.state.partner_data.email_verified}
                isError={
                  this.state.send &&
                  !this.state.valid &&
                  (this.state.errors.includes("email") ||
                    this.state.errors.includes("email_verified"))
                }
                inputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <Input
                type="email"
                label="Email"
                iconPrefix={Images.form_email}
                placeholder="Enter your personal/company email"
                style={{ margin: 0, marginBottom: 25 }}
                value={partner_data.email}
                onChange={(e) => {
                  this.setState({
                    ...this.state,
                    partnerEmailError: false,
                  });
                  this.onChange("email", e.target.value, "partner");
                }}
                onBlur={() => this.onBlurValidationHandler("partnerEmail")}
                caption={
                  this.state.partnerEmailError && "Provide corrent email format"
                }
                isError={
                  (this.state.send &&
                    !this.state.valid &&
                    (this.state.errors.includes("email") ||
                      this.state.errors.includes("email_verified"))) ||
                  this.state.partnerEmailError
                }
                inputProps={this.props.isResubmit && { readOnly: true }}
                isRequired={true}
              />
            )}

            {!this.props.isResubmit && (
              <Input
                type={!this.state.showPassword ? "password" : "text"}
                label="Password"
                iconPrefix={Images.password}
                iconSuffix={
                  !this.state.showPassword ? Images.eye_close : Images.eye
                }
                clickableIcon={["suffix"]}
                onIconClick={() =>
                  this.setState({ showPassword: !this.state.showPassword })
                }
                placeholder="Example:- Saytum@123"
                style={{ margin: 0, marginBottom: 25 }}
                value={partner_data.password}
                onChange={(e) => {
                  this.setState({
                    ...this.state,
                    partnerPasswordError: false,
                  });
                  this.onChange("password", e.target.value, "partner");
                }}
                onBlur={() => this.onBlurValidationHandler("partnerPassword")}
                caption={
                  this.state.partnerPasswordError && "Password length must be of 6-12"
                }
                isError={
                  (this.state.send &&
                    !this.state.valid &&
                    this.state.errors.includes("password")) ||
                  this.state.partnerPasswordError
                }
                isRequired={true}
              />
            )}

            <VerifyMobileNumber
              onVerified={(data) => {
                this.setState({
                  send: false,
                  partner_data: {
                    ...this.state.partner_data,
                    mobile_prefix: data.mobile_prefix,
                    mobile_number: data.mobile_number,
                    mobile_number_verified: true,
                  },
                });
              }}
              isChanged={(param) => {
                this.setState({
                  send: false,
                  partner_data: {
                    ...this.state.partner_data,
                    mobile_number_change: param,
                    mobile_number_verified: !param ? true : false,
                  },
                });
              }}
              isResubmit={this.props.isResubmit}
              onChange={(e) => this.onChange("mobile_number", e, "partner")}
              onSelect={(e) => this.onChange("mobile_prefix", e, "partner")}
              type={this.props.type}
              isVerified={this.state.partner_data.mobile_number_verified}
              isError={
                this.state.send &&
                !this.state.valid &&
                (this.state.errors.includes("mobile_number") ||
                  this.state.errors.includes("mobile_prefix") ||
                  this.state.errors.includes("mobile_number_verified"))
              }
              isEmailRequired={true}
              email={this.state.partner_data.email}
            />
          </>
        );
    };

    return (
      <>
        <div className="container">
          <div className="container__auth">
            <Row>
              <Col>
                <div className="container__auth__header">
                  {!this.props.isResubmit ? (
                    <h1 className="main-header-text">
                      Create new {this.state.selectedOption.value} account
                    </h1>
                  ) : this.props.type === "join-partner" ? (
                    <h1 className="main-header-text">Join Partner</h1>
                  ) : (
                    <h1 className="main-header-text">Resubmit account</h1>
                  )}

                  <h4 className="secondary-header-text">
                    Join to bidding for multiple jobs. Get your income increased
                    and better.
                  </h4>
                </div>
                <div className="container__auth__body">
                {this.state.selectedOption.value === "user" && (
                    <SocialAuthentication
                      activity="register"
                      handleActivity={(provider, response) =>
                        this.handleSocialRegister(provider, response)
                      }
                    />
                  )}
                  {this.state.selectedOption.value === "user" &&
                    renderUserForm()}

                  {this.state.selectedOption.value === "partner" &&
                    renderPartnerForm()}

                  <Button
                    size="lg"
                    type="warning"
                    buttonText={
                      this.state.selectedOption.value === "user"
                        ? "Register"
                        : "Next"
                    }
                    variation="primary"
                    style={{ margin: 0, marginTop: 20, marginBottom: 20 }}
                    onClick={() => this.onNext()}
                  />

                  {!this.props.isResubmit && (
                    <p className="hyperlink__text text-center">
                      Do you have an account?{" "}
                      <a
                        href={`/login?role=${role}`}
                        className="hyperlink text-warning"
                      >
                        Login
                      </a>
                    </p>
                  )}

                  
                </div>
              </Col>
              <Col className="banner-container">
                <img src={Images.banner_login} alt="user-login.png" />
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}
const mapsStateToProps = ({ authenticated }) => {
  return {
    user: authenticated.userInfo,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;

  return {
    ...ownProps,
    ...stateProps,
    fetchPending: () => dispatch({ type: "FETCH_PENDING" }),
    fetchFinish: () => dispatch({ type: "FETCH_FINISH" }),
    toastPending: (body) => {
      dispatch({ type: "TOAST_PENDING", ...body });
    },
  };
};

export default connect(
  mapsStateToProps,
  undefined,
  mergeProps
)(RegisterStepOne);
