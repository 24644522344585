import React from "react";
import "./uploader.scss";
import Images from "common/Images";
import Tools from "common/Tools";

class ImageUploader extends React.PureComponent {
  render() {
    const {
      onChange,
      value,
      containerClass,
      inputClass,
      imageClass,
      labelClass,
      iconClass,
      isError,
      disabled,
      onBlur,
      isRequired
    } = this.props;

    return (
      <div style={this.props.style}>
        <label
          className={`uploader__label ${
            this.props.isError ? "input__label__error" : undefined
          }`}
        >
          {this.props.label}
          {isRequired && <span className="input__required">*</span>}
        </label>

        <div
          className={`uploader ${
            isError ? "uploader__error" : ""
          } ${containerClass}`}
        >
          <input
            type="file"
            onChange={(e) => onChange(e)}
            onBlur={(e) => onBlur(e)}
            className={`uploader__input ${inputClass}`}
            disabled={disabled}
          />
          {!Tools.isNotEmpty(value) ? (
            isError ? (
              <div>
                <img
                  alt="upload"
                  src={Images.upload_error}
                  className={`uploader__icon ${iconClass}`}
                />
                <p
                  className={`uploader__file-label uploader__error__label ${labelClass}`}
                >
                  Upload image
                </p>
              </div>
            ) : (
              <div>
                <img
                  alt="upload"
                  src={Images.upload}
                  className={`uploader__icon ${iconClass}`}
                />
                <p className={`uploader__file-label ${labelClass}`}>
                  Upload image Ex:-(.jpg , .jpeg, etc)
                </p>
              </div>
            )
          ) : (
            <div>
              <img
                src={value.file}
                alt="upload"
                className={`uploader__image ${imageClass}`}
              />
            </div>
          )}
        </div>

        {this.props.caption && (
          <label
            className={`uploader__caption ${
              this.props.isError ? "uploader__caption__error" : undefined
            }`}
          >
            {this.props.caption}
          </label>
        )}
      </div>
    );
  }
}

export default ImageUploader;
