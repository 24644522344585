import React from 'react';
import PropTypes from 'prop-types';
import InputType from './input';
import SelectType from './select';
import InputDatePicker from './datepicker';
import InputAutoComplete from './autocomplete';
import InputPhone from './phone';
import DropdownType from './dropdown';
import InputCheckbox from './checkbox';
import InputArea from './textarea';
import InputFilter from './filter';
import InputFile from './file';
import InputEmoji from './emoji';

class Input extends React.PureComponent {

    render() {
        if(this.props.type === "select") {
            return <SelectType {...this.props} />
        }
        if(this.props.type === "datepicker") {
            return <InputDatePicker {...this.props} />
        }
        if(this.props.type === "autocomplete") {
            return <InputAutoComplete {...this.props} />
        }
        if(this.props.type === "phone") {
            return <InputPhone {...this.props} />
        }
        if(this.props.type === "dropdown") {
            return <DropdownType {...this.props} />
        }
        if(this.props.type === "checkbox") {
            return <InputCheckbox {...this.props} />
        }
        if(this.props.type === "textarea") {
            return <InputArea {...this.props} />
        }
        if(this.props.type === "filter") {
            return <InputFilter {...this.props} />
        }
        if(this.props.type === "file") {
            return <InputFile {...this.props} />
        }
        if(this.props.type === "emoji") {
            return <InputEmoji {...this.props} />
        }
        return <InputType {...this.props} />
    }

}

Input.defaultProps = {
    type: "text"
}

Input.propTypes = {
    type: PropTypes.string,
    onChange: PropTypes.func
}

export default (Input);