import React from 'react';
import { connect } from 'react-redux';
import { SaytumAPI } from 'services/SaytumAPI';
import { navigate } from 'common/Tools';
import { CreateMaterialCategoriesContainer } from 'container';

class CreateMaterialCategories extends React.PureComponent {


    handleCreate = async(params) => {
        this.props.SET_PROCESS({ isVisible: true, isCovered: true, text: "Processing" });
        const { token } = this.props;
        
        const add = await SaytumAPI.authenticated_post("material-categories", token, params);

        if(add.fetch_status === "success") {
            this.props.SET_NOTIFICATION({ isVisible: true, message: add.message, type: "success" });
            this.props.RESET_PROCESS();
            navigate(this.props.history, "/dashboard?screen=master_data&child=material_categories");
        }
        else {
            this.props.RESET_PROCESS();
            this.props.SET_NOTIFICATION({ isVisible: true, message: add.message, type: "error" });
        }
        this.props.RESET_PROCESS();
    }

    render() {
        return(
            <CreateMaterialCategoriesContainer
                {...this.props}
            />
        )
    }

}

const mapsStateToProps = ({ authenticated }) => {
    return {
        token: authenticated.token
    }
}

const mapsDispatchToState = (dispatch) => {
    return {
        SET_PROCESS: (process) => dispatch({ type: 'SET_PROCESS', items: process }),
        SET_NOTIFICATION: (notification) => dispatch({ type: 'SET_NOTIFICATION', items: notification }),
        RESET_PROCESS: () => dispatch({ type: 'RESET_PROCESS' })
    }
}

export default connect(mapsStateToProps, mapsDispatchToState)(CreateMaterialCategories);