import { Constant } from "common";

export const LogisticPartnerAPI = {

    fetchLogisticPartner(token) {
        try {
            return fetch(`${Constant.API_URL}/partner`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(async(response) => {
                if(response.ok) {
                    return response.json()
                }
                var error_message = await response.json().then((json) => {
                    if(typeof(json.message) === "object") {
                        for (const index in json.message) {
                            return json.message[index];
                        }
                    }
                    else {
                        return json; 
                    }
                })
                throw new Error(JSON.stringify(error_message));
            })
            .then((json) => {
                return { status_code: 200, ...json };
            }).catch((error) => {
                return { status_code: 500, ...error };
            });
        }
        catch(error) {
            return { status_code: 500, ...error };
        }
    },

    toggleActivate(token, body) {
        try {
            return fetch(`${Constant.API_URL}/user/activate`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(body)
            }).then(async(response) => {
                if(response.ok) {
                    return response.json();
                }
                var error_message = await response.json().then((json) => {
                    if(typeof(json.message) === "object") {
                        for (const index in json.message) {
                            return json.message[index];
                        }
                    }
                    else {
                        return json; 
                    }
                })
                throw new Error(JSON.stringify(error_message))
            })
            .then((json) => {
                return { status_code: 200, ...json };
            }).catch((error) => {
                return { status_code: 500, ...error };
            });
        }
        catch(error) {
            return { status_code: 500, ...error };
        }
    },

    approval(token, id, approval, body) {
        try {
            return fetch(`${Constant.API_URL}/partner/${id}/confirm?type=${approval}`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(body)
            }).then(async(response) => {
                if(response.ok) {
                    return response.json();
                }
                var error_message = await response.json().then((json) => {
                    if(typeof(json.message) === "object") {
                        for (const index in json.message) {
                            return json.message[index];
                        }
                    }
                    else {
                        return json; 
                    }
                })
                throw new Error(JSON.stringify(error_message))
            })
            .then((json) => {
                return { status_code: 200, ...json };
            }).catch((error) => {
                return { status_code: 500, ...error };
            });
        }
        catch(error) {
            return { status_code: 500, ...error };
        }
    }

}