
import { FleetsAPI } from "services"

const types = {
    FETCH_FLEETS_SUCCESS: "FETCH_FLEETS_SUCCESS",
    FETCH_FLEETS_FAILURE: "FETCH_FLEETS_FAILURE",
    CLEAR_FLEETS: "CLEAR_FLEETS",
    CREATE_FLEET_SUCCESS: "CREATE_FLEET_SUCCESS",
    CREATE_FLEET_FAILURE: "CREATE_FLEET_FAILURE",
    DELETE_FLEET_SUCCESS: "DELETE_FLEET_SUCCESS",
    DELETE_FLEET_FAILURE: "DELETE_FLEET_FAILURE",
    UPDATE_FLEET_SUCCESS: "UPDATE_FLEET_SUCCESS",
    UPDATE_FLEET_FAILURE: "UPDATE_FLEET_FAILURE"
}

export const actions = {
    fetchPartnersFleets: async(
        dispatch,
        token,
        user_id
    ) => {
        var json = await FleetsAPI.fetchPartnersFleet(token, user_id)
        if(json.status_code === 500) {
            dispatch(actions.fetchPartnersFleetsFailure(json.message))
        }
        else {
            dispatch(actions.fetchPartnersFleetsSuccess(json))
        }
        return json
    },
    fetchPartnersFleetsFailure: (error) => ({
        type: types.FETCH_FLEETS_FAILURE,
        error
    }),
    fetchPartnersFleetsSuccess: (items) => ({
        type: types.FETCH_FLEETS_SUCCESS,
        items
    }),
    createFleet: async(
        dispatch,
        token,
        body
    ) => {
        var json = await FleetsAPI.createFleet(token, body)
        if(json.status_code === 500) {
            dispatch(actions.createFleetFailure(json.message))
        }
        else {
            dispatch(actions.createFleetSuccess(json))
        }
        return json
    },
    createFleetFailure: (error) => ({
        type: types.CREATE_FLEET_FAILURE,
        error
    }),
    createFleetSuccess: (items) => ({
        type: types.CREATE_FLEET_SUCCESS,
        items
    }),
    deleteFleet: async(
        dispatch,
        token,
        id
    ) => {
        var json = await FleetsAPI.deleteFleet(token, id)
        if(json.status_code === 500) {
            dispatch(actions.deleteFleetFailure(json.message))
        }
        else {
            dispatch(actions.deleteFleetSuccess(json))
        }
        return json
    },
    deleteFleetFailure: (error) => ({
        type: types.DELETE_FLEET_FAILURE,
        error
    }),
    deleteFleetSuccess: (items) => ({
        type: types.DELETE_FLEET_SUCCESS,
        items
    }),
    updateFleet: async(
        dispatch,
        token,
        id,
        body
    ) => {
        var json = await FleetsAPI.updateFleet(token, id, body)
        if(json.status_code === 500) {
            dispatch(actions.updateFleetFailure(json.message))
        }
        else {
            dispatch(actions.updateFleetSuccess(json))
        }
        return json
    },
    updateFleetFailure: (error) => ({
        type: types.UPDATE_FLEET_FAILURE,
        error
    }),
    updateFleetSuccess: (items) => ({
        type: types.UPDATE_FLEET_SUCCESS,
        items
    })
}


const initialState = {
    lists: [],
    total: 0,
    error: null
}

export const reducer = (state = initialState, action) => {

    const { items, error } = action;

    var data = []
    var selectedFleet = null
    
    switch (action.type) {
        case types.FETCH_FLEETS_FAILURE: {
            return Object.assign({}, state, {
                error: error
            })
        }
        case types.FETCH_FLEETS_SUCCESS: {
            return Object.assign({}, state, {
                error: null,
                lists: items.data.data,
                total: items.data.data.length
            })
        }
        case types.CLEAR_FLEETS: {
            return Object.assign({}, state, {
                error: null,
                lists: [],
                total: 0
            })
        }
        case types.CREATE_FLEET_FAILURE: {
            return Object.assign({}, state, {
                error: error
            })
        }
        case types.CREATE_FLEET_SUCCESS: {
            return Object.assign({}, state, {
                error: null,
                lists: items,
                total: 1
            })
        }
        case types.DELETE_FLEET_FAILURE: {
            return Object.assign({}, state, {
                error: error
            })
        }
        case types.DELETE_FLEET_SUCCESS: {
            selectedFleet = state.lists.find((item) => parseFloat(item.id) === parseFloat(items.data.data))
            data = []
            state.lists.map((item) => {
                if(parseFloat(item.id) !== parseFloat(selectedFleet.id)) {
                    data.push(item)
                }
                return true
            })
            data.sort((a, b) => (a.id > b.id) ? 1 : -1);

            return Object.assign({}, state, {
                error: null,
                lists: data,
                total: data.length
            })
        }
        case types.UPDATE_FLEET_FAILURE: {
            return Object.assign({}, state, {
                error: error
            })
        }
        case types.UPDATE_FLEET_SUCCESS: {
            return Object.assign({}, state, {
                error: null,
                lists: items,
                total: 1
            })
        }
        default:
            return state;
    }
}
