import React from 'react';
import "./styles.scss";

class DetailContent extends React.PureComponent {

    render() {
        return(
            <>
                <div className="detail__container" style={this.props.style}>
                    <div className="detail__container__header">
                        <h1 className="detail__container__header__title">{this.props.header}</h1>

                        {this.props.description && (
                            <p className="detail__container__header__description">{this.props.description}</p>
                        )}
                    </div>

                    {this.props.children}
                </div>
            </>
        )
    }

}

export default (DetailContent);