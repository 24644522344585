// import { Constant } from "common";
import { FirebaseAPI } from "./FirebaseAPI";

export const ContactApi = {
  sendEmail(obj) {
    try {
      // return fetch(`${Constant.API_URL}/contactUs`, {
      //   // return fetch(
      //   //   `https://fbd1-2405-201-5003-f060-697e-7cb7-c032-b6cd.in.ngrok.io/contact-us`,
      //   //   {
      //   method: "POST",
      //   body: JSON.stringify(obj),
      //   headers: {
      //     "Content-Type": "application/json",
      //     // 'Content-Type': 'application/x-www-form-urlencoded',
      //   },
      // })
      return FirebaseAPI.contactFunctions(
        "contactUs",
        obj
      )
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          }

          // var error_message = await response.json().then((json) => {
          //   if (typeof json.message === "object") {
          //     for (const index in json.message) {
          //       return json.message[index];
          //     }
          //   } else {
          //     return json;
          //   }
          // });
          // throw new Error(JSON.stringify(error_message));
        })
        .then((json) => {
          return { status_code: 200, ...json };
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      return { status_code: 500, ...error };
    }
  },
};
