import { Constant } from "common";

export const LocationAPI = {
  getCountries(token) {
    try {
      return fetch(`${Constant.API_URL}/categories/api/countries`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          }
          var error_message = await response.json().then((json) => {
            if (typeof json.message === "object") {
              for (const index in json.message) {
                return json.message[index];
              }
            } else {
              return json;
            }
          });
          throw new Error(JSON.stringify(error_message));
        })
        .then((json) => {
          return { status_code: 200, ...json };
        })
        .catch((error) => {
          return { status_code: 500, ...error };
        });
    } catch (error) {
      return { status_code: 500, ...error };
    }
  },

  getCities(token, state_code = "") {
    try {
      let url = "";
      if (state_code) {
        url = `${Constant.API_URL}/categories/api/cities?state_code=${state_code}`;
      } else {
        url = `${Constant.API_URL}/categories/api/cities?state_code`;
      }
      return fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          }
          var error_message = await response.json().then((json) => {
            if (typeof json.message === "object") {
              for (const index in json.message) {
                return json.message[index];
              }
            } else {
              return json;
            }
          });
          throw new Error(JSON.stringify(error_message));
        })
        .then((json) => {
          return { status_code: 200, ...json };
        })
        .catch((error) => {
          return { status_code: 500, ...error };
        });
    } catch (error) {
      return { status_code: 500, ...error };
    }
  },

  getStates(token, country_id = "") {
    try {
      let url = "";
      if (country_id) {
        url = `${Constant.API_URL}/categories/api/states?country_id=${country_id}`;
      } else {
        url = `${Constant.API_URL}/categories/api/states`;
      }
      return fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          }
          var error_message = await response.json().then((json) => {
            if (typeof json.message === "object") {
              for (const index in json.message) {
                return json.message[index];
              }
            } else {
              return json;
            }
          });
          throw new Error(JSON.stringify(error_message));
        })
        .then((json) => {
          return { status_code: 200, ...json };
        })
        .catch((error) => {
          return { status_code: 500, ...error };
        });
    } catch (error) {
      return { status_code: 500, ...error };
    }
  },
};
