import React from "react";
import { connect } from "react-redux";
import {
  AuctionsContainer,
  ConsignmentsContainer,
  ProfileContainer,
  ShipmentsContainer,
  InvoiceContainer,
} from "container";
import { Tools } from "common";

class DashboardUserContainer extends React.PureComponent {
  render() {
    var { activeMenu, history } = this.props;

    if (
      activeMenu === "profile" ||
      Tools.getParamFromURL(history, "screen") === "profile" ||
      Tools.getParamFromURL(history, "child") === "profile"
    ) {
      return <ProfileContainer {...this.props} />;
    } else if (
      (activeMenu === "consignment" ||
        Tools.getParamFromURL(history, "screen") === "consignment" ||
        Tools.getParamFromURL(history, "child") === "consignment") &&
      Tools.isProfileCompleted()
    ) {
      return <ConsignmentsContainer {...this.props} />;
    } else if (
      (activeMenu === "auction" ||
        Tools.getParamFromURL(history, "screen") === "auction" ||
        Tools.getParamFromURL(history, "child") === "auction") &&
      Tools.isProfileCompleted()
    ) {
      return <AuctionsContainer {...this.props} />;
    } else if (
      activeMenu === "activity" ||
      Tools.getParamFromURL(history, "screen") === "activity" ||
      Tools.getParamFromURL(history, "child") === "activity"
    ) {
      return <ShipmentsContainer {...this.props} />;
    } else if (
      activeMenu === "invoice" ||
      Tools.getParamFromURL(history, "screen") === "invoice" ||
      Tools.getParamFromURL(history, "child") === "invoice"
    ) {
      return <InvoiceContainer {...this.props} />;
    } else {
      return <ProfileContainer {...this.props} />;
    }
  }
}

const mapsStateToProps = ({ navigation }) => {
  return {
    activeMenu: navigation.activeMenu,
  };
};

export default connect(mapsStateToProps)(DashboardUserContainer);
