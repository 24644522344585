import React from 'react';
import { CreateFleetCategoriesContainer } from 'container';

class CreateFleetCategories extends React.PureComponent {

    render() {
        return(
            <CreateFleetCategoriesContainer
                {...this.props}
            />
        )
    }

}

export default (CreateFleetCategories);