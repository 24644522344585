import React from "react";

import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Tools } from "common";
import { Redirect } from "react-router-dom";
import {
  DashboardAdminContainer,
  DashboardDeliveryBoyContainer,
  DashboardPartnerContainer,
  DashboardUserContainer,
  RejectedPartnerContainer,
  UnconfirmedPartnerContainer,
} from "container";

const Dashboard = (props) => {
  const history = useHistory();

  console.log("props.userInfo", props.userInfo);

  const { role } = props.userInfo;

  localStorage.removeItem("@registrationData");

  if (Object.keys(props.userInfo).length > 0) {
    if (role === "partner") {
      const { is_confirmed, notes } = props.userInfo.partner;

      if (!is_confirmed && !Tools.isNotEmpty(notes)) {
        return (
          <>
            <Redirect to="/dashboard?status=unconfirmed" />
            <UnconfirmedPartnerContainer {...props} />
          </>
        );
      } else if (!is_confirmed && Tools.isNotEmpty(notes)) {
        return (
          <>
            <Redirect to="/dashboard?status=rejected" />
            <RejectedPartnerContainer {...props} />
          </>
        );
      } else {
        return <DashboardPartnerContainer {...props} />;
      }
    } else if (role === "admin" || role === "officer") {
      return <DashboardAdminContainer {...props} />;
    } else if (role === "user") {
      return <DashboardUserContainer {...props} />;
    } else if (role === "delivery_boy") {
      return <DashboardDeliveryBoyContainer {...props} />;
    }
  } else {
    return history.go(-1);
  }
};

const mapsStateToProps = ({ authenticated }) => {
  return {
    userInfo: authenticated.userInfo,
  };
};

export default connect(mapsStateToProps)(Dashboard);
