import React from 'react';


class FormGroup extends React.PureComponent {

    render() {
        return(
            <div 
                className={`form__group`}
            >
                {this.props.children}
            </div>
        )
    }

}

export default (FormGroup);