import React from "react";
import { connect } from "react-redux";
import {
  Button,
  FormBody,
  FormWrapper,
  Input,
  ImageUploader,
  ButtonGroup,
} from "components";
import { Images, Tools, Constant, Languages, navigate } from "common";
import { FirebaseAPI } from "services";

var validation = {
  name: "required",
  mobile_prefix: "required",
  mobile_number: "mobile_number",
  image: "required",
};

class CreateDeliveryBoyContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        mobile_prefix : Constant.COUNTRY_CODE.default
      },
      errors: [],
      nameError: false,
      imageError: false,
      mobileError: false,
    };
  }

  onChange = (target, value) => {
    this.setState({
      send: false,
      data: {
        ...this.state.data,
        [target]: value,
      },
    });
  };

  onImageChange = (event) => {
    const reader = new FileReader();
    var file = event.target.files[0];

    let local = this;
    reader.onload = function (event) {
      local.setState({
        data: {
          ...local.state.data,
          image: {
            file: event.target.result,
            name: file.name,
            value: event.target.result,
          },
        },
      });
    };

    reader.readAsDataURL(file);
  };

  validation = async () => {
    var validate = await Tools.validation(validation, this.state.data);
    this.setState({
      valid: validate.valid,
      send: true,
      errors: validate.errors,
    });

    return validate.valid;
  };

  onAdd = async () => {
    this.setState({
      send: true,
    });

    var valid = await this.validation();
    var is_unique_account = await this.isAccountUnique();

    if (valid && is_unique_account) {
      var params = this.state.data;
      const { user, toastPending, createDeliveryBoy, token, history } =
        this.props;
      var data = {
        firstname: params.name,
        lastname: "",
        mobile_prefix: params.mobile_prefix.value,
        mobile_number: params.mobile_number.replace(/ /g, ""),
        provider: "saytum",
        file: params.image.value,
        partner_id: user.partner.id,
        phone:
          params.mobile_prefix.value + params.mobile_number.replace(/ /g, ""),
      };
      var json = await createDeliveryBoy(token, data);
      if (json.status_code === 200) {
        navigate(history, "/dashboard?screen=master_data&child=worker");
      }
      toastPending({ message: json.message, toastType: json.status_code });
    }
  };

  isAccountUnique = async () => {
    const { fetchPending, fetchFinish, toastPending } = this.props;

    fetchPending();
    const { mobile_prefix, mobile_number } = this.state.data;

    var phone = mobile_prefix.value + mobile_number.replace(/ /g, "");

    var param = { phone: phone.replace('+',''), role: "delivery_boy" };
    var is_unique_account = await FirebaseAPI.callFunctions(
      "isUniqueAccount",
      param
    );

    if (is_unique_account.status_code === 200) {
      fetchFinish();
      return true;
    }

    var message = is_unique_account.message;
    if (is_unique_account.status === "app/account-registered") {
      message = Languages.registration.credintial_registered("Mobile Number");
    }

    fetchFinish();
    toastPending({ message: message, toastType: 500 });
    return false;
  };

  onBlurValidation = (target) => {
    if (target === "name") {
      if (!Tools.isNotEmpty(this.state.data.name)) {
        this.setState({
          ...this.state,
          nameError: true,
        });
      } else {
        this.setState({
          ...this.state,
          nameError: false,
        });
      }
    }
    if (target === "image") {
      if (!Tools.isNotEmpty(this.state.data.image)) {
        this.setState({
          ...this.state,
          imageError: true,
        });
      } else {
        this.setState({
          ...this.state,
          imageError: false,
        });
      }
    }
    if (target === "mobile") {
      if (
        !Tools.isNotEmpty(this.state.data.mobile_prefix) ||
        !Tools.isNotEmpty(this.state.data.mobile_number)
      ) {
        this.setState({
          ...this.state,
          mobileError: true,
        });
      } else {
        this.setState({
          ...this.state,
          mobileError: false,
        });
      }
    }
  };

  render() {
    const { data } = this.state;
    const { innerWidth } = window;

    return (
      <div
        className="container"
        style={
          innerWidth > 768
            ? { marginTop: 50, marginBottom: 50 }
            : { marginTop: 15, marginBottom: 15 }
        }
      >
        <FormWrapper>
          <FormBody>
            <h1
              className="text__verification__header"
              style={{ marginBottom: 0 }}
            >
              Add Worker
            </h1>
            <h3
              className="text__verification__body"
              style={{ marginBottom: 30 }}
            >
              Create your new worker master
            </h3>
            <Input
              type={"text"}
              iconPrefix={Images.user}
              label="Name"
              placeholder="Please type here"
              style={{ margin: 0, marginBottom: 30 }}
              caption={this.state.nameError && "This field is required"}
              value={data.name}
              isError={
                (this.state.send &&
                  !this.state.valid &&
                  this.state.errors.includes("name")) ||
                this.state.nameError
              }
              onBlur={() => this.onBlurValidation("name")}
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  nameError: false,
                });
                this.onChange("name", e.target.value);
              }}
              isRequired={true}
            />
            <Input
              type="phone"
              phoneCodes={Constant.COUNTRY_CODE.options}
              onSelect={(e) => this.onChange("mobile_prefix", e, "partner")}
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  mobileError: false,
                });
                this.onChange("mobile_number", e.target.value, "partner");
              }}
              onBlur={() => this.onBlurValidation("mobile")}
              value={data.mobile_number}
              selectedPrefix={data.mobile_prefix}
              iconPrefix={Images.form_phone}
              style={{ margin: 0, marginBottom: 25 }}
              label="Mobile Number"
              caption={
                this.state.mobileError
                  ? "This field is required"
                  : !Tools.isMobileNumberValid(
                      data.mobile_prefix,
                      data.mobile_number
                    )
                  ? "Mobile number should be " +
                    data.mobile_prefix?.length +
                    " number"
                  : null
              }
              isError={
                (this.state.send &&
                  !this.state.valid &&
                  (this.state.errors.includes("mobile_prefix") ||
                    this.state.errors.includes("mobile_number"))) ||
                this.state.mobileError
              }
              isRequired={true}
            />
            <ImageUploader
              value={data.image ?? null}
              label="Image"
              style={{ margin: 0, marginBottom: 25 }}
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  imageError: false,
                });
                this.onImageChange(e);
              }}
              onBlur={() => this.onBlurValidation("image")}
              caption={this.state.imageError && "This field is required"}
              isError={
                (this.state.send &&
                  !this.state.valid &&
                  this.state.errors.includes("image")) ||
                this.state.imageError
              }
              isRequired={true}
            />

            <ButtonGroup position="right">
              <Button
                buttonText="Cancel"
                size="sm"
                type="default"
                style={{ margin: 0 }}
                onClick={() => this.props.history.goBack()}
              />
              <Button
                buttonText="Save"
                size="sm"
                type="warning"
                style={{ margin: 0 }}
                onClick={() => this.onAdd()}
              />
            </ButtonGroup>
          </FormBody>
        </FormWrapper>
      </div>
    );
  }
}

const mapsStateToProps = ({ authenticated, fleet_categories }) => {
  return {
    token: authenticated.token,
    categories: fleet_categories.data,
    user: authenticated.userInfo,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/DeliveryBoysRedux");

  return {
    ...ownProps,
    ...stateProps,
    createDeliveryBoy: async (token, body) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await actions.createDeliveryBoy(dispatch, token, body);
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    fetchPending: () => dispatch({ type: "FETCH_PENDING" }),
    fetchFinish: () => dispatch({ type: "FETCH_FINISH" }),
    toastPending: (body) => {
      dispatch({ type: "TOAST_PENDING", ...body });
    },
  };
};

export default connect(
  mapsStateToProps,
  undefined,
  mergeProps
)(CreateDeliveryBoyContainer);
