import React from 'react';
import { RegisterVerificationContainer } from 'container';

class RegisterVerification extends React.PureComponent {

    render() {
        return(
            <RegisterVerificationContainer 
            />
        )
    }

}

export default (RegisterVerification);