import React from "react";
import {
  BrowserRouter,
  Switch
} from "react-router-dom";
import AppRouter from './AppRouter';

import Routes from "./Routes";

class AppNavigation extends React.Component {

    render() {
        return (    
            <BrowserRouter>
                <Switch>
                    {Routes.map((item, index) => (
                        <AppRouter 
                            key={index}
                            path={item.path} 
                            component={item.component}
                            exact 
                            {...item.routeParams}
                        />
                    ))}
                </Switch>
            </BrowserRouter>
        )
    }
}


export default (AppNavigation);