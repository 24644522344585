import React from "react";
import {
  Button,
  ButtonGroup,
  FormWrapper,
  FormHeader,
  FormBody,
  Input,
  FormGroup,
} from "components";
import { Tools } from "common";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";

var validation = {
  material_price: "required",
};

class CreateConsignmentStepFour extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: [],
      materialPriceError: false,
      materialPriceErrorMessage:"",
    };
  }

  UNSAFE_componentWillMount() {
    var data = localStorage.getItem("@createConsignment");

    if (data) {
      data = JSON.parse(data);
      this.setState({
        data: {
          ...this.state.data,
          ...data,
          pickup_date: new Date(data.pickup_date),
        },
      });
    }
  }

  onBlurValidationHandler = (target) => {
    if (target === "materialPrice") {
      if (!Tools.isNotEmpty(this.state.data.material_price)) {
        this.setState({
          ...this.state,
          materialPriceError: true,
          materialPriceErrorMessage: "This field is required",
        });
      }else if(parseFloat(this.state.data.material_price) > 10000){
        this.setState({
          ...this.state,
          materialPriceError: true,
          materialPriceErrorMessage: "We are not accepting such greater goods",
        })
      }
      else {
        this.setState({
          ...this.state,
          materialPriceError: false,
        });
      }
    }
  };

  onChange = (target, value) => {
    if (target === "material_price") {
      const re = /^[0-9\b]+$/;

      if (value === "" || re.test(value)) {
        this.setState({
          send: false,
          data: {
            ...this.state.data,
            material_price: value,
          },
        });
      }
    } else {
      this.setState({
        send: false,
        data: {
          ...this.state.data,
          [target]: value,
        },
      });
    }
  };

  validation = async () => {
    var validate = {};
    validate = await Tools.validation(validation, this.state.data);

    this.setState({
      valid: validate.valid,
      errors: validate.errors,
    });

    return validate.valid;
  };

  onNext = async () => {
    this.setState({
      send: true,
    });

    if (parseFloat(this.state.data.material_price) > 10000) {
      this.setState({
        materialPriceError: true,
        materialPriceErrorMessage: "We are not accepting such greater goods",
      });
      return;
    }

    var valid = await this.validation();

    if (valid) {
      const { data } = this.state;
      var consignment = localStorage.getItem("@createConsignment");
      if (consignment) {
        consignment = JSON.parse(consignment);
        consignment = {
          ...consignment,
          ...data,
        };
      } else {
        consignment = {
          ...data,
        };
      }
      this.saveLocally(consignment);
      localStorage.setItem("@createConsignment", JSON.stringify(consignment));

      this.props.onNext();
    }
  };

  saveLocally = (local) => {
    const { local_consignment, setLocalConsingment } = this.props;
    var local_consignments = local_consignment || [];

    const { data } = this.state;
    var selected_consignments = local_consignments.find(
      (item) => item.id === local.id
    );

    if (selected_consignments) {
      selected_consignments = {
        ...selected_consignments,
        ...data,
      };
      var index = local_consignments
        .map(function (e) {
          return e.id;
        })
        .indexOf(local.id);

      local_consignments[index] = selected_consignments;

      setLocalConsingment(local_consignments);
    }

    return true;
  };

  onBack = () => {
    this.props.onPrevious();
  };

  generateData = () => {
    const data = {
      good_value: this.state.good_value,
      gst_number: this.state.gst_number,
      is_insurance: this.state.is_insurance,
    };
    return data;
  };

  render() {
    const { data } = this.state;
    const { innerWidth } = window;

    return (
      <div
        style={
          innerWidth > 768
            ? { marginTop: 50, marginBottom: 50 }
            : { marginTop: 15, marginBottom: 20 }
        }
      >
        <FormWrapper>
          <FormHeader title="Declarated Good Value" />
          <FormBody>
            <Input
              type="number"
              label="Goods Value"
              placeholder="Please type here"
              style={{ marginBottom: 20 }}
              value={data.material_price}
              min="1"
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  materialPriceError: false,
                });
                this.onChange("material_price", e.target.value);
              }}
              onBlur={() => this.onBlurValidationHandler("materialPrice")}
              caption={
                this.state.materialPriceError && this.state.materialPriceErrorMessage
              }
              isError={
                (this.state.send &&
                  !this.state.valid &&
                  this.state.errors.includes("material_price")) ||
                this.state.materialPriceError
              }
              isRequired={true}
            />
            <Input
              type="text"
              label="Consignor GST - Goods and service tax Number"
              placeholder="Please type here"
              style={{ marginBottom: 20 }}
              value={data.gst_number}
              onChange={(e) => this.onChange("gst_number", e.target.value)}
            />
            {/* <Input
              type="checkbox"
              label="Prompt for insurance"
              info="With insurance 500INR."
              placeholder="Please type here"
              style={{ marginBottom: 20 }}
              value={data.is_insurance}
              onChange={(e) => this.onChange("is_insurance", e)}
            /> */}

            <FormGroup>
              <Col xs={12} style={innerWidth > 768 ? {} : { marginLeft: 20 }}>
                <p className="label">Declarated Goods Value</p>
                <h4 className="text text__md text__success text__bold">
                  {data.material_price || 0} INR
                </h4>
              </Col>
              <Col xs={12}>
                <ButtonGroup position="right">
                  <Button
                    buttonText="Back"
                    type="default"
                    size="sm"
                    onClick={() => this.onBack()}
                  />
                  <Button
                    buttonText="Save"
                    type="success"
                    size="sm"
                    onClick={() => this.onNext()}
                  />
                </ButtonGroup>
              </Col>
            </FormGroup>
          </FormBody>
        </FormWrapper>
      </div>
    );
  }
}

const mapsStateToProps = ({ consignment }) => {
  return {
    local_consignment: consignment.local_data,
  };
};

const mapsDispatchToState = (dispatch) => {
  return {
    setLocalConsingment: (items) =>
      dispatch({ type: "SET_LOCAL_CONSIGNMENT", items }),
  };
};

export default connect(
  mapsStateToProps,
  mapsDispatchToState
)(CreateConsignmentStepFour);
