import React from "react";
import { connect } from "react-redux";
import { Button, FormBody, FormWrapper, Input } from "components";
import { Tools, Images } from "common";

var validation = {
  email: "email",
};

class ForgotPasswordContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        email: "",
      },
      send: false,
      error: false,
      //   errors: [],
    };
  }

  onChange = (target, value) => {
    this.setState({
      send: false,
      data: {
        ...this.state.data,
        [target]: value,
      },
    });
  };

  onBlurValidation = () => {
    if (!Tools.isEmailValid(this.state.data.email)) {
      this.setState({
        ...this.state,
        error: true,
      });
    } else {
      this.setState({
        ...this.state,
        error: false,
      });
    }
  };

  validation = async () => {
    var validate = await Tools.validation(validation, this.state.data);

    this.setState({
      valid: validate.valid,
      //   errors: validate.errors,
      send: true,
    });

    return validate.valid;
  };

  onRequest = async () => {
    var valid = await this.validation();
    if (valid) {
      const { sendResetPasswordLink, toastPending } = this.props;
      var json = await sendResetPasswordLink(this.state.data.email);
      toastPending({ message: json.message, toastType: json.status_code });
    }
  };

  render() {
    return (
      <div
        className="container"
        style={window.innerWidth > 768 ? { marginTop: 100 } : { marginTop: 15 }}
      >
        <FormWrapper>
          <FormBody>
            <h1 className="text__verification__header">Forgot Password</h1>
            <h3
              className="text__verification__body"
              style={
                window.innerWidth > 768
                  ? { marginBottom: 30 }
                  : { marginBottom: 20 }
              }
            >
              Provide your registered email to request reset password
            </h3>
            <Input
              type="email"
              label="Email Address"
              iconPrefix={Images.form_email}
              placeholder="example@email.com"
              style={{ margin: 0, marginBottom: 30 }}
              caption={this.state.error && "Provide correct email format"}
              value={this.state.data.email}
              onBlur={this.onBlurValidation}
              isError={
                (this.state.send && !this.state.valid) || this.state.error
              }
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  error: false,
                });
                this.onChange("email", e.target.value);
              }}
              isRequired={true}
            />
            <Button
              buttonText="Request Reset Password Link"
              size="lg"
              type="warning"
              style={{ margin: 0 }}
              onClick={() => this.onRequest()}
            />
          </FormBody>
        </FormWrapper>
      </div>
    );
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/AuthenticatedRedux");

  return {
    ...ownProps,
    ...stateProps,
    sendResetPasswordLink: async (email) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await actions.sendResetPasswordLink(dispatch, email);
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    toastPending: (body) => {
      dispatch({ type: "TOAST_PENDING", ...body });
    },
  };
};

export default connect(
  undefined,
  undefined,
  mergeProps
)(ForgotPasswordContainer);
