import React from 'react';
import { connect } from 'react-redux';
import { ProfileContainer, ShipmentsContainer } from 'container';
import { Tools } from 'common';

class DashboardDeliveryBoyContainer extends React.PureComponent {
    
    render() {

        var { activeMenu, history } = this.props;

        if(
            activeMenu === "profile" || 
            Tools.getParamFromURL(history, "screen") === "profile" || 
            Tools.getParamFromURL(history, "child") === "profile"
        ) {
            return (
                <ProfileContainer 
                    {...this.props} 
                />
            )
        }
        else if(
            activeMenu === "task" || 
            Tools.getParamFromURL(history, "screen") === "task" || 
            Tools.getParamFromURL(history, "child") === "task"
        ) {
            return (
                <ShipmentsContainer 
                    {...this.props} 
                />
            )
        }
        else {
            return (
                <ProfileContainer 
                    {...this.props} 
                />
            )
        }
    }

}


const mapsStateToProps = ({ authenticated, navigation }) => {
    return{
        token: authenticated.token,
        activeMenu: navigation.activeMenu
    } 
}

export default connect(mapsStateToProps)(DashboardDeliveryBoyContainer);