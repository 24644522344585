import React from 'react';
import { RegisterContainer } from 'container';
import { connect } from 'react-redux';
import { Tools } from 'common';
import { FirebaseAPI } from 'services/FirebaseAPI';

class ResubmitRegisterContainer extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            step: 1
        }
    }

    UNSAFE_componentWillMount() {
        this.initialData();
    }

    initialData = async() => {
        const { 
            user, 
            fetchPending,
            fetchFinish
        } = this.props;
        const { partner } = user;

        fetchPending()
        var data = {
            is_document_image_changed: false,
            accountType: { value: "partner", label: "Saytum Service Provider" },
            account_type: partner.is_company ? { value: "company", label: "Company"  } : { value: "personal", label: "Personal" },
            name: partner.name,
            email: partner.email,
            password: null,
            address: partner.address,
            mobile_prefix: { value: partner.mobile_prefix, label: partner.mobile_prefix },
            mobile_number: partner.mobile_number
        }

        if(!partner.is_company) {
            data = {
                ...data,
                document_type: Tools.isNotEmpty(partner.driving_licence_number) ?
                                { value: "license", label: "Driving License" }
                            : Tools.isNotEmpty(partner.passport_number) ?
                                { value: "passport", label: "Passport" }
                            :
                                { value: "aadhaar", label: "Aadhaar" },
                document_number: Tools.isNotEmpty(partner.driving_licence_number) ?
                                    partner.driving_licence_number
                                : Tools.isNotEmpty(partner.passport_number) ?
                                    partner.passport_number
                                :
                                    partner.national_identity_number,
                document_image : Tools.isNotEmpty(partner.driving_licence_number) ?
                                    { file: await FirebaseAPI.getStorageFile(partner.driving_licence_image), name: partner.driving_licence_image, value: await FirebaseAPI.getStorageFile(partner.driving_licence_image) }
                                : Tools.isNotEmpty(partner.passport_number) ?
                                    { file: await FirebaseAPI.getStorageFile(partner.passport_image), name: partner.passport_image, value: await FirebaseAPI.getStorageFile(partner.passport_image) }
                                :
                                    { file: await FirebaseAPI.getStorageFile(partner.national_identity_image), name: partner.national_identity_image, value: await FirebaseAPI.getStorageFile(partner.national_identity_image) },
                pan_number: partner.pan_number
            }
        }
        else {
            data = {
                ...data,
                company_registration_number: partner.company_registration_number,
                gst_number: partner.gst_number
            }
        }

        await localStorage.setItem("@registrationData", JSON.stringify(data));

        this.setState({
            data
        });

        fetchFinish()
    }

    render() {
        const { data } = this.state;

        if(data !== null) {
            return(
                <RegisterContainer 
                    isResubmit
                    type="edit-profile"
                    {...this.props}
                />
            )
        }

        return (
            <>

            </>
        )
    }

}

const mapsStateToProps = ({ authenticated }) => {
    return {
        user: authenticated.userInfo,
        token: authenticated.token      
    }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;

    return {
      ...ownProps,
      ...stateProps,
      fetchPending: () => dispatch({ type: "FETCH_PENDING" }),
      fetchFinish: () => dispatch({ type: "FETCH_FINISH" }),
      toastPending: (body) => {
        dispatch({ type: "TOAST_PENDING", ...body })
      }
    };
}

export default connect(
    mapsStateToProps,
    undefined,
    mergeProps
)(ResubmitRegisterContainer);