
const type = {
    SET_NOTIFICATION_LIST: "SET_NOTIFICATION_LIST"
}

const initialState = {
    error: null,
    lists: [],
    total: 0
}

export const reducer = (state = initialState, action) => {

    const { items } = action;

    switch (action.type) {
        case type.SET_NOTIFICATION_LIST:
            return {
                ...state,
                error: null,
                lists: items,
                total: items.length
            }
        default:
            return state;
    }
}
