import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import "./dropdown.scss";

class DropdownType extends React.PureComponent {
  render() {
    const { options, label, isRequired } = this.props;
    return (
      <div className="dropdown" style={this.props.style}>
        <label className={`dropdown__label`}>
          {label}
          {isRequired && <span className="input__required">*</span>}
        </label>
        <Select
          classNamePrefix={`dropdown__select`}
          options={options}
          onChange={(e) => this.props.onChange(e)}
          onBlur={(e) => this.props.onBlur(e)}
          value={this.props.value}
          {...this.props.selectProps}
        />
      </div>
    );
  }
}

DropdownType.propTypes = {
  options: PropTypes.element.isRequired,
};

export default DropdownType;
