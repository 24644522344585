
import Login from "./Login";
import Home from "./Home";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from './ResetPassword';
import EditProfile from "./EditProfile";
import JoinPartner from "./JoinPartner";
import Register from "./Register";
import RegisterVerification from "./RegisterVerification";
import ResubmitRegister from "./ResubmitRegister";
import CreateFleetCategories from "./CreateFleetCategories";
import EditFleetCategories from "./EditFleetCategories";
import CreateMaterialCategories from "./CreateMaterialCategories";
import EditMaterialCategories from "./EditMaterialCategories";
import CreateOfficer from "./CreateOfficer";
import Dashboard from "./Dashboard";
import CreateDeliveryBoy from "./CreateDeliveryBoy";
import CreateFleet from "./CreateFleet";
import EditFleet from "./EditFleet";
import CreateConsignment from "./CreateConsignment";
import EditConsignment from "./EditConsignment";
import AuctionDetails from "./AuctionDetails";
import Verification from './Verification';
import ShipmentDetail from "./ShipmentDetail";
import CreateRefund from "./CreateRefund";
import InvoicePage from "./Invoice";
import { PrivacyPolicy, TermsAndConditions } from "container";
import Faq from "container/Faq/index";
import ContactUs from "container/ContactUs";
import Tracking from "../container/Shipments/Tracking/Tracking";


const Routes = [
    {
        "name": "login",
        "path": "/login",
        "component": Login
    },
    {
        name: "termsAndConditions",
        path: "/terms-and-conditions",
        component: TermsAndConditions,
      },
      {
        name: "faq",
        path: "/faq",
        component: Faq,
      },
      {
        name: "privacyPolicyy",
        path: "/privacy-policy",
        component: PrivacyPolicy,
      },
      {
        name: "contactUs",
        path: "/contact-us",
        component: ContactUs,
    },
    {
        name: "tracking",
        path: "/tracking",
        component: Tracking,
    },
    {
        "name": "dashboard",
        "path": "/dashboard",
        "component": Dashboard,
        "routeParams": {
            mustLogin: true
        }
    },
    {
        "name": "home",
        "path": "/",
        "component": Home
    },
    {
        "name": "register",
        "path": "/register",
        "component": Register
    },
    {
        "name": "register-verification",
        "path": "/verify_email",
        "component": Verification
    },
    {
        "name": "verification",
        "path": "/verification",
        "component": RegisterVerification
    },
    {
        "name": "forgot-password",
        "path": "/forgot-password",
        "component": ForgotPassword
    },
    {
        "name": "reset-password",
        "path": "/reset-password",
        "component": ResetPassword
    },
    {
        "name": "resubmit-registration",
        "path": "/resubmit-registration",
        "component": ResubmitRegister
    },
    {
        "name": "create-officer",
        "path": "/officer/create",
        "component": CreateOfficer,
        "routeParams": {
            mustLogin: true
        }
    },
    {
        "name": "create-material-categories",
        "path": "/material-categories/create",
        "component": CreateMaterialCategories,
        "routeParams": {
            mustLogin: true
        }
    },
    {
        "name": "edit-material-categories",
        "path": "/material-categories/edit/:categoryId",
        "component": EditMaterialCategories,
        "routeParams": {
            mustLogin: true
        }
    },
    {
        "name": "create-fleet-categories",
        "path": "/fleet-categories/create",
        "component": CreateFleetCategories,
        "routeParams": {
            mustLogin: true
        }
    },
    {
        "name": "edit-fleet-categories",
        "path": "/fleet-categories/edit/:categoryId",
        "component": EditFleetCategories,
        "routeParams": {
            mustLogin: true
        }
    },
    {
        "name": "create-fleet",
        "path": "/fleet/create",
        "component": CreateFleet,
        "routeParams": {
            mustLogin: true
        }
    },
    {
        "name": "edit-fleet",
        "path": "/fleet/edit/:id",
        "component": EditFleet,
        "routeParams": {
            mustLogin: true
        }
    },
    {
        "name": "create-delivery-boy",
        "path": "/delivery-boy/create",
        "component": CreateDeliveryBoy,
        "routeParams": {
            mustLogin: true
        }
    },
    {
        "name": "create-consignment",
        "path": "/consignment/create",
        "component": CreateConsignment,
        "routeParams": {
            mustLogin: true
        }
    },
    {
        "name": "edit-consignment",
        "path": "/consignment/edit/:id",
        "component": EditConsignment,
        "routeParams": {
            mustLogin: true
        }
    },
    {
        "name": "auction-detail",
        "path": "/auction/:id",
        "component": AuctionDetails,
        "routeParams": {
            mustLogin: true
        }
    },
    {
        "name": "edit-profile",
        "path": "/profile/edit",
        "component": EditProfile,
        "routeParams": {
            mustLogin: true
        }
    },
    {
        "name": "join-partner",
        "path": "/join-partner",
        "component": JoinPartner,
        "routeParams": {
            mustLogin: true
        }
    },
    {
        "name": "shipment-detail",
        "path": "/shipment/:id",
        "component": ShipmentDetail,
        "routeParams": {
            mustLogin: true
        }
    },
    {
        "name": "create-refund",
        "path": "/refund/create",
        "component": CreateRefund,
        "routeParams": {
            mustLogin: true
        }
    },
    {
        "name": "invoice",
        "path": "/invoice/:id",
        "component": InvoicePage,
        "routeParams": {
            mustLogin: true
        }
    },
    {
        "name": "404",
        "path": "*",
        "component": () => "404 NOT FOUND"
    }
]

export default Routes;

