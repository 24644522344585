import Geocode from "react-geocode";
import { FirebaseAPI } from "services/FirebaseAPI";
import store from "store/configureStore";
import Config from "./Config";
import Constant from "./Constant";
import moment from "moment";

const Tools = {
  isNotEmpty: (param) => {
    if (param !== undefined && param !== null) {
      if (typeof param === "string" && param.length > 0) {
        return true;
      } else if (typeof param === "number") {
        if (param.toString().length > 0) {
          return true;
        }
      } else if (
        param instanceof Date &&
        param !== null &&
        param !== undefined
      ) {
        return true;
      } else if (param instanceof Object && param !== {}) {
        if (typeof param.value !== "undefined") {
          if (Tools.isNotEmpty(param.value)) {
            return true;
          }
        } else {
          return true;
        }
      } else {
        if (typeof param.value === "string" && param.value.length > 0) {
          return true;
        }
      }
    }

    return false;
  },

  isOrderNameValid: (orderName) => {
    const order_name = orderName.trim();
    const format = /^(?!\s)[a-zA-Z0-9\s]*(?<!\s)$/;
    if (!format.test(order_name)) {
      return false;
    }
    return true;
  },

  isNameValid: (name) => {
    // const nameRegex = /^[a-zA-Z]+$/; // Allows only alphabets
    const nameFormat = /^[a-zA-Z]+(?: [a-zA-Z]+)*$/;
    if (!nameFormat.test(name)) {
      return false;
    }
    return true;
  },

  isEmailValid: (param) => {
    var notEmpty = Tools.isNotEmpty(param);
    if (notEmpty) {
      const pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      var valid = pattern.test(String(param).toLowerCase());
      return valid;
    }

    return false;
  },

  isPasswordValid: (param) => {
    var notEmpty = Tools.isNotEmpty(param);
    const passwordFormat = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    if (notEmpty) {
      // var valid = param.toString().length >= 8;
      if(!passwordFormat.test(param)){
        return false
      }
      return true;
    }else{
        return false;
    }
  },

  isPasswordConfirmed: (param1, param2) => {
    var notEmpty = Tools.isNotEmpty(param1);
    var notEmpty2 = Tools.isNotEmpty(param2);
    var passwordValid = Tools.isPasswordValid(param1);
    var passwordValid2 = Tools.isPasswordValid(param2);

    if (notEmpty && notEmpty2 && passwordValid && passwordValid2) {
      var valid = param1.toString() === param2.toString();
      return valid;
    }

    return false;
  },

  isMobileNumberValid: (prefix, param) => {
    var notEmpty = Tools.isNotEmpty(param);
    if (notEmpty) {
      var valid = param.toString().length === prefix.length;
      return valid;
    }
    return false;
  },

  generateRegistrationData: async (params, isResubmit = false) => {
    // var location = await Tools.reverseLocation(params.address);
    var data = {
      firstname: params.name,
      name: params.name,
      // address: params.address,
      // latitude: location.lat,
      // longitude: location.lng,
      mobile_prefix: params.mobile_prefix?.value
        ? params.mobile_prefix?.value
        : params.mobile_prefix,
      mobile_number: params.mobile_number.replace(/ /g, ""),
      email: params.email,
      provider: "saytum",
    };

    if (!isResubmit) {
      data = {
        ...data,
        password: params.password,
      };
    }

    if (params.account_type.value === "personal") {
      data = {
        ...data,
        is_company: 0,
        pan_number: params.pan_number,
      };

      if (params.document_type.value === "aadhaar") {
        data = {
          ...data,
          national_identity_number: params.document_number,
        };

        if (isResubmit) {
          if (params.is_document_image_changed) {
            data = {
              ...data,
              national_identity_image: params.document_image.value,
            };
          }
        } else {
          data = {
            ...data,
            national_identity_image: params.document_image.value,
          };
        }
      } else if (params.document_type.value === "passport") {
        data = {
          ...data,
          passport_number: params.document_number,
        };

        if (isResubmit) {
          if (params.is_document_image_changed) {
            data = {
              ...data,
              passport_image: params.document_image.value,
            };
          }
        } else {
          data = {
            ...data,
            passport_image: params.document_image.value,
          };
        }
      } else {
        data = {
          ...data,
          driving_licence_number: params.document_number,
        };

        if (isResubmit) {
          if (params.is_document_image_changed) {
            data = {
              ...data,
              driving_licence_image: params.document_image.value,
            };
          }
        } else {
          data = {
            ...data,
            driving_licence_image: params.document_image.value,
          };
        }
      }
    } else {
      data = {
        ...data,
        is_company: 1,
        gst_number: params.gst_number,
        company_registration_number: params.company_registration_number,
      };
    }

    return data;
  },

  reverseLocation: async (address_name) => {
    await Geocode.setApiKey(Constant.GOOGLE_PLACE_API_KEY);
    var location = await Geocode.fromAddress(address_name).then((response) => {
      return response.results[0].geometry.location;
    });
    return location;
  },

  formatCoordinate: async (coordinate) => {
    let formattedCoordinate = await null;
    if (coordinate !== null) {
      formattedCoordinate = await coordinate.toFixed(6);
    }

    return formattedCoordinate;
  },

  ensureIsLogged: async () => {
    return FirebaseAPI.ensureTokenIsExpired()
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  },

  getFreshTokenID: async (refreshToken) => {
    var getFreshToken = await FirebaseAPI.generateFreshTokenID(refreshToken);

    store.dispatch({
      type: "SET_TOKEN",
      items: getFreshToken.id_token,
    });
    return getFreshToken.id_token;
  },

  getCurrentValidToken: async () => {
    var state = store.getState();
    const { refreshToken, token } = state.authenticated;
    var isLogged = await Tools.ensureIsLogged();
    if (!isLogged) {
      var fresh_token = await Tools.getFreshTokenID(refreshToken);
      return fresh_token;
    }
    return token;
  },

  isProfileCompleted: () => {
    var state = store.getState();
    const {
      mobile_number,
      mobile_prefix,
      address,
      cities,
      countries,
      states,
      postal_code,
    } = state.authenticated.userInfo;

    if (
      Tools.isNotEmpty(mobile_number) &&
      Tools.isNotEmpty(mobile_prefix) &&
      Tools.isNotEmpty(address) &&
      Tools.isNotEmpty(cities) &&
      Tools.isNotEmpty(countries) &&
      Tools.isNotEmpty(states) &&
      Tools.isNotEmpty(postal_code)
    ) {
      return true;
    }

    return false;
  },

  generateConsignmentStatusFilter: () => {
    var state = store.getState();
    const { status } = state.consignment;

    var consignment_status_filter = [
      { label: "Saved Locally", key: "SAVED LOCALLY" },
    ];
    status.filter((item) => {
      if (item.status_type === "consignment") {
        consignment_status_filter.push({
          label: item?.status_name,
          key: item?.status_name,
        });
      }
      return false;
    });

    return consignment_status_filter;
  },

  generateAuctionStatusFilter: () => {
    var state = store.getState();
    const { status } = state.consignment;

    var auction_status_filter = [];
    status.filter((item) => {
      if (item.status_type === "auction") {
        auction_status_filter.push({
          label: item?.status_name,
          key: item?.status_name,
        });
      }
      return false;
    });

    return auction_status_filter;
  },

  generateMaterialCategoriesFilter: () => {
    var state = store.getState();
    const { lists } = state.material_categories;

    var material_categories_filter = [];
    lists.map((item) => {
      material_categories_filter.push({ label: item.name, key: item.name });
      return false;
    });

    return material_categories_filter;
  },

  generateFleetCategoriesFilter: () => {
    var state = store.getState();
    const { lists } = state.fleet_categories;

    var fleet_categories_filter = [];
    lists.map((item) => {
      fleet_categories_filter.push({ label: item.name, key: item.name });
      return false;
    });

    return fleet_categories_filter;
  },

  generateShipmentStatusFilter: () => {
    var state = store.getState();
    var { status } = state.consignment;

    var shipment_status = status.filter(
      (item) => item.status_type === "shipment"
    );
    var shipment_status_filter = [];
    shipment_status.map((item) => {
      shipment_status_filter.push({ label: item.status_name, key: item.id });
      return false;
    });

    return shipment_status_filter;
  },

  validation: (field, value) => {
    var valid = true;
    var errors = [];
    var validate = false;

    for (var validation_key in field) {
      var val = value[validation_key];
      if (field[validation_key] === "required") {
        validate = Tools.isNotEmpty(val);
      } else if (field[validation_key] === "email") {
        validate = Tools.isEmailValid(val);
      } else if (field[validation_key] === "password") {
        validate = Tools.isPasswordValid(val);
      } else if (field[validation_key] === "valid") {
        validate = val;
      } else if (field[validation_key] === "mobile_number") {
        var mobile_prefix = value["mobile_prefix"];
        if (Tools.isNotEmpty(mobile_prefix)) {
          validate = Tools.isMobileNumberValid(mobile_prefix, val);
        }
      } else if (field[validation_key] === "password_confirmation") {
        validate = Tools.isPasswordConfirmed(value["password"], val);
      }

      if (!validate) {
        errors.push(validation_key);
      }
    }

    if (errors.length > 0) {
      valid = false;
    }

    return { valid, errors };
  },

  getCountryDetail: (name) => {
    var data = [
      {
        id: 101,
        name: "India",
        iso3: "IND",
        iso2: "IN",
        numeric_code: "356",
        phone_code: "91",
        capital: "New Delhi",
        currency: "INR",
        currency_symbol: "₹",
        tld: ".in",
        native: "भारत",
        region: "Asia",
        subregion: "Southern Asia",
        timezones: [
          {
            zoneName: "Asia/Kolkata",
            gmtOffset: 19800,
            gmtOffsetName: "UTC+05:30",
            abbreviation: "IST",
            tzName: "Indian Standard Time",
          },
        ],
        translations: {
          kr: "인도",
          br: "Índia",
          pt: "Índia",
          nl: "India",
          hr: "Indija",
          fa: "هند",
          de: "Indien",
          es: "India",
          fr: "Inde",
          ja: "インド",
          it: "India",
          cn: "印度",
        },
        latitude: "20.00000000",
        longitude: "77.00000000",
        emoji: "🇮🇳",
        emojiU: "U+1F1EE U+1F1F3",
      },
    ];

    var result = data.find(
      (item) => item.name.toLowerCase() === name.toLowerCase()
    );

    return result;
  },

  getNamePhoto: (name) => {
    var result = "";
    if (Tools.isNotEmpty(name)) {
      var target = name.split(" ");
      for (let index = 0; index < target.length; index++) {
        if (result.length < 2) {
          result += target[index][0] === undefined ? '' : target[index][0];
        }
      }
    }
    return result;
  },

  isProfileApproved: () => {
    var state = store.getState();
    const { is_confirmed } = state.authenticated.userInfo.partner;

    if (is_confirmed) {
      return true;
    }

    return false;
  },

  generateLocalID: () => {
    var state = store.getState();
    const { id } = state.authenticated.userInfo;

    var result = id + "-local-" + moment().format("X");
    return result;
  },

  getParamFromURL: (history, keyword) => {
    var params = history.location.search;
    var search = new URLSearchParams(params);

    return search.get(keyword);
  },
};

export const navigate = (history, page, historyType = "push") => {
  var state = store.getState();
  const { userInfo } = state.authenticated;

  if (userInfo.role === "user") {
    if (Config.profileCompletedRequired.includes(page)) {
      if (Tools.isProfileCompleted()) {
        if (historyType === "replace") {
          history.replace(page);
        } else {
          history.push(page);
        }
      }
    } else {
      if (historyType === "replace") {
        history.replace(page);
      } else {
        history.push(page);
      }
    }
  } else {
    if (historyType === "replace") {
      history.replace(page);
    } else {
      history.push(page);
    }
  }

  store.dispatch({
    type: "SET_NAVIGATION",
    items: page,
  });
};

export default Tools;
