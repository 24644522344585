import React from "react";
import { Table, Confirmation, Button } from "components";
import { connect } from "react-redux";
// import { DropdownButton, Dropdown } from "react-bootstrap";
import moment from "moment";
import { Tools, Images } from "common";
import Workers from "services/Workers";
import { FaDownload } from "react-icons/fa";
class AuctionsContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      intervalID: "",
      confirmation: {
        isVisible: false,
        header: "",
        description: "",
        onCancel: null,
        onConfirm: null,
      },
    };
  }

  componentDidMount() {
    this.fetchData();
    var intervalID = setInterval(() => {
      this.fetchData();
    }, 60000);
    this.setState({ intervalID: intervalID });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalID);
  }

  fetchData = async () => {
    const {
      clearAuction,
      fetchAuctions,
      token,
      user,
      fetchFleetCategories,
      fetchMaterialCategories,
      fetchConsignmentStatus,
      fetchPending,
      fetchFinish,
    } = this.props;
    var id = user.id;
    if (user.role === "partner") id = user.partner.id;
    fetchPending();
    clearAuction();
    await fetchAuctions(token, user.role, id);
    await fetchFleetCategories(token);
    await fetchMaterialCategories(token);
    await fetchConsignmentStatus(token);
    fetchFinish();
  };

  openConfirmation = (headerText, descriptionText, onCancel, onConfirm) => {
    this.setState({
      confirmation: {
        isVisible: true,
        header: headerText,
        description: descriptionText,
        onCancel: onCancel,
        onConfirm: onConfirm,
      },
    });
  };

  onCancelConfirmation = () => {
    this.setState({
      confirmation: {
        isVisible: false,
      },
    });
  };

  onPublish = async (params) => {
    this.onCancelConfirmation();

    this.props.SET_PROCESS({
      isVisible: true,
      label: "Publishing consignment",
    });

    await Workers.publishConsignment(params);

    this.props.RESET_PROCESS();
  };

  onSearch = (keyword) => {
    const { auctions } = this.props;

    var filteredData = auctions.filter((item) =>
      item.order_name.toLowerCase().includes(keyword.toLowerCase())
    );

    this.setState({
      keyword: keyword,
      filteredData: filteredData,
    });
  };

  onFilter = async (filters) => {
    var filterDate = await this.filterDate(filters);
    var filterStatus = await this.filterStatus(filterDate, filters);
    var filterFleetCategories = await this.filterFleetCategories(
      filterStatus,
      filters
    );
    var filterMaterialCategories = await this.filterMaterialCategories(
      filterFleetCategories,
      filters
    );
    var finalData = filterMaterialCategories;
    var filteredData = finalData;

    this.setState({
      isFiltering: false,
      keyword: "filter",
      filteredData: filteredData,
    });
  };

  filterDate = (filters) => {
    const { auctions } = this.props;

    var isFilterDate = filters.filter((item) => item.target === "created_date");
    if (isFilterDate.length > 0) {
      var filteredData = [];
      var date_from = filters.find(
        (item) => item.target === "created_date" && item.key === "date_from"
      );
      var date_to = filters.find(
        (item) => item.target === "created_date" && item.key === "date_to"
      );
      if (date_to) {
        var range = moment.range(date_from.value, date_to.value);

        filteredData = auctions.filter((item) => {
          if (moment(item.created_at).within(range)) {
            return item;
          }
          return false;
        });
      } else {
        filteredData = auctions.filter((item) => {
          var created_at = moment(item.created_at).format("YYYY-MM-DD");
          var filterDate = moment(date_from.value).format("YYYY-MM-DD");

          if (moment(created_at).isSame(filterDate)) {
            return item;
          }
          return false;
        });
      }

      return filteredData;
    }

    return auctions;
  };

  filterStatus = async (data, filters) => {
    const { status } = this.props;

    var filterStatus = filters.filter((item) => item.target === "status");
    if (filterStatus.length > 0) {
      var filteredData = [];
      filterStatus.map((filter_item) => {
        var selectedStatus = status.find(
          (item) => item?.status_name === filter_item.key
        );
        data.map((item) => {
          if (
            parseFloat(item.auction_status) ===
            parseFloat(selectedStatus.status_code)
          ) {
            filteredData.push(item);
          }
          return false;
        });
        return false;
      });

      return filteredData;
    }

    return data;
  };

  filterFleetCategories = async (data, filters) => {
    const { fleet_categories } = this.props;
    var filterCategories = filters.filter(
      (item) => item.target === "fleet_categories"
    );
    if (filterCategories.length > 0) {
      var filteredData = [];
      filterCategories.map((filter_item) => {
        var selectedCategories = fleet_categories.find(
          (item) => item.name === filter_item.key
        );
        data.map((item) => {
          if (
            parseFloat(item.fleet_category_id) ===
            parseFloat(selectedCategories.id)
          ) {
            filteredData.push(item);
          }
          return false;
        });
        return false;
      });

      return filteredData;
    }

    return data;
  };

  filterMaterialCategories = async (data, filters) => {
    const { material_categories } = this.props;
    var filterCategories = filters.filter(
      (item) => item.target === "material_categories"
    );
    if (filterCategories.length > 0) {
      var filteredData = [];
      filterCategories.map((filter_item) => {
        var selectedCategories = material_categories.find(
          (item) => item.name === filter_item.key
        );
        data.map((item) => {
          if (
            parseFloat(item.material_category_id) ===
            parseFloat(selectedCategories.id)
          ) {
            filteredData.push(item);
          }
          return false;
        });
        return false;
      });

      return this.filterData;
    }

    return data;
  };

  render() {
    const { auctions, user, token } = this.props;
    const { confirmation, keyword, filteredData } = this.state;

    var columns = [
      {
        Header: "No",
        id: "no",
        accessor: (props, index) => (
          <div className="table__body__row__column__text-style">
            {index + 1}
          </div>
        ),
      },
      {
        Header: "Order ID",
        id: "order_id",
        accessor: (props, index) => (
          <div className="table__body__row__column__text-style">
            {props.order_id}
          </div>
        ),
      },
      {
        Header: "Order Name",
        id: "order_name",
        accessor: (props, index) => {
          // console.log("props from the table -----------------------", props);
          return (
            <div className="table__body__row__column__text-style">
              {props.order_name}
            </div>
          );
        },
      },
      {
        Header: "Pickup Address",
        id: "pickup_address",
        accessor: (props) => {
          return (
            <div className="table__body__row__column__text-style">
              {props.pickup_address}
            </div>
          );
        },
      },
      {
        Header: "Delivery Address",
        id: "delivery_address",
        accessor: (props) => {
          return (
            <div className="table__body__row__column__text-style">
              {props.delivery_address}
            </div>
          );
        },
      },
      {
        Header: "Published Date",
        // accessor: "auction_created_date",
        id: "created_at",
        // Cell: (props) => {
        accessor: (props) => {
          console.log("published_date" , moment(props.created_at).format("DD MMM YYYY"));
          console.log("invoices_data" , props);
          return (
            <div className="table__body__row__column__text-style">
              {moment(props.created_at).format("DD MMM YYYY")}
            </div>
          );
        },
      },
      // {
      //   Header: "Fleet Category",
      //   id: "fleet_category",
      //   accessor: (props) => {
      //     const { fleet_categories } = this.props;
      //     var category = fleet_categories.find(
      //       (item) =>
      //         parseFloat(item.id) === parseFloat(props.fleet_category_id)
      //     );
      //     return (
      //       <div className="table__body__row__column__text-style">
      //         {category ? category.name : ""}
      //       </div>
      //     );
      //   },
      // },
      // {
      //   Header: "Material Category",
      //   id: "material_category",
      //   accessor: (props) => {
      //     const { material_categories } = this.props;
      //     var category = material_categories.find(
      //       (item) =>
      //         parseFloat(item.id) === parseFloat(props.material_category_id)
      //     );
      //     return (
      //       <div className="table__body__row__column__text-style">
      //         {category ? category.name : ""}
      //       </div>
      //     );
      //   },
      // },
      {
        Header: "Amount",
        id: "amount",
        accessor: (props) => {
          return (
            <div className="table__body__row__column__text-style">
              {Tools.isNotEmpty(props.amount) ? `INR ${props.amount}` : 0}
            </div>
          );
        },
      },
      {
        Header: "Action",
        id: "action",
        accessor: (props) => {
          return (
            // <a
            //   className="invoice_download"
            //   href={props.invoice_pdf}
            //   target="#"
            //   style={{ color: "#4592C5" }}
            // >
            //   <FaDownload size={20} />
            // </a>
            <button onClick={async() => {
              const {handleDownload} = this.props;
              // console.log("props id token", props.order_id, token, "function", handleDownload);
              await handleDownload(props.invoice , token)
            }}>
              <FaDownload size={20} />
            </button>
            // <div className="table__body__row__column__text-style">
            //   {Tools.isNotEmpty(props.amount) ? `INR ${props.amount}` : 0}
            // </div>
          );
        },
      },
      // {
      //   Header: "Bid Status",
      //   id: "bid_status",
      //   accessor: (props) => {
      //     var bid_status = {};
      //     if (Tools.isNotEmpty(props.bid_status)) {
      //       bid_status = status.find(
      //         (item) =>
      //           parseFloat(item.status_code) === parseFloat(props.bid_status)
      //       );
      //     }
      //     return (
      //       <div
      //         className={`table__body__row__column__btn-style table__body__row__column__btn-style__primary`}
      //       >
      //         {Tools.isNotEmpty(props.bid_status)
      //           ? bid_status.status_name
      //           : "Not bid yet"}
      //       </div>
      //     );
      //   },
      // },
      // {
      //   Header: "Total Bids",
      //   id: "total_bids",
      //   accessor: (props) => {
      //     return (
      //       <div className="table__body__row__column__text-style">
      //         {props.total_bids}
      //       </div>
      //     );
      //   },
      // },
      // {
      //   Header: "Status",
      //   id: "status",
      //   show: false,
      //   accessor: (props) => {
      //     const { status } = this.props;
      //     var auction_status = status.find(
      //       (item) =>
      //         parseFloat(item.status_code) === parseFloat(props.auction_status)
      //     );
      //     return (
      //       <div
      //         className={`table__body__row__column__btn-style table__body__row__column__btn-style__primary`}
      //       >
      //         {Tools.isNotEmpty(auction_status)
      //           ? auction_status.status_name
      //           : ""}
      //       </div>
      //     );
      //   },
      // },
      // {
      //   Header: "Assign",
      //   id: "assign",
      //   accessor: (props) => {
      //     console.log(props);
      //     return (
      //       <div className="table__body__row__column__btn-style table__body__row__column__btn-style__success">
      //         {props.bid_status !== 122
      //           ? "Not Ready"
      //           : props.status < 142
      //           ? "Not Assign"
      //           : `Assign to ${props.delivery_boy_name}`}
      //       </div>
      //     );
      //   },
      // },
      // {
      //   Header: "Actions",
      //   id: "auction_id",
      //   accessor: (props) => {
      //     return (
      //       <DropdownButton
      //         className="table__body__row__column__dropdown center"
      //         key="up"
      //         drop="up"
      //         variant="secondary"
      //         title={<i className="material-icons">more_horiz</i>}
      //       >
      //         <Dropdown.Item
      //           eventKey="1"
      //           onClick={() =>
      //             navigate(this.props.history, "/auction/" + props.auction_id)
      //           }
      //           className="dropdown-item__detail"
      //         >
      //           See Detail
      //         </Dropdown.Item>
      //       </DropdownButton>
      //     );
      //   },
      // },
    ];

    var hiddenColumns = [];
    if (user.role !== "partner") {
      hiddenColumns = ["bid_amount", "bid_status", "assign"];
    } else if (user.role === "partner") {
      hiddenColumns = ["status", "total_bids"];
    }

    const data = keyword ? filteredData : auctions;
    // const filterOptions = [
    //   {
    //     type: "date",
    //     key: "created_date",
    //     label: "Date",
    //   },
    //   {
    //     type: "option",
    //     key: "status",
    //     label: "Status",
    //     items: Tools.generateAuctionStatusFilter(),
    //   },
    //   // {
    //   //     type: "option",
    //   //     key: "material_categories",
    //   //     label: "Material Categories",
    //   //     items: Tools.generateMaterialCategoriesFilter()
    //   // },
    //   // {
    //   //     type: "option",
    //   //     key: "fleet_categories",
    //   //     label: "Fleet Categories",
    //   //     items: Tools.generateFleetCategoriesFilter()
    //   // }
    // ];

    return (
      <div className="dashboard">
        <Confirmation
          isVisible={confirmation.isVisible}
          header={confirmation.header}
          description={confirmation.description}
          confirmationText="Yes"
          onCancel={confirmation.onCancel}
          onConfirm={confirmation.onConfirm}
        />

        <div className="dashboard__header">
          <div className="flex flex__sm">
            <h1 className="header" style={{ marginRight: 20 }}>
              Invoice
            </h1>
            {/* <Filter
              filterOptions={filterOptions}
              withFilter
              isFilterVisible={this.state.isFiltering}
              onCancel={() => this.setState({ isFiltering: false })}
              onFilter={() =>
                this.setState({ isFiltering: !this.state.isFiltering })
              }
              onApplyFilter={(e) => this.onFilter(e)}
              onSearch={(e) => this.onSearch(e)}
            /> */}
            <Button
              type="primary"
              variation="primary"
              buttonText="Refresh"
              size="sm"
              iconPosition="before-text"
              icon={Images.refresh}
              style={{ background: "#1a4057" }}
              onClick={() => this.fetchData()}
            />
          </div>
        </div>
        <div className="dashboard__body">
          <Table columns={columns} data={data} hiddenColumns={hiddenColumns} />
        </div>
      </div>
    );
  }
}

const mapsStateToProps = ({
  authenticated,
  consignment,
  material_categories,
  fleet_categories,
  auction,
}) => {
  return {
    token: authenticated.token,
    user: authenticated.userInfo,
    material_categories: material_categories.lists,
    fleet_categories: fleet_categories.lists,
    status: consignment.status,
    auctions: auction.lists,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/InvoiceRedux");
  const {
    actions: material_categories_actions,
  } = require("redux/MaterialCategoriesRedux");
  const {
    actions: fleet_categories_actions,
  } = require("redux/FleetCategoriesRedux");
  const { actions: consignment_actions } = require("redux/ConsignmentRedux");

  return {
    ...ownProps,
    ...stateProps,
    fetchAuctions: async (token, role = "user", user_id = null) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await actions.fetchAuctions(dispatch, token, role, user_id);
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    handleDownload: async (id ,token) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await actions.downloadInvoice(id, token);
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    fetchMaterialCategories: async (token) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await material_categories_actions.fetchMaterialCategories(
        dispatch,
        token
      );
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    fetchFleetCategories: async (token) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await fleet_categories_actions.fetchFleetCategories(
        dispatch,
        token
      );
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    fetchConsignmentStatus: async (token) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await consignment_actions.fetchConsignmentStatus(
        dispatch,
        token
      );
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    clearAuction: () => dispatch({ type: "CLEAR_AUCTION" }),
    fetchPending: () => dispatch({ type: "FETCH_PENDING" }),
    fetchFinish: () => dispatch({ type: "FETCH_FINISH" }),
    toastPending: (body) => {
      dispatch({ type: "TOAST_PENDING", ...body });
    },
  };
};

export default connect(
  mapsStateToProps,
  undefined,
  mergeProps
)(AuctionsContainer);
