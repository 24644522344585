import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import registerServiceWorkerDev from "serviceWorker.dev";
import ReactGA from "react-ga4";
import { Constant } from "common";

var worker = new Worker(registerServiceWorkerDev);
worker.onmessage = function(oEvent) {
  console.log('Worker said : ' + oEvent.data);
};

ReactGA.initialize(Constant.GA_ID);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
  );

ReactGA.send({ hitType: "pageview", page: window.location.pathname });
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
registerServiceWorkerDev();
