import React from 'react';
import "./file.scss";

class InputFile extends React.PureComponent {

    render() {

        const { onClick, isRequired } = this.props;

        return(
            <div className={`input ${this.props.className}`} style={this.props.style}>
                <label className={`input__label ${this.props.isError ? "input__label__error" : undefined}`}>
                    {this.props.label}
                    {isRequired && <span className='input__required'>*</span>}
                    </label>
                
                <div className="input__file">
                    <label className="input__file__name">{this.props.value || "No file selected"}</label>
                    <button 
                        className={`input__file__btn ${this.props.btnClassName}`}
                        onClick={onClick}
                    >
                        {this.props.btnText || "Upload"}
                    </button>   
                </div>        

                {this.props.caption && (
                    <label className={`input__caption ${this.props.isError ? "input__caption__error" : undefined}`}>{this.props.caption}</label>
                )}
            </div>
        )
    }

}

export default (InputFile);