import React from 'react';
import "./form.scss";


class FormBody extends React.PureComponent {

    render() {
        return(
            <div className={`form__wrapper__body ${this.props.className}`} style={this.props.style}>
                {this.props.children}
            </div>
        )
    }

}

export default (FormBody);