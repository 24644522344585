import React from 'react';
import "./emoji.scss";

class InputEmoji extends React.PureComponent {

    render() {

        const { onChange, options } = this.props;

        return(
            <div className={`input ${this.props.className}`} style={this.props.style}>
                <label className={`input__label ${this.props.isError ? "input__label__error" : undefined}`}>{this.props.label}</label>
                
                <div className="input__emojies">

                    {options.map((item, index) => (
                        <button 
                            key={index}
                            className={`input__emojies__btn ${this.props.btnClassName}`}
                            onClick={() => onChange(item)}
                        >
                            {item.icon}
                        </button>  
                    ))}

                </div>        

                {this.props.caption && (
                    <label className={`input__caption ${this.props.isError ? "input__caption__error" : undefined}`}>{this.props.caption}</label>
                )}
            </div>
        )
    }

}

export default (InputEmoji);