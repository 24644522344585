import React from "react";
import images from "common/Images";
import { Input } from "components";
import Tools from "common/Tools";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import "./styles.scss";
import VerificationModal from "./verification";
import { SaytumAPI } from "services/SaytumAPI";
import languages from "common/Languages";

class VerifyEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      send: false,
      valid: false,
      data: {},
      emailError: false,
    };
  }

  async componentDidMount() {
    this.props.RESET_PROCESS();
    const { user, type } = this.props;
    this.setState(
      {
        data: {
          email: user.email,
          isUseDefaultEmail: user.email ? true : false,
        },
      },
      () => {
        if (type === "edit-profile") {
          this.setState({
            data: {
              ...this.state.data,
              email_verified: true,
            },
          });
        }
      }
    );
  }

  onBlurValidationHandler = () => {
    if (!Tools.isEmailValid(this.state.data.email)) {
      this.setState({
        ...this.state,
        emailError: true,
      });
    } else {
      this.setState({
        ...this.state,
        emailError: false,
      });
    }
  };

  onChange = (target, value) => {
    this.setState(
      {
        data: {
          ...this.state.data,
          [target]: value,
          email_verified: false,
        },
        send: false,
      },
      () => {
        const { user } = this.props;
        if (target === "email") {
          var currentEmail = user.email ? user.email.toString() : "";
          if (currentEmail === value.toString()) {
            this.setState(
              {
                data: {
                  ...this.state.data,
                  email_verified: true,
                },
              },
              () => {
                this.props.isChanged(false);
              }
            );
          } else {
            this.props.isChanged(true);
            this.setState({
              data: {
                ...this.state.data,
                isUseDefaultEmail: false,
              },
            });
          }
        }
      }
    );
  };

  handleRequestVerification = async () => {
    var valid = await this.validation();

    if (valid) {
      this.saytumAPIRequestCode();
    } else {
      this.props.SET_NOTIFICATION({
        isVisible: true,
        message: "Email not valid",
        type: "error",
      });
    }
  };

  saytumAPIRequestCode = async () => {
    this.props.SET_PROCESS({ isVisible: true });
    const { email } = this.state.data;
    var token = await Tools.getCurrentValidToken();
    if (Tools.isEmailValid(email)) {
      var param = {
        email: email,
        purpose: "update_profile",
        type: "email",
      };
      var verification = await SaytumAPI.authenticated_post(
        "user/request-code",
        token,
        param
      );
      if (verification.fetch_status === "success") {
        this.setState({
          showVerification: true,
        });
        this.props.RESET_PROCESS();
      } else {
        this.props.SET_NOTIFICATION({
          isVisible: true,
          message: verification.message,
          type: "error",
        });
        this.props.RESET_PROCESS();
      }
    } else {
      this.setState({ send: true, valid: false });
      this.props.RESET_PROCESS();
    }
  };

  validation = () => {
    const { email } = this.state.data;

    var valid = Tools.isEmailValid(email);

    this.setState({
      valid: valid,
      send: true,
    });

    return valid;
  };

  onVerified = (data) => {
    this.setState(
      {
        showVerification: false,
      },
      () => {
        data = {
          ...data,
          ...this.state.data,
        };

        this.saytumAPIVerifyCode(data);
      }
    );
  };

  saytumAPIVerifyCode = async (data) => {
    this.props.SET_PROCESS({ isVisible: true });
    var token = await Tools.getCurrentValidToken();
    var param = {
      email: data.email,
      verification_code: data.verification_code,
      type: "email",
    };
    var verify = await SaytumAPI.authenticated_post(
      "user/verify-code",
      token,
      param
    );
    if (verify.fetch_status === "success") {
      this.setState({ data: { ...this.state.data, email_verified: true } });
      this.props.SET_NOTIFICATION({
        isVisible: true,
        message: languages.verification.success("Email"),
        type: "success",
        duration: 3000,
      });
      this.props.onVerified(data);
    } else {
      this.setState({ showVerification: true });
      this.props.SET_NOTIFICATION({
        isVisible: true,
        message: languages.verification.invalid_code,
        type: "error",
        duration: 5000,
      });
    }

    this.props.RESET_PROCESS();
  };

  render() {
    const { data } = this.state;
    var inputProps = { ...this.props.inputProps };
    var selectProps = { ...this.props.inputProps };
    if (this.props.isResubmit) {
      inputProps = {
        ...inputProps,
        readOnly: true,
      };
      selectProps = {
        ...selectProps,
        isDisabled: true,
      };
    }

    return (
      <>
        {this.state.showVerification && (
          <VerificationModal
            isVisible={this.state.showVerification}
            onVerified={(data) => this.onVerified(data)}
            type="email"
            onCancel={() =>
              this.setState({ verified: false, showVerification: false })
            }
            onRequestNewCode={() =>
              this.setState({ showVerification: false, verified: false }, () =>
                this.handleRequestVerification()
              )
            }
          />
        )}

        <Input
          type="email"
          onChange={(e) => {
            this.setState({
              ...this.state,
              emailError: false,
            });
            this.onChange("email", e.target.value);
          }}
          value={data.email}
          iconPrefix={images.form_email}
          iconSuffix={images.qrcode}
          clickableIcon={["suffix"]}
          clickableIconLabel="Verify"
          onIconClick={(position) => {
            if (position === "suffix") {
              this.handleRequestVerification();
            }
          }}
          onBlur={this.onBlurValidationHandler}
          clickableIconClassName="button__clickable-icon"
          style={{ margin: 0, marginBottom: 25 }}
          label="Email Address"
          caption={
            !data.isUseDefaultEmail &&
            (this.state.emailError
              ? this.props.caption
                ? this.props.caption
                : "Provide correct email format"
              : !data.email_verified
              ? "Email need to verified"
              : null)
          }
          isError={this.props.isError || this.state.emailError}
          selectProps={selectProps}
          inputProps={inputProps}
          isRequired={true}
        />
      </>
    );
  }
}

const mapsStateToProps = ({ authenticated }) => {
  return {
    user: authenticated.userInfo,
  };
};

const mapsDispatchToState = (dispatch) => {
  return {
    SET_PROCESS: (process) => dispatch({ type: "SET_PROCESS", items: process }),
    SET_NOTIFICATION: (notification) =>
      dispatch({ type: "SET_NOTIFICATION", items: notification }),
    RESET_PROCESS: () => dispatch({ type: "RESET_PROCESS" }),
  };
};

export default connect(
  mapsStateToProps,
  mapsDispatchToState
)(withRouter(VerifyEmail));
