import React from 'react';
import PropTypes from 'prop-types';
import "./button.scss";
import ButtonDashboard from './dashboard';

class Button extends React.PureComponent {

    render() {

        const { type } = this.props;

        if(type === "dashboard") {
            return <ButtonDashboard {...this.props} />
        }

        return(
            <button 
                type="button" 
                onClick={this.props.onClick}
                className={`button button__${this.props.size} button__${this.props.type} button__${this.props.type}__${this.props.variation} button__${this.props.type}__${this.props.isActive ? "active" : "inactive"} ${this.props.className ?? ""}`}
                style={this.props.style}
                {...this.props.buttonProps}
            >
                {this.props.iconPosition === "before-text" && (
                    <img 
                        src={this.props.icon} 
                        alt="button-icon" 
                        className={`button__icon  button__icon__${this.props.iconPosition} ${this.props.iconClassName}`} 
                        style={{...this.props.iconStyle}} 
                    />
                )}

                {this.props.buttonText}

                {this.props.iconPosition === "after-text" && (
                    <img 
                        src={this.props.icon} 
                        alt="button-icon" 
                        className={`button__icon  button__icon__${this.props.iconPosition} ${this.props.iconClassName}`} 
                        style={{...this.props.iconStyle}} 
                    />
                )}
            </button>
        )
    }

}

Button.defaultProps = {
    buttonText: 'Button Text',
    type: 'default',
    size: 'md',
    variation: 'primary'
};

Button.propTypes = {
    buttonText: PropTypes.string,
    onClick: PropTypes.func,
    type: PropTypes.string,
    size: PropTypes.string,
    variation: PropTypes.string
}

export default (Button);