import images from 'common/Images';
import { Button } from 'components';
import React from 'react';
import "./styles.scss";

class Detail extends React.PureComponent {

    render() {

        const { withBackButton, backButtonAction } = this.props;
        const { innerWidth } = window;

        return(
            <div className={`detail ${this.props.className}`} style={this.props.style}>
                <div className={`detail__wrapper ${this.props.containerClassName}`} style={this.props.wrapperStyle}>

                    {withBackButton && (
                        <Button
                            buttonText="back"
                            size="sm"
                            type="default"
                            icon={images.arrow_left_grey}
                            iconPosition="before-text"
                            style={{ marginLeft: 0, minWidth: 'auto', padding: 0, textTransform: 'capitalize', color: '#92929D', marginBottom: innerWidth > 768 ? 20 : 10, marginTop:  20 }}
                            iconStyle={{ marginRight: 2, marginTop: -2 }}
                            onClick={backButtonAction}
                        />
                    )}

                    <div className="detail__wrapper__inner">
                        {this.props.children}
                    </div>

                </div>
            </div>
        )
    }

}

export default (Detail);