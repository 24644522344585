import React from 'react';
import { ForgotPasswordContainer } from 'container';

class ForgotPassword extends React.PureComponent {

    render() {
        return(
            <ForgotPasswordContainer
                {...this.props}
            />
        )
    }

}

export default (ForgotPassword);