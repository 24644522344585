import React from "react";
import { Input, Button, FormBody, ButtonGroup, Modal } from "components";
import { Tools, Images } from "common";

class VerificationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      send: false,
      valid: false,
      data: {},
      passwordError: false,
    };
  }

  componentDidMount() {
    this.setState({
      data: {},
    });
  }

  onBlurValidationHandler = () => {
    if (!Tools.isNotEmpty(this.state.data.verification_code)) {
      this.setState({
        ...this.state,
        passwordError: true,
      });
    } else {
      this.setState({
        ...this.state,
        passwordError: false,
      });
    }
  };

  onChange = (target, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [target]: value,
      },
      send: false,
    });
  };

  onVerify = async () => {
    var valid = await this.validation();

    if (valid) {
      var data = {
        ...this.state.data,
      };
      this.props.onVerified(data);
    }
  };

  validation = () => {
    const { verification_code } = this.state.data;

    var valid = Tools.isNotEmpty(verification_code);

    this.setState({
      valid: valid,
      send: true,
    });

    return valid;
  };

  render() {
    const { data } = this.state;
    const { type } = this.props;

    return (
      <Modal
        reference={(ref) => (this.rejection_modal = ref)}
        isVisible={this.props.isVisible}
        className="modal__form"
      >
        <FormBody>
          <h1
            className="text__verification__header"
            style={{ marginBottom: 0 }}
          >
            Verify {type === "mobile_number" ? "Mobile Number" : "Email"}
          </h1>
          <h3 className="text__verification__body" style={{ marginBottom: 30 }}>
            We send you verification code in your{" "}
            {type === "mobile_number" ? "Mobile Number" : "Email"}
          </h3>
          <Input
            type={"text"}
            iconPrefix={Images.password}
            label="Verification Code"
            placeholder="Please type here"
            style={{ margin: 0, marginBottom: 15 }}
            value={data.verification_code}
            caption={this.state.passwordError && "This field is required"}
            isError={
              (this.state.send && !this.state.valid) || this.state.passwordError
            }
            onChange={(e) => {
              this.setState({
                ...this.state,
                passwordError: false,
              });
              this.onChange("verification_code", e.target.value);
            }}
            isRequired={true}
          />
          <p className="hyperlink__text text-left">
            It may take a minute to receive your code. Don't receive
            verification code?
            <Button
              buttonText="Request New Code"
              size="md"
              type="default"
              className="button__new-code"
              onClick={() =>
                this.setState({ data: { verification_code: "" } }, () =>
                  this.props.onRequestNewCode()
                )
              }
            />
          </p>

          <ButtonGroup position="center">
            <Button
              buttonText="Cancel"
              size="md"
              type="default"
              style={{
                margin: 0,
                width: "48%",
                minWidth: "48%",
                marginRight: "2%",
              }}
              onClick={() => this.props.onCancel()}
            />
            <Button
              buttonText="Verify"
              size="md"
              type="warning"
              style={{
                margin: 0,
                width: "48%",
                minWidth: "48%",
                marginLeft: "2%",
              }}
              onClick={() => this.onVerify()}
            />
          </ButtonGroup>
        </FormBody>
      </Modal>
    );
  }
}

export default VerificationModal;
