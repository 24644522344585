import React from 'react';
import { Button, FormBody, FormWrapper, ButtonGroup } from 'components';
import { connect } from 'react-redux';
import { navigate, Images } from 'common';


class RejectedPartnerContainer extends React.PureComponent {

    handleUserLogout = async() => {
        const {
            logout,
            token,
            toastPending
        } = this.props

        var json = await logout(token)
        if(json.status_code === 200) {
            navigate(this.props.history, "/", "replace");
        }
        toastPending({ message: json.message, toastType: json.status_code })
    }

    render() {

        const { innerWidth } = window;

        return (
            <div className="container" style={innerWidth > 768 ? { marginTop: 100 } : {}}>

                <FormWrapper>
                    <FormBody>
                        <img src={Images.warning} alt="success" className="image__verification" />
                        <h1 className="text__verification__header">Thank you for joining us</h1>
                        <h3 className="text__verification__body">
                            Your profile is rejected by our staff due to some missing requirement. Please check the note below than try to resubmit registration!
                        </h3>
                        <h3 className="text__verification__body text__italic" style={{ marginBottom: 30 }}>
                            {this.props.user.partner.notes}
                        </h3>
                        <ButtonGroup position="center" style={{ flexWrap: 'wrap' }}>
                            <Button
                                buttonText="resubmit profile"
                                size={innerWidth > 768 ? "md" : "lg"}
                                type="success"
                                style={innerWidth > 768 ? { margin: 0, marginRight: '2%' } : { margin: 0, marginBottom: 10 }}
                                onClick={() => navigate(this.props.history, "/resubmit-registration")}
                            />
                            <Button
                                buttonText="signout"
                                size={innerWidth > 768 ? "md" : "lg"}
                                type="danger"
                                style={innerWidth > 768 ? { margin: 0, marginLeft: '2%' } : { margin: 0 }}
                                onClick={() => this.handleUserLogout()}
                            />

                        </ButtonGroup>
                    </FormBody>
                </FormWrapper>

            </div>
        )
    }

}


const mapsStateToProps  = ({ authenticated }) => {
    return {
        user: authenticated.userInfo,
        token: authenticated.token
    }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { actions } = require("redux/AuthenticatedRedux");

    return {
      ...ownProps,
      ...stateProps,
      logout: async(token) => {
        dispatch({ type: "FETCH_PENDING" });
        var logout = await actions.logout(
            dispatch,
            token
        )
        dispatch({ type: "FETCH_FINISH" });
        return logout
      },
      toastPending: (body) => {
          dispatch({ type: "TOAST_PENDING", ...body })
      }
    };
}


export default connect(
    mapsStateToProps, 
    undefined,
    mergeProps
)(RejectedPartnerContainer);