import React, { useEffect, useState } from "react";
import './Loginaskbox.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { faUser, faHandshake } from '@fortawesome/free-solid-svg-icons';
import { navigate } from "common/Tools";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Loginaskbox = ({ closebox, logout, token, toastPending, user }) => {

    const [photo, setPhoto] = useState("");
    const history = useHistory();


    const navigateToPage = (page) => {
        navigate(history, page);
        window.location.reload();
    };

    const { pathname } = history.location;

    return (
        <>
            <div className="boxbg" onClick={closebox}>
                <div className="log-box">
                    <button className="cross-button" onClick={closebox} aria-label="Close login box">
                        <FontAwesomeIcon icon={faCircleXmark} />
                    </button>
                    <h2 className="log-title">Login as</h2>
                    <div className="log-buttons">
                        <button className={`log-button ${pathname === "/users/login" && "active"}`}
                        onClick={(e) => {
                            e.preventDefault();
                            navigateToPage("/login?role=user");
                        }}
                        >
                            <FontAwesomeIcon icon={faUser} className="faicon-logbox" />
                            <span className="btn-txt1">User</span>
                        </button>
                        <button className={`log-button ${pathname === "/logistic-partner/login" && "active"}`}
                        onClick={(e) => {
                            e.preventDefault();
                            navigateToPage("/login?role=logistic_partner");
                        }}>
                            <FontAwesomeIcon icon={faHandshake} className="faicon-logbox" />
                            <span className="btn-txt2">Partner</span>
                        </button>
                        <button className={`log-button ${pathname === "/delivery_boys/login" && "active"}`}
                        onClick={(e) => {
                            e.preventDefault();
                            navigateToPage("/login?role=delivery_boy");
                        }}>
                            <FontAwesomeIcon icon={faHandshake} className="faicon-logbox" />
                            <span className="btn-txt3">Delivery Boy</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Loginaskbox;
