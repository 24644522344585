import images from 'common/Images';
import { Button, ButtonGroup, FormBody, Input, Modal, Camera, SignaturePad } from 'components';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import "./styles.scss";
import { data } from 'jquery';
import { Tools } from 'common';

var validation = {
  sign_attachment: "required",
  // pod: "required",
  remarks:"required",
};

class CompleteShipmentContainer extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = {
          isCameraOpen: false,
          isSignaturePadOpen: false,
          data: {},
          errors:[],
          signAttachmentError: false,
          podError: false,
          descriptionError: false,
          valid: false,
          send: false,
        };
    }

    onCapture = (image) => {
        this.setState({ isCameraOpen: false }, () => this.onChange("pod", image))
    }

    onSign = (image) => {
        this.setState({ isSignaturePadOpen: false }, () => this.onChange("sign_attachment", image))
    }

    onChange = (target, value) => {
        this.setState({
            send: false,
            data: {
                ...this.state.data,
                [target]: value
            }
        })
    }

    onBlurValidationHandler = (target) => {
        const { data } = this.state;

        if (target === "sign_attachment") {
          if (!data.sign_attachment) {
            this.setState({
              ...this.state,
              signAttachmentError: true,
            });
          } else {
            this.setState({
              ...this.state,
              signAttachmentError: false,
            });
          }
        }

        if (target === "pod") {
          if (!data.pod) {
            this.setState({
              ...this.state,
              podError: true,
            });
          } else {
            this.setState({
              ...this.state,
              podError: false,
            });
          }
        }

        if (target === "remarks") {
          if (!data.remarks) {
            this.setState({
              ...this.state,
              descriptionError: true,
            });
          } else {
            this.setState({
              ...this.state,
              descriptionError: false,
            });
          }
        }
    }

    validation = async () =>{
        var validate = {};
        validate = await Tools.validation(validation, this.state.data);

        this.setState({
        valid: validate.valid,
        errors: validate.errors,
        });

        return validate.valid;
    };

onUpdate = async() => {
        const valid = await this.validation();
            this.setState({
            send: true
        });
        
        if (valid) {
          const {
            token,
            fetchTrackingHistories,
            updateTrackingLocation,
            markAsDelivered,
            match,
            toastPending,
            onClose,
            isDashboard,
            selectedConsignmentId,
            trackings,
          } = this.props;
          onClose();
          var consignment_id = isDashboard
            ? selectedConsignmentId
            : match.params.id;
          var tracking = trackings.find(
            (item) => parseFloat(item.id) === parseFloat(consignment_id)
          );
          var params = {
            consignment_id: parseFloat(consignment_id),
            tracking_event_time: moment().format("YYYY-MM-DD HH:mm:ss"),
            location:
              tracking.delivery_address_two +
              "," +
              tracking.delivery_address_one,
            status: 145,
            ...this.state.data,
          };
          var json = await updateTrackingLocation(token, params);
          if (json.status_code === 200) {
            json = await markAsDelivered(token, {
              consignment_id: parseFloat(consignment_id),
            });
            if (json.status_code === 200) {
              fetchTrackingHistories(token, parseFloat(consignment_id));
            }
          }
          toastPending({ message: json.message, toastType: json.status_code });
        }
    };

    render() {

        const {
            data
        } = this.state;

        return (
          <>
            <Modal
              reference={(ref) => (this.rejection_modal = ref)}
              isVisible={this.props.isVisible}
              className="modal__form"
            >
              <button
                className={`button__link modal__detail__closer`}
                onClick={() => this.props.onClose()}
              >
                <img src={images.close} alt="close" />
              </button>

              <FormBody className="modal__form__container">
                <h1 className="modal__form__header">
                  Shipment Delivered Verification
                </h1>
                <h3
                  className="text__verification__body"
                  style={{ marginBottom: 30 }}
                >
                  Please Fill all required data to submit
                </h3>

                <Input
                  type="file"
                  label="Recipent Signature"
                  style={{ marginBottom: 20 }}
                  btnText="Sign Now"
                  onClick={() => this.setState({ isSignaturePadOpen: true })}
                  value={data.sign_attachment}
                  caption={
                    this.state.signAttachmentError && "This field is required"
                  }
                  isError={
                    (this.state.send &&
                      !this.state.valid &&
                      this.state.errors.includes("sign_attachment")) ||
                    this.state.signAttachmentError
                  }
                  onBlur={() => this.onBlurValidationHandler("sign_attachment")}
                  isRequired={true}
                />

                <Input
                  type="file"
                  label="POD"
                  style={{ marginBottom: 20 }}
                  btnText="Upload"
                  onClick={() => this.setState({ isCameraOpen: true })}
                  value={data.pod}
                  caption={this.state.podError && "This field is required"}
                  isError={
                    (this.state.send &&
                      !this.state.valid &&
                      this.state.errors.includes("pod")) ||
                    this.state.podError
                  }
                  // onBlur={() => this.onBlurValidationHandler("pod")}
                  // isRequired={true}
                />
                <Input
                  type="textarea"
                  label="Description"
                  placeholder="Please type here"
                  style={{ marginBottom: 20 }}
                  value={data.remarks}
                  onChange={(e) => this.onChange("remarks", e.target.value)}
                  caption={
                    this.state.descriptionError && "This field is required"
                  }
                  isError={
                    (this.state.send &&
                      !this.state.valid &&
                      this.state.errors.includes("remarks")) ||
                    this.state.descriptionError
                  }
                  onBlur={() => this.onBlurValidationHandler("remarks")}
                  isRequired={true}
                />

                <ButtonGroup
                  position="right"
                  className="modal__form__btn-group"
                >
                  <Button
                    buttonText="Cancel"
                    size="sm"
                    type="default"
                    style={{ margin: 0 }}
                    onClick={() => this.props.onClose()}
                  />
                  <Button
                    buttonText="Update"
                    size="sm"
                    type="warning"
                    style={{ margin: 0 }}
                    onClick={() => this.onUpdate()}
                  />
                </ButtonGroup>
              </FormBody>
            </Modal>

            {this.state.isCameraOpen && (
              <Camera
                onClose={() => this.setState({ isCameraOpen: false })}
                onCapture={(image) => this.onCapture(image)}
              />
            )}

            <SignaturePad
              isVisible={this.state.isSignaturePadOpen}
              onClose={() => this.setState({ isSignaturePadOpen: false })}
              onSign={(signature) => this.onSign(signature)}
            />
          </>
        );
    }

}

const mapsStateToProps = ({ 
    authenticated,
    consignment,
    tracking
}) => {
    return {
        token: authenticated.token,
        consignments: consignment.lists,
        trackings: tracking.lists
    }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { actions } = require("redux/TrackingRedux");

    return {
      ...ownProps,
      ...stateProps,
      updateTrackingLocation: async(token, data) => {  
        dispatch({ type: "FETCH_PENDING" })
        var json = await actions.updateTrackingLocations(
            dispatch,
            token,
            data
        )
        dispatch({ type: "FETCH_FINISH" })
        return json
      },
      markAsDelivered: async(token, data) => {  
        dispatch({ type: "FETCH_PENDING" })
        var json = await actions.markAsDelivered(
            dispatch,
            token,
            data
        )
        dispatch({ type: "FETCH_FINISH" })
        return json
      },
      clearTrackingHistories: () => dispatch({ type: "CLEAR_TRACKING_HISTORIES" }),
      fetchTrackingHistories: async(
        token,
        consignment_id
      ) => {
        dispatch({ type: "FETCH_PENDING" })
        var json = await actions.fetchTrackingHistories(dispatch, token, consignment_id)
        dispatch({ type: "FETCH_FINISH" })
        return json
      },
      toastPending: (body) => {
        dispatch({ type: "TOAST_PENDING", ...body })
      }
    };
}

export default connect(
    mapsStateToProps,
    undefined,
    mergeProps
)(CompleteShipmentContainer);