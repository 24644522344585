
const Config = {
    minTime: () => {
        var minTime = new Date();
        return new Date(minTime);
    },
    maxTime: () => {
        var maxTime = new Date();
        maxTime.setHours(20); maxTime.setMinutes(30);

        return new Date(maxTime);
    },
    profileCompletedRequired: [
        "consignment",
        "auction",
        "/join-partner",
        "fleet",
        "worker"
    ],
    profileApproveRequired: [
        "dashboard"
    ],
    savedLocallyStatusKey: "SAVED LOCALLY"
}

export default Config;