import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  Button,
  FormBody,
  FormWrapper,
  Input,
  ImageUploader,
  ButtonGroup,
} from "components";
import { Tools, Images, navigate } from "common";

var validation = {
  name: "required",
  category: "required",
  description: "required",
  image: "required",
};
class CreateFleetContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      errors: [],
      nameError: false,
      categoryError: false,
      descriptionError: false,
      imageError: false,
    };
  }

  componentDidMount() {
    const { fetchFleetsCategories, token } = this.props;
    fetchFleetsCategories(token);
  }

  onChange = (target, value) => {
    this.setState({
      send: false,
      data: {
        ...this.state.data,
        [target]: value,
      },
    });
  };

  onBlurValidation = (target) => {
    if (target === "name") {
      if (!Tools.isNotEmpty(this.state.data.name)) {
        this.setState({
          ...this.state,
          nameError: true,
        });
      } else {
        this.setState({
          ...this.state,
          nameError: false,
        });
      }
    }
    if (target === "category") {
      if (!Tools.isNotEmpty(this.state.data.category)) {
        this.setState({
          ...this.state,
          categoryError: true,
        });
      } else {
        this.setState({
          ...this.state,
          categoryError: false,
        });
      }
    }
    if (target === "description") {
      if (!Tools.isNotEmpty(this.state.data.description)) {
        this.setState({
          ...this.state,
          descriptionError: true,
        });
      } else {
        this.setState({
          ...this.state,
          descriptionError: false,
        });
      }
    }
    if (target === "image") {
      if (!Tools.isNotEmpty(this.state.data.image)) {
        this.setState({
          ...this.state,
          imageError: true,
        });
      } else {
        this.setState({
          ...this.state,
          imageError: false,
        });
      }
    }
  };

  onImageChange = (event) => {
    const reader = new FileReader();
    var file = event.target.files[0];

    let local = this;
    reader.onload = function (event) {
      local.setState({
        data: {
          ...local.state.data,
          image: {
            file: event.target.result,
            name: file.name,
            value: event.target.result,
          },
        },
      });
    };

    reader.readAsDataURL(file);
  };

  validation = async () => {
    var validate = await Tools.validation(validation, this.state.data);

    this.setState({
      valid: validate.valid,
      send: true,
      errors: validate.errors,
    });

    return validate.valid;
  };

  onAdd = async () => {
    this.setState({
      send: true,
    });

    var valid = await this.validation();

    if (valid) {
      const { token, createFleet, history, toastPending } = this.props;
      var params = this.state.data;
      var data = {
        name: params.name,
        description: params.description,
        fleet_categories_id: params.category.id,
        file: params.image.file,
      };
      var json = await createFleet(token, data);
      if (json.status_code === 200) {
        navigate(history, "/dashboard?screen=master_data&child=fleet");
      }
      toastPending({ message: json.message, toastType: json.status_code });
    }
  };

  render() {
    const { data } = this.state;
    const { categories } = this.props;
    const { innerWidth } = window;

    const options = [];
    categories.map((item) => {
      options.push({ value: item.name, id: item.id, label: item.name });
      return true;
    });

    return (
      <div
        className="container"
        style={
          innerWidth > 768
            ? { marginTop: 50, marginBottom: 50 }
            : { marginTop: 15, marginBottom: 15 }
        }
      >
        <FormWrapper>
          <FormBody>
            <h1
              className="text__verification__header"
              style={{ marginBottom: 0 }}
            >
              Add Fleet
            </h1>
            <h3
              className="text__verification__body"
              style={{ marginBottom: 30 }}
            >
              Create your new fleet master
            </h3>
            <Input
              type={"text"}
              iconPrefix={Images.user}
              label="Name"
              placeholder="Please type here"
              style={{ margin: 0, marginBottom: 30 }}
              onBlur={() => this.onBlurValidation("name")}
              caption={this.state.nameError && "This field is required"}
              isError={
                (this.state.send && !this.state.valid) || this.state.nameError
              }
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  nameError: false,
                });
                this.onChange("name", e.target.value);
              }}
              isRequired={true}
            />
            <Input
              type="select"
              options={options}
              style={{ margin: 0, marginBottom: 25 }}
              label="Fleet Categories"
              value={data.category}
              // onBlur={() => this.onBlurValidation("category")}
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  categoryError: false,
                });
                this.onChange("category", e);
              }}
              caption={this.state.categoryError && "This field is required"}
              isError={
                (this.state.send && !this.state.valid) ||
                this.state.categoryError
              }
              isRequired={true}
            />
            <Input
              type={"textarea"}
              iconPrefix={Images.form_edit}
              label="Description"
              placeholder="Please type here"
              style={{ margin: 0, marginBottom: 30 }}
              value={data.description}
              onBlur={() => this.onBlurValidation("description")}
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  descriptionError: false,
                });
                this.onChange("description", e.target.value);
              }}
              caption={this.state.descriptionError && "This field is required"}
              isError={
                (this.state.send && !this.state.valid) ||
                this.state.descriptionError
              }
              isRequired={true}
            />
            <ImageUploader
              value={data.image ?? {}}
              label="Image"
              style={{ margin: 0, marginBottom: 25 }}
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  imageError: false,
                });
                this.onImageChange(e);
              }}
              onBlur={() => this.onBlurValidation("image")}
              caption={this.state.imageError && "This field is required"}
              isError={
                (this.state.send && !this.state.valid) || this.state.imageError
              }
              isRequired={true}
            />

            <ButtonGroup position="right">
              <Button
                buttonText="Cancel"
                size="sm"
                type="default"
                style={{ margin: 0 }}
                onClick={() => this.props.history.goBack()}
              />
              <Button
                buttonText="Save"
                size="sm"
                type="warning"
                style={{ margin: 0 }}
                onClick={() => this.onAdd()}
              />
            </ButtonGroup>
          </FormBody>
        </FormWrapper>
      </div>
    );
  }
}

const mapsStateToProps = ({ authenticated, fleet_categories }) => {
  return {
    token: authenticated.token,
    categories: fleet_categories.lists,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/FleetsRedux");
  const {
    actions: fleet_categories_actions,
  } = require("redux/FleetCategoriesRedux");

  return {
    ...ownProps,
    ...stateProps,
    fetchFleetsCategories: async (token) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await fleet_categories_actions.fetchFleetCategories(
        dispatch,
        token
      );
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    createFleet: async (token, body) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await actions.createFleet(dispatch, token, body);
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    toastPending: (body) => {
      dispatch({ type: "TOAST_PENDING", ...body });
    },
  };
};

export default connect(
  mapsStateToProps,
  undefined,
  mergeProps
)(withRouter(CreateFleetContainer));
