const Notifications = {
    RegistrationSuccess: "Registration Successfull. We have sent verification email in your registered email id. Please verify to complete the registration.",
    FreshVerificationSentSuccess: "We have resent the verification email to your registered email id. Please verify to complete the registration.",
    AccountVerifiedSuccess: "Account verification successfully completed",
    IncorrectPassword: "Incorrect password",
    EmailNotRegistered: "Email not registered",
    VerificationLinkExpired: "Verification link is expired",
    LogoutSuccess: "You are logged out",
    EmailRegistered: "This email is already registered",
    SocialRegistrationSuccess: "Registration Successfull",
    InvalidOTP: "Invalid verification code",
    ResetPasswordLinkSentSuccess: "We have sent password reset link to your registered email",
    MobileNumberVerificationSuccess: "Mobile number verification is successfull",
    ResetPasswordSuccess: "Password reset successfull",
    ConsignmentPublished: "Consignment order is accepted, Delivery partner will be sharing the price quotation soon",
    MobileNumberRegistered: "Mobile number already registered",
    InvalidProvider: "This email id is not registered via google, Please login using email",
    UserNotFound: "Selected user doesn't exist"
}

export default Notifications