import React from 'react';
import { Button, Table, Confirmation, Filter } from 'components';
import { connect } from 'react-redux';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import * as Moment from 'moment';
import { Tools, Images, navigate } from 'common';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

class FleetsContainer extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            confirmation: {
                isVisible: false,
                header: "",
                description: "",
                onCancel: null,
                onConfirm: null
            },
            rejectionData: {
                notes: "",
                isVisible: false
            },
            loaded: false
        }
    }

    async componentDidMount() {
        const {
            clearFleets,
            fetchPartnersFleets,
            token,
            user,
            clearFleetCategories,
            fetchFleetsCategories
        } = this.props
        clearFleets()
        clearFleetCategories()
        await fetchPartnersFleets(token, user.id)
        await fetchFleetsCategories(token)
        this.setState({ loaded: true })
    }

    openConfirmation = (headerText, descriptionText, onCancel, onConfirm) => {
        this.setState({
            confirmation: {
                isVisible: true,
                header: headerText,
                description: descriptionText,
                onCancel: onCancel,
                onConfirm: onConfirm
            }
        })
    }

    onCancelConfirmation = () => {
        this.setState({
            confirmation: {
                isVisible: false
            }
        })
    }

    onChange = (target, value, parentTarget=null) => {
        if(parentTarget === null) {
            this.setState({
                send: false,
                [target]: value
            })
        }
        else {
            this.setState({
                send: false,
                [parentTarget]: {
                    ...this.state[parentTarget],
                    [target]: value
                }
            })
        }
    }

    onDelete = async(item) => {
        this.onCancelConfirmation();
        const { 
            token,
            deleteFleet,
            toastPending 
        } = this.props;
        var json = await deleteFleet(token, item.id)
        toastPending({ message: json.message, toastType: json.status_code })
    }

    onSearch = (keyword) => {
        const { fleets } = this.props;
        
        var filteredData = fleets.filter((item) => item.name.toLowerCase().includes(keyword.toLowerCase()));
        this.setState({
            keyword: keyword,
            filteredData: filteredData
        });
    }

    onFilter = async(filters) => {
        var filterDate = await this.filterDate(filters);
        var filterFleetCategories = await this.filterFleetCategories(filterDate, filters);
        var finalData = filterFleetCategories;
        var filteredData = finalData;

        this.setState({
            isFiltering: false,
            keyword: "filter",
            filteredData: filteredData
        })
    }

    filterDate = (filters) => {
        const { fleets } = this.props;

        var isFilterDate = filters.filter((item) => item.target === "created_date");
        if(isFilterDate.length > 0) {
            var filteredData = [];
            var date_from = filters.find((item) => item.target === "created_date" && item.key === "date_from")    
            var date_to = filters.find((item) => item.target === "created_date" && item.key === "date_to");
            if(date_to) {
                var range = moment.range(date_from.value, date_to.value);
                
                filteredData = fleets.filter((item) => {
                    if(moment(item.created_at).within(range)) {
                        return item;
                    }
                    return false;
                });
            }
            else {
                filteredData = fleets.filter((item) => {
                    var created_at = moment(item.created_at).format("YYYY-MM-DD");
                    var filterDate = moment(date_from.value).format("YYYY-MM-DD");

                    if(moment(created_at).isSame(filterDate)) {
                        return item;
                    }
                    return false;
                });
            }

            return filteredData;
        }
        
        return fleets;
    }

    filterFleetCategories = async(data, filters) => {
        const { fleet_categories } = this.props;
        var filterCategories = filters.filter((item) => item.target === "fleet_categories");
        if(filterCategories.length > 0) {
            var filteredData = [];
            filterCategories.map((filter_item) => {
                var selectedCategories = fleet_categories.find((item) => item.name === filter_item.key);
                data.map((item) => {
                    if(parseFloat(item.fleet_categories_id) === parseFloat(selectedCategories.id)) {
                        filteredData.push(item)
                    }
                    return false;
                })
                return false;
            })
            
            return filteredData;
        }

        return data;
    }

    render() {

        const { fleets, fleet_categories } = this.props;
        const { confirmation, keyword, filteredData } = this.state;
        
        var data = keyword ? filteredData : fleets;

        const columns = [
            {
                Header: 'No',
                id: 'no',
                accessor: (props, index) => (
                    <div className="table__body__row__column__text-style">{index + 1}</div>
                )
            },
            {
                Header: 'Name',
                id: 'name',
                accessor: props => (
                    <div className="table__body__row__column__text-style">{props.name}</div>
                )
            },
            {
                Header: 'Category',
                id: 'category',
                accessor: props => {
                    var category = fleet_categories.find((item) => parseFloat(item.id) === parseFloat(props.fleet_categories_id));
                    return (
                        <div className="table__body__row__column__text-style">{category.name}</div>
                    )
                }
            },
            {
                Header: 'Created Date',
                accessor: 'created_date',
                Cell: props => {
                    return (
                        <div className="table__body__row__column__text-style">{moment(props.value).format("DD MMM YYYY")}</div>
                    ) 
                    
                },
            },
            {
                Header: 'Actions',
                id: "id",
                accessor: props => {
                    return(
                        <DropdownButton
                            className="table__body__row__column__dropdown"
                            key="up"
                            drop="up"
                            variant="secondary"
                            title={<i className="material-icons">more_horiz</i>}
                        >
                            <Dropdown.Item eventKey="4" 
                                onClick={() => navigate(this.props.history, "/fleet/edit/" + props.id)}
                                className="dropdown-item__edit"
                            >
                                Edit
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="4" 
                                onClick={() => this.openConfirmation(
                                    "Are you sure",
                                    "Are you sure to delete this data",
                                    () => this.onCancelConfirmation(),
                                    () => this.onDelete(props)
                                )}
                                className="dropdown-item__delete"
                            >
                                Delete
                            </Dropdown.Item>

                        </DropdownButton>
                    )
                }
            }
        ];

        const filterOptions = [
            {
                type: "date",
                key: "created_date",
                label: "Date"
            },
            {
                type: "option",
                key: "fleet_categories",
                label: "Fleet Categories",
                items: Tools.generateFleetCategoriesFilter()
            }
        ];

        return(
            <div className="dashboard">

                <Confirmation
                    isVisible={confirmation.isVisible}
                    header={confirmation.header}
                    description={confirmation.description}
                    confirmationText="Yes"
                    onCancel={confirmation.onCancel}
                    onConfirm={confirmation.onConfirm}
                />

                <div className="dashboard__header">
                    <div className="flex flex__sm">
                        <h1 className="header" style={{ marginRight: 20 }}>My Fleet</h1>
                        <Filter 
                            filterOptions={filterOptions}
                            withFilter
                            isFilterVisible={this.state.isFiltering}
                            onCancel={() => this.setState({ isFiltering: false })}
                            onFilter={() => this.setState({ isFiltering: !this.state.isFiltering })}
                            onApplyFilter={(e) => this.onFilter(e)}
                            onSearch={(e) => this.onSearch(e)}
                        />
                    </div>
                    <div className="flex flex__sm">  
                        <Button
                            icon={Images.plus}
                            iconPosition="before-text"
                            buttonText="Add Fleet"
                            type="dashboard"
                            size="sm"
                            style={{ marginRight: 0 }}
                            onClick={() => {
                                navigate(this.props.history, "/fleet/create");
                            }}
                        />  
                    </div>
                </div>
                <div className="dashboard__body">
                    {this.state.loaded && (
                        <Table 
                            columns={columns}
                            data={data}
                        />
                    )}
                </div>
            </div>
        )
    }

}

const mapsStateToProps = ({ authenticated, fleet, fleet_categories }) => {
    return {
        token: authenticated.token,
        user: authenticated.userInfo,
        fleets: fleet.lists,
        fleet_categories: fleet_categories.lists
    }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { actions } = require("redux/FleetsRedux");
    const { actions: fleet_categories_actions } = require("redux/FleetCategoriesRedux")

    return {
      ...ownProps,
      ...stateProps,
      fetchPartnersFleets: async(
        token,
        user_id
      ) => {
        dispatch({ type: "FETCH_PENDING" })
        var json = await actions.fetchPartnersFleets(dispatch, token, user_id)
        dispatch({ type: "FETCH_FINISH" })
        return json
      },
      clearFleets: () => dispatch({ type: "CLEAR_FLEETS" }),
      fetchFleetsCategories: async(
        token
      ) => {
        dispatch({ type: "FETCH_PENDING" })
        var json = await fleet_categories_actions.fetchFleetCategories(dispatch, token)
        dispatch({ type: "FETCH_FINISH" })
        return json
      },
      clearFleetCategories: () => dispatch({ type: "CLEAR_FLEET_CATEGORIES" }),
      deleteFleet: async(
        token,
        id
      ) => {
        dispatch({ type: "FETCH_PENDING" })
        var json = await actions.deleteFleet(dispatch, token, id)
        dispatch({ type: "FETCH_FINISH" })
        return json
      },
      toastPending: (body) => {
        dispatch({ type: "TOAST_PENDING", ...body })
      }
    };
}

export default connect(
    mapsStateToProps, 
    undefined,
    mergeProps
)(FleetsContainer);