import React from 'react';
import './Privacypolicypage.scss';

function PrivacyPolicy() {
    return (
        <div className="pp-container">
            <div className='pp-header'><h1>Privacy Policy</h1></div>
            <div className="pp-content">
                <div className="pp-pnt1">
                    <h3>Information we Collect</h3>
                    <p>We collect various types of information from our users, including:</p>
                    <ul>
                        <li>Personal information such as your name, email address, phone number, and address.</li>
                        <li>Payment information such as your credit card details.</li>
                        <li>Transactional information such as your order history and shipping information.</li>
                        <li>Usage information such as your IP address, device information, and browsing behavior.</li>
                    </ul>
                    <p>We collect this information when you create an account on our platform, place an order, or use our services.</p>
                </div>
                <div className="section-divider"></div>
                <div className="pp-pnt2">
                    <h3>How We Use Your Information</h3>
                    <p>We use your personal information to:</p>
                    <ul>
                        <li>Provide and improve our services.</li>
                        <li>Process and fulfill your orders.</li>
                        <li>Communicate with you about your orders, account, and promotions.</li>
                        <li>Protect against fraud and unauthorized access.</li>
                        <li>Comply with legal requirements.</li>
                    </ul>
                    <p>We may also use your information for marketing purposes, such as sending you newsletters and promotional offers. You can opt out of receiving these emails at any time.</p>
                </div>
                <div className="section-divider"></div>
                <div className="pp-pnt3">
                    <h3>Disclosure of Your Information</h3>
                    <p>We may share your personal information with third-party service providers who help us provide our services, such as shipping carriers and payment processors. We may also disclose your information to comply with legal requirements or protect our rights and property.<br/><br/>
                    We do not sell or rent your personal information to third parties.</p>
                </div>
                <div className="section-divider"></div>
                <div className="pp-pnt4">
                    <h3>Security of Your Information</h3>
                    <p>We take reasonable measures to protect your personal information from unauthorized access, disclosure, or destruction. We use SSL encryption to protect your payment information during transmission.<br/><br/>
                    However, no data transmission over the internet can be guaranteed to be 100% secure. Therefore, we cannot guarantee the security of your information.</p>
                </div>
                <div className="section-divider"></div>
                <div className="pp-pnt5">
                    <h3>Your Rights</h3>
                    <p>You have the right to access, correct, or delete your personal information. You can update your account information by logging into your account on our platform. If you want to delete your account, please contact us.</p>
                </div>
                <div className="section-divider"></div>
                <div className="pp-pnt6">
                    <h3>Changes to This Privacy Policy</h3>
                    <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our platform. Your continued use of our platform after we post any modifications to the Privacy Policy will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.</p>
                </div>
                <div className="section-divider"></div>
                <div className="pp-pnt7">
                    <h3>Contact Us</h3>
                    <p>If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:support@saytum.com">support@saytum.com</a></p>
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicy;
