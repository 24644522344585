import { Detail, DetailContent, DetailInformation } from 'components';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { 
    AuctionBidsContainer,
    CreateAuctionBidContainer,
    AuctionPaysContainer
} from 'container';

class AuctionDetailsContainer extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            consignment_data: {},
            auction_data: null
        }
    }

    render() {
        
        const { 
            auctions, 
            match, 
            material_categories, 
            fleet_categories,
            user
        } = this.props;
        const { innerWidth } = window;

        var id = match.params.id;
        var auction = auctions.find((item) => parseFloat(item.auction_id) === parseFloat(id))

        var material_category = material_categories.find((item) => parseFloat(item.id) === parseFloat(auction.material_category_id));
        var fleet_category = fleet_categories.find((item) => parseFloat(item.id) === parseFloat(auction.fleet_category_id));
        
        return(
            <>
                <Detail
                    style={innerWidth > 768 ? { marginTop: 50, marginBottom: 50 } : { marginTop: 15, marginBottom: 0 }}
                    withBackButton
                    backButtonAction={() => this.props.history.goBack()}
                >
                    <DetailContent
                        header="Auction Detail"
                    >
                        <div className="details__item">
                            <div className="details__item__group">
                                <p className="label">Order Name</p>
                                <p className="text">{auction.order_name}</p>
                            </div>
                            {auction.status >= 142 && (
                                <div className="details__item__group">
                                    <p className="label">Delivery Boy</p>
                                    <p className="text">{auction.delivery_boy_name}</p>
                                </div>
                            )}
                        </div>
                        <div className="details__item">
                            <h4 className="details__item__header">Pickup</h4>

                            <div className="details__item__group">
                                <p className="label">Sender Name</p>
                                <p className="text">{auction.pickup_name}</p>
                            </div>
                            <div className="details__item__group">
                                <p className="label">Mobile Number</p>
                                <p className="text">{auction.pickup_mobile_prefix} {auction.pickup_mobile_number}</p>
                            </div>
                            <div className="details__item__group">
                                <p className="label">Address</p>
                                <p className="text">{auction.pickup_address_one}</p>
                            </div>
                            <Row>
                                <Col>
                                    <div className="details__item__group">
                                        <p className="label">City</p>
                                        <p className="text">{auction.pickup_cities}</p>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="details__item__group">
                                        <p className="label">State</p>
                                        <p className="text">{auction.pickup_states}</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="details__item__group">
                                        <p className="label">Country</p>
                                        <p className="text">{auction.pickup_countries}</p>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="details__item__group">
                                        <p className="label">Postal Code</p>
                                        <p className="text">{auction.pickup_postal_code}</p>
                                    </div>
                                </Col>
                            </Row>
                            <div className="details__item__group">
                                <p className="label">Pickup Date</p>
                                <p className="text">{auction.pickup_time ? auction.pickup_time.replace(/_/g, " ") : ""} - {moment(auction.pickup_date).format("DD MMM YYYY HH:mm")}</p>
                            </div>
                        </div>
                        <div className="details__item">
                            <h4 className="details__item__header">Delivery</h4>

                            <div className="details__item__group">
                                <p className="label">Receiver Name</p>
                                <p className="text">{auction.delivery_name}</p>
                            </div>
                            <div className="details__item__group">
                                <p className="label">Mobile Number</p>
                                <p className="text">{auction.delivery_mobile_prefix} {auction.delivery_mobile_number}</p>
                            </div>
                            <div className="details__item__group">
                                <p className="label">Address</p>
                                <p className="text">{auction.delivery_address_one}</p>
                            </div>
                            <Row>
                                <Col>
                                    <div className="details__item__group">
                                        <p className="label">City</p>
                                        <p className="text">{auction.delivery_cities}</p>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="details__item__group">
                                        <p className="label">State</p>
                                        <p className="text">{auction.delivery_states}</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="details__item__group">
                                        <p className="label">Country</p>
                                        <p className="text">{auction.delivery_countries}</p>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="details__item__group">
                                        <p className="label">Postal Code</p>
                                        <p className="text">{auction.delivery_postal_code}</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="details__item">
                            <h4 className="details__item__header">Material</h4>

                            <div className="details__item__group">
                                <p className="label">Material Category</p>
                                <p className="text">{material_category ? material_category.name : ""}</p>
                            </div>
                            <div className="details__item__group">
                                <p className="label">Material Detail</p>
                                <p className="text">{auction.material_detail}</p>
                            </div>
                            <Row>
                                <Col>
                                    <div className="details__item__group">
                                        <p className="label">Weight</p>
                                        <p className="text">{auction.weight} Kg</p>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="details__item__group">
                                        <p className="label">Transportation Mode</p>
                                        <p className="text">{fleet_category ? fleet_category.name : ""}</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className="details__item__group">
                                        <p className="label">Consignor GST Number</p>
                                        <p className="text">{auction.gst_number || "-"}</p>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="details__item__group">
                                        <p className="label">Material Price</p>
                                        <p className="text">{auction.material_price} INR</p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </DetailContent>
                    <DetailInformation>
                        {auction && (
                            <div className="details__information" style={{ marginBottom: 20 }}> 
                                {user.role === "user" && (
                                    <AuctionBidsContainer 
                                        auction={auction}
                                        {...this.props}
                                    />
                                )}

                                {user.role === "partner" && (
                                    <CreateAuctionBidContainer 
                                        auction={auction}
                                        {...this.props}
                                    />
                                )}
                            </div>
                        )}
                        {user.role === "user" && (
                            <>
                                {auction.auction_status === 112 || auction.status === 103 ? (
                                    <div className="details__information" style={{ marginBottom: 20 }}> 
                                            <AuctionPaysContainer 
                                                auction={auction}
                                                {...this.props}
                                            />
                                    </div>
                                ) : null}
                            </>
                        )}
                    </DetailInformation>
                </Detail>
                
            </>
        )
    }

}

const mapsStateToProps = ({ authenticated, consignment, material_categories, fleet_categories, auction }) => {
    return {
        user: authenticated.userInfo,
        consignments: consignment.data,
        material_categories: material_categories.lists,
        fleet_categories: fleet_categories.lists,
        status: consignment.status,
        auctions: auction.lists
    }
}

export default connect(mapsStateToProps)(AuctionDetailsContainer);