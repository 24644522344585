import React from 'react';
import "./styles.scss";
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Tools from 'common/Tools';
import { FirebaseAPI } from 'services/FirebaseAPI';


class ProfileDocument extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            document_image: ""
        }
    }

    async componentDidMount() {
        const { partner } = this.props.user;
        var document_image = await "";
        if(Tools.isNotEmpty(partner.passport_number)) {
            document_image = await FirebaseAPI.getStorageFile(partner.passport_image);
        } 
        else if(Tools.isNotEmpty(partner.driving_licence_number)) {
            document_image = await FirebaseAPI.getStorageFile(partner.driving_licence_image);
        } 
        else if(Tools.isNotEmpty(partner.national_identity_number)) {
            document_image = await FirebaseAPI.getStorageFile(partner.national_identity_image);
        } 

        this.setState({
            document_image
        })
    }

    render() {

        const { user } = this.props;
        const { partner } = user;

        return(
            user.role === "partner" && (
                <div className="details__item">
                    <h4 className="details__item__header">Document Detail</h4>

                    {partner.is_company ? (
                        <>
                            <Row>
                                <Col>
                                    <div className="details__item__group">
                                        <p className="label">Company Registration Number</p>
                                        <p className="text">{partner.company_registration_number || "-"}</p>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="details__item__group">
                                        <p className="label">GST Number</p>
                                        <p className="text">{partner.gst_number || "-"}</p>
                                    </div>
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <>
                            {/* {Tools.isNotEmpty(partner.passport_number) && (
                                <>
                                    <Row>
                                        <Col>
                                            <div className="details__item__group">
                                                <p className="label">Passport Number</p>
                                                <p className="text">{partner.passport_number}</p>
                                            </div>
                                        </Col>
                                        {/* <Col>
                                            <div className="details__item__group">
                                                <p className="label">Pan Number</p>
                                                <p className="text">{partner.pan_number || "-"}</p>
                                            </div>
                                        </Col> 
                                    </Row>
                                    
                                    <div className="details__item__group">
                                        <p className="label">Passport Image</p>
                                        <img src={this.state.document_image} alt="document" className="profile__content__document__image" />
                                    </div>
                                </>
                            )} */}

                            {/* {Tools.isNotEmpty(partner.national_identity_number) && (
                                <>
                                    <Row>
                                        <Col>
                                            <div className="details__item__group">
                                                <p className="label">Aadhaar Number</p>
                                                <p className="text">{partner.national_identity_number}</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="details__item__group">
                                                <p className="label">Pan Number</p>
                                                <p className="text">{partner.pan_number}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                    
                                    <div className="details__item__group">
                                        <p className="label">Aadhaar Image</p>
                                        <img src={this.state.document_image} alt="document" className="profile__content__document__image" />
                                    </div>
                                </>
                            )} */}

                            {Tools.isNotEmpty(partner.driving_licence_number) && (
                                <>
                                    <Row>
                                        <Col>
                                            <div className="details__item__group">
                                                <p className="label">Driving License Number</p>
                                                <p className="text">{partner.driving_licence_number}</p>
                                            </div>
                                        </Col>
                                        {/* <Col>
                                            <div className="details__item__group">
                                                <p className="label">Pan Number</p>
                                                <p className="text">{partner.pan_number}</p>
                                            </div>
                                        </Col> */}
                                    </Row>
                                    
                                    <div className="details__item__group">
                                        <p className="label">Driving License Image</p>
                                        <img src={this.state.document_image} alt="document" className="profile__content__document__image" />
                                    </div>
                                </>
                            )}
                        </>
                    )}
                    
                </div>
            )
        )
    }

}

const mapsStateToProps = ({ authenticated }) => {
    return {
        user: authenticated.userInfo
    }
}

export default connect(mapsStateToProps)(withRouter(ProfileDocument));