import React from "react";
import { connect } from "react-redux";
import { Button, FormBody, FormWrapper, Input } from "components";
import { Tools, navigate, Images } from "common";

var validation = {
  password: "password",
  password_confirmation: "password_confirmation",
};

class ResetPasswordContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        password: "",
        password_confirmation: "",
      },
      send: false,
      errors: [],
      passwordError: false,
      newPasswordError: false,
    };
  }

  onChange = (target, value) => {
    this.setState({
      send: false,
      data: {
        ...this.state.data,
        [target]: value,
      },
    });
  };

  onBlurValidation = (target) => {
    if (target === "password") {
      if (!Tools.isPasswordValid(this.state.data.password)) {
        this.setState({
          ...this.state,
          passwordError: true,
        });
      } else {
        this.setState({
          ...this.state,
          passwordError: false,
        });
      }
    }
    if (target === "newPassword") {
      if (!Tools.isNotEmpty(this.state.data.password_confirmation)) {
        this.setState({
          ...this.state,
          newPasswordError: true,
        });
      } else {
        this.setState({
          ...this.state,
          newPasswordError: false,
        });
      }
    }
  };

  validation = async () => {
    var validate = await Tools.validation(validation, this.state.data);

    this.setState({
      valid: validate.valid,
      errors: validate.errors,
      send: true,
    });

    return validate.valid;
  };

  handleChangePassword = async () => {
    const valid = await this.validation();
    if (valid) {
      var params = this.props.history.location.search;
      var search = new URLSearchParams(params);
      const { resetPassword, toastPending, history } = this.props;

      var json = await resetPassword({
        password: this.state.data.password,
        oobCode: search.get("oobCode"),
      });
      if (json.status_code === 200) {
        navigate(history, "/", "login", "replace");
      }

      toastPending({ message: json.message, toastType: json.status_code });
    }
  };

  render() {
    const { show_password_confirm, show_password } = this.state;

    return (
      <div
        className="container"
        style={
          window.innerWidth > 768
            ? { marginTop: 50, marginBottom: 50 }
            : { marginTop: 15, marginBottom: 15 }
        }
      >
        <FormWrapper>
          <FormBody>
            <h1 className="text__verification__header">Reset Password</h1>
            <h3
              className="text__verification__body"
              style={{ marginBottom: 30 }}
            >
              Create new password for your account
            </h3>
            <Input
              type={!show_password ? "password" : "text"}
              iconPrefix={Images.password}
              iconSuffix={!show_password ? Images.eye_close : Images.eye}
              clickableIcon={["suffix"]}
              onIconClick={() =>
                this.setState({ show_password: !show_password })
              }
              label="New Password"
              placeholder="example@email.com"
              style={{ margin: 0, marginBottom: 30 }}
              caption={
                this.state.passwordError && "Password length must be of 6-12"
              }
              value={this.state.data.password}
              isError={
                (this.state.send && !this.state.valid) ||
                this.state.passwordError
              }
              onBlur={() => this.onBlurValidation("password")}
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  passwordError: false,
                });
                this.onChange("password", e.target.value);
              }}
            />
            <Input
              type={!show_password_confirm ? "password" : "text"}
              iconPrefix={Images.password}
              iconSuffix={
                !show_password_confirm ? Images.eye_close : Images.eye
              }
              clickableIcon={["suffix"]}
              onIconClick={() =>
                this.setState({ show_password_confirm: !show_password_confirm })
              }
              label="Re-Type New Password"
              placeholder="example@email.com"
              style={{ margin: 0, marginBottom: 30 }}
              caption={
                this.state.newPasswordError
                  ? "Password length must be of 6-12"
                  : !Tools.isPasswordConfirmed(
                      this.state.data.password,
                      this.state.data.password_confirmation
                    ) && "Password confirmation does not match"
              }
              value={this.state.data.password_confirmation}
              isError={
                (this.state.send && !this.state.valid) ||
                this.state.newPasswordError
              }
              onBlur={() => this.onBlurValidation("newPassword")}
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  newPasswordError: false,
                });
                this.onChange("password_confirmation", e.target.value);
              }}
            />
            <Button
              buttonText="Reset Password"
              size="lg"
              type="warning"
              style={{ margin: 0 }}
              onClick={() => this.handleChangePassword()}
            />
          </FormBody>
        </FormWrapper>
      </div>
    );
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/AuthenticatedRedux");

  return {
    ...ownProps,
    ...stateProps,
    resetPassword: async (body) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await actions.resetPassword(dispatch, body);
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    toastPending: (body) => {
      dispatch({ type: "TOAST_PENDING", ...body });
    },
  };
};

export default connect(
  undefined,
  undefined,
  mergeProps
)(ResetPasswordContainer);
