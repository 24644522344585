import React from 'react';
import { Button, FormBody, ButtonGroup, Modal } from 'components';
import "./details.scss";



class AuctionBidderDetailContainer extends React.PureComponent {

    render() {

        const { data } = this.props;

        return(
            <Modal
                reference={(ref) => this.rejection_modal = ref}
                isVisible={this.props.isVisible}
                className="modal__form"
            >
                <FormBody>
                    <h1 className="text__verification__header" style={{ marginBottom: 30 }}>Bid Detail</h1>
                    
                    <div className="bid-detail__item">
                        <p className="label">Company Name</p>
                        <p className="text">{data.partner.name}</p>
                    </div>
                    <div className="bid-detail__item">
                        <p className="label">Bid Amount</p>
                        <p className="text">INR {data.bid_price}</p>
                    </div>
                    <div className="bid-detail__item">
                        <p className="label">Estimated Delivery Time</p>
                        <p className="text">{data.delivery_days}</p>
                    </div>
                    <div className="bid-detail__item">
                        <p className="label">Cover Letter</p>
                        <p className="text">{data.description}</p>
                    </div>


                    <ButtonGroup position="center" className="bid-detail__button-group">
                        <Button
                            buttonText="Close"
                            size="md"
                            type="default"
                            style={{ margin: 0, width: '48%', minWidth: '48%', marginRight: '2%' }}
                            onClick={() => this.props.onClose()}
                        />
                        <Button
                            buttonText="Accept Bid"
                            size="md"
                            type="success"
                            style={{ margin: 0, width: '48%', minWidth: '48%', marginLeft: '2%' }}
                            onClick={() => this.props.onAccept(data)}
                        />
                    </ButtonGroup>
                </FormBody>
            </Modal>
        )
    }

}

export default AuctionBidderDetailContainer;