import React from 'react';
import FacebookSocialButton from './facebook-social-button';
import GoogleSocialButton from './google-social-button';

class SocialButton extends React.PureComponent {

    render() {

        const { type } = this.props;

        if(type === "google") {
            return <GoogleSocialButton {...this.props} />
        }
        if(type === "facebook") {
            return <FacebookSocialButton {...this.props} />
        }

    }

}

export default (SocialButton);