import React, { useState } from "react";
import { Images, Constant, Tools } from "common";
import { Button, Input } from "components";
import { toast } from "react-toastify";
import { ContactApi } from "services";

const ContactUs = () => {
    const [field, setField] = useState({
        name: "",
                email: "",
        mobile: "",
                message: "",
        mobile_prefix: Constant.COUNTRY_CODE.default,
      });

  const [fieldError, setFieldError] = useState({
    name: false,
    email: false,
    mobile: false,
    message: false,
  });

  const onSubmitHandler = () => {
    const validate = validationHandler();
    if (!validate) {
      const newObj = {
        name: field.name,
        email: field.email,
        mobile_number: field.mobile_prefix.value + field.mobile,
        message: field.message,
      };

      ContactApi.sendEmail(newObj)
        .then(() => {
          setField({
            name: "",
            email: "",
            mobile: "",
            mobile_prefix: Constant.COUNTRY_CODE.default,
            message: "",
          });

          return toast.success(
            "Thankyou for contacting us, Our team will be in touch with you shortly ",
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
        })
        .catch((err) =>
          toast.error("Something went wrong, please try again later" + err.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          })
        );
    } else {
      toast.error("Validation error", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const onChangeHandler = (e, name) => {
    setField({
      ...field,
      [name]: e.target.value,
    });
  };
  const onBlurValidationHandler = (target) => {
    if (target === "name") {
      if (!Tools.isNotEmpty(field.name)) {
        setFieldError({
          ...fieldError,
          name: true,
          });
      } else {
        setFieldError({
          ...fieldError,
          name: false,
        });
      }
    }
    if (target === "email") {
      if (!Tools.isEmailValid(field.email)) {
        setFieldError({
          ...fieldError,
          email: true,
        });
      } else {
        setFieldError({
          ...fieldError,
          email: false,
        });
      }
    }
        if (target === "message") {
      if (!Tools.isNotEmpty(field.message)) {
        setFieldError({
          ...fieldError,
          message: true,
        });
      } else {
        setFieldError({
          ...fieldError,
          message: false,
        });
      }
    }
  };

  const validationHandler = () => {
    let name = false,
      email = false,
      message = false,
      error = false;

    if (!Tools.isNotEmpty(field.name)) {
      name = true;
      error = true;
    }
    if (!Tools.isEmailValid(field.email)) {
      email = true;
      error = true;
    }
    if (!Tools.isNotEmpty(field.message)) {
      message = true;
      error = true;
    }

    setFieldError({
      name,
      email,
      message,
    });
    return error;
  };
  return (
    <>
      <div className="landing-page__contact" >
        <div className="landing-page__contact__container">
          <h1 className="landing-page__contact__container__header">
            Contact Us
          </h1>

          <div className="landing-page__contact__container__content">
            <div className="landing-page__contact__container__content__left">
              <form className="form">
                <Input
                  type="text"
                  label="Name"
                  name="name"
                  placeholder="Please type here"
                  style={{ marginBottom: 20 }}
                  value={field.name}
                  onChange={(e) => {
                    setFieldError({
                      ...fieldError,
                      name: false,
                    });
                    onChangeHandler(e, "name");
                  }}
                  onBlur={() => onBlurValidationHandler("name")}
                  caption={fieldError.name && "this field is required"}
                  isError={fieldError.name}
                  isRequired={true}
                />
                <Input
                  type="email"
                  label="Email"
                  name="email"
                  placeholder="Please type here"
                  style={{ marginBottom: 20 }}
                  value={field.email}
                  onChange={(e) => {
                    setFieldError({
                      ...fieldError,
                      email: false,
                    });
                    onChangeHandler(e, "email");
                  }}
                  onBlur={() => onBlurValidationHandler("email")}
                  caption={
                    fieldError.email
                      ? field.email
                        ? "please enter valid email"
                        : "this field is required"
                      : ""
                  }
                  isError={fieldError.email}
                  isRequired={true}
                />
                <Input
                  type="phone"
                  phoneCodes={Constant.COUNTRY_CODE.options}
                  label="Mobile Number"
                  name="mobile"
                  placeholder="Please type here"
                  style={{ marginBottom: 20 }}
                  value={field.mobile}
                  selectedPrefix={field.mobile_prefix}
                  onSelect={(e) => {
                    setFieldError({
                      ...fieldError,
                      mobile: false,
                    });
                    setField({
                      ...field,
                      mobile_prefix: e,
                    });
                  }}
                  onChange={(e) => {
                    setFieldError({
                      ...fieldError,
                      mobile: false,
                    });
                    onChangeHandler(e, "mobile");
                  }}
                  onBlur={() => onBlurValidationHandler("mobile")}
                  caption={fieldError.mobile && "this field is required"}
                  isError={fieldError.mobile}
                  isRequired={true}
                  // iconSuffix={Images.qrcode}
                  // clickableIcon={["suffix"]}
                  // clickableIconLabel="Verify"
                  // onIconClick={(param) => {
                  //   handleVerification();
                  // }}
                  // clickableIconClassName={`button__clickable-icon `}
                />
                <Input
                  type="textarea"
                  label="Message"
                  name="message"
                  placeholder="Please type here"
                  style={{ marginBottom: 20 }}
                  value={field.message}
                  onChange={(e) => {
                    setFieldError({
                      ...fieldError,
                      message: false,
                    });
                    onChangeHandler(e, "message");
                  }}
                  onBlur={() => onBlurValidationHandler("message")}
                  caption={fieldError.message && "this field is required"}
                  isError={fieldError.message}
                  isRequired={true}
                />
                {/* {verificationId && <div id="recaptcha-container"></div>} */}
                {/* {load && <ReCAPTCHA onChange={onChange} sitekey={siteKey} />} */}
                {/* {error && (
                  <p style={{ color: "red" }}>
                    Please verify you are not a Robot
                  </p>
                )} */}
                <Button
                  type="warning"
                  buttonText="SEND"
                  onClick={onSubmitHandler}
                  size="lg"
                  className="button__no-margin landing-page__contact__btn"
                />
              </form>
            </div>

            <div className="landing-page__contact__container__content__right">
              <div className="landing-page__contact__container__content__right__group">
                <img
                  src={Images.address}
                  alt="address"
                  className="landing-page__contact__container__content__right__group__icon"
                />
                <p className="landing-page__contact__container__content__right__group__content">
                  2nd floor, Kohinoor Tower, Junwani Rd, Kohka, Bhilai,
                  Chhattisgarh 490023{" "}
                </p>
              </div>
              <div className="landing-page__contact__container__content__right__group">
                <img
                  src={Images.phone}
                  alt="phone"
                  className="landing-page__contact__container__content__right__group__icon"
                />
                <p className="landing-page__contact__container__content__right__group__content">
                  +91 7827934515{" "}
                </p>
              </div>
              <div className="landing-page__contact__container__content__right__group">
                <img
                  src={Images.email}
                  alt="email"
                  className="landing-page__contact__container__content__right__group__icon"
                />
                <a href="mailto:support@saytum.com" className="hyperlink"><p className="landing-page__contact__container__content__right__group__content">
                    support@saytum.com{" "}
                  </p></a>
              </div>

              {/* <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14877.826268367337!2d81.3180686!3d21.2137364!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a293d241c0af23d%3A0x2d7cf3c08594d562!2sSaytum%20Technologies%20Private%20Limited!5e0!3m2!1sen!2ssg!4v1677930590282!5m2!1sen!2ssg"
                title="Map"
                width="600"
                height="450"
                allowFullScreen=""
                loading="lazy"
              /> */}
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14877.366115530387!2d81.32138508715818!3d21.218301399999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a292316bdc01ed3%3A0x85f2615d7a98f5eb!2sSpace%20Station%20Co-Working!5e0!3m2!1sen!2sin!4v1707118094048!5m2!1sen!2sin"
                title="Map"
                width="600"
                height="450"
                allowFullScreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
