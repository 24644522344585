
const types = {
    FETCH_PENDING: "FETCH_PENDING",
    FETCH_FINISH: "FETCH_FINISH",
    TOAST_PENDING: "TOAST_PENDING",
    TOAST_FINISH: "TOAST_FINISH",
    RESET_ERROR: "RESET_ERROR",
    SET_ERROR: "SET_ERROR",

    SET_TAB: "SET_TAB",
}

const initialState = {
    loading: { isVisible: false, label: "", description: "" },
    notification: { isVisible: false, message: "", type: "", duration: 3000 },
    active_tab: { title: "", link: "" },
    error: { hasError: false },

    isFetching: false,
    showToast: false,
    toast: null,
    toastType: null
}

export const reducer = (state = initialState, action) => {

    const { items, toastType, message } = action;

    switch (action.type) {
        case types.SET_TAB:
            return {
                ...state,
                active_tab: items,
            }
        
            
        case types.SET_ERROR: {
            return {
                ...state,
                error: items,
            }
        }
        case types.RESET_ERROR: {
            return {
                ...state,
                error: initialState.error,
            }
        }
        case types.FETCH_PENDING: {
            return Object.assign({}, state, {
                isFetching: true
            })
        }
        case types.FETCH_FINISH: {
            return Object.assign({}, state, {
                isFetching: false
            })
        }
        case types.TOAST_PENDING: {
            return Object.assign({}, state, {
                showToast: true,
                toast: message,
                toastType: toastType
            })
        }
        case types.TOAST_FINISH: {
            return Object.assign({}, state, {
                showToast: false,
                toast: null,
                toastType: null
            })
        }
        default:
            return state;
    }
}
