import { Tools, Images } from 'common';
import { Button, Input, ImageUploader } from 'components';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

var validation = {
    "document_type": "required",
    "document_number": "required",
    "document_image": "required"
}

class JoinPartnerStepTwoContainer extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            data: {
            },
            old_data: {},
            errors: []
        }
    }

    componentDidMount() {
        var data = localStorage.getItem("@registrationData");
        if(data) {
            data = JSON.parse(data);
            this.setState({ 
                data: {
                    ...this.state.data,
                    ...data
                },
                old_data: {
                    ...this.state.data,
                    ...data
                }
            });
        }
    }
    

    onImageChange = (event) => {
        const reader = new FileReader();
        var file = event.target.files[0];

        let local = this;
        reader.onload = function(event) {
          local.setState({
            data: {
                ...local.state.data,
                document_image: {
                    file: event.target.result,
                    name: file.name,
                    value: event.target.result
                },
                is_document_image_changed: true
            }
          });
        };

        reader.readAsDataURL(file);
    }

    onChange = (target, value) => {
        this.setState({
            data: {
                ...this.state.data,
                [target]: value
            },
            send: false
        }, () => {
            if(target === "document_type") {
                if(this.state.old_data.document_type && (value.value === this.state.old_data.document_type.value)) {
                    this.setState({
                        data: {
                            ...this.state.data,
                            document_number: this.state.old_data.document_number,
                            document_image: this.state.old_data.document_image
                        }
                    })
                }
                else {
                    this.setState({
                        data: {
                            ...this.state.data,
                            document_number: null,
                            document_image: null
                        }
                    })
                }
            }
        })
    }

    validation = () => {
        var validate = Tools.validation(validation, this.state.data);
        this.setState({
            valid: validate.valid,
            errors: validate.errors,
            send: true
        });

        return validate.valid;
    }

    onNext = async() => {
        var valid = await this.validation();

        if(valid) {
            var data = localStorage.getItem("@registrationData");
            data = JSON.parse(data);
            data = {
                ...data,
                ...this.state.data
            }
            localStorage.setItem("@registrationData", JSON.stringify(data));
            this.props.onNext()
        }
    } 

    render() {

        const { data } = this.state;
        const options = [
            // { value: "aadhaar", label: "Aadhaar" }, 
            // { value: "passport", label: "Passport" },
            { value: "license", label: "Driving License" }
        ];
        var isResubmit = Tools.getParamFromURL(this.props.history, "resubmit") === "yes";

        return(
            <>
                <div className="container">
                    <div className="container__auth">
                        <Row>
                            <Col>
                                <div className="container__auth__header">
                                    <Button
                                        buttonText="back"
                                        size="sm"
                                        type="default"
                                        icon={Images.arrow_left_grey}
                                        iconPosition="before-text"
                                        style={{ marginLeft: 0, minWidth: 'auto', padding: 0, textTransform: 'capitalize', color: '#92929D', marginBottom: 20 }}
                                        iconStyle={{ marginRight: 2, marginTop: -2 }}
                                        onClick={this.props.onPrevious}
                                    />
                                    <h1 className="main-header-text">Select Document</h1>
                                    <h4 className="secondary-header-text">Join to bidding for multiple jobs. Get your income increased and better.</h4>
                                </div>
                                <div className="container__auth__body">
                                    <Input
                                        type="select"
                                        options={options}
                                        style={{ margin: 0, marginBottom: 25 }}
                                        label="Select the document for your verification"
                                        value={data.document_type}
                                        onChange={(e) => this.onChange("document_type", e)}
                                        caption={!Tools.isNotEmpty(data.document_type) && "This field is required"}
                                        isError={this.state.send && !this.state.valid && this.state.errors.includes("document_type")}
                                        isRequired={true}
                                    />
                                    <Input
                                        type="text"
                                        label="Document Number"
                                        iconPrefix={Images.user}
                                        placeholder="Enter your document number"
                                        style={{ margin: 0, marginBottom: 25 }}
                                        value={data.document_number ?? ""}
                                        onChange={(e) => this.onChange("document_number", e.target.value)}
                                        caption={!Tools.isNotEmpty(data.document_number) && "This field is required"}
                                        isError={this.state.send && !this.state.valid && this.state.errors.includes("document_number")}
                                        isRequired={true}
                                    />
                                    <ImageUploader
                                        value={data.document_image ?? {}}
                                        label="Document Image"
                                        style={{ margin: 0, marginBottom: 25 }}
                                        onChange={(e) => this.onImageChange(e)}
                                        caption={!Tools.isNotEmpty(data.document_image) && "This field is required"}
                                        isError={this.state.send && !this.state.valid && this.state.errors.includes("document_image")}
                                        isRequired={true}
                                    />
                                    {/* <Input
                                        type="text"
                                        label="Pan Number"
                                        iconPrefix={Images.user}
                                        placeholder="Enter your pan number"
                                        style={{ margin: 0, marginBottom: 25 }}
                                        value={data.pan_number}
                                        onChange={(e) => this.onChange("pan_number", e.target.value)}
                                    /> */}
                                    <Button
                                        size="lg"
                                        type="warning"
                                        buttonText={isResubmit ? "Resubmit Registration" : "Join Partner"}
                                        variation="primary"
                                        style={{ margin: 0, marginTop: 30, marginBottom: 20 }}
                                        onClick={() => this.onNext()}
                                    />
                                </div>
                            </Col>
                            <Col className="banner-container">
                                <img src={Images.banner_login} alt="user-login.png" />
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    }

}

export default (JoinPartnerStepTwoContainer);