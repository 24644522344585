import images from "common/Images";
import { Button } from "components";
import React from "react";
import Webcam from "react-webcam";
import "./styles.scss";


class Camera extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            resetCameraView: false,
            videoConstraints: { 
                facingMode: {
                    exact: "environment" 
                }
            }
        }
    }

    onCapture = () => {
        var image = this.cameraRef.getScreenshot({ width: 1920, height: 1080 });
        this.props.onCapture(image)
    }

    onSwitchCamera() {
        const { facingMode } = this.state.videoConstraints;
        const newFacingMode = facingMode === 'user' ? { exact: 'environment' } : 'user'
    
        this.setState({
            videoConstraints: { facingMode: newFacingMode }
        })
    }

    render() {
        return(
            <div className="camera__wrapper">

                <Webcam
                    ref={(ref) => this.cameraRef = ref}
                    className="camera__selector"            
                    screenshotFormat="image/jpeg"
                    videoConstraints={this.state.videoConstraints}
                />

                <div className="camera__container">
                    <Button
                        buttonText=""
                        icon={images.camera}
                        iconPosition="before-text"
                        className="camera__btn"
                        iconClassName="camera__btn__icon"
                        onClick={() => this.onCapture()}
                    />

                    <Button
                        buttonText=""
                        icon={images.rotate}
                        iconPosition="before-text"
                        className="camera__btn"
                        iconClassName="camera__btn__icon"
                        onClick={() => this.onSwitchCamera()}
                    />

                    <Button
                        buttonText=""
                        icon={images.close_dark}
                        iconPosition="before-text"
                        className="camera__btn"
                        iconClassName="camera__btn__icon"
                        onClick={() => this.props.onClose()}
                    />
                </div>

            </div>
        )
    }

}

export default Camera;