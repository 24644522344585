const en = {
    // Common
    welcome_top: "Welcome to Saytum!",
    welcome_center: "Saytum is a digital platform that brings together multiple logistics service providers under one roof, enabling businesses to easily compare, select and book logistics services for their transportation and supply chain needs.",
    welcome_bottom: "Welcome to Saytum platform, where we strive to simplify the transportation and logistics industry for businesses and individuals alike. Our platform brings together a vast network of reliable carriers and logistics providers, allowing users to easily compare and choose the best transportation options to fit their specific needs. With our user-friendly interface and advanced technology, we aim to streamline the end to end process, save time and money, and provide exceptional customer service. ",
    about: "about",
    mission: "mission",
    contact: "contact",
    download_app: "download app",

    // Validation
    required: "This fields is required",

    // Notification
    registration: {
        success: "Registration successfull. We have sent verification email to your registered email id. Please verify to complete the registration.",
        resent_verification: "We have resent the verification email to your registered email id. Please verify to complete the registration",
        verified: "Account verification successfully completed",
        finish: "Registration Successfull",
        email_registered: "The email id is already registered",
        credintial_registered: (credintial) => { return `${credintial} already registered` }
    },
    login: {
        success: (name) => { return `Welcome Back ${name}` },
        incorrect_password: "Incorrect password",
        user_not_found: "Email not registered"
    },
    logout: {
        success: "You are logged out"
    },
    verification: {
        invalid_code: "Invalid verification code",
        success: (credintial) => { return `${credintial} verification is successfull` }
    },
    reset_password: {
        verification_sent: "We have sent password reset link to your registered email",
        success: "Password reset successfull",
        email_not_found: "Email does not exists"
    },
    consignment: {
        published: "Consignment order is accepted, Delivery partners will be sharing the price quotation soon"
    },
    account_not_verified: "Your account is not verified"
}

export default en;