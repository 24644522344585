import React from 'react';
import './ToggleSwitch.css';

const ToggleSwitch = ({ activeSection, handleSectionChange }) => {
  return (
    <div className="toggle-switch">
      <input
        type="checkbox"
        id="toggle"
        className='toggleCheckbox'
        checked={activeSection === 'partner'}
        onChange={() => handleSectionChange(activeSection === 'user' ? 'partner' : 'user')}
      />
      <label htmlFor="toggle" className="toggle-label">
        <div className={`toggle-text user ${activeSection === 'user' ? 'active' : ''}`}>User</div>
        <div className={`toggle-text partner ${activeSection === 'partner' ? 'active' : ''}`}>Partner</div>
      </label>
    </div>
  );
};

export default ToggleSwitch;
