import { Images, Tools } from 'common';
import { Button, Input } from 'components';
import React from 'react';
import { Row, Col } from 'react-bootstrap';

class RegisterStepThree extends React.PureComponent {

    state = {
        data: {
        },
        errors: []
    }

    componentDidMount() {
        var data = localStorage.getItem("@registrationData");
        if(data) {
            data = JSON.parse(data);
            this.setState({ data: {
                    ...this.state.data,
                    ...data 
            } });
        }
    }

    onChange = (target, value) => {
        this.setState({
            data: {
                ...this.state.data,
                [target]: value
            }
        })
    }

    onNext = async() => {
        var data = localStorage.getItem("@registrationData");
        data = JSON.parse(data);
        data = {
            ...data,
            ...this.state.data
        }

        localStorage.setItem("@registrationData", JSON.stringify(data));
        this.props.onNext()
    } 

    render() {

        const { data } = this.state;
        var isResubmit = Tools.getParamFromURL(this.props.history, "resubmit") === "yes";

        return(
            <>
                <div className="container">
                    <div className="container__auth">
                        <Row>
                            <Col>
                                <div className="container__auth__header">
                                    <Button
                                        buttonText="back"
                                        size="sm"
                                        type="default"
                                        icon={Images.arrow_left_grey}
                                        iconPosition="before-text"
                                        style={{ marginLeft: 0, minWidth: 'auto', padding: 0, textTransform: 'capitalize', color: '#92929D', marginBottom: 20 }}
                                        iconStyle={{ marginRight: 2, marginTop: -2 }}
                                        onClick={this.props.onPrevious}
                                    />

                                    <h1 className="main-header-text">Complete Registration</h1>
                                    <h4 className="secondary-header-text">Join to bidding for multiple jobs. Get your income increased and better.</h4>
                                </div>
                                <div className="container__auth__body">
                                    
                                    <Input
                                        type="text"
                                        label="Company Registration Number"
                                        iconPrefix={Images.user}
                                        placeholder="Enter your company registration number"
                                        style={{ margin: 0, marginBottom: 25 }}
                                        value={data.company_registration_number}
                                        onChange={(e) => this.onChange("company_registration_number", e.target.value)}
                                    />
                                    <Input
                                        type="text"
                                        label="GST Number"
                                        iconPrefix={Images.user}
                                        placeholder="Enter your GST number"
                                        style={{ margin: 0, marginBottom: 25 }}
                                        value={data.gst_number}
                                        onChange={(e) => this.onChange("gst_number", e.target.value)}
                                    />

                                    <Button
                                        size="lg"
                                        type="warning"
                                        buttonText={isResubmit ? "Resubmit Registration" : "Join Partner"}
                                        variation="primary"
                                        style={{ margin: 0, marginTop: 20, marginBottom: 20 }}
                                        onClick={() => this.onNext()}
                                    />
                                </div>
                            </Col>
                            <Col className="banner-container">
                                <img src={Images.banner_login} alt="user-login.png" />
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    }

}

export default (RegisterStepThree);