import React from 'react';
import { Input, Confirmation } from 'components';
import { Images, navigate } from 'common';
import { connect } from 'react-redux';
import { FirebaseAPI } from 'services';
import { Row, Col } from 'react-bootstrap';
import "./card.scss";
import ContentLoader from 'components/Loader/content';

class DeliveryBoysCardContainer extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            confirmation: {
                isVisible: false,
                header: "",
                description: "",
                onCancel: null,
                onConfirm: null
            },
            rejectionData: {
                notes: "",
                isVisible: false
            },
            filteredData: [],
            keyword: "",
            loading: true
        }
    }

    async componentDidMount() {
        const {
            token,
            partner,
            fetchPartnersDeliveryBoys,
            clearDeliveryBoys
        } = this.props
        this.setState({ loading: true })
        clearDeliveryBoys()
        var json = await fetchPartnersDeliveryBoys(token, partner.id)
        if(json.status_code === 200) {
            var data = await this.initialData(json.data.data)
            this.setState({ data })
        }
        this.setState({ loading: false })
    }

    initialData = (data) => {
        const promises = data.map(async (item) => {
            return {
                ...item,
                photo_profile: await FirebaseAPI.getStorageFile(item.photo)
            }
        });
        return Promise.all(promises);
    }

    openConfirmation = (headerText, descriptionText, onCancel, onConfirm) => {
        this.setState({
            confirmation: {
                isVisible: true,
                header: headerText,
                description: descriptionText,
                onCancel: onCancel,
                onConfirm: onConfirm
            }
        })
    }

    onCancelConfirmation = () => {
        this.setState({
            confirmation: {
                isVisible: false
            }
        })
    }

    onSearch = () => {
        const { keyword, data } = this.state;
        var filteredData = data.filter((item) => item.name.toLowerCase().includes(keyword.toLowerCase()))    
        
        this.setState({
            filteredData: filteredData
        });
    }

    handleAssignJob = async(item) => {
        const {
            auction,
            pickupScheduleAuction,
            toastPending,
            token,
            history
        } = this.props
        var param = {
            consignment_id: auction.id,
            auction_id: auction.auction_id,
            partner_id: item.partner_id,
            delivery_boy_id: item.id
        }
        var json = await pickupScheduleAuction(token, param)
        if(json.status_code === 200) {
            navigate(history, "/dashboard?screen=auction")
        }
        toastPending({ message: json.message, toastType: json.status_code })
    }

    render() {

        const { confirmation, keyword, filteredData, data } = this.state;
        const { innerWidth } = window;

        var selectedData = keyword ? filteredData : data;
        return(
            <>

                <Confirmation
                    isVisible={confirmation.isVisible}
                    header={confirmation.header}
                    description={confirmation.description}
                    confirmationText="Yes"
                    onCancel={confirmation.onCancel}
                    onConfirm={confirmation.onConfirm}
                />

                <Input
                    style={innerWidth > 768 ? { marginLeft: 40, marginRight: 40, marginBottom: 0, marginTop: 20 } : {  marginLeft: 10, marginRight: 10, marginBottom: 20, marginTop: 20 }}
                    iconPrefix={Images.search}
                    placeholder="Search..."
                    value={this.state.keyword}
                    onChange={(e) => this.setState({ keyword: e.target.value }, () => this.onSearch())}
                />

                {this.state.loading ? (
                    <ContentLoader 
                        isVisible={this.state.loading}
                    />
                ) : (
                    <div className="worker-card">
                        {selectedData.length > 0 ?
                            <Row>
                                {selectedData.map((item, index) => {
                                    return (
                                        <Col lg="6" key={index}>
                                            <div className="worker-card__item">
                                                <div className="flex worker-card__item__wrapper">
                                                    <img src={item.photo_profile} alt="passport" className="worker-card__item__image" />
                                                    <div>
                                                        <h4 className="worker-card__item__header">{item.name}</h4>
                                                        <p className="worker-card__item__description">{item.mobile_prefix} {item.mobile_number}</p>
                                                    </div>
                                                </div>
                                                <button className="button__link" onClick={() => this.handleAssignJob(item)}>
                                                    <img src={Images.arrow_right} alt="arrow" className="worker-card__item__arrow" />
                                                </button>
                                            </div>
                                        </Col>
                                    )
                                })}
                            </Row>
                        :
                            <div className="details__empty">
                                <img src={Images.empty} alt="empty" />
                                <p className="text text__bold text__uppercase">
                                    {this.state.keyword ?
                                        `No search results for "${this.state.keyword}"`
                                    : 
                                        "You don't have a worker yet. Go to master data menu to add it."
                                    }
                                </p>
                            </div>
                        }
                    </div>
                )}
            </>
        )
    }

}

const mapsStateToProps = ({ authenticated, delivery_boys }) => {
    return {
        token: authenticated.token,
        partner: authenticated.userInfo.partner,
        delivery_boys: delivery_boys.lists
    }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { actions } = require("redux/DeliveryBoysRedux");
    const { actions: auction_actions } = require("redux/AuctionRedux");

    return {
      ...ownProps,
      ...stateProps,
      fetchPartnersDeliveryBoys: async(
        token,
        partner_id
      ) => {
        var json = await actions.fetchPartnersDeliveryBoy(dispatch, token, partner_id)
        return json
      },
      pickupScheduleAuction: async(
        token,
        body
      ) => {
        dispatch({ type: "FETCH_PENDING" })
        var json = await auction_actions.pickupScheduleAuction(dispatch, token, body)
        dispatch({ type: "FETCH_FINISH" })
        return json
      },
      clearDeliveryBoys: () => dispatch({ type: "CLEAR_DELIVERY_BOY" }),
      toastPending: (body) => {
        dispatch({ type: "TOAST_PENDING", ...body })
      }
    };
}


export default connect(
    mapsStateToProps, 
    undefined,
    mergeProps
)(DeliveryBoysCardContainer);