import React from "react";
import { Table, Confirmation, Filter } from "components";
import { connect } from "react-redux";
import { DropdownButton, Dropdown } from "react-bootstrap";
import moment from "moment";
import { withRouter } from "react-router";
import { Tools, navigate } from "common";
import CompleteShipmentContainer from "./Modal/CompleteShipment";
import UpdateShipmentTrackingLocationContainer from "./Modal/UpdateShipmentLocation";

class ShipmentsContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      confirmation: {
        isVisible: false,
        header: "",
        description: "",
        onCancel: null,
        onConfirm: null,
      },
      isUpdateLocation: false,
      isMarkAsDelivered: false,
      selectedConsignment: 0,
    };
  }

  async componentDidMount() {
    var {
      token,
      user,
      fetchTrackings,
      clearTrackings,
      fetchConsignmentStatus,
      fetchPending,
      fetchFinish,
    } = this.props;
    fetchPending();
    clearTrackings();
    await fetchConsignmentStatus(token);
    var user_id = user.id;
    if (user.role === "delivery_boy") {
      var delivery_boy = user.delivery_boy.find(
        (item) => item.is_deleted === false
      );
      user_id = delivery_boy.id;
    } else if (user.role === "partner") {
      user_id = user.partner.id;
    }
    await fetchTrackings(token, user.role, user_id);
    fetchFinish();
  }

  onUpdateLocation = (data) => {
    const { token, updateTrackingLocation } = this.props;
    if (!Tools.isNotEmpty(data.remarks)) {
      data = {
        ...data,
        remarks: "",
      };
    }
    this.setState({ isUpdateLocation: false }, () =>
      updateTrackingLocation(token, data)
    );
  };

  filterDate = (filters) => {
    const { trackings } = this.props;

    var isFilterDate = filters.filter((item) => item.target === "created_date");
    if (isFilterDate.length > 0) {
      var filteredData = [];
      var date_from = filters.find(
        (item) => item.target === "created_date" && item.key === "date_from"
      );
      var date_to = filters.find(
        (item) => item.target === "created_date" && item.key === "date_to"
      );
      if (date_to) {
        var range = moment.range(date_from.value, date_to.value);

        filteredData = trackings.filter((item) => {
          if (moment(item.created_at).within(range)) {
            return item;
          }
          return false;
        });
      } else {
        filteredData = trackings.filter((item) => {
          var created_at = moment(item.created_at).format("YYYY-MM-DD");
          var filterDate = moment(date_from.value).format("YYYY-MM-DD");

          if (moment(created_at).isSame(filterDate)) {
            return item;
          }
          return false;
        });
      }

      return filteredData;
    }

    return trackings;
  };

  onSearch = (keyword) => {
    const { trackings } = this.props;
    var filteredData = trackings.filter(
      (item) =>
        item.delivery_name.toLowerCase().includes(keyword.toLowerCase()) ||
        item.pickup_name.toLowerCase().includes(keyword.toLowerCase()) ||
        item.order_name.toLowerCase().includes(keyword.toLowerCase()) ||
        item.id.toString().includes(keyword)
    );
    
    this.setState({
      keyword: keyword,
      filteredData: filteredData,
    });
  };

  filterStatus = async (data, filters) => {
    const { status } = this.props;

    var filterStatus = filters.filter((item) => item.target === "status");
    if (filterStatus.length > 0) {
      var filteredData = [];
      filterStatus.map((filter_item) => {
        var selectedStatus = status.find(
          (item) => item?.status_name === filter_item.key
        );
        data.map((item) => {
          if (
            parseFloat(item.auction_status) ===
            parseFloat(selectedStatus.status_code)
          ) {
            filteredData.push(item);
          }
          return false;
        });
        return false;
      });

      return filteredData;
    }

    return data;
  };

  filterFleetCategories = async (data, filters) => {
    const { fleet_categories } = this.props;
    var filterCategories = filters.filter(
      (item) => item.target === "fleet_categories"
    );
    if (filterCategories.length > 0) {
      var filteredData = [];
      filterCategories.map((filter_item) => {
        var selectedCategories = fleet_categories.find(
          (item) => item.name === filter_item.key
        );
        data.map((item) => {
          if (
            parseFloat(item.fleet_category_id) ===
            parseFloat(selectedCategories.id)
          ) {
            filteredData.push(item);
          }
          return false;
        });
        return false;
      });

      return filteredData;
    }

    return data;
  };

  filterMaterialCategories = async (data, filters) => {
    const { material_categories } = this.props;
    var filterCategories = filters.filter(
      (item) => item.target === "material_categories"
    );
    if (filterCategories.length > 0) {
      var filteredData = [];
      filterCategories.map((filter_item) => {
        var selectedCategories = material_categories.find(
          (item) => item.name === filter_item.key
        );
        data.map((item) => {
          if (
            parseFloat(item.material_category_id) ===
            parseFloat(selectedCategories.id)
          ) {
            filteredData.push(item);
          }
          return false;
        });
        return false;
      });

      return this.filterData;
    }

    return data;
  };

  onFilter = async (filters) => {
    var filterDate = await this.filterDate(filters);
    var filterStatus = await this.filterStatus(filterDate, filters);
    var filterFleetCategories = await this.filterFleetCategories(
      filterStatus,
      filters
    );
    var filterMaterialCategories = await this.filterMaterialCategories(
      filterFleetCategories,
      filters
    );
    var finalData = filterMaterialCategories;
    var filteredData = finalData;

    this.setState({
      isFiltering: false,
      keyword: "filter",
      filteredData: filteredData,
    });
  };

  render() {
    const { confirmation, keyword, filteredData } = this.state;
    const { history, user, trackings, status } = this.props;

    const columns = [
      {
        Header: "No",
        id: "no",
        accessor: (props, index) => (
          <div className="table__body__row__column__text-style">
            {index + 1}
          </div>
        ),
      },
      {
        Header: "Consignment ID",
        id: "consignment_id",
        accessor: (props, index) => (
          <div className="table__body__row__column__text-style">{props.id}</div>
        ),
      },
      {
        Header: "Sender Name",
        id: "pickup_name",
        accessor: (props) => (
          <div className="table__body__row__column__text-style">
            {props.pickup_name}
          </div>
        ),
      },
      {
        Header: "Sender Address",
        id: "pickup_address",
        accessor: (props) => (
          <div className="table__body__row__column__text-style">
            {props.pickup_address_one}
          </div>
        ),
      },
      {
        Header: "Receiver Name",
        id: "delivery_name",
        accessor: (props) => (
          <div className="table__body__row__column__text-style">
            {props.delivery_name}
          </div>
        ),
      },
      {
        Header: "Receiver Address",
        id: "delivery_address",
        accessor: (props) => (
          <div className="table__body__row__column__text-style">
            {props.delivery_address_one}
          </div>
        ),
      },
      {
        Header: "Pickup Time",
        id: "pickup_time",
        accessor: (props) => (
          <div className="table__body__row__column__text-style">
            {moment(props.pickup_date).format("DD MMM YYYY HH:mm")}
          </div>
        ),
      },
      {
        Header: "Weight",
        id: "weight",
        accessor: (props) => (
          <div className="table__body__row__column__text-style">
            {props.weight}Kg
          </div>
        ),
      },
      {
        Header: "Status",
        id: "status",
        accessor: (props) => {
          var tracking_status = status.find(
            (item) => parseFloat(item.status_code) === props.status
          );
          return (
            <div
              className={`table__body__row__column__btn-style table__body__row__column__btn-style__success`}
            >
              {tracking_status ? tracking_status?.status_name : ""}
            </div>
          );
        },
      },
      {
        Header: "Actions",
        id: "id",
        accessor: (props) => {
          return (
            <DropdownButton
              className="table__body__row__column__dropdown center"
              key="down"
              drop="down"
              variant="secondary"
              title={<i className="material-icons">more_horiz</i>}
            >
              <Dropdown.Item
                eventKey="1"
                onClick={() => navigate(history, `shipment/${props.id}`)}
                className="dropdown-item__detail"
              >
                See Detail
              </Dropdown.Item>

              {user.role === "delivery_boy" && props.status < 145 && (
                <>
                  <Dropdown.Item
                    eventKey="2"
                    onClick={() =>
                      this.setState({
                        isUpdateLocation: true,
                        selectedConsignment: props.id,
                      })
                    }
                    className="dropdown-item__update-location"
                  >
                    Update Location
                  </Dropdown.Item>
                  <Dropdown.Item
                    eventKey="3"
                    onClick={() =>
                      this.setState({
                        isMarkAsDelivered: true,
                        selectedConsignment: props.id,
                      })
                    }
                    className="dropdown-item__delivered"
                  >
                    Mark as Delivered
                  </Dropdown.Item>
                </>
              )}
            </DropdownButton>
          );
        },
      },
    ];

    const data = keyword ? filteredData : trackings;
    const filterOptions = [
      {
        type: "date",
        key: "created_date",
        label: "Date",
      },
      {
        type: "option",
        key: "status",
        label: "Status",
        items: Tools.generateShipmentStatusFilter(),
      },
    ];

    return (
      <div className="dashboard">
        <Confirmation
          isVisible={confirmation.isVisible}
          header={confirmation.header}
          description={confirmation.description}
          confirmationText="Yes"
          onCancel={confirmation.onCancel}
          onConfirm={confirmation.onConfirm}
        />

        <div className="dashboard__header">
          <div className="flex flex__sm">
            <h1 className="header" style={{ marginRight: 20 }}>
              Trackings
            </h1>
            <Filter
              filterOptions={filterOptions}
              withFilter
              isFilterVisible={this.state.isFiltering}
              onCancel={() => this.setState({ isFiltering: false })}
              onFilter={() =>
                this.setState({ isFiltering: !this.state.isFiltering })
              }
              onApplyFilter={(e) => this.onFilter(e)}
              onSearch={(e) => this.onSearch(e)}
            />
          </div>
        </div>
        <div className="dashboard__body">
          <Table columns={columns} data={data} />
        </div>

        {this.state.isUpdateLocation && (
          <UpdateShipmentTrackingLocationContainer
            {...this.props}
            isDashboard
            selectedConsignmentId={this.state.selectedConsignment}
            isVisible={this.state.isUpdateLocation}
            onClose={() => this.setState({ isUpdateLocation: false })}
          />
        )}

        {this.state.isMarkAsDelivered && (
          <CompleteShipmentContainer
            {...this.props}
            isDashboard
            selectedConsignmentId={this.state.selectedConsignment}
            isVisible={this.state.isMarkAsDelivered}
            onClose={() => this.setState({ isMarkAsDelivered: false })}
          />
        )}
      </div>
    );
  }
}

const mapsStateToProps = ({ authenticated, tracking, consignment }) => {
  return {
    token: authenticated.token,
    status: consignment.status,
    user: authenticated.userInfo,
    trackings: tracking.lists,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/TrackingRedux");
  const { actions: consignment_actions } = require("redux/ConsignmentRedux");

  return {
    ...ownProps,
    ...stateProps,
    updateTrackingLocation: async (token, data) => {
      dispatch({
        type: "SET_PROCESS",
        items: { isVisible: true, label: "Processing" },
      });
      await actions.updateTrackingLocation(dispatch, token, data);
      await actions.fetchTracking(dispatch, token, data.consignment_id);
      dispatch({ type: "RESET_PROCESS" });
    },
    fetchTrackings: async (token, role, user_id) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await actions.fetchTrackings(dispatch, token, role, user_id);
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    fetchConsignmentStatus: async (token) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await consignment_actions.fetchConsignmentStatus(
        dispatch,
        token
      );
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    fetchPending: () => dispatch({ type: "FETCH_PENDING" }),
    fetchFinish: () => dispatch({ type: "FETCH_FINISH" }),
    clearTrackings: () => dispatch({ type: "CLEAR_TRACKING" }),
  };
};

export default connect(
  mapsStateToProps,
  undefined,
  mergeProps
)(withRouter(ShipmentsContainer));
