import { PaymentApi } from "services"

const types = {
    FETCH_REFUND_SUCCESS: "FETCH_REFUND_SUCCESS",
    FETCH_REFUND_FAILURE: "FETCH_REFUND_FAILURE",
    CLEAR_REFUND: "CLEAR_REFUND",
    CREATE_REFUND_SUCCESS: "CREATE_REFUND_SUCCESS",
    CREATE_REFUND_FAILURE: "CREATE_REFUND_FAILURE"
}


export const actions = {
    fetchRefund: async(
        dispatch,
        token
    ) => {
        var json = await PaymentApi.fetchRefund(token)
        if(json.status_code === 500) {
            dispatch(actions.fetchRefundFailure(json.message))
        } else {
            var data =  json.data
            dispatch(actions.fetchRefundSuccess(data))
        }
        return json
    },
    fetchRefundSuccess: (items) => ({
        type: types.FETCH_REFUND_SUCCESS,
        items
    }),
    fetchRefundFailure: (error) => ({
        type: types.FETCH_REFUND_FAILURE,
        error
    }),
    createRefund: async(
        dispatch,
        token,
        body
    ) => {
        var json = await PaymentApi.createRefund(token, body)
        if(json.status_code === 500) {
            dispatch(actions.createRefundFailure(json.message))
        } else {
            dispatch(actions.createRefundSuccess(json))
        }
        return json
    },
    createRefundSuccess: (items) => ({
        type: types.CREATE_REFUND_SUCCESS,
        items
    }),
    createRefundFailure: (error) => ({
        type: types.CREATE_REFUND_FAILURE,
        error
    }),
} 

const initialState = {
    refund_lists: [],
    refund_total: 0,
    error: null,
}

export const reducer = (state = initialState, action) => {

    const { items, error } = action;

    switch (action.type) {
        case types.FETCH_REFUND_SUCCESS:
            return Object.assign({}, state, {
                error: null,
                refund_lists: items,
                refund_total: items.length
            })
        case types.FETCH_REFUND_FAILURE:
            return Object.assign({}, state, {
                error: error
            })
        case types.CLEAR_REFUND:
            return Object.assign({}, state, {
                error: null,
                refund_lists: [],
                refund_total: 0
            })
        default:
            return state;
    }
}
