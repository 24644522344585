import { Constant } from "common";

export const FleetsAPI = {

    fetchPartnersFleet: (token, user_id) => {
        try {
            return fetch(`${Constant.API_URL}/categories/api/fleets/${user_id}`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(async(response) => {
                if(response.ok) {
                    return response.json()
                }
                var error_message = await response.json().then((json) => {
                    if(typeof(json.message) === "object") {
                        for (const index in json.message) {
                            return json.message[index];
                        }
                    }
                    else {
                        return json; 
                    }
                })
                throw new Error(JSON.stringify(error_message));
            })
            .then((json) => {
                return { status_code: 200, ...json };
            }).catch((error) => {
                return { status_code: 500, ...error };
            });
        }
        catch(error) {
            return { status_code: 500, ...error };
        }
    },

    createFleet: (token, body) => {
        try {
            return fetch(`${Constant.API_URL}/categories/api/fleets`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(body)
            }).then(async(response) => {
                if(response.ok) {
                    return response.json();
                }
                var error_message = await response.json().then((json) => {
                    if(typeof(json.message) === "object") {
                        for (const index in json.message) {
                            return json.message[index];
                        }
                    }
                    else {
                        return json; 
                    }
                })
                throw new Error(JSON.stringify(error_message))
            })
            .then((json) => {
                return { status_code: 200, ...json };
            }).catch((error) => {
                return { status_code: 500, ...error };
            });
        }
        catch(error) {
            return { status_code: 500, ...error };
        }
    },

    deleteFleet: (token, id) => {
        try {
            return fetch(`${Constant.API_URL}/categories/api/fleets/${id}`, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }).then(async(response) => {
                if(response.ok) {
                    return response.json();
                }
                var error_message = await response.json().then((json) => {
                    if(typeof(json.message) === "object") {
                        for (const index in json.message) {
                            return json.message[index];
                        }
                    }
                    else {
                        return json; 
                    }
                })
                throw new Error(JSON.stringify(error_message))
            })
            .then((json) => {
                return { status_code: 200, ...json };
            }).catch((error) => {
                return { status_code: 500, ...error };
            });
        }
        catch(error) {
            return { status_code: 500, ...error };
        }
    },

    updateFleet: (token, id, body) => {
        try {
            return fetch(`${Constant.API_URL}/categories/api/fleets/${id}`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(body)
            }).then(async(response) => {
                if(response.ok) {
                    return response.json();
                }
                var error_message = await response.json().then((json) => {
                    if(typeof(json.message) === "object") {
                        for (const index in json.message) {
                            return json.message[index];
                        }
                    }
                    else {
                        return json; 
                    }
                })
                throw new Error(JSON.stringify(error_message))
            })
            .then((json) => {
                return { status_code: 200, ...json };
            }).catch((error) => {
                return { status_code: 500, ...error };
            });
        }
        catch(error) {
            return { status_code: 500, ...error };
        }
    },

}