import { Config, Tools } from "common";

const EnsureAccountIsComplete = (store) => (next) => (action) => {
    switch(action.type) {
      case 'SET_NAVIGATION':
        var state = store.getState();

        store.dispatch({
          type:"TOAST_FINISH"
        })
        
        const { userInfo } = state.authenticated;
        if(userInfo.role === "user" || userInfo.role === "partner") {
          if(Config.profileCompletedRequired.includes(action.items)) {
            if(Tools.isProfileCompleted()) {
              next(action)
            }
            else {
              store.dispatch({
                type:"TOAST_PENDING",
                message: "Complete your profile to continue",
                toastType: 500
              })
            }
          }
          else {
            next(action)
          }
        }
        else {
          next(action)
        }

        break

      default: next(action)
      
    }
  }
   
export default EnsureAccountIsComplete