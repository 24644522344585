import React from 'react';
import { EditFleetCategoriesContainer } from 'container';

class EditFleetCategories extends React.PureComponent {

    render() {
        return(
            <EditFleetCategoriesContainer
                {...this.props}
            />
        )
    }

}

export default (EditFleetCategories);