import React from "react";
import "./input.scss";

class InputArea extends React.PureComponent {
  render() {
    const { clickableIcon, onIconClick, isRequired } = this.props;

    return (
      <div className={`input`} style={this.props.style}>
        <label
          className={`input__label ${
            this.props.isError ? "input__label__error" : undefined
          }`}
        >
          {this.props.label}
          {isRequired && <span className="input__required">*</span>}
        </label>

        {this.props.iconPrefix &&
          (clickableIcon && clickableIcon.includes("prefix") ? (
            <button
              className="input__icon input__icon__button input__icon__button__prefix input__icon__prefix"
              onClick={() => onIconClick("prefix")}
            >
              <img src={this.props.iconPrefix} alt="form-icon-prefix" />
            </button>
          ) : (
            <img
              src={this.props.iconPrefix}
              alt="form-icon-prefix"
              className="input__icon input__icon__prefix"
            />
          ))}

        {this.props.iconSuffix &&
          (clickableIcon && clickableIcon.includes("suffix") ? (
            <button
              className="input__icon input__icon__button input__icon__button__suffix input__icon__suffix"
              onClick={() => onIconClick("suffix")}
            >
              <img src={this.props.iconSuffix} alt="form-icon-suffix" />
            </button>
          ) : (
            <img
              src={this.props.iconSuffix}
              alt="form-icon-suffix"
              className="input__icon input__icon__suffix"
            />
          ))}

        <textarea
          type={this.props.type}
          className={`input__control input__control__area ${
            this.props.isError ? "input__control__error" : undefined
          }`}
          placeholder={this.props.placeholder}
          onChange={(e) => this.props.onChange(e)}
          onBlur={(e) => this.props.onBlur(e)}
          value={this.props.value}
        >
          {this.props.value}
        </textarea>

        {this.props.caption && (
          <label
            className={`input__caption ${
              this.props.isError ? "input__caption__error" : undefined
            }`}
          >
            {this.props.caption}
          </label>
        )}
      </div>
    );
  }
}

export default InputArea;
