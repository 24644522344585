import { AuctionsAPI, ConsignmentsAPI } from "services";

const types = {
    FETCH_CONSIGNMENT_SUCCESS: "FETCH_CONSIGNMENT_SUCCESS",
    FETCH_CONSIGNMENT_FAILURE: "FETCH_CONSIGNMENT_FAILURE",
    CLEAR_CONSIGNMENTS: "CLEAR_CONSIGNMENTS",
    FETCH_CONSIGNMENT_STATUS_SUCCESS: "FETCH_CONSIGNMENT_STATUS_SUCCESS",
    FETCH_CONSIGNMENT_STATUS_FAILURE: "FETCH_CONSIGNMENT_STATUS_FAILURE",
    SET_LOCAL_CONSIGNMENT: "SET_LOCAL_CONSIGNMENT",
    CREATE_CONSIGNMENT_SUCCESS: "CREATE_CONSIGNMENT_SUCCESS",
    CREATE_CONSIGNMENT_FAILURE: "CREATE_CONSIGNMENT_FAILURE",
    PUBLISH_CONSIGNMENT_SUCCESS: "PUBLISH_CONSIGNMENT_SUCCESS",
    PUBLISH_CONSIGNMENT_FAILURE: "PUBLISH_CONSIGNMENT_FAILURE",
    UPDATE_CONSIGNMENT_FAILURE: "UPDATE_CONSIGNMENT_FAILURE",
    UPDATE_CONSIGNMENT_SUCCESS: "UPDATE_CONSIGNMENT_SUCCESS",
    UPDATE_CONSIGNMENT_STATUS_SUCCESS: "UPDATE_CONSIGNMENT_STATUS_SUCCESS",
    UPDATE_CONSIGNMENT_STATUS_FAILURE: "UPDATE_CONSIGNMENT_STATUS_FAILURE",
}

export const actions = {
    fetchConsignments: async (
      dispatch,
      token,
      user_id
    ) => {
        var json = {}
        if(user_id === null) {
            json = await ConsignmentsAPI.fetchConsignments(token);
        }
        else {
            json = await ConsignmentsAPI.fetchUsersConsignments(token, user_id)
        }
        if (json.status_code === 500) {
            dispatch(actions.fetchConsignmentFailure(json.message));
        }
        else {
            dispatch(actions.fetchConsignmentSuccess(json));
        }
        return json
    },
    fetchConsignmentSuccess: (items) => ({
        type: types.FETCH_CONSIGNMENT_SUCCESS,
        items
    }),
    fetchConsignmentFailure: (error) => ({
        type: types.FETCH_CONSIGNMENT_FAILURE,
        error
    }),
    fetchConsignmentStatus: async (
        dispatch,
        token
    ) => {
        var json = await ConsignmentsAPI.fetchConsigmentStatus(token);
        if (json.status_code === 500) {
            dispatch(actions.fetchConsigmentStatusFailure(json.message));
        }
        else {
            dispatch(actions.fetchConsigmentStatusSuccess(json));
        }
        return json
    },
    fetchConsigmentStatusFailure: (error) => ({
        type: types.FETCH_CONSIGNMENT_STATUS_FAILURE,
        error
    }),
    fetchConsigmentStatusSuccess: (items) => ({
        type: types.FETCH_CONSIGNMENT_STATUS_SUCCESS,
        items
    }),
    updateConsignmentStatus: async(
        dispatch,
        token,
        consignment_id,
        body
    ) => {
        var json = await ConsignmentsAPI.updateConsignmentStatus(token, consignment_id, body)
        if(json.status_code === 500) {
            dispatch(actions.updateConsignmentStatusFailure(json.message))
        } else {
            var data = json.data
            dispatch(actions.updateConsignmentStatusSuccess(data))
        }
        return json
    },
    updateConsignmentStatusSuccess: (items) => ({
        type: types.UPDATE_CONSIGNMENT_STATUS_SUCCESS,
        items
    }),
    updateConsignmentStatusFailure: (error) => ({
        type: types.UPDATE_CONSIGNMENT_STATUS_FAILURE,
        error
    }),
    createConsignment: async(
        dispatch,
        token,
        body
    ) => {
        var json = await ConsignmentsAPI.createConsignment(token, body)
        if(json.status_code === 500) {
            dispatch(actions.createConsignmentFailure(json.message))
        }
        else {
            dispatch(actions.createConsignmentSuccess(json))
        }
        return json
    },
    createConsignmentFailure: (error) => ({
        type: types.CREATE_CONSIGNMENT_FAILURE,
        error
    }),
    createConsignmentSuccess: (items) => ({
        type: types.CREATE_CONSIGNMENT_SUCCESS,
        items
    }),
    publishConsignment: async(
        dispatch,
        token,
        user_id,
        body
    ) => {
        var json = await ConsignmentsAPI.updateConsignment(token, body)
        var consignment_id = json.id;
        if(json.status_code === 200) {
            const auctionParam = {
                consignment_id: json.id, 
                user_id: user_id
            }
            json = await AuctionsAPI.createAuction(token, auctionParam)
            var auction_id = json.auction_id;
            if(json.status_code === 200) {
                json = await AuctionsAPI.fetchAuctionById(token, auction_id)
                if(json.status_code === 200) {
                    var updateAuctionParam = {
                        id: auction_id,
                        expire_on: json.expire_on,
                        auction_status: 111
                    }
                    json = await AuctionsAPI.updateAuction(token, updateAuctionParam)
                    if(json.status_code === 200) {
                        dispatch(actions.publishConsignmentSuccess(json, consignment_id))
                    }
                    else {
                        dispatch(actions.publishConsignmentFailure(json.message))
                    }
                }
                else {
                    dispatch(actions.publishConsignmentFailure(json.message))
                }
            }
            else {
                dispatch(actions.publishConsignmentFailure(json.message))
            }
        }
        else {
            dispatch(actions.publishConsignmentFailure(json.message))
        }
        return json;
    },
    publishConsignmentFailure: (error) => ({
        type: types.PUBLISH_CONSIGNMENT_FAILURE,
        error
    }),
    publishConsignmentSuccess: (items, consignment_id) => ({
        type: types.PUBLISH_CONSIGNMENT_SUCCESS,
        items,
        consignment_id
    }),
    updateConsignment: async(
        dispatch,
        token,
        body
    ) => {
        var json = await ConsignmentsAPI.updateConsignment(token, body)
        if(json.status_code === 500) {
            dispatch(actions.updateConsignmentFailure(json.message))
        }
        else {
            dispatch(actions.updateConsignmentSuccess(json))
        }
        return json
    },
    updateConsignmentFailure: (error) => ({
        type: types.UPDATE_CONSIGNMENT_FAILURE,
        error
    }),
    updateConsignmentSuccess: (items) => ({
        type: types.UPDATE_CONSIGNMENT_SUCCESS,
        items
    })
};

const initialState = {
    lists: [],
    local_data: [],
    total: 0,
    status: [],
    error: null
}

export const reducer = (state = initialState, action) => {

    const { items, error, consignment_id } = action;

    switch (action.type) {
        case types.FETCH_CONSIGNMENT_FAILURE: {
            return Object.assign({}, state, {
                error: error
            })
        }
        case types.FETCH_CONSIGNMENT_SUCCESS: {
            var consignments = items.consignments.sort((a, b) => (a.id < b.id) ? 1 : -1);
            return Object.assign({}, state, {
                error: null,
                lists: consignments,
                total: items.consignments.length
            })
        }
        case types.CLEAR_CONSIGNMENTS: {
            return Object.assign({}, state, {
                error: null,
                lists: [],
                total: 0
            })
        }
        case types.FETCH_CONSIGNMENT_STATUS_FAILURE: {
            return Object.assign({}, state, {
                error: error
            })
        }
        case types.FETCH_CONSIGNMENT_STATUS_SUCCESS: {
            return Object.assign({}, state, {
                error: null,
                status: items.data.data
            })
        }
        case types.SET_LOCAL_CONSIGNMENT: {
            return Object.assign({}, state, {
                local_data: items
            })
        }
        case types.CREATE_CONSIGNMENT_FAILURE: {
            return Object.assign({}, state, {
                error: error
            })
        }
        case types.CREATE_CONSIGNMENT_SUCCESS: {
            return Object.assign({}, state, {
                error: null
            })
        }
        case types.PUBLISH_CONSIGNMENT_FAILURE: {
            return Object.assign({}, state, {
                error: error
            })
        }
        case types.PUBLISH_CONSIGNMENT_SUCCESS: {
            var data = []
            state.lists.map((item) => {
                if(item.id === consignment_id) {
                    item.status = 102
                    data.push(item)
                }
                else {
                    data.push(item)
                }
                return true;
            })
            return Object.assign({}, state, {
                error: null,
                lists: data,
                total: data.length
            })
        }
        case types.UPDATE_CONSIGNMENT_FAILURE: {
            return Object.assign({}, state, {
                error: error
            })
        }
        case types.UPDATE_CONSIGNMENT_SUCCESS: {
            return Object.assign({}, state, {
                error: null
            })
        }
        default:
            return state;
    }
}
