import React from 'react';
import "./loader.scss";

class Loader extends React.Component {

    render() {

        const { isVisible } = this.props;

        return (
            <div className="loader">
                {isVisible && (
                    <>
                        <div className="loader__container">
                            <div className="loader__content"><div></div><div></div><div></div><div></div></div>
                        
                            <h4 className="loader__header">{"Please wait a minute!"}</h4>
                            <p className="loader__description">{"We are processing your request, please wait!"}</p>
                        </div>
                        <div className="loader__overlay"></div>
                    </>
                )}
                
            </div>
        )
    }

}

export default Loader;