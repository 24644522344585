import React from 'react';
import "./styles.scss";
import { SocialButton, ButtonGroup } from 'components';
import images from 'common/Images';

class SocialAuthentication extends React.PureComponent {

    render() {

        const { activity, handleActivity } = this.props;

        return(
            <div className="social-authentication">
                {activity === "login" ?
                    <p className="social-authentication__label">Login Using</p>
                :
                    <p className="social-authentication__label">Register Using</p>
                }
                <ButtonGroup position="center">
                    <SocialButton
                        icon={images.google}
                        text="Google"
                        type="google"
                        style={{ marginRight: 10 }}
                        onClick={(response) => handleActivity("google", response)}
                    />
                    {/* <SocialButton
                        icon={images.facebook}
                        type="facebook"
                        text="Facebook"
                        style={{ marginLeft: 10 }}
                        onClick={(response) => handleActivity("facebook", response)}
                    /> */}
                </ButtonGroup>
            </div>
        )
    }

}

export default (SocialAuthentication);