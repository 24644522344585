import { ShipmentDetailContainer } from "container";
import React from "react";


class ShipmentDetail extends React.PureComponent {

    render() {
        return (
            <ShipmentDetailContainer
                {...this.props}
            />
        )
    }

}

export default (ShipmentDetail);