import React from "react";
import { connect } from "react-redux";
import { Button, FormBody, FormWrapper, Input } from "components";
import { Tools, Images, navigate } from "common";

class CreateOfficerContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      firstNameError: false,
      lastNameError: false,
      emailError: false,
      passwordError: false,
    };
  }

  onBlurValidation = (target) => {
    if ("firstName") {
      if (!Tools.isNotEmpty(this.state.firstname)) {
        this.setState({
          ...this.state,
          firstNameError: true,
        });
      } else {
        this.setState({
          ...this.state,
          firstNameError: false,
        });
      }
    }
    if ("lastName") {
      if (!Tools.isNotEmpty(this.state.lastname)) {
        this.setState({
          ...this.state,
          lastNameError: true,
        });
      } else {
        this.setState({
          ...this.state,
          lastNameError: false,
        });
      }
    }
    if ("email") {
      if (!Tools.isEmailValid(this.state.email)) {
        this.setState({
          ...this.state,
          emailError: true,
        });
      } else {
        this.setState({
          ...this.state,
          emailError: false,
        });
      }
    }
    if ("password") {
      if (!Tools.isPasswordValid(this.state.password)) {
        this.setState({
          ...this.state,
          passwordError: true,
        });
      } else {
        this.setState({
          ...this.state,
          passwordError: false,
        });
      }
    }
  };

  onChange = (target, value) => {
    this.setState({
      send: false,
      [target]: value,
    });
  };

  togglePassword = () => {
    this.setState({
      show_password: !this.state.show_password,
    });
  };

  validation = async () => {
    const { email, firstname, lastname, password } = this.state;
    var valid =
      Tools.isPasswordValid(password) &&
      Tools.isEmailValid(email) &&
      Tools.isNotEmpty(firstname) &&
      Tools.isNotEmpty(lastname);

    this.setState({
      valid: valid,
      send: true,
    });

    return valid;
  };

  onAdd = async () => {
    this.setState({
      send: true,
    });

    var valid = await this.validation();

    if (valid) {
      const { createOfficer, history, toastPending } = this.props;
      var json = await createOfficer(this.state);
      if (json.status_code === 200) {
        navigate(history, "/dashboard?screen=master_data&child=back_officer");
      }
      toastPending({ message: json.message, toastType: json.status_code });
    }
  };

  render() {
    const { show_password } = this.state;
    const { innerWidth } = window;

    return (
      <div
        className="container"
        style={
          innerWidth > 768
            ? { marginTop: 50, marginBottom: 50 }
            : { marginTop: 15, marginBottom: 15 }
        }
      >
        <FormWrapper>
          <FormBody>
            <h1
              className="text__verification__header"
              style={{ marginBottom: 0 }}
            >
              Add Officer
            </h1>
            <h3
              className="text__verification__body"
              style={{ marginBottom: 30 }}
            >
              Create your new officer staff
            </h3>
            <Input
              type={"text"}
              iconPrefix={Images.user}
              label="First Name"
              placeholder="Please type here"
              style={{ margin: 0, marginBottom: 30 }}
              caption={this.state.firstNameError && "This field is required"}
              onBlur={() => this.onBlurValidation("firstName")}
              value={this.state.firstname}
              isError={
                (this.state.send && !this.state.valid) ||
                this.state.firstNameError
              }
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  firstNameError: false,
                });
                this.onChange("firstname", e.target.value);
              }}
              isRequired={true}
            />
            <Input
              type={"text"}
              iconPrefix={Images.user}
              label="Last Name"
              placeholder="Please type here"
              style={{ margin: 0, marginBottom: 30 }}
              caption={this.state.lastNameError && "This field is required"}
              onBlur={() => this.onBlurValidation("lastName")}
              value={this.state.lastname}
              isError={
                (this.state.send && !this.state.valid) || this.lastNameError
              }
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  lastNameError: false,
                });
                this.onChange("lastname", e.target.value);
              }}
              isRequired={true}
            />
            <Input
              type={"email"}
              iconPrefix={Images.form_email}
              label="Email"
              placeholder="Please type here"
              style={{ margin: 0, marginBottom: 30 }}
              caption={this.state.emailError && "Provide correct email format"}
              value={this.state.email}
              onBlur={() => this.onBlurValidation("email")}
              isError={
                (this.state.send && !this.state.valid) || this.state.emailError
              }
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  emailError: false,
                });
                this.onChange("email", e.target.value);
              }}
              isRequired={true}
            />
            <Input
              type={!show_password ? "password" : "text"}
              iconPrefix={Images.password}
              iconSuffix={!show_password ? Images.eye_close : Images.eye}
              clickableIcon={["suffix"]}
              onIconClick={() =>
                this.setState({ show_password: !show_password })
              }
              label="Password"
              placeholder="Example:- Saytum@123"
              style={{ margin: 0, marginBottom: 30 }}
              caption={
                this.state.passwordError && "Password length must be of 6-12"
              }
              onBlur={() => this.onBlurValidation("password")}
              value={this.state.password}
              isError={
                (this.state.send && !this.state.valid) ||
                this.state.passwordError
              }
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  passwordError: false,
                });
                this.onChange("password", e.target.value);
              }}
              isRequired={true}
            />
            <Button
              buttonText="Create Officer"
              size="lg"
              type="warning"
              style={{ margin: 0 }}
              onClick={() => this.onAdd()}
            />
          </FormBody>
        </FormWrapper>
      </div>
    );
  }
}

const mapsStateToProps = ({ authenticated, officer }) => {
  return {
    token: authenticated.token,
    officer: officer.lists,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/OfficerRedux");

  return {
    ...ownProps,
    ...stateProps,
    createOfficer: async (body) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await actions.createOfficer(dispatch, body);
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    toastPending: (body) => {
      dispatch({ type: "TOAST_PENDING", ...body });
    },
  };
};

export default connect(
  mapsStateToProps,
  undefined,
  mergeProps
)(CreateOfficerContainer);
