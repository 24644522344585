import React from 'react';
import { CreateOfficerContainer } from 'container';

class CreateOfficer extends React.PureComponent {

    render() {
        return(
            <CreateOfficerContainer
                {...this.props}
            />
        )
    }

}

export default (CreateOfficer);