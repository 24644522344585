import vdimg1 from '../common/images/Video1thumbnail.png';
import vdimg2 from '../common/images/Video2thumbnail.png';
import yticon from '../common/images/yticon.png';
import icon1 from '../common/images/Cost.png';
import icon2 from '../common/images/Excellence.png';
import icon3 from '../common/images/Security.png';
import usercons from '../common/images/User Consignment.png';
import userauc from '../common/images/User Auction.png';
import usetrack from '../common/images/User Track order.png';
import partauc from '../common/images/Partner Auction.png';
import partdel from '../common/images/Partner Delivery.png';
import partfleet from '../common/images/Partner Fleet.png';
import A from '../common/images/aboutus img.png';
import ceoimage from '../common/images/ceoimage6.png';
import ceoimage1 from '../common/images/Enhanced Ceo Image.png';
import mainImage from '../common/images/mainbg3.png';
import stmlogo from '../common/images/Saytum Logo white.png';
import phnimg from '../common/images/phoneimg.png';
import whatsimg from '../common/images/whatsappimg.png';
import locimg from '../common/images/locationimg.png';
import mailimg from '../common/images/mailimg.png';
import whiteLogo from '../common/images/Saytum Logo white.png';
import blueLogo from '../common/images/Saytum Logo blue.png';

const Newimages = {
  vdimg1,
  vdimg2,
  yticon,
  icon1 ,
  icon2 ,
  icon3 ,
  usercons,
  userauc ,
  usetrack,
  partauc ,
  partdel ,
  partfleet,
  A,
  ceoimage,
  ceoimage1,
  mainImage,
  stmlogo,
  phnimg,
  whatsimg,
  locimg,
  mailimg,
  whiteLogo,
  blueLogo
}

export default Newimages;