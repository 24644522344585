import React from 'react';
import { FormWrapper, FormBody, Button } from 'components';
import { FirebaseAPI } from 'services/FirebaseAPI';
import { connect } from 'react-redux';
import Countdown, { zeroPad } from 'react-countdown';
import moment from 'moment';
import "./styles.scss";
import { withRouter } from 'react-router';
import Tools, { navigate } from "common/Tools";
import { Notifications } from 'common';

class RegisterVerificationContainer extends React.PureComponent {

    state = {
        code: "",
        send: false,
        purpose: "registration",
        is_restart_countdown: false
    }

    componentDidMount() {
        var checkAccount = setInterval(async() => {
            var registration = localStorage.getItem("@verificationData");
            registration = JSON.parse(registration);
            if(Tools.isNotEmpty(registration)) {
                await FirebaseAPI.signInWithEmailAndPassword(registration).then(async(response) => {
                    if(response.status === 200) {
                        if(Tools.isNotEmpty(response.firebaseUser) && response.firebaseUser.emailVerified) {
                            clearInterval(checkAccount)
                            navigate(this.props.history, "/login");
                        }
                    }
                });
            }
            else {
                clearInterval(checkAccount)
                navigate(this.props.history, "/login");
            }
        }, 100000);
    }

    requestNewVerification = async() => {
        const {
            fetchFinish,
            fetchPending,
            toastPending
        } = this.props

        fetchPending()
        var registration = localStorage.getItem("@verificationData");
        registration = JSON.parse(registration);
        const object = {
            email: registration.email,
            password: registration.password,
        }

        await FirebaseAPI.signInWithEmailAndPassword(registration).then(async() => {
            await FirebaseAPI.sendEmailVerification(object).then(() => {
                
                var params = {
                    ...registration,
                    registrationTime: moment()
                }
                console.log("emailverify", params);
                localStorage.setItem("@verificationData", JSON.stringify(params));

                this.setState({ is_restart_countdown: true }, () => {

                    this.countdownRef.start();

                })

                toastPending({ message: Notifications.FreshVerificationSentSuccess, toastType: 200 })
            }).catch((error) => {
                console.log("error", error);
                toastPending({ message: error.message, toastType: 500 })
            });
        })

        fetchFinish()
    }

    render() {
        const { innerWidth } = window;
        
        var registration = localStorage.getItem("@verificationData");
        registration = JSON.parse(registration);

        return(
            <div className="container" style={innerWidth > 768 ? { marginTop: 100 } : { marginTop: 0 }}>

                <FormWrapper>
                    <FormBody>
                        <h1 className="text__verification__header">Thank You For Joining Us</h1>
                        <h3 className="text__verification__body" style={{ marginBottom: 30 }}>We have sent you verification link in registered email. Please check it to continue.</h3>
                        
                        <Countdown 
                            ref={(ref) => this.countdownRef = ref}
                            date={moment(registration.registrationTime).add(1, "minutes")}
                            renderer={(props) => {
                                if(props.completed) {
                                    return (
                                        <Button
                                            buttonText="Request New Verification"
                                            size="lg"
                                            type="warning"
                                            style={{ marginBottom: 0 }}
                                            onClick={() => this.requestNewVerification()}
                                        />
                                    )
                                }
                                else {
                                    return (
                                        <>
                                            <p className="register-verification__countdown">Request new verification code in <strong>{zeroPad(props.minutes)} : {zeroPad(props.seconds)}</strong></p>
                                        </>
                                    )
                                }
                            }}
                        />
                    </FormBody>
                </FormWrapper>

            </div>
        )
    }

}


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;

    return {
      ...ownProps,
      ...stateProps,
      fetchPending: () => dispatch({ type: "FETCH_PENDING" }),
      fetchFinish: () => dispatch({ type: "FETCH_FINISH" }),
      toastPending: (body) => {
        dispatch({ type: "TOAST_PENDING", ...body })
      }
    };
}

export default connect(
    undefined, 
    undefined,
    mergeProps
)(withRouter(RegisterVerificationContainer));