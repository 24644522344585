import { OfficerAPI } from "services/OfficerAPI"

const types = {
    FETCH_OFFICER_SUCCESS: "FETCH_OFFICER_SUCCESS",
    FETCH_OFFICER_FAILURE: "FETCH_OFFICER_FAILURE",
    CLEAR_OFFICER: "CLEAR_OFFICER",
    CREATE_OFFICER_SUCCESS: "CREATE_OFFICER_SUCCESS",
    CREATE_OFFICER_FAILURE: "CREATE_OFFICER_FAILURE",
    TOGGLE_ACTIVATE_OFFICER_FAILURE: "TOGGLE_ACTIVATE_OFFICER_FAILURE",
    TOGGLE_ACTIVATE_OFFICER_SUCCESS: "TOGGLE_ACTIVATE_OFFICER_SUCCESS"
}

export const actions = {
    fetchOfficer: async(
        dispatch,
        token
    ) => {
        var json = await OfficerAPI.fetchOfficer(token)
        if(json.status_code === 500) {
            dispatch(actions.fetchOfficerFailure(json.message))
        }
        else {
            dispatch(actions.fetchOfficerSuccess(json))
        }
        return json
    },
    fetchOfficerFailure: (error) => ({
        type: types.FETCH_OFFICER_FAILURE,
        error
    }),
    fetchOfficerSuccess: (items) => ({
        type: types.FETCH_OFFICER_SUCCESS,
        items
    }),
    createOfficer: async(
        dispatch,
        body
    ) => {
        var json = await OfficerAPI.createOfficer(body)
        if(json.status_code === 500) {
            dispatch(actions.createOfficerFailure(json.message))
        }
        else {
            dispatch(actions.createOfficerSuccess(json))
        } 
        return json
    },
    createOfficerFailure: (error) => ({
        type: types.CREATE_OFFICER_FAILURE,
        error
    }),
    createOfficerSuccess: (items) => ({
        type: types.CREATE_OFFICER_SUCCESS,
        items
    }),
    toggleActivateOfficer: async(
        dispatch,
        token,
        body
    ) => {
        var json = await OfficerAPI.toggleActivate(token, body);
        if(json.status_code === 500) {
            dispatch(actions.toggleActivateOfficerFailure(json.message))
        }
        else {
            dispatch(actions.toggleActivateOfficerSuccess(json, body))
        }
        return json
    },
    toggleActivateOfficerFailure: (error) => ({
        type: types.TOGGLE_ACTIVATE_OFFICER_FAILURE,
        error
    }),
    toggleActivateOfficerSuccess: (items, param) => ({
        type: types.TOGGLE_ACTIVATE_OFFICER_SUCCESS,
        items,
        param
    }),
}

const initialState = {
    lists: [],
    total: 0,
    error: null
}

export const reducer = (state = initialState, action) => {

    const { items, error, param } = action;

    switch (action.type) {
        case types.FETCH_OFFICER_FAILURE: {
            return Object.assign({}, state, {
                error: error
            })
        }
        case types.FETCH_OFFICER_SUCCESS: {
            return Object.assign({}, state, {
                error: null,
                lists: items.data.data,
                total: items.data.data.length
            })
        }
        case types.CLEAR_OFFICER: {
            return Object.assign({}, state, {
                error: null,
                lists: [],
                total: 0
            })
        }
        case types.CREATE_OFFICER_FAILURE: {
            return Object.assign({}, state, {
                error: error
            })
        }
        case types.CREATE_OFFICER_SUCCESS: {
            return Object.assign({}, state, {
                error: null,
                lists: items,
                total: 1
            })
        }
        case types.TOGGLE_ACTIVATE_OFFICER_FAILURE: {
            return Object.assign({}, state, {
                error: error
            })
        }
        case types.TOGGLE_ACTIVATE_OFFICER_SUCCESS: {
            var selectedUser = state.lists.find((item) => parseFloat(item.id) === parseFloat(items.data.id))
            if(param.type === "activate") {
                selectedUser.is_active = 1;
            }
            else {
                selectedUser.is_active = 0;
            }

            var data = [
                selectedUser
            ];
            state.lists.map((item) => {
                if(parseFloat(item.id) !== parseFloat(selectedUser.id)) {
                    data.push(item);
                }
                return true;
            });
            data.sort((a, b) => (a.id > b.id) ? 1 : -1);

            return Object.assign({}, state, {
                error: null,
                lists: data,
                total: data.length
            })
        }
        default:
            return state;
    }
}
