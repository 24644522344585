import { persistCombineReducers } from "redux-persist";
import { reducer as AuthenticatedRedux } from './AuthenticatedRedux';
import { reducer as UsersRedux } from './UsersRedux';
import { reducer as LogisticPartnersRedux } from './LogisticPartnersRedux';

import { reducer as LocationRedux } from './LocationRedux';
import { reducer as OfficerRedux } from './OfficerRedux';
import { reducer as DeliveryBoysRedux } from './DeliveryBoysRedux';
import { reducer as FleetsRedux } from './FleetsRedux';
import { reducer as MaterialCategoriesRedux } from './MaterialCategoriesRedux';
import { reducer as FleetCategoriesRedux } from './FleetCategoriesRedux';
import { reducer as ConsignmentRedux } from './ConsignmentRedux';
import { reducer as AppRedux } from './AppRedux';
import { reducer as AuctionRedux } from './AuctionRedux';
import { reducer as NotificationRedux } from './NotificationRedux';
import { reducer as NavigationRedux } from './NavigationRedux';
import { reducer as TrackingRedux } from './TrackingRedux';
import { reducer as PaymentRedux} from './PaymentRedux';

import storage from "redux-persist/es/storage";

const config = {
    key: "root",
    storage: storage
  };
  
export default persistCombineReducers(config, {
    authenticated: AuthenticatedRedux,
    users: UsersRedux,
    locations: LocationRedux,
    logistic_partners: LogisticPartnersRedux,
    officer: OfficerRedux,
    delivery_boys: DeliveryBoysRedux,
    fleet: FleetsRedux,
    material_categories: MaterialCategoriesRedux,
    fleet_categories: FleetCategoriesRedux,
    consignment: ConsignmentRedux,
    app: AppRedux,
    auction: AuctionRedux,
    notification: NotificationRedux,
    navigation: NavigationRedux,
    tracking: TrackingRedux,
    payment: PaymentRedux
});
  