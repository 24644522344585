import React from 'react';
import "./form.scss";


class FormHeader extends React.PureComponent {

    render() {
        return(
            <div className="form__wrapper__header">
                <h1 className="form__wrapper__header__title">{this.props.title}</h1>
            </div>
        )
    }

}

export default (FormHeader);