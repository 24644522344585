import { LogisticPartnerAPI } from "services"

const types = {
    CLEAR_LOGISTIC_PARTNER: "CLEAR_LOGISTIC_PARTNER",
    FETCH_LOGISTIC_PARTNER_SUCCESS: "FETCH_LOGISTIC_PARTNER_SUCCESS",
    FETCH_LOGISTIC_PARTNER_FAILURE: "FETCH_LOGISTIC_PARTNER_FAILURE",
    TOGGLE_ACTIVATE_LOGISTIC_PARTNER_FAILURE: "TOGGLE_ACTIVATE_LOGISTIC_PARTNER_FAILURE",
    TOGGLE_ACTIVATE_LOGISTIC_PARTNER_SUCCESS: "TOGGLE_ACTIVATE_LOGISTIC_PARTNER_SUCCESS",
    APPROVAL_SUCCESS: "APPROVAL_SUCCESS",
    APPROVAL_FAILURE: "APPROVAL_FAILURE"
}

export const actions = {
    fetchLogisticPartner: async(
        dispatch,
        token
    ) => {
        var json = await LogisticPartnerAPI.fetchLogisticPartner(token)
        if(json.status_code === 500) {
            dispatch(actions.fetchLogisticPartnerFailure(json.message))
        }
        else {
            dispatch(actions.fetchLogisticPartnerSuccess(json))
        }
        return json;
    },
    fetchLogisticPartnerFailure: (error) => ({
        type: types.FETCH_LOGISTIC_PARTNER_FAILURE,
        error
    }),
    fetchLogisticPartnerSuccess: (items) => ({
        type: types.FETCH_LOGISTIC_PARTNER_SUCCESS,
        items
    }),
    toggleActivatePartner: async(
        dispatch,
        token,
        body
    ) => {
        var json = await LogisticPartnerAPI.toggleActivate(token, body);
        if(json.status_code === 500) {
            dispatch(actions.toggleActivatePartnerFailure(json.message))
        }
        else {
            dispatch(actions.toggleActivatePartnerSuccess(json, body))
        }
        return json
    },
    toggleActivatePartnerFailure: (error) => ({
        type: types.TOGGLE_ACTIVATE_LOGISTIC_PARTNER_FAILURE,
        error
    }),
    toggleActivatePartnerSuccess: (items, param) => ({
        type: types.TOGGLE_ACTIVATE_LOGISTIC_PARTNER_SUCCESS,
        items,
        param
    }),
    approval: async(
        dispatch,
        token,
        user_id,
        approval,
        body
    ) => {
        var json = await LogisticPartnerAPI.approval(token, user_id, approval, body)
        if(json.status_code === 500) {
            dispatch(actions.approvalFailure(json.message))
        }
        else {
            dispatch(actions.approvalSuccess(json, approval))
        }
        return json
    },
    approvalFailure: (error) => ({
        type: types.APPROVAL_FAILURE,
        error
    }),
    approvalSuccess: (items, param) => ({
        type: types.APPROVAL_SUCCESS,
        items,
        param
    })
}

const initialState = {
    lists: [],
    total: 0,
    error: null
}

export const reducer = (state = initialState, action) => {

    const { items, error, param } = action;

    var selectedPartner = null;
    var logistic_partner_data = [];

    switch (action.type) {
        case types.FETCH_LOGISTIC_PARTNER_FAILURE: {
            return Object.assign({}, state, {
                lists: [],
                total: 0,
                error: error
            })
        }
        case types.FETCH_LOGISTIC_PARTNER_SUCCESS: {
            return Object.assign({}, state, {
                lists: items.data.data,
                total: items.data.data.length,
                error: null
            })
        }
        case types.CLEAR_LOGISTIC_PARTNER: {
            return Object.assign({}, state, {
                lists: [],
                total: 0,
                error: null
            })
        }
        case types.TOGGLE_ACTIVATE_LOGISTIC_PARTNER_FAILURE: {
            return Object.assign({}, state, {
                error: error
            })
        }
        case types.TOGGLE_ACTIVATE_LOGISTIC_PARTNER_SUCCESS: {
            selectedPartner = state.lists.find((item) => parseFloat(item.id) === parseFloat(items.data.id))
            if(param.type === "activate") {
                selectedPartner.is_active = 1;
            }
            else {
                selectedPartner.is_active = 0;
            }

            logistic_partner_data = [
                selectedPartner
            ];
            state.lists.map((item) => {
                if(parseFloat(item.id) !== parseFloat(selectedPartner.id)) {
                    logistic_partner_data.push(item);
                }
                return true;
            });
            logistic_partner_data.sort((a, b) => (a.id > b.id) ? 1 : -1);

            return Object.assign({}, state, {
                error: null,
                lists: logistic_partner_data,
                total: logistic_partner_data.length
            })
        }
        case types.APPROVAL_FAILURE: {
            return Object.assign({}, state, {
                error: error
            })
        }
        case types.APPROVAL_SUCCESS: {
            selectedPartner = items.data[0];
            logistic_partner_data = [
                selectedPartner
            ];
            state.lists.map((item) => {
                if(parseFloat(item.id) !== parseFloat(selectedPartner.id)) {
                    logistic_partner_data.push(item);
                }
                return true;
            });
            logistic_partner_data.sort((a, b) => (a.id > b.id) ? 1 : -1);

            return Object.assign({}, state, {
                error: null,
                lists: logistic_partner_data,
                total: logistic_partner_data.length
            })
        }
        default:
            return state;
    }
}
