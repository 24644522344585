import Constant from "common/Constant";
import store from "store/configureStore";

export const SaytumAPI = {
    /**
     * authenticated post method API
     * @param {All Parameter in specific URI} params 
     * @returns 
     */
    authenticated_post(url, token, data) {
        try {
            return fetch(`${Constant.API_URL}/${url}`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(data)
            }).then(async(response) => {
                if(response.ok) {
                    return response.json();
                }
                var error_message = await response.json().then((json) => {
                    if(typeof(json.message) === "object") {
                        for (const index in json.message) {
                            return json.message[index];
                        }
                    }
                    else {
                        return json; 
                    }
                })
                throw new Error(JSON.stringify(error_message))
            })
            .then((json) => {
                return { "fetch_status": "success", ...json };
            }).catch((error) => {
                store.dispatch({
                    type:"SET_ERROR",
                    items: { hasError: true }
                });
                var err = JSON.parse(error.message);
                return { "fetch_status": "error", "message": err.message, "code": err.code };
            });
        }
        catch(error) {
            store.dispatch({
                type:"SET_ERROR",
                items: { hasError: true }
            });
            return { "fetch_status": "error", "message": error.message  };
        }
    },

    /**
     * authenticated get method API
     * @param {All Parameter in specific URI} params 
     * @returns 
     */
    authenticated_get(url, token) {
        try {
            return fetch(`${Constant.API_URL}/${url}`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(async(response) => {
                if(response.ok) {
                    return response.json()
                }
                var error_message = await response.json().then((json) => {
                    if(typeof(json.message) === "object") {
                        for (const index in json.message) {
                            return json.message[index];
                        }
                    }
                    else {
                        return json; 
                    }
                })
                throw new Error(JSON.stringify(error_message));
            })
            .then((json) => {
                return { "fetch_status": "success", ...json, additional_data: json };
            }).catch((error) => {
                store.dispatch({
                    type:"SET_ERROR",
                    items: { hasError: true }
                });
                var err = JSON.parse(error.message);
                return { "fetch_status": "error", "message": err.message, "code": err.code };
            });
        }
        catch(error) {
            store.dispatch({
                type:"SET_ERROR",
                items: { hasError: true }
            });
            return { "fetch_status": "error", "message": error.message };
        }
    },

    /**
     * authenticated PUT method API
     * @param {All Parameter in specific URI} params 
     * @returns 
     */
    authenticated_put(url, token, data) {
        try {
            return fetch(`${Constant.API_URL}/${url}`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(data)
            }).then(async(response) => {
                if(response.ok) {
                    return response.json();
                }
                var error_message = await response.json().then((json) => {
                    if(typeof(json.message) === "object") {
                        for (const index in json.message) {
                            return json.message[index];
                        }
                    }
                    else {
                        return json; 
                    }
                })

                throw new Error(JSON.stringify(error_message))
            })
            .then((json) => {
                return { "fetch_status": "success", ...json };
            }).catch((error) => {
                store.dispatch({
                    type:"SET_ERROR",
                    items: { hasError: true }
                });
                var err = JSON.parse(error.message)
                return { "fetch_status": "error", "message": err.message, "code": err.code };
            });
        }
        catch(error) {
            store.dispatch({
                type:"SET_ERROR",
                items: { hasError: true }
            });
            return { "fetch_status": "error", "message": error.message };
        }
    },

    /**
     * authenticated DELETE method API
     * @param {All Parameter in specific URI} params 
     * @returns 
     */
    authenticated_delete(url, token) {
        try {
            return fetch(`${Constant.API_URL}/${url}`, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }).then(async(response) => {
                if(response.ok) {
                    return response.json();
                }
                var error_message = await response.json().then((json) => {
                    if(typeof(json.message) === "object") {
                        for (const index in json.message) {
                            return json.message[index];
                        }
                    }
                    else {
                        return json; 
                    }
                })
                throw new Error(JSON.stringify(error_message))
            })
            .then((json) => {
                return { "fetch_status": "success", ...json };
            }).catch((error) => {
                store.dispatch({
                    type:"SET_ERROR",
                    items: { hasError: true }
                });
                var err = JSON.parse(error.message);
                return { "fetch_status": "error", "message": err.message, "code": err.code };
            });
        }
        catch(error) {
            store.dispatch({
                type:"SET_ERROR",
                items: { hasError: true }
            });
            return { "fetch_status": "error", "message": error.message };
        }
    }
} 