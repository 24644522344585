export default function registerServiceWorkerDev()
{
  function determineAppServerKey() {
    const vapidPublicKey = process.env.REACT_APP_FIREBASE_VAPID_KEY;
    // "BF_myc0LEleQu0JN_p23xsgeBFOqYjOVBW4RsCbztb38MqWLWNM12WoM0X9kkP6us143FzDrDFdxNDnWVPmiLwk";
    //   "AAAAK4ZHvSQ:APA91bHv73uHBFhvqq7J_wk9_3K2U3526ryfIbv-ibhOW5xkpFc03WWY7lGsbOtvUdNCwLAiUxPpbr-E_SRl34IQ1Fp3WxKddv0Ay2owzcmPjTX97NzzaVCvMLzZD9AHsI7QH2N2lPjp";
      return urlBase64ToUint8Array(vapidPublicKey);
  }

  function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }
  
    let swUrl=  `${process.env.PUBLIC_URL}/sw.js`
    navigator.serviceWorker.register(swUrl).then((response)=>{
        return response.pushManager.getSubscription()
        .then(function (subscription) {
           response.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: determineAppServerKey()
          })
        })

    }) 

    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('../firebase-messaging-sw.js')
        .then(function(registration) {
            console.log('Registration successful, scope is:', registration.scope);
        }).catch(function(err) {
            console.log('Service worker registration failed, error:', err);
        });
    }
}