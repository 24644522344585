import React from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import {
  Button,
  ButtonGroup,
  FormWrapper,
  FormHeader,
  FormBody,
  Input,
  FormGroup,
} from "components";
import { Tools, Images, Constant, Config } from "common";
import moment from "moment";

var validation = {
  order_name: "required",
  pickup_name: "required",
  pickup_mobile_number: "required",
  pickup_mobile_prefix: "required",
  pickup_address_one: "required",
  pickup_address_two: "required",
  pickup_countries: "required",
  pickup_states: "required",
  pickup_cities: "required",
  pickup_postal_code: "required",
  pickup_time: "required",
  pickup_date: "required",
};

class CreateConsignmentStepOne extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      states: [],
      cities: [],
      data: {
        pickup_mobile_prefix: Constant.COUNTRY_CODE.default,
        Postal_Code: Constant.Postal_Code.postalCodeLength,
        pickup_time:[],
      },
      errors: [],
      country_changed: false,
      state_changed: false,
      orderNameError: false,
      orderNameErrorMessage: "",
      pickupNameError: false,
      pickupNameErrorMessage: "",
      pickupMobilePrefixError: false,
      mobilePrefixErrorMessage: "",
      pickupCountriesError: false,
      pickupStateError: false,
      pickupCitiesError: false,
      pickupPostalCodeError: false,
      pickupPostalCodeErrorMessage: "",
      pickupAddressError: false,
      pickupAddress2Error: false,
      pickupTimeError: false,
      pickupDateError: false,
      localStorageData: {},
    };
  }

  async UNSAFE_componentWillMount() {
    this.setState({
      ...this.state,
      localStorageData: localStorage.getItem("@createConsignment"),
    });
    await this.getCountries();
  }

  // componentDidUpdate() {
  //   if (this.state?.localStorageData?.cities?.length === 0) {
  //     this.initialData();
  //     this.setState({
  //       ...this.state,
  //       localStorageData: localStorage.getItem("@createConsignment"),
  //     });
  //   }
  // }

  async componentDidMount() {
    setTimeout(() => {
      this.initialData();
    }, 1500);
      await this.getCurrentUserData();
  }

  getCountries = async () => {
    const { fetchCountries, token } = this.props;
    var json = await fetchCountries(token);
    return json;
  };

  getCurrentUserData = async () => {
    const { user, fetchStates, fetchCities, token } = this.props;

    var selected_countries = null;
    var selected_cities = null;
    var selected_states = null;
    var state_options = [];
    var city_options = [];

    var getCountries = await this.getCountries();
    if (Tools.isNotEmpty(user.countries)) {
      selected_countries = getCountries.data.data.find(
        (item) => item.name === user.countries
      );
      var states = await fetchStates(token, selected_countries.id);
      states.data.data.map((item) => {
        state_options.push({ label: item.name, value: item.name, code: item.code });
        return true;
      });
    }
    if (Tools.isNotEmpty(user.states)) {
      const { states } = this.props;
      selected_states = states.find((item) => item.name === user.states);
      var cities = await fetchCities(token, selected_states.code);
      cities.data.data.map((item) => {
        city_options.push({ label: item.name, value: item.name, code: item.code });
        return true;
      });
    }
    if (Tools.isNotEmpty(user.cities)) {
      const { cities } = this.props;
      selected_cities = cities.find((item) => item.name === user.cities);
    }

    this.setState({
      data: {
        ...this.state.data,
        pickup_name: user.firstname + " " + user.lastname,
        pickup_address_one: user.address,
        pickup_countries: selected_countries
          ? {
              label: selected_countries.name,
              value: selected_countries.name,
              id: selected_countries.id,
            }
          : null,
        pickup_states: selected_states
          ? {
              label: selected_states.name,
              value: selected_states.name,
              code: selected_states.code,
            }
          : null,
        pickup_cities: selected_cities
          ? {
              label: selected_cities.name,
              value: selected_cities.name,
              id: selected_cities.id,
            }
          : null,
        pickup_postal_code: user.postal_code,
        pickup_mobile_prefix: user.mobile_prefix
          ? Constant.COUNTRY_CODE.options.find(
              (item) => item.value === user.mobile_prefix
            )
          : null,
        pickup_mobile_number: user.mobile_number,
      },
      states: state_options,
      cities: city_options,
    });
  };

  // componentDidMount() {
  //   if (typeof window !== "undefined") {
  //     this.setState({
  //       status: localStorage.getItem("@createConsignment") ? true : false,
  //     });

  //     window.addEventListener("storage", this.initialData);
  //   }
  //   this.initialData();
  //   console.log(
  //     "localStorage.getItem",
  //     localStorage.getItem("@createConsignment")
  //   );
  // }

  // componentWillUnmount() {
  //   if (typeof window !== "undefined") {
  //     window.removeEventListener("storage", this.initialData);
  //   }
  // }

  // updateState(value) {
  //   this.setState({ status: value });
  // }

  initialData = async () => {
    var data = localStorage.getItem("@createConsignment");

    if (data) {
      data = JSON.parse(data);
      var state_options = [];
      var city_options = [];

      if (Tools.isNotEmpty(data.pickup_countries)) {
        const { countries, fetchStates, token } = this.props;
        var selectedCountry = countries.find(
          (item) => item.id === data.pickup_countries.id
        );
        var states = await fetchStates(token, selectedCountry.id);
        states.data.data.map((item) => {
          state_options.push({
            label: item.name,
            value: item.name,
            code: item.code,
          });
          return true;
        });
      }
      if (Tools.isNotEmpty(data.pickup_states)) {
        const { states, fetchCities, token } = this.props;
        var selectedState = states.find(
          (item) => item.code === data.pickup_states.code
        );
        var cities = await fetchCities(token, selectedState.code);
        cities.data.data.map((item) => {
          city_options.push({
            label: item.name,
            value: item.name,
            code: item.code,
          });
          return true;
        });
      }

      this.setState({
        data: {
          ...data,
          ...this.state.data,
          pickup_date: data.pickup_date ? new Date(data.pickup_date) : null,
        },
        states: state_options,
        cities: city_options,
      });
    }
  };

  // getCountries = async () => {
  //   const { fetchCountries, token } = this.props;
  //   fetchCountries(token);
  // };

  onBlurValidationHandler = (target) => {
    if (target === "orderName") {
      if (!Tools.isNotEmpty(this.state.data.order_name)) {
        this.setState({
          ...this.state,
          orderNameError: true,
          orderNameErrorMessage: "This field is required",
        });
      } else if (!Tools.isOrderNameValid(this.state.data.order_name)) {
        this.setState({
          ...this.state,
          orderNameError: true,
          orderNameErrorMessage: "Special characters are not allowed",
        });
      } else {
        this.setState({
          ...this.state,
          orderNameError: false,
        });
      }
    }
    if (target === "pickupName") {
      if (!Tools.isNotEmpty(this.state.data.pickup_name)) {
        this.setState({
          ...this.state,
          pickupNameError: true,
          pickupNameErrorMessage: "This field is required",
        });
      } else if (!Tools.isNameValid(this.state.data.pickup_name)) {
        this.setState({
          ...this.state,
          pickupNameError: true,
          pickupNameErrorMessage: "Special characters and Numbers are not allowed",
        });
      } else {
        this.setState({
          ...this.state,
          pickupNameError: false,
        });
      }
    }
    if (target === "pickupMobilePrefix") {
      const countryCode = this.state.data.pickup_mobile_prefix;
      // console.log("countryCode",countryCode)
      const mobileNumber = this.state.data.pickup_mobile_number;
      // console.log("what", mobileNumber);
      // console.log("countryLenght" , countryCode.length);
      if (
        !Tools.isNotEmpty(this.state.data.pickup_mobile_prefix) ||
        !Tools.isNotEmpty(this.state.data.pickup_mobile_number)
      ) {
        this.setState({
          ...this.state,
          pickupMobilePrefixError: true,
          mobilePrefixErrorMessage: "This field is required",
        });
      } else if (mobileNumber.length !== countryCode.length) {
        this.setState({
          ...this.state,
          pickupMobilePrefixError: true,
          mobilePrefixErrorMessage: "Invalid Mobile Number",
        });
      } else {
        this.setState({
          ...this.state,
          pickupMobilePrefixError: false,
        });
      }
    }
    if (target === "pickupCountries") {
      if (!Tools.isNotEmpty(this.state.data.pickup_countries)) {
        this.setState({
          ...this.state,
          pickupCountriesError: true,
        });
      } else {
        this.setState({
          ...this.state,
          pickupCountriesError: false,
        });
      }
    }
    if (target === "pickupState") {
      if (!Tools.isNotEmpty(this.state.data.pickup_states)) {
        this.setState({
          ...this.state,
          pickupStateError: true,
        });
      } else {
        this.setState({
          ...this.state,
          pickupStateError: false,
        });
      }
    }
    if (target === "pickupCities") {
      if (!Tools.isNotEmpty(this.state.data.pickup_cities)) {
        this.setState({
          ...this.state,
          pickupCitiesError: true,
        });
      } else {
        this.setState({
          ...this.state,
          pickupCitiesError: false,
        });
      }
    }
    if (target === "pickupPostalCode") {
      const postalLength = this.state.data.pickup_postal_code;
      // console.log("pickupcode" , postalLength);
      // console.log("length", postalLength.length);
      const postal_code = this.state.data.Postal_Code;
      // console.log("code", postal_code);
      if (!Tools.isNotEmpty(this.state.data.pickup_postal_code)) {
        this.setState({
          ...this.state,
          pickupPostalCodeError: true,
          pickupPostalCodeErrorMessage: "This field is required",
        });
      } else if (
        postalLength.length < postal_code ||
        postalLength.length > postal_code
      ) {
        this.setState({
          ...this.state,
          pickupPostalCodeError: true,
          pickupPostalCodeErrorMessage: "Invalid Postal Code",
        });
      } else {
        this.setState({
          ...this.state,
          pickupPostalCodeError: false,
        });
      }
    }
    if (target === "pickupAddress") {
      if (!Tools.isNotEmpty(this.state.data.pickup_address_one)) {
        this.setState({
          ...this.state,
          pickupAddressError: true,
        });
      } else {
        this.setState({
          ...this.state,
          pickupAddressError: false,
        });
      }
    }
    if (target === "pickupAddress2") {
      if (!Tools.isNotEmpty(this.state.data.pickup_address_two)) {
        this.setState({
          ...this.state,
          pickupAddress2Error: true,
        });
      } else {
        this.setState({
          ...this.state,
          pickupAddress2Error: false,
        });
      }
    }
    if (target === "pickupTime") {
      if (!Tools.isNotEmpty(this.state.data.pickup_time)) {
        this.setState({
          ...this.state,
          pickupTimeError: true,
        });
      } else {
        this.setState({
          ...this.state,
          pickupTimeError: false,
        });
      }
    }
    if (target === "pickupDate") {
      if (!Tools.isNotEmpty(this.state.data.pickup_date)) {
        this.setState({
          ...this.state,
          pickupDateError: true,
        });
      } else {
        this.setState({
          ...this.state,
          pickupDateError: false,
        });
      }
    }
  };

  onChange = async (target, value) => {
    this.setState(
      {
        send: false,
        data: {
          ...this.state.data,
          [target]: value,
          country_changed: false,
          state_changed: false,
        },
      },
      async () => {

        if (target === "pickup_countries") {
          const { fetchStates, token } = this.props;
          const json = await fetchStates(token, value.code);
          const data = json.data.data.map((item) => ({
            label: item.name,
            value: item.name,
            code: item.code,
          }));
          this.setState({
            states: data,
            country_changed: true,
            data: { ...this.state.data, pickup_states: null },
          });
        } else if (target === "pickup_states") {
          const { fetchCities, token } = this.props;
          const json = await fetchCities(token, value.code);
          console.log("value" , value , "code" , value.code);
          console.log("state code" , json);
          const data = json.data.data.map((item) => ({
            label: item.name,
            value: item.name,
            code: item.code,
          }));
          this.setState({
            cities: data,
            state_changed: true,
            country_changed: false,
            data: { ...this.state.data, pickup_cities: null },
          });
          console.log("city state" , data);
        } else if (target === "pickup_cities") {
          this.setState({ state_changed: false });
        }
        // else if (target === "pickup_mobile_number") {
        //   if( this.state.data.pickup_mobile_number.length <= 8 )
        //   {
        //     console.log('length----');
        //     this.setState({
        //       ...this.state,
        //       pickupMobilePrefixError: true,
        //     });
        //   }
        // }
      }
    );
  };

  validation = async () => {
    var validate = {};
    validate = await Tools.validation(validation, this.state.data);

    this.setState({
      valid: validate.valid,
      errors: validate.errors,
    });

    return validate.valid;
  };

  onNext = async () => {
    const valid = await this.validation();
    this.setState({
      send: true,
    });
    if (valid) {
      var { data } = this.state;
      var save_locally = await this.saveLocally();
      data = {
        ...data,
        id: save_locally,
      };
      localStorage.setItem("@createConsignment", JSON.stringify(data));
      this.props.onNext();
    }
  };

  saveLocally = () => {
    const { local_consignment, setLocalConsingment, match } = this.props;
    var local_consignments = local_consignment || [];

    const { data } = this.state;
    var parameter_id = match.params.id;
    var id = 0;

    if (Tools.isNotEmpty(parameter_id)) {
      var selected_consignments = local_consignments.find(
        (item) => item.id === parameter_id
      );
      if (Tools.isNotEmpty(selected_consignments)) {
        id = selected_consignments.id;
        selected_consignments = {
          ...selected_consignments,
          ...data,
          status: Config.savedLocallyStatusKey,
        };
        var index = local_consignments
          .map(function (e) {
            return e.id;
          })
          .indexOf(data.id);
        local_consignments[index] = selected_consignments;
        setLocalConsingment(local_consignments);
        return id;
      }
    } else {
      id = Tools.generateLocalID();
      var generatedData = {
        ...data,
        status: Config.savedLocallyStatusKey,
        id: id,
        created_at: moment(),
      };
      local_consignments.push(generatedData);
      setLocalConsingment(local_consignments);
      return id;
    }

    return parameter_id;
  };

  render() {
    const { data, states, cities } = this.state;
    const { countries } = this.props;
    const { innerWidth } = window;

    var country_datas = [];
    countries.map((item) => {
      country_datas.push({ label: item.name, value: item.name, id: item.id });
      return true;
    });

    var pickup_time = [
      { label: "Same Day", value: "same_day", name: "same_day" },
      { label: "Future", value: "future", name: "future" },
    ];

    return (
      <div
        style={
          innerWidth > 768
            ? { marginTop: 50, marginBottom: 50 }
            : { marginTop: 15, marginBottom: 20 }
        }
      >
        <FormWrapper>
          <FormHeader title="pickup" />
          <FormBody>
            <Input
              type="text"
              label="Order Name"
              placeholder="Please type here"
              style={{ marginBottom: 20 }}
              value={data.order_name}
              onBlur={() => this.onBlurValidationHandler("orderName")}
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  orderNameError: false,
                });
                this.onChange("order_name", e.target.value);
              }}
              caption={
                this.state.orderNameError && this.state.orderNameErrorMessage
              }
              isError={
                (this.state.send &&
                  !this.state.valid &&
                  this.state.errors.includes("order_name")) ||
                this.state.orderNameError
              }
              isRequired={true}
            />
            <Input
              type="text"
              label="Sender Name"
              placeholder="Please type here"
              style={{ marginBottom: 20 }}
              value={data.pickup_name}
              onBlur={() => this.onBlurValidationHandler("pickupName")}
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  pickupNameError: false,
                });
                this.onChange("pickup_name", e.target.value);
              }}
              caption={
                this.state.pickupNameError && this.state.pickupNameErrorMessage
              }
              isError={
                (this.state.send &&
                  !this.state.valid &&
                  this.state.errors.includes("pickup_name")) ||
                this.state.pickupNameError
              }
              isRequired={true}
            />
            <Input
              type="phone"
              label="Mobile Number"
              phoneCodes={Constant.COUNTRY_CODE.options}
              placeholder="Please type here"
              style={{ marginBottom: 20 }}
              onSelect={(e) => this.onChange("pickup_mobile_prefix", e)}
              selectedPrefix={data.pickup_mobile_prefix}
              value={data.pickup_mobile_number}
              onBlur={() => this.onBlurValidationHandler("pickupMobilePrefix")}
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  pickupMobilePrefixError: false,
                });
                this.onChange("pickup_mobile_number", e.target.value);
              }}
              caption={
                this.state.pickupMobilePrefixError &&
                this.state.mobilePrefixErrorMessage
              }
              isError={
                (this.state.send &&
                  !this.state.valid &&
                  (this.state.errors.includes("pickup_mobile_prefix") ||
                    this.state.errors.includes("pickup_mobile_number"))) ||
                this.state.pickupMobilePrefixError
              }
              isRequired={true}
            />

            <FormGroup>
              <Col lg={6} md={6} sm={6} xs={6}>
                <Input
                  type="select"
                  label="Country"
                  options={country_datas}
                  placeholder="Please type here"
                  style={{ marginBottom: 20 }}
                  value={data.pickup_countries}
                  // onBlur={() => this.onBlurValidationHandler("pickupCountries")}
                  onChange={(e) => {
                    this.setState({
                      ...this.state,
                      pickupCountriesError: false,
                    });
                    this.onChange("pickup_countries", e);
                  }}
                  caption={
                    this.state.pickupCountriesError && "This field is required"
                  }
                  isError={
                    (this.state.send &&
                      !this.state.valid &&
                      this.state.errors.includes("pickup_countries")) ||
                    this.state.pickupCountriesError
                  }
                  isRequired={true}
                />
              </Col>
              <Col lg={6} md={6} sm={6} xs={6}>
                <Input
                  type="select"
                  label="State"
                  options={states}
                  placeholder="Please type here"
                  style={{ marginBottom: 20 }}
                  value={
                    this.state.country_changed === true
                      ? {}
                      : data.pickup_states
                  }
                  // onBlur={() => this.onBlurValidationHandler("pickupState")}
                  onChange={(e) => {
                    this.setState({
                      ...this.state,
                      pickupStateError: false,
                    });
                    this.onChange("pickup_states", e);
                  }}
                  caption={
                    this.state.pickupStateError && "This field is required"
                  }
                  isError={
                    (this.state.send &&
                      !this.state.valid &&
                      this.state.errors.includes("pickup_states")) ||
                    this.state.pickupStateError
                  }
                  isRequired={true}
                />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col lg={6} md={6} sm={6} xs={6}>
                <Input
                  type="select"
                  label="City"
                  options={cities}
                  placeholder="Please type here"
                  style={{ marginBottom: 20 }}
                  value={
                    this.state.state_changed === true ? {} : data.pickup_cities
                  }
                  // onBlur={() => this.onBlurValidationHandler("pickupCities")}
                  onChange={(e) => {
                    this.setState({
                      ...this.state,
                      pickupCitiesError: false,
                    });
                    this.onChange("pickup_cities", e);
                  }}
                  caption={
                    this.state.pickupCitiesError && "This field is required"
                  }
                  isError={
                    (this.state.send &&
                      !this.state.valid &&
                      this.state.errors.includes("pickup_cities")) ||
                    this.state.pickupCitiesError
                  }
                  isRequired={true}
                />
              </Col>
              <Col lg={6} md={6} sm={6} xs={6}>
                <Input
                  type="number"
                  min="1"
                  label="Postal Code"
                  placeholder="Please type here"
                  style={{ marginBottom: 20 }}
                  value={data.pickup_postal_code}
                  onBlur={() =>
                    this.onBlurValidationHandler("pickupPostalCode")
                  }
                  onChange={(e) => {
                    this.setState({
                      ...this.state,
                      pickupPostalCodeError: false,
                    });
                    this.onChange("pickup_postal_code", e.target.value);
                  }}
                  caption={
                    this.state.pickupPostalCodeError &&
                    this.state.pickupPostalCodeErrorMessage
                  }
                  isError={
                    (this.state.send &&
                      !this.state.valid &&
                      this.state.errors.includes("pickup_postal_code")) ||
                    this.state.pickupPostalCodeError
                  }
                  isRequired={true}
                />
              </Col>
            </FormGroup>

            <Input
              type="autocomplete"
              label="Address One"
              placeholder="Please type here"
              style={{ marginBottom: 20 }}
              value={data.pickup_address_one}
              onBlur={() => this.onBlurValidationHandler("pickupAddress")}
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  pickupAddressError: false,
                });
                this.onChange("pickup_address_one", e);
              }}
              countries={
                data.pickup_countries ? data.pickup_countries.value : null
              }
              inputProps={{
                readOnly: data.pickup_countries ? false : true,
              }}
              caption={
                this.state.pickupAddressError && "This field is required"
              }
              isError={
                (this.state.send &&
                  !this.state.valid &&
                  this.state.errors.includes("pickup_address_one")) ||
                this.state.pickupAddressError
              }
              isRequired={true}
            />
            <Input
              type="text"
              label="Address Two"
              placeholder="Please type here"
              style={{ marginBottom: 20 }}
              value={data.pickup_address_two}
              inputProps={{
                readOnly: data.pickup_countries ? false : true,
              }}
              onBlur={() => this.onBlurValidationHandler("pickupAddress2")}
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  pickupAddress2Error: false,
                });
                this.onChange("pickup_address_two", e.target.value);
              }}
              caption={
                this.state.pickupAddress2Error && "This field is required"
              }
              isError={
                (this.state.send &&
                  !this.state.valid &&
                  this.state.errors.includes("pickup_address_two")) ||
                this.state.pickupAddress2Error
              }
              isRequired={true}
            />

            <Input
              type="select"
              label="Pickup Time"
              options={pickup_time}
              placeholder="Please type here"
              style={{ marginBottom: 20 }}
              value={data.pickup_time}
              // onBlur={() => this.onBlurValidationHandler("pickupTime")}
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  pickupTimeError: false,
                });
                this.onChange("pickup_time", e);
              }}
              caption={this.state.pickupTimeError && "This field is required"}
              isError={
                (this.state.send &&
                  !this.state.valid &&
                  this.state.errors.includes("pickup_time")) ||
                this.state.pickupTimeError
              }
              isRequired={true}
            />
            <Input
              type="datepicker"
              iconSuffix={Images.datepicker}
              value={data.pickup_date}
              label="Date"
              placeholder="Please type here"
              style={{ marginBottom: 20 }}
              onBlur={() => this.onBlurValidationHandler("pickupDate")}
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  pickupDateError: false,
                });
                this.onChange("pickup_date", e);
              }}
              pickerProps={{
                // minDate: new Date(),
                showTimeSelect: true,
              }}
              selectedTime={data.pickup_time.name}
              caption={this.state.pickupDateError && "This field is required"}
              isError={
                (this.state.send &&
                  !this.state.valid &&
                  this.state.errors.includes("pickup_date")) ||
                this.state.pickupDateError
              }
              isRequired={true}
            />

            <ButtonGroup position="right">
              <Button
                buttonText="Cancel"
                type="default"
                size="sm"
                onClick={() => {
                  localStorage.removeItem("@consignmentData");
                  this.props.history.goBack();
                }}
              />
              <Button
                buttonText="Save"
                type="success"
                size="sm"
                onClick={() => this.onNext()}
              />
            </ButtonGroup>
          </FormBody>
        </FormWrapper>
      </div>
    );
  }
}

const mapsStatetoProps = ({ authenticated, locations, consignment }) => {
  return {
    user: authenticated.userInfo,
    token: authenticated.token,
    cities: locations.cities,
    states: locations.states,
    countries: locations.countries,
    local_consignment: consignment.local_data,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions: location_actions } = require("redux/LocationRedux");

  return {
    ...ownProps,
    ...stateProps,
    fetchCountries: async (token) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await location_actions.fetchCountries(dispatch, token);
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    fetchStates: async (token, country_id) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await location_actions.fetchStates(
        dispatch,
        token,
        country_id
      );
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    fetchCities: async (token, state_code) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await location_actions.fetchCities(dispatch, token, state_code);
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    toastPending: (body) => {
      dispatch({ type: "TOAST_PENDING", ...body });
    },
    setLocalConsingment: (items) =>
      dispatch({ type: "SET_LOCAL_CONSIGNMENT", items }),
  };
};

export default connect(
  mapsStatetoProps,
  undefined,
  mergeProps
)(CreateConsignmentStepOne);
