import React, { useEffect, useState } from "react";
import CreateConsignmentStepOne from "./Create/step-one";
import CreateConsignmentStepTwo from "./Create/step-two";
import CreateConsignmentStepThree from "./Create/step-three";
import CreateConsignmentStepFour from "./Create/step-four";
import CreateConsignmentStepFive from "./Create/step-five";
import { connect } from "react-redux";
import moment from "moment";
import { Tools, navigate } from "common";
// import { useCallback } from "react";

const EditConsignmentContainer = (props) => {
  const [step, setStep] = useState(1);
  // const [data, setData] = useState({});
  const [loaded, setLoaded] = useState(false);

  
  useEffect( () => {
    localStorage.removeItem("@createConsignment");
    // eslint-disable-next-line
  }, []);
  
  useEffect(() => {
    initialData();
    // eslint-disable-next-line
  }, [props]);

  const initialData = async () => {
    const {
      countries,
      consignment,
      states,
      cities,
      material_categories,
      fleet_categories,
      local_consignment,
      match,
    } = props;

    var pickup_time = [
      { label: "Same Day", value: "same_day", name: "same_day" },
      { label: "Future", value: "future", name: "future" },
    ];

    var data = consignment.concat(local_consignment);

    var id = match.params.id;
    var selectedConsignment = data.find(
      (item) => parseFloat(item.id) === parseFloat(id)
    );
    var pickupCountry = countries.find(
      (item) => item.name === selectedConsignment.pickup_countries
    );
    var pickupState = states.find(
      (item) => item.name === selectedConsignment.pickup_states
    );
    var pickupCity = cities.find(
      (item) => item.name === selectedConsignment.pickup_cities
    );
    var selectedPickupTime = pickup_time.find(
      (item) => item.value === selectedConsignment.pickup_time
    );
    var deliveryCountry = countries.find(
      (item) => item.name === selectedConsignment.delivery_countries
    );
    var deliveryState = states.find(
      (item) => item.name === selectedConsignment.delivery_states
    );
    var deliveryCity = cities.find(
      (item) => item.name === selectedConsignment.delivery_cities
    );
    var selectedMaterialCategories = material_categories.find(
      (item) =>
        parseFloat(item.id) ===
        parseFloat(selectedConsignment.material_category_id)
    );
    var selectedVehicleCategories = fleet_categories.find(
      (item) =>
        parseFloat(item.id) ===
        parseFloat(selectedConsignment.fleet_category_id)
    );

    if (id.includes("local")) {
      selectedConsignment = data.find((item) => item.id === id);
      selectedConsignment = {
        ...selectedConsignment,
        pickup_mobile_prefix: Tools.isNotEmpty(
          selectedConsignment.pickup_mobile_prefix
        )
          ? selectedConsignment.pickup_mobile_prefix.value
          : null,
        delivery_mobile_prefix: Tools.isNotEmpty(
          selectedConsignment.delivery_mobile_prefix
        )
          ? selectedConsignment.delivery_mobile_prefix.value
          : null,
      };
      pickupCountry = Tools.isNotEmpty(selectedConsignment.pickup_countries)
        ? {
            ...selectedConsignment.pickup_countries,
            name: selectedConsignment.pickup_countries.value,
            id: selectedConsignment.pickup_countries.id,
          }
        : null;
      pickupState = Tools.isNotEmpty(selectedConsignment.pickup_states)
        ? {
            ...selectedConsignment.pickup_states,
            name: selectedConsignment.pickup_states.value,
            id: selectedConsignment.pickup_states.id,
          }
        : null;
      pickupCity = Tools.isNotEmpty(selectedConsignment.pickup_cities)
        ? {
            ...selectedConsignment.pickup_cities,
            name: selectedConsignment.pickup_cities.value,
            id: selectedConsignment.pickup_cities.id,
          }
        : null;
      selectedPickupTime = Tools.isNotEmpty(selectedConsignment.pickup_time)
        ? pickup_time.find(
            (item) => item.value === selectedConsignment.pickup_time.value
          )
        : null;
      deliveryCountry = Tools.isNotEmpty(selectedConsignment.delivery_countries)
        ? {
            ...selectedConsignment.delivery_countries,
            name: selectedConsignment.delivery_countries.value,
            id: selectedConsignment.delivery_countries.id,
          }
        : null;
      deliveryState = Tools.isNotEmpty(selectedConsignment.delivery_states)
        ? {
            ...selectedConsignment.delivery_states,
            name: selectedConsignment.delivery_states.value,
            id: selectedConsignment.delivery_states.id,
          }
        : null;
      deliveryCity = Tools.isNotEmpty(selectedConsignment.delivery_cities)
        ? {
            ...selectedConsignment.delivery_cities,
            name: selectedConsignment.delivery_cities.value,
            id: selectedConsignment.delivery_cities.id,
          }
        : null;
      selectedMaterialCategories = Tools.isNotEmpty(
        selectedConsignment.material_category
      )
        ? material_categories.find(
            (item) =>
              parseFloat(item.id) ===
              parseFloat(selectedConsignment.material_category.id)
          )
        : null;
      selectedVehicleCategories = Tools.isNotEmpty(
        selectedConsignment.fleet_category
      )
        ? fleet_categories.find(
            (item) =>
              parseFloat(item.id) ===
              parseFloat(selectedConsignment.fleet_category.id)
          )
        : null;
    }

    selectedConsignment = {
      ...selectedConsignment,
      id: id,
      pickup_countries: Tools.isNotEmpty(pickupCountry)
        ? {
            label: pickupCountry.name,
            value: pickupCountry.name,
            id: pickupCountry.id,
          }
        : null,
      pickup_states: Tools.isNotEmpty(pickupState)
        ? {
            label: pickupState.name,
            value: pickupState.name,
            id: pickupState.id,
          }
        : null,
      pickup_cities: Tools.isNotEmpty(pickupCity)
        ? {
            label: pickupCity.name,
            value: pickupCity.name,
            id: pickupCity.id,
          }
        : null,
      pickup_mobile_prefix: Tools.isNotEmpty(
        selectedConsignment.pickup_mobile_prefix
      )
        ? {
            label: selectedConsignment.pickup_mobile_prefix,
            value: selectedConsignment.pickup_mobile_prefix,
          }
        : null,
      pickup_time: selectedPickupTime,
      delivery_mobile_prefix: Tools.isNotEmpty(
        selectedConsignment.delivery_mobile_prefix
      )
        ? {
            label: selectedConsignment.delivery_mobile_prefix,
            value: selectedConsignment.delivery_mobile_prefix,
          }
        : null,
      delivery_countries: Tools.isNotEmpty(deliveryCountry)
        ? {
            label: deliveryCountry.name,
            value: deliveryCountry.name,
            id: deliveryCountry.id,
          }
        : null,
      delivery_states: Tools.isNotEmpty(deliveryState)
        ? {
            label: deliveryState.name,
            value: deliveryState.name,
            id: deliveryState.id,
          }
        : null,
      delivery_cities: Tools.isNotEmpty(deliveryCity)
        ? {
            label: deliveryCity.name,
            value: deliveryCity.name,
            id: deliveryCity.id,
          }
        : null,
      material_category: Tools.isNotEmpty(selectedMaterialCategories)
        ? {
            label: selectedMaterialCategories.name,
            value: selectedMaterialCategories.name,
            id: selectedMaterialCategories.id,
          }
        : null,
      fleet_category: Tools.isNotEmpty(selectedVehicleCategories)
        ? {
            label: selectedVehicleCategories.name,
            value: selectedVehicleCategories.name,
            id: selectedVehicleCategories.id,
          }
        : null,
    };
    await localStorage.setItem(
      "@createConsignment",
      JSON.stringify(selectedConsignment)
    );
    setLoaded(true);
  };

  // const completed = () => {
  //   setLoaded(true);
  //   // this.setState({ loaded: true });
  // };

  const onNext = async () => {
    setStep(step + 1);
    // this.setState({
    //   step: this.state.step + 1,
    // });
  };

  const onDraft = async () => {
    const data = await generateData();
    const { updateConsignment, token, history, toastPending } = props;
    var json = await updateConsignment(token, data);
    if (json.status_code === 200) {
      await removeFromLocal();
      await localStorage.removeItem("@createConsignment");
      navigate(history, "/dashboard?screen=consignment");
    }
    toastPending({ message: json.message, toastType: json.status_code });
  };

  const onCreate = async () => {
    const data = await generateData();
    const { publishConsignment, token, history, user, toastPending } = props;
    const params = {
      ...data,
      status: 102,
    };
    var json = await publishConsignment(token, user.id, params);
    if (json.status_code === 200) {
      await removeFromLocal();
      await localStorage.removeItem("@createConsignment");
      navigate(history, "/dashboard?screen=consignment");
    }
    toastPending({ message: json.message, toastType: json.status_code });
  };

  const generateData = async () => {
    const generateData = {
      isVisible: true,
      label: "Getting Data",
      description: "Please wait  moment, we are preparing your data",
    };
    props.SET_PROCESS({ ...generateData });
    const { user } = await props;
    const data = typeof localStorage.getItem("@createConsignment")
      ? JSON.parse(localStorage.getItem("@createConsignment"))
      : {};
    // data = JSON.parse(data);

    const pickup_address =
      (await data.pickup_address_one) + "," + data.pickup_postal_code;
    const delivery_address =
      (await data.delivery_address_one) + "," + data.delivery_postal_code;
    const pickup_coord = await Tools.reverseLocation(pickup_address);
    const delivery_coord = await Tools.reverseLocation(delivery_address);

    const response = {
      id: parseFloat(data.id),
      order_name: data.order_name,
      pickup_name: data.pickup_name,
      pickup_mobile_prefix: data.pickup_mobile_prefix.value,
      pickup_mobile_number: data.pickup_mobile_number.replace(/ /g, ""),
      pickup_address_one: data.pickup_address_one,
      pickup_address_two: data.pickup_address_two,
      pickup_postal_code: data.pickup_postal_code,
      pickup_time: data.pickup_time.value,
      pickup_date: moment(data.pickup_date).format("YYYY-MM-DD HH:mm:ss"),
      pickup_countries: data.pickup_countries.value,
      pickup_states: data.pickup_states.value,
      pickup_cities: data.pickup_cities.value,
      pickup_latitude: pickup_coord.lat,
      pickup_longitude: pickup_coord.lng,
      delivery_name: data.delivery_name,
      delivery_mobile_prefix: data.delivery_mobile_prefix.value,
      delivery_mobile_number: data.delivery_mobile_number.replace(/ /g, ""),
      delivery_address_one: data.delivery_address_one,
      delivery_address_two: data.delivery_address_two,
      delivery_countries: data.delivery_countries.value,
      delivery_states: data.delivery_states.value,
      delivery_cities: data.delivery_cities.value,
      delivery_postal_code: data.delivery_postal_code,
      delivery_latitude: delivery_coord.lat,
      delivery_longitude: delivery_coord.lng,
      material_category_id: data.material_category.id,
      material_detail: data.material_detail,
      weight: parseFloat(data.weight) || 0,
      material_price: parseFloat(data.material_price) || 0,
      user_id: user.id,
      gst_number: data.gst_number || "",
      is_insurance: data.is_insurance || false,
      fleet_category_id: data.fleet_category ? data.fleet_category.id : "",
    };

    // if(this.state.data.material_category.label.toLowerCase() !== "envelope") {
    //     data = {
    //         ...data,
    //         fleet_category_id: this.state.data.fleet_category ? this.state.data.fleet_category.id : ""
    //     }
    // }
    props.RESET_PROCESS();

    return response;
  };

  const onPrevious = () => {
    setStep(step - 1);

    // this.setState({
    //   step: this.state.step - 1,
    // });
  };

  const removeFromLocal = () => {
    const { local_consignment, setLocalConsingment, match } = props;
    var local_consignments = local_consignment || [];

    var parameter_id = match.params.id;
    var selected_consignments = local_consignments.find(
      (item) => item.id === parameter_id
    );

    if (selected_consignments) {
      var index = local_consignments
        .map(function (e) {
          return e.id;
        })
        .indexOf(selected_consignments.id);

      local_consignments.splice(index, 1);

      setLocalConsingment(local_consignments);
    }

    return true;
  };

  // render() {
  return loaded ? (
    step === 1 ? (
      <CreateConsignmentStepOne
        {...props}
        onBack={() => props.history.goBack()}
        onNext={() => onNext()}
      />
    ) : step === 2 ? (
      <CreateConsignmentStepTwo
        {...props}
        onPrevious={() => onPrevious()}
        onNext={() => onNext()}
      />
    ) : step === 3 ? (
      <CreateConsignmentStepThree
        {...props}
        onPrevious={() => onPrevious()}
        onNext={() => onNext()}
      />
    ) : step === 4 ? (
      <CreateConsignmentStepFour
        {...props}
        onPrevious={() => onPrevious()}
        onNext={() => onNext()}
      />
    ) : (
      step === 5 && (
        <CreateConsignmentStepFive
          {...props}
          onDraft={() => onDraft()}
          onCreate={() => onCreate()}
        />
      )
    )
  ) : (
    "this"
  );
  // if (loaded) {
  //   if (step === 1) {
  //     return (
  //       <CreateConsignmentStepOne
  //         {...props}
  //         onBack={() => props.history.goBack()}
  //         onNext={() => onNext()}
  //       />
  //     );
  //   } else if (step === 2) {
  //     return (
  // <CreateConsignmentStepTwo
  //   {...props}
  //   onPrevious={() => onPrevious()}
  //   onNext={() => onNext()}
  // />
  //     );
  //   } else if (step === 3) {
  //     return (
  // <CreateConsignmentStepThree
  //   {...props}
  //   onPrevious={() => onPrevious()}
  //   onNext={() => onNext()}
  // />
  //     );
  //   } else if (step === 4) {
  //     return (
  // <CreateConsignmentStepFour
  //   {...props}
  //   onPrevious={() => onPrevious()}
  //   onNext={() => onNext()}
  // />
  //     );
  //   } else if (step === 5) {
  //     return (
  // <CreateConsignmentStepFive
  //   {...props}
  //   onDraft={() => onDraft()}
  //   onCreate={() => onCreate()}
  // />
  //     );
  //   }
  // } else {
  //   return <></>;
  // }
};

const mapsStateToProps = ({
  authenticated,
  consignment,
  locations,
  material_categories,
  fleet_categories,
}) => {
  return {
    token: authenticated.token,
    user: authenticated.userInfo,
    consignment: consignment.lists,
    countries: locations.countries,
    states: locations.states,
    cities: locations.cities,
    material_categories: material_categories.lists,
    fleet_categories: fleet_categories.lists,
    local_consignment: consignment.local_data,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/ConsignmentRedux");
  const { actions: location_actions } = require("redux/LocationRedux");

  return {
    ...ownProps,
    ...stateProps,
    fetchCountries: async (token) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await location_actions.fetchCountries(dispatch, token);
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    fetchStates: async (token, country_id) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await location_actions.fetchStates(
        dispatch,
        token,
        country_id
      );
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    fetchCities: async (token, state_code) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await location_actions.fetchCities(dispatch, token, state_code);
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    updateConsignment: async (token, body) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await actions.updateConsignment(dispatch, token, body);
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    publishConsignment: async (token, user_id, body) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await actions.publishConsignment(
        dispatch,
        token,
        user_id,
        body
      );
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    toastPending: (body) => {
      dispatch({ type: "TOAST_PENDING", ...body });
    },
    fetchPending: () => dispatch({ type: "FETCH_PENDING" }),
    fetchFinish: () => dispatch({ type: "FETCH_FINISH" }),
    setLocalConsingment: (items) =>
      dispatch({ type: "SET_LOCAL_CONSIGNMENT", items }),
    SET_PROCESS: (process) => dispatch({ type: "SET_PROCESS", items: process }),
    RESET_PROCESS: () => dispatch({ type: "RESET_PROCESS" }),
  };
};

export default connect(
  mapsStateToProps,
  undefined,
  mergeProps
)(EditConsignmentContainer);
