import { Button } from 'components';
import React from 'react';
import "./styles.scss";
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import ProfileDocument from './document';
import Tools, { navigate } from 'common/Tools';
import { FirebaseAPI } from 'services/FirebaseAPI';
import ProfileHeader from './header';
import { Constant } from 'common';


class ProfileContainer extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            photo: "",
            user : {
                mobile_prefix : Constant.COUNTRY_CODE.default
            }
        }
    }

    async componentDidMount() {
        const {
            user,
            token,
            fetchAuthenticatedUser
        } = this.props;
        await fetchAuthenticatedUser(token, user)
        var photo = await "";

        var account = user
        if (user.role === "delivery_boy") {
            account = await user.delivery_boy.find((item) => item.is_deleted === false)
            photo = await FirebaseAPI.getStorageFile(account.photo);
        }
        else {
            if (user.photo) {
                photo = await FirebaseAPI.getStorageFile(user.photo);
            }
        }

        this.setState({
            photo,
            account
        })
    }

    render() {

        const { user } = this.props;

        var name = user.firstname + " " + user.lastname;

        if (user.role === "partner") {
            name = user.partner.name;
        }
        else if (user.role === "delivery_boy") {
            name = Tools.isNotEmpty(this.state.account) ? this.state.account.name : "";
        }

        return (
            <div className="dashboard">

                {/* {(user.role === "user" || user.role === "partner") && (
                    <ProfileHeader
                        user={user}
                    />
                )} */}

                <div className="profile__wrapper">
                    <Row>
                        <Col lg={3}>
                            <div className="profile__side">
                                {/** Profile picture wrapper here */}
                                {user.role === "delivery_boy" ? (
                                    <div className="profile__side__image" style={{ backgroundImage: `url(${this.state.photo})` }}></div>
                                ) : (
                                    user.photo && user.photo_url ? (
                                        <div className="profile__side__image" style={user.photo && { backgroundImage: `url(${this.state.photo})` }}></div>
                                    ) : (
                                        <div className="profile__side__image empty">
                                            <h1 className="profile__side__image__text">{Tools.getNamePhoto(name)}</h1>
                                        </div>
                                    )
                                )}
                                {/** End profile picture wrapper here */}

                                <h3 className="profile__side__name">{name}</h3>
                                <h5 className="profile__side__type">Saytum {user.role.replace("_", " ")} Account</h5>

                                {(user.role === "user" || user.role === "partner") && (
                                    <Button
                                        type="warning"
                                        buttonText="Edit Profile"
                                        size="lg"
                                        onClick={() => navigate(this.props.history, "/profile/edit")}
                                    />
                                )}
                            </div>
                        </Col>
                        <Col lg={9}>
                            <div className="profile__content">
                                <div className="details__item">
                                    <h4 className="details__item__header">Account Detail</h4>
                                    <Row>
                                        <Col>
                                            <div className="details__item__group">
                                                <p className="label">Name</p>
                                                <p className="text">{name}</p>
                                            </div>
                                        </Col>

                                        {(user.role !== "admin" && user.role !== "officer") && (
                                            <Col>
                                                <div className="details__item__group">
                                                    <p className="label">Mobile Number</p>
                                                    {user.mobile_number ?
                                                        <p className="text">{user.role === "partner" ? user.partner.mobile_prefix + " " + user.partner.mobile_number : user.mobile_prefix + " " + user.mobile_number}</p>
                                                        :
                                                        <p className="text text__bold text__primary">not set</p>
                                                    }
                                                </div>
                                            </Col>
                                        )}

                                        {user.role !== "delivery_boy" && (
                                            <Col>
                                                <div className="details__item__group">
                                                    <p className="label">Email</p>
                                                    {user.email ?
                                                        <p className="text">{user.role === "partner" ? user.partner.email : user.email}</p>
                                                        :
                                                        <p className="text text__bold text__primary">not set</p>
                                                    }
                                                </div>
                                            </Col>
                                        )}
                                    </Row>

                                    {(user.role !== "delivery_boy" && user.role !== "admin" && user.role !== "officer") && (
                                        <Row>
                                            <Col>
                                                <div className="details__item__group">
                                                    <p className="label">Address</p>
                                                    {user.address ?
                                                        <p className="text">{user.address}</p>
                                                        :
                                                        <p className="text text__bold text__primary">not set</p>
                                                    }
                                                </div>
                                            </Col>
                                        </Row>
                                    )}
                                </div>

                                {user.role === "partner" && (
                                    <ProfileDocument />
                                )}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }

}

const mapsStateToProps = ({ authenticated }) => {
    return {
        user: authenticated.userInfo,
        token: authenticated.token
    }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { actions } = require("redux/AuthenticatedRedux");

    return {
        ...ownProps,
        ...stateProps,
        fetchAuthenticatedUser: async (
            token,
            body
        ) => {
            dispatch({ type: "FETCH_PENDING" })
            var json = await actions.fetchAuthenticatedUser(dispatch, token, body)
            dispatch({ type: "FETCH_FINISH" })
            return json
        }
    };
}

export default connect(
    mapsStateToProps,
    undefined,
    mergeProps
)(ProfileContainer);