import React from 'react';
import { connect } from 'react-redux';
import { 
    Button, 
    FormBody, 
    FormWrapper, 
    Input, 
    ImageUploader, 
    FormGroup, 
    ButtonGroup,
    VerifyMobileNumber
} from 'components';
import { Tools, Images, Constant, navigate } from 'common';
import { Col } from 'react-bootstrap';
import { FirebaseAPI } from 'services';


var validation = {
    "firstname": "required",
    "mobile_prefix": "required",
    "mobile_number": "required",
    "countries": "required",
    "states": "required",
    "cities": "required",
    "postal_code": "required",
    "address": "required"
}


class EditProfileContainer extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            data: {    
            },
            is_image_changed: false,
            errors: [],
        }
    }

    async componentDidMount() {
        this.getCurrentUserData()
    }

    getCountries = async() => {
        const {
            fetchCountries,
            token
        } = this.props
        var json = await fetchCountries(token)
        return json
    }

    getCurrentUserData = async() => {
        const { 
            user, 
            fetchStates,
            fetchCities,
            token
        } = this.props;
        
        var selected_countries = null;
        var selected_cities = null;
        var selected_states = null;
        var state_options = [];
        var city_options = [];

        var getCountries = await this.getCountries()
        if(Tools.isNotEmpty(user.countries)) {
            selected_countries = getCountries.data.data.find((item) => item.name === user.countries);
            var states = await fetchStates(token, selected_countries.id)
            states.data.data.map((item) => {
                state_options.push({ label: item.name, value: item.name, code: item.code })
                return true
            })
        }
        if(Tools.isNotEmpty(user.states)) {
            const { states } = this.props
            selected_states = states.find((item) => item.name === user.states);
            var cities = await fetchCities(token, selected_states.code)
            cities.data.data.map((item) => {
                city_options.push({ label: item.name, value: item.name, code: item.code })
                return true
            })
        }
        if(Tools.isNotEmpty(user.cities)) {
            const { cities } = this.props
            selected_cities = cities.find((item) => item.name === user.cities);
        }
        
        var image = null;
        if(user.photo) { 
            image = await FirebaseAPI.getStorageFile(user.photo);
        }

        this.setState({
            data: {
                ...this.state.data,
                firstname: user.role === "partner" ? user.partner.name : user.firstname,
                lastname: user.lastname,
                address: user.role === "partner" ? user.partner.address : user.address,
                countries: selected_countries ? { label: selected_countries.name, value: selected_countries.name, id: selected_countries.id  } : null,
                states: selected_states ? { label: selected_states.name, value: selected_states.name, id: selected_states.id  } : null,
                cities: selected_cities ? { label: selected_cities.name, value: selected_cities.name, id: selected_cities.id  } : null,
                postal_code: user.postal_code,
                image: {
                    file: image,
                    name: user.photo ? user.photo : null,
                    value: image
                },
                email: user.email,
                mobile_prefix: user.mobile_prefix ? Constant.COUNTRY_CODE.options.find((item) => item.value === user.mobile_prefix) : null,
                mobile_number: user.mobile_number
            },
            states: state_options,
            cities: city_options
        })
    }

    onChange = (target, value) => {
        this.setState({
            send: false,
            data: {
                ...this.state.data,
                [target]: value
            }
        }, async() => {
            var data = [];
            var json = null;

            if(target === "countries") {
                const { 
                    fetchStates,
                    token 
                } = this.props;
                json = await fetchStates(token, value.code)
                data = [];
                json.data.data.filter((item) => {
                    data.push({
                        label: item.name, value: item.name, code: item.code
                    })
                    return true;
                })
                this.setState({ 
                    states: data, 
                    data: {
                        ...this.state.data,
                        states: null
                    } 
                })
            }
            else if(target === "states") {
                const { 
                    fetchCities,
                    token 
                } = this.props;
                json = await fetchCities(token, value.code)
                data = [];
                json.data.data.filter((item) => {
                    data.push({
                        label: item.name, value: item.name, code: item.code
                    })
                    return true;
                })
                this.setState({ 
                    cities: data,
                    data: {
                        ...this.state.data,
                        cities: null
                    }  
                })
            }
            else if(target == "postal_code"){
                const postalLength = Constant.Postal_Code.postalCodeLength;
                const length = value.length;
                if(length < postalLength || length > postalLength){
                    this.setState({
                      valid: false,
                      send: true,
                      errors: ["postal_code"]
                    });
                }
            }
        });
    }

    onImageChange = (event) => {
        const reader = new FileReader();
        var file = event.target.files[0];

        let local = this;
        reader.onload = function(event) {
          local.setState({
            data: {
                ...local.state.data,
                image: {
                    file: event.target.result,
                    name: file.name,
                    value: event.target.result
                }
            },
            is_image_changed: true
          });
        };

        reader.readAsDataURL(file);
    }

    validation = async() => {
        const { mobile_number_verified, mobile_number_change } = this.state.data;
        var validate = Tools.validation(validation, this.state.data);
        
        if(mobile_number_change) {
            validate.valid = validate.valid && mobile_number_verified;
            if(!mobile_number_verified) {
                validate.errors.push("mobile_number_verified")
            }
        }

        this.setState({
            valid: validate.valid,
            send: true,
            errors: validate.errors
        });

        return validate.valid;
    }

    onUpdate = async() => {
        this.setState({
            send: true
        })

        var valid = await this.validation();

        if(valid) {
            const { data, is_image_changed } = this.state;
            const { 
                user, 
                updateProfile, 
                toastPending,
                history,
                token 
            } = this.props;

            var address = data.address ? data.address : user.address;
            var params = {
                ...user,
                ...data,
                countries: data.countries ? data.countries.value : user.countries,
                cities: data.cities ? data.cities.value : user.cities,
                states: data.states ? data.states.value : user.cities
            }
            if(is_image_changed) {
                params = {
                    ...params,
                    file: data.image ? data.image.value : user.photo_url
                }
            }
            if(Tools.isNotEmpty(address)) {
                var location = await Tools.reverseLocation(address);
                params = {
                    ...params,
                    latitude: location.lat,
                    longitude: location.lng
                }
            }
            params = {
                ...params,
                mobile_number: data.mobile_number ? data.mobile_number.replace(/ /g, "") : user.mobile_number,
                mobile_prefix: data.mobile_prefix ? data.mobile_prefix.value : user.mobile_prefix
            }
            var json = await updateProfile(token, params)
            if(json.status_code === 200) {        
                navigate(history, "/dashboard?screen=profile");
            }
            toastPending({ message: json.message, toastType: json.status_code })
        }

    }

    render() {

        const { data, states, cities }  = this.state;
        const { countries, user } = this.props;
        const { innerWidth } = window;

        var country_datas = [];
        countries.map((item) => {
            country_datas.push({ label: item.name, value: item.name, id: item.id })
            return true;
        });


        return(
            <div className="container" style={innerWidth > 768 ? { marginTop: 50, marginBottom: 50 } : { marginTop: 15, marginBottom: 15 }}>

                <FormWrapper>
                    <FormBody>
                        <h1 className="text__verification__header" style={{ marginBottom: 0 }}>Edit Profile</h1>
                        <h3 className="text__verification__body" style={{ marginBottom: 30 }}>Change your profile data (only editable data will be available)</h3>
                        <Input
                            type={"text"}
                            iconPrefix={Images.user}
                            label={user.role === "user" ? "Firstname" : "Name"}
                            placeholder="Please type here"
                            style={{ margin: 0, marginBottom: 30 }}
                            caption={!Tools.isNotEmpty(data.firstname) && "This field is required"}
                            value={data.firstname}
                            isError={this.state.send && !this.state.valid && this.state.errors.includes("firstname")}
                            onChange={(e) => this.onChange("firstname", e.target.value)}
                            isRequired={true}
                        />

                        {user.role === "user" && (
                            <Input
                                type={"text"}
                                iconPrefix={Images.user}
                                label="Lastname"
                                placeholder="Please type here"
                                style={{ margin: 0, marginBottom: 30 }}
                                caption={!Tools.isNotEmpty(data.lastname) && "This field is required"}
                                value={data.lastname}
                                onChange={(e) => this.onChange("lastname", e.target.value)}
                                isRequired={true}
                            />
                        )}

                        <VerifyMobileNumber
                            onVerified={(data) => {
                                this.setState({
                                    data: {
                                        ...this.state.data,
                                        mobile_prefix: data.mobile_prefix,
                                        mobile_number: data.mobile_number,
                                        mobile_number_verified: true
                                    }
                                })
                            }}
                            isChanged={(param) => {
                                this.setState({ 
                                    data: {
                                        ...this.state.data,
                                        mobile_number_change: param 
                                    }
                                })
                            }}
                            type="edit-profile"
                            isVerified={data.mobile_number_verified}
                            isError={this.state.send && !this.state.valid && (this.state.errors.includes("mobile_number_verified") || this.state.errors.includes("mobile_number") || this.state.errors.includes("mobile_prefix"))}
                            caption={data.mobile_number !== null ? "You can leave this the same as the original (no edit mobile number)" : "This field is required"}
                            isRequired={true}
                        />

                        <Input
                            type={"email"}
                            iconPrefix={Images.form_email}
                            label="Email Address"
                            placeholder="Please type here"
                            style={{ margin: 0, marginBottom: 30 }}
                            value={data.email}
                            inputProps={{
                                readOnly: true
                            }}
                            isRequired={true}
                        />
                        <FormGroup>
                            <Col lg={6} sm={6} md={6} xs={6}>
                                <Input
                                    type="select"
                                    label="Country"
                                    options={country_datas}
                                    placeholder="Please type here"
                                    style={{ marginBottom: 20, marginLeft: 0, marginRight: 10 }}
                                    value={data.countries}
                                    onChange={(e) => this.onChange("countries", e)}
                                    isError={this.state.send && !this.state.valid && this.state.errors.includes("countries")}
                                    caption={this.state.send && !this.state.valid && this.state.errors.includes("countries") && "This field is required"}
                                    isRequired={true}
                                />
                            </Col>
                            <Col lg={6} sm={6} md={6} xs={6}>
                                <Input
                                    type="select"
                                    label="State"
                                    options={states}
                                    placeholder="Please type here"
                                    style={{ marginBottom: 20, marginLeft: 10, marginRight: 0 }}
                                    value={data.states}
                                    onChange={(e) => this.onChange("states", e)}
                                    isError={this.state.send && !this.state.valid && this.state.errors.includes("states")}
                                    caption={this.state.send && !this.state.valid && this.state.errors.includes("states") && "This field is required"}
                                    isRequired={true}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Col lg={6} sm={6} md={6} xs={6}>
                                <Input
                                    type="select"
                                    label="City"
                                    options={cities}
                                    placeholder="Please type here"
                                    style={{ marginBottom: 20, marginLeft: 0, marginRight: 10 }}
                                    value={data.cities}
                                    onChange={(e) => this.onChange("cities", e)}
                                    isError={this.state.send && !this.state.valid && this.state.errors.includes("cities")}
                                    caption={this.state.send && !this.state.valid && this.state.errors.includes("cities") && "This field is required"}
                                    isRequired={true}
                                />
                            </Col>
                            <Col lg={6} sm={6} md={6} xs={6}>
                                <Input
                                    type="number"
                                    min="1"
                                    label="Postal Code"
                                    placeholder="Please type here"
                                    style={{ marginBottom: 20, marginLeft: 10, marginRight: 0 }}
                                    value={data.postal_code}
                                    onChange={(e) => this.onChange("postal_code", e.target.value)}
                                    isError={this.state.send && !this.state.valid && this.state.errors.includes("postal_code")}
                                    caption={this.state.send && !this.state.valid && this.state.errors.includes("postal_code") && "This field is required"}
                                    isRequired={true}
                                />
                            </Col>
                        </FormGroup>
                        <Input
                            type={"autocomplete"}
                            iconPrefix={Images.form_map}
                            label="Address"
                            placeholder="Please type here"
                            style={{ margin: 0, marginBottom: 30 }}
                            value={data.address}
                            onChange={(e) => this.onChange("address", e)}
                            countries={data.countries ? data.countries.value : null}
                            inputProps={{
                                readOnly: data.countries ? false : true
                            }}
                            isError={this.state.send && !this.state.valid && this.state.errors.includes("address")}
                            caption={this.state.send && !this.state.valid && this.state.errors.includes("address") && "This field is required"}
                            isRequired={true}
                        />
                        <ImageUploader
                            value={data.image ?? {}}
                            label="Image"
                            style={{ margin: 0, marginBottom: 25 }}
                            onChange={(e) => this.onImageChange(e)}
                        />
                        <ButtonGroup 
                            position="right"
                        >
                            <Button
                                buttonText="Cancel"
                                size="sm"
                                type="default"
                                onClick={() => this.props.history.goBack()}
                            />
                            <Button
                                buttonText="Update"
                                size="sm"
                                type="warning"
                                style={{ marginRight: 0 }}
                                onClick={() => this.onUpdate()}
                            />
                        </ButtonGroup>
                    </FormBody>
                </FormWrapper>

            </div>
        )
    }

}

const mapsStateToProps = ({ authenticated, locations }) => {
    return {
        user: authenticated.userInfo,
        token: authenticated.token,
        cities: locations.cities,
        states: locations.states,
        countries: locations.countries
    } 
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { actions } = require("redux/LocationRedux");
    const { actions: auth_actions } = require("redux/AuthenticatedRedux")

    return {
      ...ownProps,
      ...stateProps,
      fetchCountries: async(token) => {
        dispatch({ type: "FETCH_PENDING" });
        var json = await actions.fetchCountries(
            dispatch,
            token
        )
        dispatch({ type: "FETCH_FINISH" });
        return json
      },
      fetchStates: async(
          token,
          country_id
      ) => {
        dispatch({ type: "FETCH_PENDING" });
        var json = await actions.fetchStates(
            dispatch,
            token,
            country_id
        )
        dispatch({ type: "FETCH_FINISH" });
        return json
      },
      fetchCities: async(
        token,
        state_code
      ) => {
        dispatch({ type: "FETCH_PENDING" });
        var json = await actions.fetchCities(
            dispatch,
            token,
            state_code
        )
        dispatch({ type: "FETCH_FINISH" });
        return json
      },
      toastPending: (body) => {
        dispatch({ type: "TOAST_PENDING", ...body })
      },
      updateProfile: async(
          token,
          body
      ) => {
        dispatch({ type: "FETCH_PENDING" });
        var json = await auth_actions.updateProfile(
            dispatch,
            token,
            body
        )
        dispatch({ type: "FETCH_FINISH" });
        return json
      }
    };
}

export default connect(
    mapsStateToProps, 
    undefined,
    mergeProps
)(EditProfileContainer);