import React, { useState, useEffect, useRef } from 'react';
// import { useNavigate } from 'react-router-dom';
import './Navbar.css';
import "./navbar.scss";
import "./dropdown.scss";
import Newimages from "common/Newimage";
import { Redirect, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Constant, Images, navigate, Tools } from 'common';
import { connect } from 'react-redux';
import { Dropdown } from "react-bootstrap";
import { FirebaseAPI } from 'services';

const Navbar = (props) => {

    const [consignmentNumber, setConsignmentNumber] = useState("");
    const [navbar, setNavbar] = useState(false);
    const [logoStm, setLogoStm] = useState(Newimages.blueLogo);
    const [showDropdown, setShowDropdown] = useState(false);
    const [photo, setPhoto] = useState("");
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);
    const { token, user, } = props;
    const history = useHistory();
    const { pathname } = history.location;
    const menuRef = useRef(null);
    const hamburgerRef = useRef(null);
    // console.log("token" , token);
    // const navigate = useNavigate();

    const scrollToSection = (id) => {
    const targetElement = document.getElementById(id);
    if (targetElement) {
        // const navbarHeight = document.querySelector('.navbar').offsetHeight;
        // const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY - navbarHeight ;
        const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
            top: targetPosition,
            behavior: 'smooth',
        });
    }
};


    const changeBg = () => {
        if (window.scrollY >= 100 || pathname !== "/") {
        // if (pathname !== "/") {
            setNavbar(true);
            setLogoStm(Newimages.whiteLogo);
        } else {
            setNavbar(false);
            setLogoStm(Newimages.blueLogo);
        }
    };

    useEffect(() => {
        changeBg();
    }, [pathname]);

    useEffect(() => {
      // changeBg();
        window.addEventListener('scroll', changeBg);
        return () => {
            window.removeEventListener('scroll', changeBg);
        };
    }, []);

    useEffect(() => {
        const fetchPhoto = async () => {
        let photo = '';
        if (user.role === 'delivery_boy') {
            photo = await FirebaseAPI.getStorageFile(user.delivery_boy[0].photo);
        } else {
            if (user.photo) {
            photo = user.photo;
            photo = await FirebaseAPI.getStorageFile(photo);
            } else {
            photo = Images.logo_white;
            }
        }

        setPhoto(photo);
        };

        fetchPhoto();
    }, [user]);

    const getToken = async (data) => {
    toggleDropdown();
        if (!data) {
            return window.alert("wrong Consignment Number");
        }
        // console.log("consignment" , data);
    const res = await FirebaseAPI.signInAnonymously();
    const token = res.user.idToken;
    const getConsignments = await props.fetchConsignments(token);
    // console.log("........." , getConsignments.consignments)
    if (getConsignments.status_code === 200) {
      const filteredData = getConsignments.consignments.filter((consignment) =>
        consignment.id.toString() === data
      );
        // console.log("filter" , filteredData);

    if (Object.keys(filteredData).length > 0) {
        localStorage.setItem("@anonymousData", JSON.stringify(res));
        // var response = localStorage.getItem("@anonymousData");
        // console.log("data", response);
        navigate(history, `/tracking?id=${data}`);
    } else {
        navigate(history, "/", "replace");
        props.toastPending({
            message: "Wrong Consignment Number",
            toastType: getConsignments.status_code,
        });
    }
    }
  };

    const handleTrackButtonClick = () => {
        setShowDropdown(!showDropdown);
    };

    const toggleDropdown = (e) => {
        setShowOverlay(e);
    };

    const handleUserLogout = async () => {
        const { logout, token, toastPending } = props;

        var json = await logout(token);
        if (json.status_code === 200) {
            navigate(history, "/", "replace");
        }
        toastPending({ message: json.message, toastType: json.status_code });
    };

    const onChange = (e) => {
    setConsignmentNumber(e.target.value);
    // console.log("onchange" , e.target.value);
    };

    const toggleMenu = () => {
        setIsMenuOpen((prev) => !prev);
    };

    const closeMenu = () => {
    setIsMenuOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (e) => {
        if (menuRef.current && !menuRef.current.contains(e.target) &&
            hamburgerRef.current && !hamburgerRef.current.contains(e.target)) {
            closeMenu();
        }
        };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
    }, []);

    // const handleNavLinkClick = (event, path, sectionId) => {
    //     event.preventDefault();
    //     if (window.location.pathname === '/' && path === '/') {
    //         // If on the landing page, just scroll to section
    //         scrollToSection(sectionId);
    //     } else {
    //         // Navigate to the landing page and then scroll to section
    //         Redirect(path, { replace: true });
    //         setTimeout(() => scrollToSection(sectionId), 1000);
    //     }
    // };

   const renderAuthenticated = () => {

    var name = user.firstname;
    if (user.role === "user" || user.role === "admin") {
      name = user.firstname + " " + user.lastname;
    } else if (user.role === "partner") {
      name = user.partner.name;
    }

    return (
      <div className="navbar__right navbar__group">
        <Dropdown
          onToggle={(e) => toggleDropdown(e)}
          onSelect={(e) => toggleDropdown(e)}
        >
          <Dropdown.Toggle className="navbar__switcher__btn btn__outline">
            <div className="navbar__profile">
              {/** Profile picture wrapper here */}
              {user.role === "delivery_boy" ? (
                <div
                  className="navbar__profile__image"
                  style={{ backgroundImage: `url(${photo})` }}
                ></div>
              ) : user.photo && user.photo_url ? (
                <div
                  className="navbar__profile__image"
                  style={
                    user.photo && {
                      backgroundImage: `url(${photo})`,
                    }
                  }
                ></div>
              ) : (
                <div className="navbar__profile__image empty">
                  <h1 className="navbar__profile__image__text">
                    {Tools.getNamePhoto(name)}
                  </h1>
                </div>
              )}
              {/** End profile picture wrapper here */}
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu align="right" className="navbar__switcher__dropdown">
            {(user.role !== "partner" ||
              (user.role === "partner" && user.partner.is_confirmed)) && (
              <Dropdown.Item
                onClick={() =>
                  navigate(history, "/dashboard", "replace")
                }
                className={[
                  "navbar__switcher__dropdown__item",
                  pathname === "/dashboard" && "active",
                ]}
              >
                <p className="navbar__switcher__dropdown__item__title">
                  My Dashboard
                </p>
              </Dropdown.Item>
            )}

            <Dropdown.Item
              onClick={() => handleUserLogout()}
              className={["navbar__switcher__dropdown__item"]}
            >
              <p className="navbar__switcher__dropdown__item__title">Logout</p>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  };

    return (
        <div>
        <div className={navbar ? 'ndnavbar active' : 'ndnavbar'}>
            {/* <a className="nav-linkimg" href="/" onClick={(e) => handleNavLinkClick(e, '/', 'home')}> */}
            <a className="nav-linkimg" href="/">
                <img src={logoStm} alt="Logo" className="nav-logo" />
            </a>
            <ul className="nav-btns">
            <div className="track-container">
                        <button className="navbar-track" onClick={handleTrackButtonClick}>Track Order</button>
                        {showDropdown && (
                            <div className="newdropdown">
                                <input type="number" min="0" placeholder="Consignment Number" className="newdropdown-input"
                                onChange={(e) => {
                                    setConsignmentNumber(e.target.value);
                                    onChange("consignmentNumber", e.target.value);
                                  }}/>
                                <button className="newdropdown-btn"
                                onClick={(e) => getToken(consignmentNumber)}>Track</button>
                            </div>
                        )}
            </div>
            <div className={`nav-btn ${isMenuOpen ? 'active' : ''}`} ref={menuRef}>
            {Constant.NAVBAR_MENU.map((item, index) => (
                <li key={index}
                className={`nav-items`}>
                    <a className="nav-links" href={item.link} onClick={closeMenu}>{item.title}</a>
                    
                {/* </li>
                <li className="nav-items">
                    <a className="nav-links" href="/" onClick={(e) => handleNavLinkClick(e, '/', 'home')}>Home</a>
                    </li>
                    <li className="nav-items">
                    <a className="nav-links" href="/" onClick={(e) => handleNavLinkClick(e, '/', 'aboutus')}>About</a>
                    </li>
                    <li className="nav-items">
                    <a className="nav-links" href="/" onClick={(e) => handleNavLinkClick(e, '/', 'mission')}>Mission</a>
                    </li> */}
                </li>
                ))}
                <li className="nav-items">
                    <a className="nav-links" href="#contact" onClick={closeMenu}>Contact</a>
                </li>
                </div>
                {/* authenticated */}
                {token && renderAuthenticated()}
                <div className={`hamburger ${isMenuOpen ? 'toggle' : ''}`} onClick={toggleMenu} ref={hamburgerRef}>
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                </div>
            </ul>
        </div>
        </div>
    );
};

const mapsStateToProps = ({ authenticated }) => {
  return {
    user: authenticated.userInfo,
    token: authenticated.token,
  };
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/ConsignmentRedux");
  const { actions:actionslogout } = require("redux/AuthenticatedRedux");
  return {
    ...ownProps,
    ...stateProps,
    fetchConsignments: async (token, user_id = null) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await actions.fetchConsignments(dispatch, token, user_id);
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    logout: async (token) => {
        localStorage.removeItem('isReload');
        dispatch({ type: "FETCH_PENDING" });
        var logout = await actionslogout.logout(dispatch, token);
        dispatch({ type: "FETCH_FINISH" });
        return logout;
      },
    toastPending: (body) => {
      dispatch({ type: "TOAST_PENDING", ...body });
    },
  };
};
export default connect(mapsStateToProps, undefined, mergeProps) (Navbar);
