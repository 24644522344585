import React from 'react';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { Detail, DetailContent, DetailInformation, Button, ButtonGroup, Input } from 'components';
import { Images } from 'common';

class CreateConsignmentStepFive extends React.PureComponent {

    render() {

        var data = typeof(localStorage.getItem("@createConsignment")) != undefined ? JSON.parse(localStorage.getItem("@createConsignment")) : {};
        // data = JSON.parse(data);

        const { innerWidth } = window;

        return(
          <Detail
            style={innerWidth > 768 ? { marginTop: 50, marginBottom: 50 } : { marginTop: 15, marginBottom: 0 }}
          >
            <DetailContent
                    header="Consignment Detail"
                >
              <div className="details__item">
                <p className="label">Order Name</p>
                <p className="text">{data.order_name}</p>
              </div>
              <div className="details__item">
                <h4 className="details__item__header">Pickup</h4>

                <div className="details__item__group">
                  <p className="label">Sender Name</p>
                  <p className="text">{data.pickup_name}</p>
                </div>
                <div className="details__item__group">
                  <p className="label">Mobile Number</p>
                  <p className="text">{data.pickup_mobile_prefix ? data.pickup_mobile_prefix.value : ""} {data.pickup_mobile_number}</p>
                </div>
                <div className="details__item__group">
                  <p className="label">Address</p>
                  <p className="text">{data.pickup_address_one}</p>
                </div>
                <Row>
                  <Col>
                    <div className="details__item__group">
                      <p className="label">City</p>
                      <p className="text">{data.pickup_cities ? data.pickup_cities.value : ""}</p>
                    </div>
                  </Col>
                  <Col>
                    <div className="details__item__group">
                      <p className="label">State</p>
                      <p className="text">{data.pickup_states ? data.pickup_states.value : ""}</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="details__item__group">
                      <p className="label">Country</p>
                      <p className="text">{data.pickup_countries ? data.pickup_countries.value : ""}</p>
                    </div>
                  </Col>
                  <Col>
                    <div className="details__item__group">
                      <p className="label">Postal Code</p>
                      <p className="text">{data.pickup_postal_code}</p>
                    </div>
                  </Col>
                </Row>
                <div className="details__item__group">
                  <p className="label">Pickup Date</p>
                  <p className="text">{data.pickup_time ? data.pickup_time.label : ""} - {moment(data.pickup_date).format("DD MMM YYYY HH:mm")}</p>
                </div>
              </div>
              <div className="details__item">
                <h4 className="details__item__header">Delivery</h4>

                <div className="details__item__group">
                  <p className="label">Receiver Name</p>
                  <p className="text">{data.delivery_name}</p>
                </div>
                <div className="details__item__group">
                  <p className="label">Mobile Number</p>
                  <p className="text">{data.delivery_mobile_prefix ? data.delivery_mobile_prefix.value : ""} {data.delivery_mobile_number}</p>
                </div>
                <div className="details__item__group">
                  <p className="label">Address</p>
                  <p className="text">{data.delivery_address_one}</p>
                </div>
                <Row>
                  <Col>
                    <div className="details__item__group">
                      <p className="label">City</p>
                      <p className="text">{data.delivery_cities ? data.delivery_cities.value : ""}</p>
                    </div>
                  </Col>
                  <Col>
                    <div className="details__item__group">
                      <p className="label">State</p>
                      <p className="text">{data.delivery_states ? data.delivery_states.value : ""}</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="details__item__group">
                      <p className="label">Country</p>
                      <p className="text">{data.delivery_countries ? data.delivery_countries.value : ""}</p>
                    </div>
                  </Col>
                  <Col>
                    <div className="details__item__group">
                      <p className="label">Postal Code</p>
                      <p className="text">{data.delivery_postal_code}</p>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="details__item">
                <h4 className="details__item__header">Material</h4>

                <div className="details__item__group">
                  <p className="label">Material Category</p>
                  <p className="text">{data.material_category ? data.material_category.value : ""}</p>
                </div>
                <div className="details__item__group">
                  <p className="label">Material Detail</p>
                  <p className="text">{data.material_detail}</p>
                </div>
                <Row>
                  <Col>
                    <div className="details__item__group">
                      <p className="label">Weight</p>
                      <p className="text">{data.weight} Kg</p>
                    </div>
                  </Col>
                  <Col>
                    <div className="details__item__group">
                      <p className="label">Transportation Mode</p>
                      <p className="text">
                        {data.fleet_category ? data.fleet_category.value : ""}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </DetailContent>
            <DetailInformation>
              <div className="details__information" style={{ marginBottom: 20 }}>
                <div className="details__item__group" style={{ marginBottom: 30 }}>
                  <p className="label label__lg">Total Cost</p>
                  <p className="text text__bold text__lg">{data.material_price ?? "0" } INR</p>
                </div>
                <div className="details__item__group">
                  <p className="label">Consignor GST Number</p>
                  <p className="text text__bold">{data.gst_number ? data.gst_number : "-"}</p>
                </div>
                {/* <div className="details__item__group details__item__group__height">
                            <Input
                                type="checkbox"
                                value={data.is_insurance}
                                label="Prompt for insurance"
                                info="With insurance 500INR."
                                style={{ margin: 0, marginBottom: 20 }}
                                inputProps={{
                                    disabled: true
                                }}
                            />  
                        </div> */}
                <ButtonGroup position="center">
                  <Col style={innerWidth > 768 ? { marginRight: 10 } : { marginRight: 5 }}>
                    <Button
                      buttonText="SAVE AS DRAFT"
                      icon={Images.draft_green}
                      iconPosition="before-text"
                      type="success"
                      variation="outline"
                      size="lg"
                      style={{ margin: 0 }}
                      onClick={() => this.props.onDraft()}
                    />
                  </Col>
                  <Col style={innerWidth > 768 ? { marginLeft: 10 } : { marginLeft: 5 }}>
                    <Button
                      buttonText="PLACE ORDER"
                      icon={Images.shopping_cart}
                      iconPosition="before-text"
                      type="success"
                      variation="primary"
                      size="lg"
                      style={{ margin: 0 }}
                      onClick={() => this.props.onCreate()}
                    />
                  </Col>
                </ButtonGroup>
              </div>
            </DetailInformation>
          </Detail>
        )
    }

}

export default (CreateConsignmentStepFive);