// import Constant from 'common/Constant';
import React from 'react';
import {GoogleLogin} from '@react-oauth/google';
import jwt_decode from "jwt-decode";

class GoogleSocialButton extends React.PureComponent {

    render() {

        const { text, icon, onClick } = this.props;

        return(
            <GoogleLogin
                // clientId={Constant.GOOGLE_OAUTH_KEY}
                //onSuccess={(response) => onClick(response)}
                onSuccess={credentialResponse => {
                    const credDecode = jwt_decode(credentialResponse.credential);
                    // console.log("credres" , credentialResponse);
                    const cred = credentialResponse.credential;
                    const response = {cred , credDecode};
                    onClick(response);
                    // console.log("res" , credDecode);
                }}
                className="button__social button__social__google"
                icon={false}
                // size='large'
                width={250}
                logo_alignment='center'
            >
                <img className="button__social__icon" src={icon} alt="google-icon" />
                <p className="button__social__text">{text}</p>
            </GoogleLogin>
        )
    }

}

export default (GoogleSocialButton);
