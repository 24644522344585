import Tools from 'common/Tools';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import "./styles.scss";

class TrackingContainer extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            trackings: []
        }
    }

    componentDidMount() {
        const {
            match,
            clearTrackingHistories,
            fetchTrackingHistories,
            token
        } = this.props
        clearTrackingHistories()
        fetchTrackingHistories(token, match.params.id)
    }
    
    render() {

        const { trackings } = this.props
        return(
            <div className="tracking">
                
                {trackings.map(function (item, index) {
                    return (
                        <div className="tracking__item" key={index}>
                            <div className="tracking__item__time">
                                <p className="tracking__item__time__text">
                                    {moment(item.tracking_event_time).format("DD MMM")}
                                    <span className="tracking__item__time__text small">{moment(item.tracking_event_time).utc().format("HH:mm A")}</span>
                                </p>
                            </div>
                            <div className="tracking__item__indicator"></div>
                            <div className="tracking__item__description">
                                <p className="tracking__item__description__location">{item.location}</p>
                                {Tools.isNotEmpty(item.remarks) && (
                                    <p className="tracking__item__description__note">{item.remarks}</p>
                                )}
                            </div>
                        </div>
                    )
                })}

            </div>
        )
    }

}

const mapsStateToProps = ({ tracking, authenticated }) => {
    return {
        trackings: tracking.histories,
        token: authenticated.token
    }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { actions } = require("redux/TrackingRedux");

    return {
      ...ownProps,
      ...stateProps,
      clearTrackingHistories: () => dispatch({ type: "CLEAR_TRACKING_HISTORIES" }),
      fetchTrackingHistories: async(
        token,
        consignment_id
      ) => {
        dispatch({ type: "FETCH_PENDING" })
        var json = await actions.fetchTrackingHistories(dispatch, token, consignment_id)
        dispatch({ type: "FETCH_FINISH" })
        // console.log("=>> GOEL", json);
        return json
      },
    };
}

export default connect(mapsStateToProps, undefined, mergeProps)(TrackingContainer);