import React from 'react';
import { CreateConsignmentContainer } from 'container';

class CreateConsignment extends React.PureComponent {

    render() {
        return(
            <CreateConsignmentContainer
                {...this.props}
            />
        )
    }

}

export default (CreateConsignment);