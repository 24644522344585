import React from "react";
import './Loginaskbox.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { faUser, faHandshake } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { navigate } from "common/Tools";


const Signupaskbox = ({ closesignupbox }) => {

    const history = useHistory();


    const navigateToPage = (page) => {
        navigate(history, page);
        window.location.reload();
    };

    const { pathname } = history.location;

    return (
        <>
            <div className="boxbg" onClick={closesignupbox}>
            </div>
            <div className="log-box">
                <button className="cross-button" onClick={closesignupbox} aria-label="Close login box">
                    <FontAwesomeIcon icon={faCircleXmark}/>
                </button>
                <h2 className="log-title">Sign-up as</h2>
                <div className="sign-buttons">
                    <button className={`log-button ${pathname === "/register?role=user" && `active`}`}
                    onClick={(e) => {
                        e.preventDefault();
                        navigateToPage("/register?role=user");
                    }}>
                        <FontAwesomeIcon icon={faUser} className="faicon-logbox"/>
                        <span className="btn-txt1">User</span>
                    </button>
                    <button className={`log-button ${pathname === "/register?role=logistic_partner" && `active`}`}
                    onClick={(e) => {
                        e.preventDefault();
                        navigateToPage("/register?role=logistic_partner");
                    }}>
                        <FontAwesomeIcon icon={faHandshake} className="faicon-logbox"/>
                        <span className="btn-txt2">Partner</span>
                    </button>
                </div>
            </div>
        </>
    );
};

export default Signupaskbox;