import React from 'react';
import TabDefault from './tabs';
import PropTypes from 'prop-types';
class Tabs extends React.PureComponent {

    render() {
        if(this.props.type === "default") {
            return <TabDefault {...this.props} />
        }
    }

}

Tabs.defaultProps = {
    type: "default"
}

Tabs.propTypes = {
    type: PropTypes.string
}

export default Tabs;