import React from 'react';
import "./button.scss";
import Button from './button';

class ButtonDashboard extends React.PureComponent {

    render() {

        const { innerWidth } = window;
        
        return(
            <div className={innerWidth > 768 ? "" : "button__dashboard"}>
                <Button
                    icon={this.props.icon}
                    iconPosition={this.props.iconPosition}
                    buttonText={this.props.buttonText}
                    type="warning"
                    size="sm"
                    style={{ marginRight: 0 }}
                    onClick={this.props.onClick}
                />
            </div>
        )
    }

}

export default (ButtonDashboard);