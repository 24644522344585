
const type = {
    SET_NAVIGATION: "SET_NAVIGATION",
    RESET_NAVIGATION: "RESET_NAVIGATION"
}

const initialState = {
    activeMenu: ""
}

export const reducer = (state = initialState, action) => {

    const { items } = action;
    switch (action.type) {
        case type.SET_NAVIGATION:
            return {
                ...state,
                activeMenu: items
            }
        case type.RESET_NAVIGATION:
            return {
                ...state,
                activeMenu: "profile"
            }
        default:
            return state;
    }
}
