import React from "react";
import './footer.css';
import Newimages from "common/Newimage";

const Footer = () => {
    return (
        <>
        <div className="footer" id="contact">
            <div className="blue-bg">
                <div className="footer-top">
                    <div className="footer-logo">
                    <a className="nav-linkimg" href="/">
                        <img src={Newimages.stmlogo} alt="Saytum Logo" />
                    </a>
                    </div>
                    <div className="footer-content">
                        <div className="cont-left">
                            <a href="/privacy-policy">Privacy Policy</a><br />
                            <a href="/terms-and-conditions">Terms and Conditions</a><br />
                            <a href="/faq">FAQ</a>
                        </div>
                        <div className="cont-mid">
                            <div className="phone-wrapper">
                                <img src={Newimages.phnimg} alt="Phone" className="phone-img" />
                                <a href="tel:+917827934515"><p className="foot-phone">+91 7827934515</p></a>
                            </div>
                            <div className="whatsapp-wrapper">
                                <img src={Newimages.whatsimg} alt="WhatsApp" className="whats-img" />
                                <a href="https://wa.me/+917827934515?text=I'm%20inquiring"><p className="foot-whatsapp">+91 7827934515</p></a>
                            </div>
                        </div>
                        <div className="cont-right">
                            <div className="address-wrapper">
                                <img src={Newimages.locimg} alt="Location" className="loc-img" />
                                <p className="foot-address">2nd floor, Kohinoor Tower, Junwani Rd, Kohka, Bhilai, Chhattisgarh 490023</p>
                            </div>
                            <div className="mail-wrapper">
                                <img src={Newimages.mailimg} alt="Mail" className="mail-img" />
                                <a href="mailto:support@saytum.com" style={{ color: "white" }}><p className="foot-mail">support@saytum.com</p></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">©2024 Saytum Technologies Pvt Ltd. All Rights Reserved</div>
            </div>
        </div>
        <div className="extra-space"></div>
        </>
    );
}

export default Footer;
