import React from 'react';
import "./tabs.scss";

class TabDefault extends React.PureComponent {

    render() {
        return(
            <div className="tabs">
                {this.props.children}
            </div>
        )
    }

}

export default (TabDefault);