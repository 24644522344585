import {
  InvoiceAPI,
  ConsignmentsAPI,
  LogisticPartnerAPI,
  PaymentApi,
} from "services";

const types = {
  FETCH_AUCTION_SUCCESS: "FETCH_AUCTION_SUCCESS",
  FETCH_AUCTION_FAILURE: "FETCH_AUCTION_FAILURE",
  CLEAR_AUCTION: "CLEAR_AUCTION",
  FETCH_AUCTIONS_BIDDERS_SUCCESS: "FETCH_AUCTIONS_BIDDERS_SUCCESS",
  FETCH_AUCTIONS_BIDDERS_FAILURE: "FETCH_AUCTIONS_BIDDERS_FAILURE",
  CLEAR_AUCTIONS_BIDDERS: "CLEAR_AUCTIONS_BIDDERS",
  CREATE_BID_AUCTION_SUCCESS: "CREATE_BID_AUCTION_SUCCESS",
  CREATE_BID_AUCTION_FAILURE: "CREATE_BID_AUCTION_FAILURE",
  WITHDRAW_BID_AUCTION_SUCCESS: "WITHDRAW_BID_AUCTION_SUCCESS",
  WITHDRAW_BID_AUCTION_FAILURE: "WITHDRAW_BID_AUCTION_FAILURE",
  ACCEPT_BID_AUCTION_SUCCESS: "ACCEPT_BID_AUCTION_SUCCESS",
  ACCEPT_BID_AUCTION_FAILURE: "ACCEPT_BID_AUCTION_FAILURE",
  PICKUP_SCHEDULE_AUCTION_SUCCESS: "PICKUP_SCHEDULE_AUCTION_SUCCESS",
  PICKUP_SCHEDULE_AUCTION_FAILURE: "PICKUP_SCHEDULE_AUCTION_FAILURE",
  FETCH_PAYMENT_DETAIL_SUCCESS: "FETCH_PAYMENT_DETAIL_SUCCESS",
  FETCH_PAYMENT_DETAIL_FAILURE: "FETCH_PAYMENT_DETAIL_FAILURE",
  FETCH_CONSIGNMENTS_DETAIL_SUCCESS: "FETCH_CONSIGNMENTS_DETAIL_SUCCESS",
  FETCH_CONSIGNMENTS_DETAIL_FAILURE: "FETCH_CONSIGNMENTS_DETAIL_FAILURE",
  UPDATE_PAYMENT_DETAIL_SUCCESS: "UPDATE_PAYMENT_DETAIL_SUCCESS",
  UPDATE_PAYMENT_DETAIL_FAILURE: "UPDATE_PAYMENT_DETAIL_FAILURE",
  CLEAR_PAYMENT_DETAIL: "CLEAR_PAYMENT_DETAIL",
  FETCH_ORDER_AUCTION_SUCCESS: "FETCH_ORDER_AUCTION_SUCCESS",
  FETCH_ORDER_AUCTION_FAILURE: "FETCH_ORDER_AUCTION_FAILURE",
  GENERATE_INVOICE_SUCCESS: "GENERATE_INVOICE_SUCCESS",
  GENERATE_INVOICE_SUCCESS: "GENERATE_INVOICE_SUCCESS",
  CLEAR_ORDER_AUCTION: "CLEAR_ORDER_AUCTION",
  CREATE_ORDER_AUCTION_SUCCESS: "CREATE_ORDER_AUCTION_SUCCESS",
  CREATE_ORDER_AUCTION_FAILURE: "CREATE_ORDER_AUCTION_FAILURE",
  VERIFY_AUCTION_PAYMENTS_SUCCESS: "VERIFY_AUCTION_PAYMENTS_SUCCESS",
  VERIFY_AUCTION_PAYMENTS_FAILURE: "VERIFY_AUCTION_PAYMENTS_FAILURE",
  VERIFY_AUCTION_PAYMENT_SELF_SUCCESS: "VERIFY_AUCTION_PAYMENT_SELF_SUCCESS",
  VERIFY_AUCTION_PAYMENT_SELF_FAILURE: "VERIFY_AUCTION_PAYMENT_SELF_FAILURE",
  FETCH_AUCTION_BIDDERS_WON_SUCCESS: "FETCH_AUCTION_BIDDERS_WON_SUCCESS",
  FETCH_AUCTION_BIDDERS_WON_FAILURE: "FETCH_AUCTION_BIDDERS_WON_FAILURE",
  CLEAR_AUCTION_BIDDERS_WON: "CLEAR_AUCTION_BIDDERS_WON",
};

export const actions = {
  fetchAuctions: async (dispatch, token, role, user_id) => {
    var json = {};
    if (role === "user") {
      json = await ConsignmentsAPI.fetchUsersConsignments(token, user_id);
    } else {
      json = await ConsignmentsAPI.fetchConsignments(token);
    }

    if (json.status_code === 200) {
      // var consignments = json.consignments;
      if (role === "user") {
        json = await InvoiceAPI.fetchUsersAuctions(token, user_id);
      } else if (role === "admin") {
        json = await InvoiceAPI.fetchAdminAuctions(token);
      } else {
        json = await InvoiceAPI.fetchAuctions();
      }
      if (json.status_code === 200) {
        // console.log("json >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", data);
        var data = [];
        json.data &&
          json.data.length > 0 &&
          json.data.map((item) => {
            // console.log(item);
            data.push(item);
            // var c_id = role === "partner" ? item.id : item.consignment_id;
            // consignments.map((c_item) => {
            //   if (parseFloat(c_id) === parseFloat(c_item.id)) {
            //     data.push({
            //       ...c_item,
            //       auction_id: role === "user" ? item.id : item.auction_id,
            //       auction_created_date: item.created_at,
            //       auction_status: item.auction_status,
            //       bid_status: item.bid_status,
            //       bid_price: item.bid_price,
            //       delivery_boy_name: item.delivery_boy_name,
            //       delivery_boy_id: item.delivery_boy_id,
            //     });
            //   }
            //   return true;
            // });
            return true;
          });
        dispatch(actions.fetchAuctionsSuccess(data));
      } else {
        dispatch(actions.fetchAuctionsFailure(json.message));
      }
    } else {
      dispatch(actions.fetchAuctionsFailure(json.message));
    }
    return json;
  },
  fetchAuctionsSuccess: (items) => ({
    type: types.FETCH_AUCTION_SUCCESS,
    items,
  }),
  fetchAuctionsFailure: (error) => ({
    type: types.FETCH_AUCTION_FAILURE,
    error,
  }),
  fetchAuctionsBidders: async (dispatch, token, auction_id) => {
    var json = await InvoiceAPI.fetchAuctionsBidders(token, auction_id);
    var bids = json.bids;
    if (json.status_code === 500) {
      dispatch(actions.fetchAuctionsBiddersFailure(json.message));
    } else {
      json = await LogisticPartnerAPI.fetchLogisticPartner(token);
      if (json.status_code === 200) {
        var data = [];
        bids.map((item) => {
          var user = json.data.data.find(
            (p_item) =>
              parseFloat(p_item.partner.id) === parseFloat(item.bidder_id)
          );
          data.push({
            ...item,
            partner: user.partner,
          });
          return true;
        });
        dispatch(actions.fetchAuctionsBiddersSuccess(data));
      } else {
        dispatch(actions.fetchAuctionsBiddersFailure(json.message));
      }
    }
    return json;
  },
  fetchAuctionsBiddersFailure: (error) => ({
    type: types.FETCH_AUCTIONS_BIDDERS_FAILURE,
    error,
  }),
  fetchAuctionsBiddersSuccess: (items) => ({
    type: types.FETCH_AUCTIONS_BIDDERS_SUCCESS,
    items,
  }),
  createBidAuction: async (dispatch, token, auction_id, body) => {
    var json = await InvoiceAPI.createBidAuction(token, auction_id, body);
    if (json.status_code === 500) {
      dispatch(actions.createBidAuctionFailure(json.message));
    } else {
      dispatch(actions.createBidAuctionSuccess(json));
    }
    return json;
  },
  createBidAuctionFailure: (error) => ({
    type: types.CREATE_BID_AUCTION_FAILURE,
    error,
  }),
  createBidAuctionSuccess: (items) => ({
    type: types.CREATE_BID_AUCTION_SUCCESS,
    items,
  }),
  withdrawBidAuction: async (dispatch, token, auction_id, bid_id) => {
    var param = {
      bid_status: 124,
      id: bid_id,
    };
    var json = await InvoiceAPI.updateBidAuction(token, auction_id, param);
    if (json.status_code === 500) {
      dispatch(actions.withdrawBidAuctionFailure(json.message));
    } else {
      dispatch(actions.withdrawBidAuctionSuccess(json));
    }
    return json;
  },
  withdrawBidAuctionFailure: (error) => ({
    type: types.WITHDRAW_BID_AUCTION_FAILURE,
    error,
  }),
  withdrawBidAuctionSuccess: (items) => ({
    type: types.WITHDRAW_BID_AUCTION_SUCCESS,
    items,
  }),
  acceptBidAuction: async (dispatch, token, body) => {
    var json = await InvoiceAPI.acceptBidAuction(token, body);
    if (json.status_code === 500) {
      dispatch(actions.acceptBidAuctionFailure(json.message));
    } else {
      dispatch(actions.acceptBidAuctionSuccess(json));
    }
    return json;
  },
  acceptBidAuctionFailure: (error) => ({
    type: types.ACCEPT_BID_AUCTION_FAILURE,
    error,
  }),
  acceptBidAuctionSuccess: (items) => ({
    type: types.ACCEPT_BID_AUCTION_SUCCESS,
    items,
  }),
  pickupScheduleAuction: async (dispatch, token, body) => {
    var json = await InvoiceAPI.pickupScheduleAuction(token, body);
    if (json.status_code === 500) {
      dispatch(actions.pickupScheduleAuctionFailure(json.message));
    } else {
      dispatch(actions.pickupScheduleAuctionSuccess(json));
    }
    return json;
  },
  pickupScheduleAuctionFailure: (error) => ({
    type: types.PICKUP_SCHEDULE_AUCTION_FAILURE,
    error,
  }),
  pickupScheduleAuctionSuccess: (items) => ({
    type: types.PICKUP_SCHEDULE_AUCTION_SUCCESS,
    items,
  }),
  fetchPaymentsDetail: async (dispatch, token, bid_id, consignment_id) => {
    var json = await PaymentApi.fetchPayments(token, bid_id, consignment_id);
    if (json.status_code === 500) {
      dispatch(actions.fetchPaymentDetailFailure(json.message));
    } else {
      var data = json.data;
      dispatch(actions.fetchPaymentDetailSuccess(data));
    }
    return json;
  },
  fetchPaymentDetailSuccess: (items) => ({
    type: types.FETCH_PAYMENT_DETAIL_SUCCESS,
    items,
  }),
  fetchPaymentDetailFailure: (error) => ({
    type: types.FETCH_PAYMENT_DETAIL_FAILURE,
    error,
  }),
  fetchConsignmentsDetail: async (dispatch, token, consignment_id) => {
    var json = await PaymentApi.fetchConsignmentsDetails(token, consignment_id);
    if (json.status_code === 500) {
      dispatch(actions.fetchConsignmentsDetailFailure(json.message));
    } else {
      var data = json.data;
      dispatch(actions.fetchConsignmentsDetailSuccess(data));
    }
    return json;
  },
  fetchConsignmentsDetailSuccess: (items) => ({
    type: types.FETCH_CONSIGNMENTS_DETAIL_SUCCESS,
    items,
  }),
  fetchConsignmentsDetailFailure: (error) => ({
    type: types.FETCH_CONSIGNMENTS_DETAIL_FAILURE,
    error,
  }),
  updatePayments: async (dispatch, token, payment_id, body) => {
    var json = await PaymentApi.updatePayments(token, payment_id, body);
    if (json.status_code === 500) {
      dispatch(actions.updatePaymentsFailure(json.message));
    } else {
      var data = json.data;
      dispatch(actions.updatePaymentsSuccess(data));
    }
    return json;
  },
  updatePaymentsSuccess: (items) => ({
    type: types.UPDATE_PAYMENT_DETAIL_SUCCESS,
    items,
  }),
  updatePaymentsFailure: (error) => ({
    type: types.UPDATE_PAYMENT_DETAIL_FAILURE,
    error,
  }),
  verifyAuctionPaymentSelf: async (
    dispatch,
    token,
    order_id,
    consigment_id
  ) => {
    var json = await PaymentApi.verifyPaymentSelf(
      token,
      order_id,
      consigment_id
    );
    if (json.status_code === 500) {
      dispatch(actions.verifyAuctionPaymentSelfFailure(json.message));
    } else {
      var data = json.data;
      dispatch(actions.verifyAuctionPaymentSelfSuccess(data));
    }
    return json;
  },
  verifyAuctionPaymentSelfSuccess: (items) => ({
    type: types.VERIFY_AUCTION_PAYMENT_SELF_SUCCESS,
    items,
  }),
  verifyAuctionPaymentSelfFailure: (error) => ({
    type: types.VERIFY_AUCTION_PAYMENT_SELF_FAILURE,
    error,
  }),
  fetchOrderDetail: async (dispatch, token, order_id) => {
    var json = await PaymentApi.fetchOrderDetail(token, order_id);
    if (json.status_code === 500) {
      dispatch(actions.fetchOrderDetailFailure(json.message));
    } else {
      var data = json.data;
      dispatch(actions.fetchOrderDetailSuccess(data));
    }
    return json;
  },
  fetchOrderDetailSuccess: (items) => ({
    type: types.FETCH_ORDER_AUCTION_SUCCESS,
    items,
  }),
  fetchOrderDetailFailure: (error) => ({
    type: types.FETCH_ORDER_AUCTION_FAILURE,
    error,
  }),
  generateInvoice: async (dispatch, token, body) => {
    let json = {}
    json = await InvoiceAPI.generateInvoice(token, body);
    if (json.status_code === 500) {
      dispatch(actions.generateInvoiceFailure(json.message));
    } else{
      dispatch(actions.generateInvoiceSuccess(json));
    }
    return json;
  },
  generateInvoiceSuccess: (items) => ({
    type: types.GENERATE_INVOICE_SUCCESS,
    items,
  }),
  generateInvoiceFailure: (error) => ({
    type: types.GENERATE_INVOICE_FAILURE,
    error,
  }),
  createOrderAuction: async (dispatch, token, body) => {
    let json = {};
    json = await PaymentApi.createOrder(token, body);
    if (json.status_code === 500) {
      dispatch(actions.createOrderAuctionFailure(json.message));
    } else {
      dispatch(actions.createOrderAuctionSuccess(json));
    }
    return json;
  },
  createOrderAuctionSuccess: (items) => ({
    type: types.CREATE_ORDER_AUCTION_SUCCESS,
    items,
  }),
  createOrderAuctionFailure: (error) => ({
    type: types.CREATE_ORDER_AUCTION_FAILURE,
    error,
  }),
  verifyAuctionPayments: async (dispatch, token, body) => {
    var json = await PaymentApi.verifyPayments(token, body);
    if (json.status_code === 500) {
      dispatch(actions.verifyAuctionPaymentsFailure(json.message));
    } else {
      var data = json.data;
      dispatch(actions.verifyAuctionPaymentsSuccess(data));
    }
    return json;
  },
  verifyAuctionPaymentsSuccess: (items) => ({
    type: types.VERIFY_AUCTION_PAYMENTS_SUCCESS,
    items,
  }),
  verifyAuctionPaymentsFailure: (errors) => ({
    type: types.VERIFY_AUCTION_PAYMENTS_FAILURE,
    errors,
  }),
  fetchAuctionBidderWon: async (dispatch, token, auction_id, bid_status) => {
    var json = await InvoiceAPI.fetchAuctionBiddersWon(token, {
      auction_id,
      bid_status,
    });
    if (json.status_code === 500) {
      dispatch(actions.fetchAuctionBidderWonFailure(json.message));
    } else {
      var data = json.data.data;
      dispatch(actions.fetchAuctionBidderWonSuccess(data));
    }
    return json;
  },
  fetchAuctionBidderWonSuccess: (items) => ({
    type: types.FETCH_AUCTION_BIDDERS_WON_SUCCESS,
    items,
  }),
  fetchAuctionBidderWonFailure: (error) => ({
    type: types.FETCH_AUCTION_BIDDERS_WON_FAILURE,
    error,
  }),
  downloadInvoice: async(id, token)=>{
    var json = await InvoiceAPI.invoiceDownload(id, token);
    return json;
  },
};

const initialState = {
  lists: [],
  total: 0,
  error: null,
  bid_lists: [],
  bid_total: 0,

  specific_user_data: [],
  specific_user_total: 0,
  bidder_data: [],
  bidder_total: 0,
  bidder_won: [],
  payment_detail: [],
};

export const reducer = (state = initialState, action) => {
  const { items, error } = action;

  switch (action.type) {
    case types.FETCH_AUCTION_SUCCESS: {
      var auctions = items.sort((a, b) => (a.id < b.id ? 1 : -1));
      return Object.assign({}, state, {
        error: null,
        lists: auctions,
        total: items.length,
      });
    }
    case types.FETCH_AUCTION_FAILURE: {
      return Object.assign({}, state, {
        error: error,
      });
    }
    case types.CLEAR_AUCTION: {
      return Object.assign({}, state, {
        error: null,
        lists: [],
        length: 0,
      });
    }
    case types.FETCH_AUCTIONS_BIDDERS_FAILURE: {
      return Object.assign({}, state, {
        error: error,
      });
    }
    case types.FETCH_AUCTIONS_BIDDERS_SUCCESS: {
      return Object.assign({}, state, {
        error: null,
        bid_lists: items,
        bid_total: items.length,
      });
    }
    case types.CLEAR_AUCTIONS_BIDDERS: {
      return Object.assign({}, state, {
        error: null,
        bid_lists: [],
        bid_total: 0,
      });
    }
    case types.CREATE_BID_AUCTION_FAILURE: {
      return Object.assign({}, state, {
        error: error,
      });
    }
    case types.CREATE_BID_AUCTION_SUCCESS: {
      return Object.assign({}, state, {
        error: null,
      });
    }
    case types.WITHDRAW_BID_AUCTION_FAILURE: {
      return Object.assign({}, state, {
        error: error,
      });
    }
    case types.WITHDRAW_BID_AUCTION_SUCCESS: {
      return Object.assign({}, state, {
        error: null,
      });
    }
    case types.ACCEPT_BID_AUCTION_FAILURE: {
      return Object.assign({}, state, {
        error: error,
      });
    }
    case types.ACCEPT_BID_AUCTION_SUCCESS: {
      return Object.assign({}, state, {
        error: null,
      });
    }
    case types.PICKUP_SCHEDULE_AUCTION_FAILURE: {
      return Object.assign({}, state, {
        error: error,
      });
    }
    case types.PICKUP_SCHEDULE_AUCTION_SUCCESS: {
      return Object.assign({}, state, {
        error: null,
      });
    }
    case types.FETCH_AUCTION_BIDDERS_WON_SUCCESS: {
      return Object.assign({}, state, {
        error: null,
        bidder_won: items,
      });
    }
    case types.FETCH_AUCTION_BIDDERS_WON_FAILURE: {
      return Object.assign({}, state, {
        error: error,
      });
    }
    case types.CLEAR_AUCTION_BIDDERS_WON: {
      return Object.assign({}, state, {
        error: null,
        bidder_won: [],
      });
    }
    case types.FETCH_PAYMENT_DETAIL_SUCCESS: {
      return Object.assign({}, state, {
        error: null,
        payment_detail: items,
      });
    }
    case types.FETCH_PAYMENT_DETAIL_FAILURE: {
      return Object.assign({}, state, {
        error: error,
      });
    }
    case types.FETCH_CONSIGNMENTS_DETAIL_SUCCESS: {
      return Object.assign({}, state, {
        error: null,
        payment_detail: items,
      });
    }
    case types.FETCH_CONSIGNMENTS_DETAIL_FAILURE: {
      return Object.assign({}, state, {
        error: error,
      });
    }
    case types.UPDATE_PAYMENT_DETAIL_SUCCESS: {
      return Object.assign({}, state, {
        error: null,
        payment_detail: items,
      });
    }
    case types.UPDATE_PAYMENT_DETAIL_FAILURE: {
      return Object.assign({}, state, {
        error: error,
      });
    }
    case types.CLEAR_PAYMENT_DETAIL: {
      return Object.assign({}, state, {
        error: null,
        payment_detail: [],
      });
    }
    case types.VERIFY_AUCTION_PAYMENTS_SUCCESS: {
      return Object.assign({}, state, {
        error: null,
        payment_detail: items,
      });
    }
    case types.VERIFY_AUCTION_PAYMENTS_FAILURE: {
      return Object.assign({}, state, {
        error: error,
      });
    }
    default:
      return state;
  }
};
