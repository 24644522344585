import React from "react";
import {
  Button,
  Table,
  Input,
  Confirmation,
  FormBody,
  ButtonGroup,
  Modal,
  Filter,
} from "components";
import { connect } from "react-redux";
import { DropdownButton, Dropdown } from "react-bootstrap";
import * as Moment from "moment";
import { Tools, Images } from "common";
import SpecificPartnerDetail from "./Detail";
import { extendMoment } from "moment-range";

const moment = extendMoment(Moment);

class LogisticPartnerContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      confirmation: {
        isVisible: false,
        header: "",
        description: "",
        onCancel: null,
        onConfirm: null,
      },
      rejectionData: {
        notes: "",
        isVisible: false,
      },
      selectedItem: {},
      error: false,
    };
  }

  componentDidMount() {
    const { token, fetchLogisticPartner, clearLogisticPartners } = this.props;
    clearLogisticPartners();
    fetchLogisticPartner(token);
  }

  handleApproval = async (item, type, notes = "") => {
    this.onCancelConfirmation();
    this.closeRejectionModal();
    const { token, approval, toastPending } = this.props;

    var param = {};
    if (type === "reject") {
      param = {
        notes: notes,
      };
    }
    var json = await approval(token, item.id, type, param);
    toastPending({ message: json.message, toastType: json.status_code });
  };

  openConfirmation = (headerText, descriptionText, onCancel, onConfirm) => {
    this.setState({
      confirmation: {
        isVisible: true,
        header: headerText,
        description: descriptionText,
        onCancel: onCancel,
        onConfirm: onConfirm,
      },
    });
  };

  onCancelConfirmation = () => {
    this.setState({
      confirmation: {
        isVisible: false,
      },
    });
  };

  onBlurValidation = () => {
    if (!Tools.isNotEmpty(this.state.notes)) {
      this.setState({
        ...this.state,
        error: true,
      });
    } else {
      this.setState({
        ...this.state,
        error: false,
      });
    }
  };

  openRejectionModal = (params) => {
    this.setState({
      rejectionData: {
        ...this.state.rejectionData,
        ...params,
        isVisible: true,
      },
    });
  };

  closeRejectionModal = () => {
    this.setState({
      rejectionData: {
        isVisible: false,
        notes: "",
      },
    });
  };

  onReject = async () => {
    const { notes } = this.state.rejectionData;
    var valid = await Tools.isNotEmpty(notes);

    if (valid) {
      this.openConfirmation(
        "Are you sure",
        "Are you sure to reject this user? (" +
          (this.state.rejectionData.partner
            ? this.state.rejectionData.partner.name
            : "") +
          ")",
        () => this.onCancelConfirmation(),
        () => this.handleApproval(this.state.rejectionData, "reject", notes)
      );
    } else {
      this.setState({
        send: true,
        valid: valid,
      });
    }
  };

  onChange = (target, value, parentTarget = null) => {
    if (parentTarget === null) {
      this.setState({
        send: false,
        [target]: value,
      });
    } else {
      this.setState({
        send: false,
        [parentTarget]: {
          ...this.state[parentTarget],
          [target]: value,
        },
      });
    }
  };

  handleToggleActivate = async (item, target) => {
    const { toggleActivatePartner, toastPending, token } = this.props;
    this.onCancelConfirmation();
    var param = {
      id: item.id,
      type: target,
      role: "partner",
    };
    this.onCancelConfirmation();
    var json = await toggleActivatePartner(token, param);
    toastPending({ message: json.message, toastType: json.status_code });
  };

  onSearch = (keyword) => {
    const { logistic_partners } = this.props;
    var filteredData = logistic_partners.filter((item) => {
      var firstname = item.firstname + " ";
      var name = firstname.concat(item.lastname);
      if (name.toLowerCase().includes(keyword.toLowerCase())) {
        return item;
      }
      return false;
    });
    this.setState({
      keyword: keyword,
      filteredData: filteredData,
    });
  };

  onFilter = async (filters) => {
    var filterDate = await this.filterDate(filters);
    var filterStatus = await this.filterStatus(filterDate, filters);
    var filteredData = filterStatus;

    this.setState({
      isFiltering: false,
      keyword: "filter",
      filteredData: filteredData,
    });
  };

  filterDate = (filters) => {
    const { logistic_partners } = this.props;
    var isFilterDate = filters.filter((item) => item.target === "created_date");
    if (isFilterDate.length > 0) {
      var filteredData = [];
      var date_from = filters.find(
        (item) => item.target === "created_date" && item.key === "date_from"
      );
      var date_to = filters.find(
        (item) => item.target === "created_date" && item.key === "date_to"
      );
      if (date_to) {
        var range = moment.range(date_from.value, date_to.value);

        filteredData = logistic_partners.filter((item) => {
          if (moment(item.created_at).within(range)) {
            return item;
          }
          return false;
        });
      } else {
        filteredData = logistic_partners.filter((item) => {
          var created_at = moment(item.created_at).format("YYYY-MM-DD");
          var filterDate = moment(date_from.value).format("YYYY-MM-DD");

          if (moment(created_at).isSame(filterDate)) {
            return item;
          }
          return false;
        });
      }

      return filteredData;
    }

    return logistic_partners;
  };

  filterStatus = (data, filters) => {
    var filterStatus = filters.filter((item) => item.target === "status");
    if (filterStatus.length > 0) {
      var filteredData = [];
      filterStatus.map((filter_item) => {
        if (filter_item.key === "active" && filter_item.value) {
          data.map((item) => {
            if (item.is_active) {
              filteredData.push(item);
            }
            return false;
          });
        } else if (filter_item.key === "deactive" && filter_item.value) {
          data.map((item) => {
            if (!item.is_active) {
              filteredData.push(item);
            }
            return false;
          });
        } else {
          filteredData = data;
        }
        return false;
      });

      return filteredData;
    }

    return data;
  };

  render() {
    const { logistic_partners } = this.props;
    const { confirmation, keyword, filteredData } = this.state;

    var data = keyword ? filteredData : logistic_partners;

    const columns = [
      {
        Header: "No",
        id: "no",
        accessor: (props, index) => (
          <div className="table__body__row__column__text-style">
            {index + 1}
          </div>
        ),
      },
      {
        Header: "Name",
        id: "name",
        accessor: (props) => (
          <div className="table__body__row__column__text-style">
            {props.partner.name}
          </div>
        ),
      },
      {
        Header: "Email",
        id: "email",
        accessor: (props) => (
          <div className="table__body__row__column__text-style">
            {props.partner.email}
          </div>
        ),
      },
      {
        Header: "Mobile Number",
        id: "mobile_number",
        accessor: (props) => {
          return (
            <div className="table__body__row__column__text-style">
              {props.partner.mobile_prefix} {props.partner.mobile_number}
            </div>
          );
        },
      },
      {
        Header: "Created Date",
        id: "created_date",
        accessor: (props) => {
          return (
            <div className="table__body__row__column__text-style">
              {moment(props.partner.created_at).format("DD MMM YYYY")}
            </div>
          );
        },
      },
      {
        Header: "Status",
        id: "status",
        accessor: (props) => {
          const { is_confirmed, notes } = props.partner;
          var status = is_confirmed
            ? props.is_active
              ? "success"
              : "default"
            : notes !== null
            ? "danger"
            : "primary";
          var status_text = is_confirmed
            ? props.is_active
              ? "Active"
              : "Deactive"
            : notes !== null
            ? "Rejected"
            : "Not Confirmed";

          return (
            <div
              className={`table__body__row__column__btn-style table__body__row__column__btn-style__${status}`}
            >
              {status_text}
            </div>
          );
        },
      },
      {
        Header: "Actions",
        id: "id",
        accessor: (props) => {
          const { is_confirmed, notes } = props.partner;

          return (
            <DropdownButton
              className="table__body__row__column__dropdown"
              key="up"
              drop="up"
              variant="secondary"
              title={<i className="material-icons">more_horiz</i>}
            >
              <Dropdown.Item
                eventKey="1"
                onClick={() =>
                  this.setState({ selectedItem: props }, () =>
                    this.setState({ openDetail: true })
                  )
                }
                className="dropdown-item__detail"
              >
                See Detail
              </Dropdown.Item>

              {!is_confirmed ? (
                <>
                  {notes === null ? (
                    <>
                      <Dropdown.Item
                        eventKey="2"
                        onClick={() =>
                          this.openConfirmation(
                            "Are you sure",
                            "Are you sure to approve this user? (" +
                              (props.partner ? props.partner.name : "") +
                              ")",
                            () => this.onCancelConfirmation(),
                            () => this.handleApproval(props, "approve")
                          )
                        }
                        className="dropdown-item__approve"
                      >
                        Approve
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="3"
                        onClick={() => this.openRejectionModal(props)}
                        className="dropdown-item__activate"
                      >
                        Reject
                      </Dropdown.Item>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : props.is_active ? (
                <Dropdown.Item
                  eventKey="4"
                  onClick={() =>
                    this.openConfirmation(
                      "Are you sure",
                      "Are you sure to deactivate this user? (" +
                        (props.partner ? props.partner.name : "") +
                        ")",
                      () => this.onCancelConfirmation(),
                      () => this.handleToggleActivate(props, "deactivate")
                    )
                  }
                  className="dropdown-item__activate"
                >
                  Deactivate
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  eventKey="4"
                  onClick={() =>
                    this.openConfirmation(
                      "Are you sure",
                      "Are you sure to activate this user? (" +
                        (props.partner ? props.partner.name : "") +
                        ")",
                      () => this.onCancelConfirmation(),
                      () => this.handleToggleActivate(props, "activate")
                    )
                  }
                  className="dropdown-item__activate"
                >
                  Activate
                </Dropdown.Item>
              )}
            </DropdownButton>
          );
        },
      },
    ];

    const filterOptions = [
      {
        type: "date",
        key: "created_date",
        label: "Date",
      },
      {
        type: "option",
        key: "status",
        label: "Status",
        items: [
          { key: "active", label: "Active" },
          { key: "deactive", label: "Deactive" },
        ],
      },
    ];

    const renderDetail = () => {
      return (
        <SpecificPartnerDetail
          isVisible={this.state.openDetail}
          data={this.state.selectedItem}
          onClose={() => this.setState({ openDetail: false })}
        />
      );
    };

    const renderRejectModal = () => {
      const { notes, isVisible } = this.state.rejectionData;

      return (
        <Modal
          reference={(ref) => (this.rejection_modal = ref)}
          isVisible={isVisible}
          className="modal__form"
        >
          <FormBody>
            <h1
              className="text__verification__header"
              style={{ marginBottom: 0 }}
            >
              Reject Logistic Partner Registration
            </h1>
            <h3
              className="text__verification__body"
              style={{ marginBottom: 30 }}
            >
              Please explain the reason for your refusal to help partner fix it
            </h3>
            <Input
              type={"text"}
              iconPrefix={Images.user}
              label="Name"
              placeholder="Please type here"
              style={{ margin: 0, marginBottom: 15 }}
              value={
                this.state.rejectionData.partner
                  ? this.state.rejectionData.partner.name
                  : ""
              }
              inputProps={{
                readOnly: true,
              }}
            />
            <Input
              type={"text"}
              iconPrefix={Images.user}
              label="Email"
              placeholder="Please type here"
              style={{ margin: 0, marginBottom: 15 }}
              value={
                this.state.rejectionData.partner
                  ? this.state.rejectionData.partner.email
                  : ""
              }
              inputProps={{
                readOnly: true,
              }}
            />
            <Input
              type={"textarea"}
              iconPrefix={Images.form_edit}
              label="Reason"
              placeholder="Please type here"
              style={{ margin: 0, marginBottom: 30 }}
              value={notes}
              caption={this.state.error && "This field is required"}
              // onBlur={this.onBlurValidation}
              isError={
                (this.state.send && !this.state.valid) || this.state.error
              }
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  error: false,
                });
                this.onChange("notes", e.target.value, "rejectionData");
              }}
              isRequired={true}
            />
            <ButtonGroup position="center">
              <Button
                buttonText="Cancel"
                size="md"
                type="default"
                style={{
                  margin: 0,
                  width: "48%",
                  minWidth: "48%",
                  marginRight: "2%",
                }}
                onClick={() => this.closeRejectionModal()}
              />
              <Button
                buttonText="Reject"
                size="md"
                type="danger"
                style={{
                  margin: 0,
                  width: "48%",
                  minWidth: "48%",
                  marginLeft: "2%",
                }}
                onClick={() => this.onReject()}
              />
            </ButtonGroup>
          </FormBody>
        </Modal>
      );
    };

    return (
      <div className="dashboard">
        {renderRejectModal()}

        {renderDetail()}

        <Confirmation
          isVisible={confirmation.isVisible}
          header={confirmation.header}
          description={confirmation.description}
          confirmationText="Yes"
          onCancel={confirmation.onCancel}
          onConfirm={confirmation.onConfirm}
        />

        <div className="dashboard__header">
          <div className="flex flex__sm">
            <h1 className="header" style={{ marginRight: 20 }}>
              Logistic Partner
            </h1>
            <Filter
              filterOptions={filterOptions}
              withFilter
              isFilterVisible={this.state.isFiltering}
              onCancel={() => this.setState({ isFiltering: false })}
              onFilter={() =>
                this.setState({ isFiltering: !this.state.isFiltering })
              }
              onApplyFilter={(e) => this.onFilter(e)}
              onSearch={(e) => this.onSearch(e)}
            />
          </div>
        </div>
        <div className="dashboard__body">
          <Table columns={columns} data={data} />
        </div>
      </div>
    );
  }
}

const mapsStateToProps = ({ authenticated, logistic_partners }) => {
  return {
    token: authenticated.token,
    logistic_partners: logistic_partners.lists,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/LogisticPartnersRedux");

  return {
    ...ownProps,
    ...stateProps,
    fetchLogisticPartner: async (token) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await actions.fetchLogisticPartner(dispatch, token);
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    toggleActivatePartner: async (token, body) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await actions.toggleActivatePartner(dispatch, token, body);
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    approval: async (token, user_id, approval, body) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await actions.approval(
        dispatch,
        token,
        user_id,
        approval,
        body
      );
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    clearLogisticPartners: () => dispatch({ type: "CLEAR_LOGISTIC_PARTNER" }),
    toastPending: (body) => {
      dispatch({ type: "TOAST_PENDING", ...body });
    },
  };
};

export default connect(
  mapsStateToProps,
  undefined,
  mergeProps
)(LogisticPartnerContainer);
