import { Constant } from "common";
import { FirebaseAPI } from "./FirebaseAPI";
import firebase from 'firebase';

export const OfficerAPI = {

    fetchOfficer: (token) => {
        try {
            return fetch(`${Constant.API_URL}/officer`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(async(response) => {
                if(response.ok) {
                    return response.json()
                }
                var error_message = await response.json().then((json) => {
                    if(typeof(json.message) === "object") {
                        for (const index in json.message) {
                            return json.message[index];
                        }
                    }
                    else {
                        return json; 
                    }
                })
                throw new Error(JSON.stringify(error_message));
            })
            .then((json) => {
                return { status_code: 200, ...json };
            }).catch((error) => {
                return { status_code: 500, ...error };
            });
        }
        catch(error) {
            return { status_code: 500, ...error };
        }
    },

    createOfficer: async(body) => {
        try {
            return await firebase.auth().createUserWithEmailAndPassword(body.email, body.password).then(async(response) => {  
                return response.user.getIdToken().then(function(token) {
                    const { uid, photoURL } = response.user;
                    
                    body = {
                        ...body,
                        uuid: uid,
                        file: photoURL,
                        provider: "saytum"
                    }

                    return fetch(`${Constant.API_URL}/user/register?type=user&role=officer`, {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        },
                        body: JSON.stringify(body)
                    }).then(async(response) => {
                        if(response.ok) {
                            return response.json();
                        }
                        var error_message = await response.json().then((json) => {
                            if(typeof(json.message) === "object") {
                                for (const index in json.message) {
                                    return json.message[index];
                                }
                            }
                            else {
                                return json; 
                            }
                        })
                        throw new Error(JSON.stringify(error_message))
                    })
                    .then((json) => {
                        return { status_code: 200, ...json };
                    }).catch((error) => {
                        FirebaseAPI.deleteUser();
                        return { status_code: 500, ...error };
                    });
                });
            }).catch((error) => {
                return { status: 500, ...error };
            });
        }
        catch(error) {
            return { status_code: 500, ...error };
        }
    },

    toggleActivate(token, body) {
        try {
            return fetch(`${Constant.API_URL}/user/activate`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(body)
            }).then(async(response) => {
                if(response.ok) {
                    return response.json();
                }
                var error_message = await response.json().then((json) => {
                    if(typeof(json.message) === "object") {
                        for (const index in json.message) {
                            return json.message[index];
                        }
                    }
                    else {
                        return json; 
                    }
                })
                throw new Error(JSON.stringify(error_message))
            })
            .then((json) => {
                return { status_code: 200, ...json };
            }).catch((error) => {
                return { status_code: 500, ...error };
            });
        }
        catch(error) {
            return { status_code: 500, ...error };
        }
    }

}