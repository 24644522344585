import React from "react";
import { Button, Detail, DetailContent, DetailInformation } from "components";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import TrackingContainer from "./Tracking";
import "./styles.scss";
import UpdateShipmentTrackingLocationContainer from "./Modal/UpdateShipmentLocation";
import CompleteShipmentContainer from "./Modal/CompleteShipment";
import { connect } from "react-redux";
import ShipmentCourierContainer from "./Courier";
import ReviewDeliveryContainer from "./Modal/ReviewDelivery";

class ShipmentDetailContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      shipment: {},
      isMarkAsDelivered: false,
      isUpdateLocation: false,
      isReviewDelivery: false,
    };
  }

  componentDidMount() {
    const { match, trackings } = this.props;
    var data = trackings.find(
      (item) => parseFloat(item.id) === parseFloat(match.params.id)
    );
    this.setState({
      shipment: data,
    });
  }

  render() {
    const { shipment } = this.state;
    const { history, user, material_categories, fleet_categories } = this.props;
    const { innerWidth } = window;
    var material_category = material_categories.find(
      (item) =>
        parseFloat(item.id) === parseFloat(shipment.material_category_id)
    );
    var fleet_category = fleet_categories.find(
      (item) => parseFloat(item.id) === parseFloat(shipment.fleet_category_id)
    );

    return (
      <>
        <Detail
          style={
            innerWidth > 768
              ? { marginTop: 50, marginBottom: 50 }
              : { marginTop: 15, marginBottom: 0 }
          }
          withBackButton
          backButtonAction={() => history.goBack()}
        >
          <DetailContent header="Shipment Detail">
            <div className="details__item">
              <h4 className="details__item__header">Pickup</h4>
              <div className="details__item__group">
                <p className="label">Sender Name</p>
                <p className="text">{shipment.pickup_name || ""}</p>
              </div>
              <div className="details__item__group">
                <p className="label">Mobile Number</p>
                <p className="text">
                  {shipment.pickup_mobile_prefix}{" "}
                  {shipment.pickup_mobile_number}
                </p>
              </div>
              <div className="details__item__group">
                <p className="label">Address</p>
                <p className="text">{shipment.pickup_address_one}</p>
              </div>
              <Row>
                <Col>
                  <div className="details__item__group">
                    <p className="label">City</p>
                    <p className="text">{shipment.pickup_cities}</p>
                  </div>
                </Col>
                <Col>
                  <div className="details__item__group">
                    <p className="label">State</p>
                    <p className="text">{shipment.pickup_states}</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="details__item__group">
                    <p className="label">Country</p>
                    <p className="text">{shipment.pickup_countries}</p>
                  </div>
                </Col>
                <Col>
                  <div className="details__item__group">
                    <p className="label">Postal Code</p>
                    <p className="text">{shipment.pickup_postal_code}</p>
                  </div>
                </Col>
              </Row>
              <div className="details__item__group">
                <p className="label">Pickup Date</p>
                <p className="text">
                  {shipment.pickup_time
                    ? shipment.pickup_time.replace(/_/g, " ")
                    : ""}{" "}
                  - {moment(shipment.pickup_date).format("DD MMM YYYY")}
                </p>
              </div>
            </div>
            <div className="details__item">
              <h4 className="details__item__header">Delivery</h4>

              <div className="details__item__group">
                <p className="label">Receiver Name</p>
                <p className="text">{shipment.delivery_name}</p>
              </div>
              <div className="details__item__group">
                <p className="label">Mobile Number</p>
                <p className="text">
                  {shipment.delivery_mobile_prefix}{" "}
                  {shipment.delivery_mobile_number}
                </p>
              </div>
              <div className="details__item__group">
                <p className="label">Address</p>
                <p className="text">{shipment.delivery_address_one}</p>
              </div>
              <Row>
                <Col>
                  <div className="details__item__group">
                    <p className="label">City</p>
                    <p className="text">{shipment.delivery_cities}</p>
                  </div>
                </Col>
                <Col>
                  <div className="details__item__group">
                    <p className="label">State</p>
                    <p className="text">{shipment.delivery_states}</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="details__item__group">
                    <p className="label">Country</p>
                    <p className="text">{shipment.delivery_countries}</p>
                  </div>
                </Col>
                <Col>
                  <div className="details__item__group">
                    <p className="label">Postal Code</p>
                    <p className="text">{shipment.delivery_postal_code}</p>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="details__item">
              <h4 className="details__item__header">Material</h4>

              <div className="details__item__group">
                <p className="label">Material Category</p>
                <p className="text">
                  {material_category ? material_category.name : ""}
                </p>
              </div>
              <div className="details__item__group">
                <p className="label">Material Detail</p>
                <p className="text">{shipment.material_detail}</p>
              </div>
              <Row>
                <Col>
                  <div className="details__item__group">
                    <p className="label">Weight</p>
                    <p className="text">{shipment.weight} Kg</p>
                  </div>
                </Col>
                <Col>
                  <div className="details__item__group">
                    <p className="label">Transportation Mode</p>
                    <p className="text">
                      {fleet_category ? fleet_category.name : ""}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </DetailContent>
          <DetailInformation>
            <div className="details__information">
              <h4 className="details__item__header shipment__information__header">
                Shipment Tracking
              </h4>

              {user.role !== "delivery_boy" && (
                <ShipmentCourierContainer data={shipment.delivery_boy} />
              )}

              <TrackingContainer
                data={shipment.shipment_history}
                {...this.props}
              />

              {user.role === "delivery_boy" && shipment.status < 145 && (
                <div className="flex shipment__tracking__btn-group">
                  <Button
                    buttonText="Update Location"
                    type="warning"
                    variation="outline"
                    className="shipment__tracking__btn"
                    onClick={() => this.setState({ isUpdateLocation: true })}
                  />
                  <Button
                    buttonText="Mark as Delivered"
                    type="warning"
                    className="shipment__tracking__btn"
                    onClick={() => this.setState({ isMarkAsDelivered: true})}
                  />
                </div>
              )}

              {/* {user.role === "user" && (
                                <div className="flex shipment__tracking__btn-group">
                                    <Button
                                        buttonText="Review Delivery"
                                        type="warning"
                                        className="shipment__tracking__btn full"
                                        onClick={() => this.setState({ isReviewDelivery: true })}
                                    />
                                </div>
                            )} */}
            </div>
          </DetailInformation>
        </Detail>

        {this.state.isUpdateLocation && (
          <UpdateShipmentTrackingLocationContainer
            {...this.props}
            isVisible={this.state.isUpdateLocation}
            onClose={() => this.setState({ isUpdateLocation: false })}
          />
        )}

        {this.state.isMarkAsDelivered && (
          <CompleteShipmentContainer
            {...this.props}
            isVisible={this.state.isMarkAsDelivered}
            onClose={() => this.setState({ isMarkAsDelivered: false })}
          />
        )}

        {this.state.isReviewDelivery && (
          <ReviewDeliveryContainer
            {...this.props}
            isVisible={this.state.isReviewDelivery}
            onClose={() => this.setState({ isReviewDelivery: false })}
            onReview={(data) =>
              this.setState({ isReviewDelivery: false }, () =>
                console.log(data)
              )
            }
          />
        )}
      </>
    );
  }
}

const mapsStateToProps = ({
  authenticated,
  tracking,
  material_categories,
  fleet_categories,
}) => {
  return {
    user: authenticated.userInfo,
    token: authenticated.token,
    trackings: tracking.lists,
    material_categories: material_categories.lists,
    fleet_categories: fleet_categories.lists,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/TrackingRedux");
  const {
    actions: material_categories_actions,
  } = require("redux/MaterialCategoriesRedux");
  const {
    actions: fleet_categories_actions,
  } = require("redux/FleetCategoriesRedux");

  return {
    ...ownProps,
    ...stateProps,
    updateTrackingLocation: async (token, data) => {
      dispatch({
        type: "SET_PROCESS",
        items: { isVisible: true, label: "Processing" },
      });
      await actions.updateTrackingLocation(dispatch, token, data);
      await actions.fetchTracking(dispatch, token, data.consignment_id);
      dispatch({ type: "RESET_PROCESS" });
    },
    fetchFleetCategories: async (token) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await fleet_categories_actions.fetchFleetCategories(
        dispatch,
        token
      );
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    fetchMaterialCategories: async (token) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await material_categories_actions.fetchMaterialCategories(
        dispatch,
        token
      );
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
  };
};

export default connect(
  mapsStateToProps,
  undefined,
  mergeProps
)(ShipmentDetailContainer);
