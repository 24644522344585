import Constant from 'common/Constant';
import React from 'react';
import FacebookLogin from 'react-facebook-login';

class FacebookSocialButton extends React.PureComponent {

    render() {

        const { text, onClick } = this.props;

        return(
            <FacebookLogin
                appId={Constant.FACEBOOK_APP_ID}
                fields="name,email,picture"
                callback={(response) => onClick(response)}
                cssClass="button__social button__social__facebook"
                textButton={text}
                icon=""
            />
        )
    }

}

export default (FacebookSocialButton);