import React from "react";
import "./input.scss";
import Select from "react-select";
import Constant from "common/Constant";
import "./select.scss";

class InputPhone extends React.PureComponent {
  onChange = (e) => {
    var mask = this.props.selectedPrefix
      ? this.props.selectedPrefix.mask
      : Constant.COUNTRY_CODE.default.mask;
    var pattern = new RegExp(mask);
    if (pattern.test(e.target.value)) {
      this.props.onChange(e);
    }
  };

  render() {
    const { clickableIcon, onIconClick, phoneCodes, placeholder, isRequired } = this.props;

    return (
      <div className={`input`} style={this.props.style}>
        <label
          className={`input__label ${
            this.props.isError ? "input__label__error" : undefined
          }`}
        >
          {this.props.label}
          {isRequired && <span className="input__required">*</span>}
        </label>

        {this.props.iconPrefix &&
          (clickableIcon && clickableIcon.includes("prefix") ? (
            <button
              className="input__icon input__icon__button input__icon__button__prefix input__icon__prefix"
              onClick={() => onIconClick("prefix")}
            >
              <img src={this.props.iconPrefix} alt="form-icon-prefix" />
            </button>
          ) : (
            <img
              src={this.props.iconPrefix}
              alt="form-icon-prefix"
              className="input__icon input__icon__prefix"
            />
          ))}

        <div className="input__phone">
          {!this.props.isError ? (
            <Select
              className={`input__select input__select__phone ${
                this.props.iconPrefix && "input__select__icon__prefix"
              }`}
              classNamePrefix={`input__select`}
              options={phoneCodes}
              onChange={(e) => this.props.onSelect(e)}
              value={this.props.selectedPrefix}
              {...this.props.selectProps}
            />
          ) : (
            <Select
              className={`input__select input__select__phone ${
                this.props.iconPrefix && "input__select__icon__prefix"
              }`}
              classNamePrefix={`input__select__error`}
              options={phoneCodes}
              onChange={(e) => this.props.onSelect(e)}
              value={this.props.selectedPrefix}
              {...this.props.selectProps}
            />
          )}

          <input
            type={"text"}
            className={`input__control input__phone__control ${
              this.props.isError ? "input__control__error" : undefined
            } ${
              this.props.type === "password"
                ? "input__control__password"
                : undefined
            }`}
            placeholder={placeholder ?? "Mobile Number"}
            onChange={(e) => this.onChange(e)}
            onBlur={(e) => this.props.onBlur(e)}
            value={this.props.value ?? ""}
            maxLength={
              this.props.selectedPrefix
                ? this.props.selectedPrefix.length
                : Constant.COUNTRY_CODE.default.length
            }
            {...this.props.inputProps}
          />

          {this.props.iconSuffix &&
            (clickableIcon && clickableIcon.includes("suffix") ? (
              <button
                className={`input__icon input__icon__button input__icon__button__suffix input__icon__suffix ${
                  this.props.clickableIconClassName
                } ${
                  this.props.inputProps && this.props.inputProps.readOnly
                    ? "disabled"
                    : ""
                }`}
                disabled={
                  this.props.inputProps && this.props.inputProps.readOnly
                    ? true
                    : false
                }
                onClick={() => onIconClick("suffix")}
              >
                <img src={this.props.iconSuffix} alt="form-icon-suffix" />
                {this.props.clickableIconLabel && (
                  <p className="input__icon__label">
                    {this.props.clickableIconLabel}
                  </p>
                )}
              </button>
            ) : (
              <img
                src={this.props.iconSuffix}
                alt="form-icon-suffix"
                className="input__icon input__icon__suffix"
              />
            ))}
        </div>

        {this.props.caption && (
          <label
            className={`input__caption ${
              this.props.isError ? "input__caption__error" : undefined
            }`}
          >
            {this.props.caption}
          </label>
        )}
      </div>
    );
  }
}

export default InputPhone;
