import React from "react";
import "./input.scss";

class InputType extends React.PureComponent {
  render() {
    const { clickableIcon, onIconClick, isRequired } = this.props;

    return (
      <div className={`input`} style={this.props.style}>
        <label
          className={`input__label ${
            this.props.isError ? "input__label__error" : undefined
          }`}
        >
          {this.props.label}
          {isRequired && <span className="input__required">*</span>}
        </label>

        {this.props.iconPrefix &&
          (clickableIcon && clickableIcon.includes("prefix") ? (
            <button
              className="input__icon input__icon__button input__icon__button__prefix input__icon__prefix"
              onClick={() => onIconClick("prefix")}
            >
              <img src={this.props.iconPrefix} alt="form-icon-prefix" />
            </button>
          ) : (
            <img
              src={this.props.iconPrefix}
              alt="form-icon-prefix"
              className={`input__icon input__icon__prefix ${this.props.iconPrefixClassName}`}
            />
          ))}

        <input
          type={this.props.type}
          className={`input__control ${
            this.props.isError ? "input__control__error" : undefined
          } ${
            this.props.type === "password"
              ? "input__control__password"
              : undefined
          } ${this.props.inputClassName ? this.props.inputClassName : ""}`}
          placeholder={this.props.placeholder}
          onChange={(e) => this.props.onChange(e)}
          //   onFocus={(e) => this.props.onFocus(e)}
          onBlur={(e) => this.props.onBlur(e)}
          value={this.props.value}
          {...this.props.inputProps}
        /> 
        
        {this.props.iconSuffix &&
          (clickableIcon && clickableIcon.includes("suffix") ? (
            <button
              className={`input__icon input__icon__button input__icon__button__suffix input__icon__suffix ${
                this.props.clickableIconClassName
              } ${
                this.props.inputProps && this.props.inputProps.readOnly
                  ? "disabled"
                  : ""
              }`}
              disabled={
                this.props.inputProps && this.props.inputProps.readOnly
                  ? true
                  : false
              }
              onClick={() => onIconClick("suffix")}
            >
              <img src={this.props.iconSuffix} alt="form-icon-suffix" />
              {this.props.clickableIconLabel && (
                <p className="input__icon__label">
                  {this.props.clickableIconLabel}
                </p>
              )}
            </button>
          ) : (
            <img
              src={this.props.iconSuffix}
              alt="form-icon-suffix"
              className="input__icon input__icon__suffix"
            />
          ))}

        {this.props.caption && (
          <label
            className={`input__caption ${
              this.props.isError ? "input__caption__error" : undefined
            }`}
          >
            {this.props.caption}
          </label>
        )}

       

      </div>
    );
  }
}

export default InputType;
