import Constant from 'common/Constant';
import languages from 'common/Languages';
import firebase from 'firebase';
import moment from 'moment';
import store from "store/configureStore";

export const FirebaseAPI = {


    /**
     * signin with email and password
     * @param {All Parameter in specific URI} params 
     * @returns 
     */
    async signInWithEmailAndPassword(params, mustVerified=true) {
        return await firebase.auth().signInWithEmailAndPassword(params.email, params.password).then(async(response) => {
            var firebaseToken = await "";

        if (mustVerified) {
          if (response.user.emailVerified) {
            await response.user.getIdToken().then(function (idToken) {
              firebaseToken = idToken;
            });
            return {
              status: 200,
              token: firebaseToken,
              refreshToken: response.user.refreshToken,
              firebaseUser: response.user,
            };
          } else {
            return {
              status: 500,
              message: languages.account_not_verified,
              code: "auth/user-not-verified",
            };
          }
        } else {
          await response.user.getIdToken().then(function (idToken) {
            firebaseToken = idToken;
          });
          return {
            status: 200,
            token: firebaseToken,
            refreshToken: response.user.refreshToken,
            firebaseUser: response.user,
          };
        }
      })
      .catch((error) => {
        store.dispatch({
          type: "SET_ERROR",
          items: { hasError: true },
        });
        return { status: 500, ...error };
      });
  },


  
  /**
   * Sign in user anonymously
   */
    async signInAnonymously(){
        return await firebase
            .auth()
            .signInAnonymously()
            .then(async (response) => {
                var firebaseToken = await "";

                await response.user.getIdToken().then(function (idToken) {
                    firebaseToken = idToken;
                });

                response.user.idToken = firebaseToken;

                return { status: 200, user: response.user };
            })
            .catch((error) => {
                store.dispatch({
                    type: "SET_ERROR",
                    items: { hasError: true },
                });
                return { status: 500, ...error };
            });
    },

    /**
     * create user with email and password
     * @param {All Parameter in specific URI} params 
     * @returns 
     */
     async createUserWithEmailPassword(params) {
        return await firebase.auth().createUserWithEmailAndPassword(params.email, params.password).then(async(response) => {
            var firebaseToken = null;

            await response.user.getIdToken().then(function(idToken) {
                firebaseToken = idToken;
            });

            response.user.idToken = firebaseToken;
            
            return { status: 200, user: response.user };
        }).catch((error) => {
            store.dispatch({
                type:"SET_ERROR",
                items: { hasError: true }
            });
            return { status: 500, ...error };
        });
    },

    /**
     * signin with facebook credintial
     * @param {All Parameter in specific URI} params 
     * @returns 
    */
    async signInWithFacebookCredintial(params) {
        const credintial = firebase.auth.FacebookAuthProvider.credential(params.accessToken);
        return await firebase.auth().signInWithCredential(credintial).then(async (response) => {
            var firebaseToken = await "";
            await response.user.getIdToken().then(function(idToken) {  // <------ Check this line
                firebaseToken = idToken;
            });
            return { status: 200, token: firebaseToken, refreshToken: response.user.refreshToken, firebaseUser: response.user };
        }).catch((error) => {
            store.dispatch({
                type:"SET_ERROR",
                items: { hasError: true }
            });
            return { status: 500, ...error };
        });
    },

    /**
     * signin with google credintial
     * @param {All Parameter in specific URI} params 
     * @returns 
    */
    async signInWithGoogleCredintial(params) {
        const credintial = firebase.auth.GoogleAuthProvider.credential(params.accessToken);
        return await firebase.auth().signInWithCredential(credintial).then(async (response) => {
            var firebaseToken = await "";
            await response.user.getIdToken().then(function(idToken) {  // <------ Check this line
                firebaseToken = idToken;
            });

            return { status: 200, token: firebaseToken, refreshToken: response.user.refreshToken, firebaseUser: response.user };
        }).catch((error) => {
            store.dispatch({
                type:"SET_ERROR",
                items: { hasError: true }
            });
            return { status: 500, ...error };
        });
    },

    /**
     * signin with phone credintial
     * @param {All Parameter in specific URI} params 
     * @returns 
    */
     async signInWithMobileNumber(params) {
        const credintial = firebase.auth.PhoneAuthProvider.credential(params.verificationId, params.verification_code);
        return await firebase.auth().signInWithCredential(credintial).then(async (response) => {
            var firebaseToken = await "";
            await response.user.getIdToken().then(function(idToken) {  // <------ Check this line
                firebaseToken = idToken;
            });

            return { status: 200, token: firebaseToken, refreshToken: response.user.refreshToken, firebaseUser: response.user };
        }).catch((error) => {
            store.dispatch({
                type:"SET_ERROR",
                items: { hasError: true }
            });
            return { status: 500, ...error };
        });
    },

    /**
     * signin with custom token
     * @param {All Parameter in specific URI} params 
     * @returns 
    */
    async signInWithCustomToken(token) {
        return await firebase.auth().signInWithCustomToken(token).then(async(response) => {
            var firebaseToken = null;
            await response.user.getIdToken().then(function(idToken) {
                firebaseToken = idToken;
            });
            return { status: 200, token: firebaseToken, refreshToken: response.user.refreshToken, firebaseUser: response.user };
        }).catch((error) => {
            store.dispatch({
                type:"SET_ERROR",
                items: { hasError: true }
            });
            return { status: 500, ...error };
        });
    },

    /**
     * signout
     * @param {All Parameter in specific URI} params 
     * @returns 
    */
     async signOut() {
        return await firebase.auth().signOut().then(() => {
            return { status: 200 };
        }).catch((error) => {
            store.dispatch({
                type:"SET_ERROR",
                items: { hasError: true }
            });
            return { status: 500, ...error };
        });
    },

    async getCurrentUser() {
        return await firebase.auth().currentUser.getIdTokenResult();
    },

    async sendEmailVerification(params) {
        return FirebaseAPI.signInWithEmailAndPassword(params).then(async() => {
            return await firebase.auth().currentUser.sendEmailVerification();
        })
    },

    async verifyEmail(oobCode) {
        try {
            return await firebase.auth().applyActionCode(oobCode).then( (response) => {
                return { fetch_status: "success", ...response }
            }).catch((error) => {
                store.dispatch({
                    type:"SET_ERROR",
                    items: { hasError: true }
                });
                return { fetch_status: "error", ...error }
            })        
        }
        catch(error) {
            store.dispatch({
                type:"SET_ERROR",
                items: { hasError: true }
            });
            return { fetch_status: "error", ...error }
        }
    },

    async deleteUser() {
        const user = firebase.auth().currentUser;
        if(user) {
            user.delete().then(() => {
                return { status: 200, message: "account was deleted" }
            }).catch((error) => {
                store.dispatch({
                    type:"SET_ERROR",
                    items: { hasError: true }
                });
                return { status: 500, ...error }
            });
        }
    },

    async getClientFCMToken() {
        var permission = await this.requestNotificationPermission();
        if(permission === "granted") {
            return firebase.messaging().getToken({ vapidKey: Constant.FIREBASE.vapidKey }).then((token) => {
                if(token) {
                    return { status: 200, token: token };
                }
                else {
                    store.dispatch({
                        type:"SET_ERROR",
                        items: { hasError: true }
                    });
                    return { status: 500, message: "Notification permission blocked" };
                }
            }).catch(() => {
                store.dispatch({
                    type:"SET_ERROR",
                    items: { hasError: true }
                });
                return { status: 500, message: "Notification permission blocked" };
            });
        }
        else {
            store.dispatch({
                type:"SET_ERROR",
                items: { hasError: true }
            });
            return { status: 500, message: "Notification permission blocked" };
        }
    },

    async requestNotificationPermission() {
        return await Notification.requestPermission().then((permission) => {
            return permission;
        }).catch((error) => {
            store.dispatch({
                type:"SET_ERROR",
                items: { hasError: true }
            });
            return { status: 500, ...error }
        })
    },

    async ensureTokenIsExpired() {
        var state = store.getState();
        const { tokenExpirationTime } = state.authenticated;
        return new Promise((resolve, reject) => {
            var now = moment();
            if(moment(tokenExpirationTime).diff(now) > 0) {
                resolve(true)
            }
            else {
                reject(false)
            }
        })
    },

    async generateFreshTokenID(refresh_token) {
        var details = { grant_type: "refresh_token", refresh_token: refresh_token }

        var formBody = [];
        for (var property in details) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(details[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        try {
            return fetch("https://securetoken.googleapis.com/v1/token?key=" + Constant.FIREBASE.apiKey, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: formBody
            }).then(async(response) => {
                if(response.ok) {
                    return response.json();
                }
                var error_message = await response.json().then((json) => {
                    return json.message;
                })
                throw new Error(error_message)
            })
            .then((json) => {
                return { "fetch_status": "success", ...json };
            }).catch((error) => {
                store.dispatch({
                    type:"SET_ERROR",
                    items: { hasError: true }
                });
                return { "fetch_status": "error", ...error };
            });
        }
        catch(error) {
            return { status: 500, ...error }
        }
    },

    async getToken() {
        return firebase.auth().currentUser.getIdToken();
    },

    async getStorageFile(path, storage_bucket=Constant.FIREBASE.storageBucket) {
        var storage = firebase.storage().refFromURL("gs://" + storage_bucket);
        return await storage.child(path).getDownloadURL().then((response) => {
            return response;
        }).catch((error) => {
            store.dispatch({
                type:"SET_ERROR",
                items: { hasError: true }
            });
            return { status: 500, ...error }
        });
    },

  async callFunctions(function_name, param) {
    var functions = firebase
      .app()
      .functions(Constant.FIREBASE.region)
      .httpsCallable(function_name);
    return functions(param)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return { status: 500, ...error };
      });
  },
  async contactFunctions(function_name, param) {
    var functions = firebase
      .app()
      .functions(Constant.FIREBASE.region)
      .httpsCallable(function_name);
    return functions(param)
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        return { status: 500, ...error };
      });
  },
}; 