import React from "react";
import "./filter.scss";

class InputFilter extends React.PureComponent {
  render() {
    const { clickableIcon, onIconClick } = this.props;

    return (
      <div className={`input__filter`} style={this.props.style}>
        {this.props.iconPrefix &&
          (clickableIcon && clickableIcon.includes("prefix") ? (
            <button
              className="input__filter__icon input__filter__icon__button input__filter__icon__button__prefix input__filter__icon__prefix"
              onClick={() => onIconClick("prefix")}
            >
              <img src={this.props.iconPrefix} alt="form-icon-prefix" />
            </button>
          ) : (
            <img
              src={this.props.iconPrefix}
              alt="form-icon-prefix"
              className={`input__filter__icon input__filter__icon__prefix ${this.props.iconPrefixClassName}`}
            />
          ))}

        {this.props.iconSuffix &&
          (clickableIcon && clickableIcon.includes("suffix") ? (
            <button
              className={`input__filter__icon input__filter__icon__button input__icon__button__suffix input__filter__icon__suffix ${this.props.clickableIconClassName}`}
              onClick={() => onIconClick("suffix")}
            >
              <img src={this.props.iconSuffix} alt="form-icon-suffix" />
              {this.props.clickableIconLabel && (
                <p className="input__filter__icon__label">
                  {this.props.clickableIconLabel}
                </p>
              )}
            </button>
          ) : (
            <img
              src={this.props.iconSuffix}
              alt="form-icon-suffix"
              className="input__filter__icon input__filter__icon__suffix"
            />
          ))}

        <input
          type="text"
          className={`input__filter__control ${
            this.props.inputClassName ? this.props.inputClassName : ""
          }`}
          placeholder={this.props.placeholder}
          onChange={(e) => this.props.onChange(e)}
          onBlur={(e) => this.props.onBlur(e)}
          value={this.props.value}
          {...this.props.inputProps}
        />
      </div>
    );
  }
}

export default InputFilter;
