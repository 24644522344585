import React from 'react';
import { Button, Confirmation } from 'components';
import { connect } from 'react-redux';
import { navigate } from 'common';
import moment from 'moment';
import "./styles.scss";
import { Col, Row } from 'react-bootstrap';

class AuctionPaysContainer extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            bids_data: [],
            confirmation: {}
        }
    }

    componentDidMount() {
        this.getBidderWon()
    }

    async getBidderWon() {
        const {
            fetchAuctionBidderWon,
            clearAuctionsBiddersWon,
            token,
            auction,
            bidders
        } = this.props;
        let json;
        // console.log("auction" , auction);
        clearAuctionsBiddersWon()
        if(auction.status === 103) {
            json = await fetchAuctionBidderWon(token, auction.auction_id, 121)
            // console.log("121" , json);
        } else {
            json = await fetchAuctionBidderWon(token, auction.auction_id, 122)
            // console.log("122" , json);
        }
        const { data } = json.data
        // console.log("auction_pay" , json);
        console.log("consignment" , bidders);
        await this.getPaymentDetail(token, data[0].id , bidders[0].consignment_id)
    }

    async getPaymentDetail(token, bid_id, consignment_id) {
        const {
            fetchPaymentDetail,
            clearPaymentDetail,
            bidders
        } = this.props;
        clearPaymentDetail()
        console.log("consignment" , bidders[0].consignment_id);
        var json = await fetchPaymentDetail(token, bid_id, bidders[0].consignment_id)
        return json
    }

    onCreateOrder = async() => {
        const {
            token,
            createOrder,
            auction,
            bids_data,
            payment_detail,
            verifySelf,
            toastPending,
            fetchOrderDetail,
            acceptBidAuction,
            history,
            bidders
        } = this.props;
        // console.log("auction_pay_payment", payment_detail);
        // console.log("auctionPay_bidsData", bids_data);
        if(!payment_detail.order_id) { // order_id doesn't exist
            var params = {
                amount: bids_data[0].bid_price * 100,
                currency: "INR",
                receipt: null,
                bid_id: bids_data[0].id,
                notes: {
                    consignment_id: bidders[0].consignment_id,
                    consignment_name: auction.order_name,
                    pickup_name: auction.pickup_name,
                    pickup_address_two: auction.pickup_address_two,
                    pickup_mobile_number: auction.pickup_mobile_prefix + auction.pickup_mobile_number,
                    pickup_date: moment(auction.pickup_date).format('YYYY-MM-DD hh:mm:ss'),
                    pickup_time: auction.pickup_time, 
                    delivery_name: auction.delivery_name,
                    delivery_address_two: auction.delivery_address_two,
                    delivery_mobile_number: auction.delivery_mobile_prefix + auction.delivery_mobile_number,
                    material_detail: auction.material_detail,
                    weight: auction.weight.toString(),
                    material_price: auction.material_price.toString(),
                    logistic_partner_id: bids_data[0]?.logistic_partner?.id,
                    logistic_partner_name: bids_data[0]?.logistic_partner?.name
                },
                partial_payment: false
            }
            var json = await createOrder(token, params)
            if(json.status_code === 200) {
                this.onPayNow(json.data)
                await this.getPaymentDetail(token, bids_data[0].id, bidders[0].consignment_id);
            }
        } else if(payment_detail.payment_status === 151) { // payment status created
            var orderData = await fetchOrderDetail(token, payment_detail.order_id)
            if(orderData.status_code === 200) {
                this.onPayNow(orderData.data)
            } else {
                toastPending({ message: orderData.message, toastType: orderData.status_code })
            }
        } else { // payment status failed
            var acceptData = { bid_id: bids_data[0].id, auction_id: bids_data[0].auction_id, bidder_id: bids_data[0].bidder_id, consignment_id:bidders[0].consignment_id }
            var result = await verifySelf(token, payment_detail.order_id, bidders[0].consignment_id)
            if(result.status_code === 500) {
                await await this.getPaymentDetail(token, bids_data[0].id, bidders[0].consignment_id)
            } else {
              await acceptBidAuction(token, acceptData)
              navigate(history, "/dashboard?screen=auction")
            }
            toastPending({ message: result.message, toastType: result.status_code })
        }
    }

    openConfirmation = (headerText, descriptionText, onCancel, onConfirm) => {
        this.setState({
            confirmation: {
                isVisible: true,
                header: headerText,
                description: descriptionText,
                onCancel: onCancel,
                onConfirm: onConfirm
            }
        })
    }

    closeConfirmation = () => {
        this.setState({
            confirmation: {
                isVisible: false
            }
        })
    }

    onPayNow = (data) => {
        this.closeConfirmation()
        const { token, auction, bids_data, verifyPayments, toastPending, updatePayments, bidders } = this.props;
        var options = {
            key: process.env.REACT_APP_RAZORPAY_KEY_ID,
            amount: data.amount.toString(),
            currency: data.currency,
            name: "Saytum",
            description: "",
            image: require('images/logo.png'),
            order_id: data.id,
            handler: async function (response){
                var json = await verifyPayments(token, {
                    bid_id: bids_data[0].id,
                    consigment_id: bidders[0].consignment_id,
                    razorpay_order_id: response.razorpay_order_id,
                    razorpay_payment_id: response.razorpay_payment_id,
                    razorpay_signature: response.razorpay_signature
                });
                if(json.status_code === 500) {
                    await updatePayments(token, data.id, {
                        consigment_id: bidders[0].consignment_id,
                        payment_status: 154
                    });
                }
                toastPending({ message: json.message, toastType: json.status_code })
            },
            prefill: {
                name: data.notes.pickup_name,
                email: "",
                contact: data.notes.pickup_mobile_number,
            },
            notes: {
                address: data.notes.pickup_address_two
            },
            theme: {
                "color": "#3399cc"
            }
        }
        var payment = new window.Razorpay(options)
        payment.open()
        payment.on('payment.failed', async function (response){
            await updatePayments(token, data.id, {
                consigment_id: bidders[0].consignment_id,
                payment_status: 154
            })
        })
    }

    render() {

        const { auction, bids_data, payment_detail } = this.props
        // console.log(this.props)
        // console.log("auctionpay_paymetn" , payment_detail);
        const { confirmation } = this.state
        return (
            <div className="payment">
                <h4 className="details__item__header">{'Auction Payment'}</h4>
                <Row>
                    <Col>
                        <div className="payment__list">
                            <div className="payment__item">
                                <p className="label">Pickup Name</p>
                                <p className="text">{auction.pickup_name}</p>
                            </div>
                            <div className="payment__item">
                                <p className="label">Logistic Company</p>
                                <p className="text">{bids_data[0]?.logistic_partner?.name}</p>
                            </div>
                            <div className="payment__item">
                                <p className="label">Price</p>
                                <p className="text">{"₹ " + (bids_data[0]?.bid_price)}</p>
                            </div>
                        </div>    
                    </Col>
                    <Col>
                        <div className="payment__list">
                            <div className="payment__item">
                                <p className="label">Payment Status</p>
                                <p className="text">{payment_detail.payment_status === 151 ? "Created" : payment_detail.payment_status === 152 ? "Captured" : payment_detail.payment_status === 154 ? "Failed" : "Checkout"}</p>
                            </div>
                            { payment_detail.payment_status >= 151 &&
                                <div className="payment__item">
                                    <p className="label">Order</p>
                                    <p className="text">{payment_detail.order_id}</p>
                                </div>
                            }
                        </div>
                    </Col>
                </Row>
                    { payment_detail === null || payment_detail.payment_status === 151 ?
                        <div className="payment__btn">
                                <Button
                                    size="lg"
                                    type="primary"
                                    buttonText="Pay"
                                    variation="primary"
                                    style={{ margin: 0, marginTop: 20, marginBottom: 20 }}
                                    onClick={() => this.onCreateOrder()}
                                />
                        </div> :
                        payment_detail.payment_status === 154 &&
                        <div className="payment__btn">
                            <Button
                                size="lg"
                                type="primary"
                                buttonText="Verify"
                                variation="primary"
                                style={{ margin: 0, marginTop: 20, marginBottom: 20 }}
                                onClick={() => this.onCreateOrder()}
                            />
                        </div>
                    }
                    <Confirmation
                        isVisible={confirmation.isVisible}
                        header={confirmation.header}
                        description={confirmation.description}
                        confirmationText="Pay Now"
                        cancelText="Close"
                        onCancel={confirmation.onCancel}
                        onConfirm={confirmation.onConfirm}
                    />
            </div>
        )
    }

}

const mapsStateToProps = ({ 
    authenticated, 
    consignment, 
    auction 
}) => {
    return {
        token: authenticated.token,
        partner: authenticated.userInfo.partner,
        status: consignment.status,
        bidders: auction.bid_lists,
        bid_total: auction.bid_total,
        bids_data: auction.bidder_won,
        payment_detail: auction.payment_detail
    }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { actions } = require("redux/AuctionRedux");
    
    return {
      ...ownProps,
      ...stateProps,
      fetchPaymentDetail: async(
          token,
          bid_id, consignment_id
      ) => {
        dispatch({ type: "FETCH_PENDING" })
        var json = await actions.fetchPaymentsDetail(dispatch, token, bid_id, consignment_id)
        dispatch({ type: "FETCH_FINISH" })
        return json
      },
      updatePayments: async(
        token,
        payment_id,
        body
      ) => {
        dispatch({ type: "FETCH_PENDING" })
        var json = await actions.updatePayments(dispatch, token, payment_id, body)
        dispatch({ type: "FETCH_FINISH" })
        return json
      },
      clearPaymentDetail: () => dispatch({ type: "CLEAR_PAYMENT_DETAIL" }),
      verifySelf: async(
        token,
        order_id,
        consigment_id
      ) => {
        dispatch({ type: "FETCH_PENDING" })
        var json = await actions.verifyAuctionPaymentSelf(dispatch, token, order_id, consigment_id)
        dispatch({ type: "FETCH_FINISH" })
        return json
      },
      fetchOrderDetail: async(
        token,
        order_id
      ) => {
       dispatch({ type: "FETCH_PENDING" })
       var json = await actions.fetchOrderDetail(dispatch, token, order_id)
        dispatch({ type: "FETCH_FINISH" })
        return json
      },
      createOrder: async(
        token,
        body
      ) => {
        dispatch({ type: "FETCH_PENDING" })
        var json = await actions.createOrderAuction(dispatch, token, body)
        dispatch({ type: "FETCH_FINISH" })
        return json
      },
      verifyPayments: async(
          token,
          body
      ) => {
        dispatch({ type: "FETCH_PENDING" })
        var json = await actions.verifyAuctionPayments(dispatch, token, body)
        dispatch({ type: "FETCH_FINISH" })
        return json
      },
      fetchAuctionBidderWon: async(
          token,
          auction_id,
          bid_status
      ) => {
        dispatch({ type: "FETCH_PENDING" })
        var json = await actions.fetchAuctionBidderWon(dispatch, token, auction_id, bid_status)
        dispatch({ type: "FETCH_FINISH" })
        return json
      },
      clearAuctionsBiddersWon: () => dispatch({ type: "CLEAR_AUCTION_BIDDERS_WON" }),
      toastPending: (body) => {
        dispatch({ type: "TOAST_PENDING", ...body })
      },
      acceptBidAuction: async(
        token,
        body
      ) => {
        dispatch({ type: "FETCH_PENDING" })
        var json = await actions.acceptBidAuction(dispatch, token, body)
        dispatch({ type: "FETCH_FINISH" })
        return json
      },
    };
}

export default connect(
    mapsStateToProps,
    undefined,
    mergeProps
)(AuctionPaysContainer)