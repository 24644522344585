import React from 'react';
import { ResetPasswordContainer } from 'container';


class ResetPassword extends React.PureComponent {

    render() {
        return(
            <ResetPasswordContainer 
                {...this.props}
            />
        )
    }

}

export default (ResetPassword);