import images from 'common/Images';
import { Button, ButtonGroup } from 'components/Button';
import Input from 'components/Input';
import React from 'react';
import "./styles.scss";


class Filter extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            filters: [],
            is_date_to_enabled: false
        }
    }

    renderDatePicker = (item) => {
        
        const { data } = this.state;
        var today = new Date();
        var tomorrow = today.setDate(today.getDate() + 1)

        return (
            <>
                <p className="filter__item__label">{item.label}</p>
                <div className="filter__item__option">
                    <Input
                        style={{ marginTop: 0, marginBottom: 0 }}
                        type="datepicker"
                        pickerProps={{ 
                            className: "filter__picker__control filter__picker__control__with-icon__suffix",
                            wrapperClassName: "filter__picker",
                            dateFormat: "dd/MM/yyyy",
                            maxDate: tomorrow
                        }}
                        iconSuffix={images.datepicker}
                        iconSuffixClassName="filter__picker__icon__suffix"
                        value={data ? data.date_from : ""}
                        onChange={(e) => this.onChange("date_from", e, item)}
                    />  
                    <label style={window.innerWidth > 768 ? { display: 'block' } : { display: "none" }}>-</label>
                    <Input
                        style={{ marginTop: 0, marginBottom: 0 }}
                        type="datepicker"
                        pickerProps={{ 
                            className: "filter__picker__control filter__picker__control__with-icon__suffix",
                            wrapperClassName: "filter__picker",
                            dateFormat: "dd/MM/yyyy",
                            maxDate: tomorrow,
                            disabled: !this.state.is_date_to_enabled
                        }}
                        iconSuffix={images.datepicker}
                        iconSuffixClassName="filter__picker__icon__suffix"
                        value={data ? data.date_to : ""}
                        onChange={(e) => this.onChange("date_to", e, item)}
                    />  
                </div>
            </>
        )
    }

    renderOption = (item) => {
        
        const { data } = this.state;

        return (
            <>
                <p className="filter__item__label">{item.label}</p>
                <div className="filter__item__option">
                    {item.items.map((child, index) => (
                        <Input
                            type="checkbox"
                            wrapperClassName="filter__checkbox__wrapper"
                            className="filter__checkbox"
                            controlClassName="filter__checkbox__control"
                            onChange={(e) => this.onChange(child.key, e, item)}
                            value={data ? data[child.key] : ""}
                            label={child.label}
                            size="sm"
                            key={index}
                        /> 
                    ))}
                </div>
            </>
        )
    }

    onChange = (target, value, item=null) => {
        if(item !== null) {
            var output = {
                target: item.key,
                key: target,
                value: value
            }

            if(item.key !== "created_date") {
                var onlyNotSameData = [];
                if(value === true) {
                    onlyNotSameData = this.state.filters.filter((item) => item.key !== target);
                    this.setState({
                        current_data: {
                            ...this.state.data
                        },
                        current_filter: [
                            ...onlyNotSameData
                        ],
                        data: {
                            ...this.state.data,
                            [target]: value
                        },
                        filters: [
                            ...onlyNotSameData,
                            output
                        ]
                    })
                }
                else {
                    onlyNotSameData = this.state.filters.filter((item) => item.key !== target);
                    this.setState({
                        current_data: {
                            ...this.state.data
                        },
                        current_filter: [
                            ...onlyNotSameData
                        ],
                        data: {
                            ...this.state.data,
                            [target]: value
                        },
                        filters: [
                            ...onlyNotSameData
                        ]
                    })
                }
            }
            else {
                onlyNotSameData = this.state.filters.filter((item) => item.key !== target);
                this.setState({
                    current_data: {
                        ...this.state.data
                    },
                    current_filter: [
                        ...onlyNotSameData
                    ],
                    data: {
                        ...this.state.data,
                        [target]: value
                    },
                    filters: [
                        ...onlyNotSameData,
                        output
                    ]
                })
            }
        }
        else {
            this.setState({
                data: {
                    ...this.state.data,
                    [target]: value
                }
            }, () => {
                this.props.onSearch(value)
            })
        }

        if(target === "date_from") {
            this.setState({
                is_date_to_enabled: true
            })
        }
    }

    onFilter = () => {
        this.props.onApplyFilter(this.state.filters);
    }

    onReset = () => {
        this.setState({
            data: {},
            filters: []
        }, () => this.onFilter())
    }

    onCancel = () => {
        this.setState({
            data: this.state.data,
            filters: this.state.filters
        }, () => this.props.onCancel());
    }

    render() {
        
        const { data } = this.state;
        const { filterOptions } = this.props;

        return(
            <div className="filter">
                <Input
                    type="filter"
                    onChange={(e) => this.onChange("keyword", e.target.value)}
                    value={data ? data.keyword : ""}
                    iconPrefix={images.search}
                    iconSuffix={this.props.withFilter ? images.filter : ""}
                    clickableIcon={['suffix']}
                    clickableIconLabel="Filter"
                    placeholder="Search.."
                    onIconClick={(position) => {
                        if(position === "suffix") {   
                            this.props.onFilter()
                        }
                    }}
                    clickableIconClassName="button__filter"
                />

                <div className="filter__wrapper" style={this.props.isFilterVisible ? { display: 'block' } : { display: 'none' }}>
                    
                    {filterOptions && (
                        filterOptions.map((item, index) => {

                            return (
                                <div className="filter__item" key={index}>
                                    <>
                                        {item.type === "date" && (
                                            this.renderDatePicker(item)
                                        )}
                                        {item.type === "option" && (
                                            this.renderOption(item)
                                        )}
                                    </>
                                </div>
                            )
    
                        })
                    )}
                    
                    <div className="flex__space-between">
                        <Button
                            type="default"
                            size="sm"
                            buttonText="Clear Filter"
                            style={{ minWidth: 'auto', padding: 0, margin: 0, marginTop: 20, marginRight: 20, color: '#F14336' }}
                            onClick={() => this.onReset()}
                            className="button__filter__action"
                        />
                        <ButtonGroup 
                            position="right"
                        >
                            <Button
                                type="default"
                                size="sm"
                                buttonText="Cancel"
                                style={{ minWidth: 'auto', padding: 0, margin: 0, marginTop: 20, marginRight: 20, color: '#979797' }}
                                onClick={() => this.onCancel()}
                                className="button__filter__action"
                            />
                            <Button
                                type="default"
                                size="sm"
                                buttonText="Apply"
                                style={{ minWidth: 'auto', padding: 0, margin: 0, marginTop: 20, color: '#F18200' }}
                                onClick={() => this.onFilter()}
                                className="button__filter__action"
                            />
                        </ButtonGroup>
                    </div>
                </div>
            </div>
        )
    }

}

export default (Filter);