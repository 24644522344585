import { 
    Button,
    Input, 
    VerifyEmail, 
    VerifyMobileNumber 
} from 'components';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Images, Tools } from 'common';

var validation = {
    partner: {
        resubmit: {
            "account_type": "required",
            "name": "required",
            "email": "email",
            "mobile_prefix": "required",
            "mobile_number": "required"
        },
        normal: {
            "account_type": "required",
            "name": "required",
            "email": "email",
            "password": "password",
            "mobile_prefix": "required",
            "mobile_number": "required",
            "mobile_number_verified": "valid"
        }
    }
}

const accountType = [
    { value: "personal", label: "Personal" }, 
    { value: "company", label: "Company" }
];

class JoinPartnerStepOneContainer extends React.PureComponent {
    
    constructor(props) {
        super(props);
        this.state = {
            showPassword: false,
            selectedOption: [],
            send: false,
            valid: false,
            data: {
            },
            partner_data: {
            },
            errors: []
        }
    }

    componentDidMount() {
        const { 
            history 
        } = this.props
        
        var data = localStorage.getItem("@registrationData");
        if(
            Tools.getParamFromURL(history, "role") === "logistic_partner" || 
            this.props.isResubmit
        ) {
            this.setState({
                selectedOption: { value: "partner", label: "Saytum Service Provider" }
            })
        }
        else {
            this.setState({
                selectedOption: { value: "user", label: "Saytum User" }
            })
        }

        if(data) {
            data = JSON.parse(data);
            this.setState({ partner_data: {
                ...this.state.partner_data,
                ...data
            } })
        }
    }

    onChange = (target, value) => {
        this.setState({
            partner_data: {
                ...this.state.partner_data,
                [target]: value
            },
            send: false
        })
    }

    onNext = async() => {
        var valid = await this.validation();

        if(valid) {
            var data = this.state.partner_data;
            data = {
                ...data,
                accountType: this.state.selectedOption
            }
            localStorage.setItem("@registrationData", JSON.stringify(data));
            this.props.onNext()
        }
    }   

    validation = () => {
        const { selectedOption, data, partner_data } = this.state;
        
        var validate = {};
        if(selectedOption.value === "user") {
            validate = Tools.validation(validation.user, data);
        }
        else if(selectedOption.value === "partner") {
            const { mobile_number_verified, mobile_number_change, email_change, email_verified } = partner_data;

            if(this.props.isResubmit) {
                validate = Tools.validation(validation.partner.resubmit, partner_data);
            }
            else {
                validate = Tools.validation(validation.partner.normal, partner_data);
                
                if(this.props.type === "join-partner" || this.props.isResubmit) {
                    validate.valid = validate.valid && email_verified;

                    if(!validate) {
                        validate.errors.push("email_verified");
                    }
                }
            }

            if(mobile_number_change) {
                validate.valid = validate.valid && mobile_number_verified;
                if(!validate) {
                    validate.errors.push("mobile_number_verified");
                }
            }

            if(email_change) {
                validate.valid = validate.valid && email_verified;
                if(!validate) {
                    validate.errors.push("email_verified");
                }
            }
        }

        this.setState({
            valid: validate.valid,
            errors: validate.errors,
            send: true
        });

        return validate.valid;
    }

    render() {
        const { partner_data } = this.state;

        return(
            <>
                <div className="container">
                    <div className="container__auth">
                        <Row>
                            <Col>
                                <div className="container__auth__header">
                                    <h1 className="main-header-text">Join Partner</h1>
                                    <h4 className="secondary-header-text">Join to bidding for multiple jobs. Get your income increased and better.</h4>
                                </div>
                                
                                <div className="container__auth__body">
                                    
                                    <Input
                                        type="select"
                                        options={accountType}
                                        style={{ margin: 0, marginBottom: 25 }}
                                        label="Register Your Profile as"
                                        value={partner_data.account_type}
                                        onChange={(e) => this.onChange("account_type", e, "partner")}
                                        caption={!Tools.isNotEmpty(partner_data.account_type) && "This field is required"}
                                        isError={this.state.send && !this.state.valid && this.state.errors.includes("account_type")}
                                        isRequired={true}
                                    />
                                    <Input
                                        type="text"
                                        label="Name"
                                        iconPrefix={Images.user}
                                        placeholder="Enter your personal/company name"
                                        style={{ margin: 0, marginBottom: 25 }}
                                        value={partner_data.name}
                                        onChange={(e) => this.onChange("name", e.target.value, "partner")}
                                        caption={!Tools.isNotEmpty(partner_data.name) && "This field is required"}
                                        isError={this.state.send && !this.state.valid && this.state.errors.includes("name")}
                                        isRequired={true}
                                    />

                                    <VerifyEmail
                                        onVerified={(data) => {
                                            this.setState({
                                                send: false,
                                                partner_data: {
                                                    ...this.state.partner_data,
                                                    email: data.email,
                                                    email_verified: true
                                                }
                                            })
                                        }}
                                        isChanged={(param) => {
                                            this.setState({ 
                                                send: false,
                                                partner_data: {
                                                    ...this.state.partner_data,
                                                    email_change: param,
                                                    email_verified: !param ? true : false
                                                }
                                            })
                                        }}
                                        type={this.props.type}
                                        isVerified={this.state.partner_data.email_verified}
                                        isError={this.state.send && !this.state.valid && (this.state.errors.includes("email") || this.state.errors.includes("email_verified"))}
                                        inputProps={{
                                            readOnly: true
                                        }}
                                    />
                                    

                                    <VerifyMobileNumber
                                        onVerified={(data) => {
                                            this.setState({
                                                send: false,
                                                partner_data: {
                                                    ...this.state.partner_data,
                                                    mobile_prefix: data.mobile_prefix,
                                                    mobile_number: data.mobile_number,
                                                    mobile_number_verified: true
                                                }
                                            })
                                        }}
                                        isChanged={(param) => {
                                            this.setState({ 
                                                send: false,
                                                partner_data: {
                                                    ...this.state.partner_data,
                                                    mobile_number_change: param,
                                                    mobile_number_verified: !param ? true : false 
                                                }
                                            })
                                        }}
                                        isResubmit={true}
                                        onChange={(e) => this.onChange("mobile_number", e, "partner")}
                                        onSelect={(e) => this.onChange("mobile_prefix", e, "partner")}
                                        type={this.props.type}
                                        isVerified={this.state.partner_data.mobile_number_verified}
                                        isError={this.state.send && !this.state.valid && (this.state.errors.includes("mobile_number") || this.state.errors.includes("mobile_prefix") || this.state.errors.includes("mobile_number_verified"))}
                                        isEmailRequired={true}
                                        email={this.state.partner_data.email}
                                    />


                                    <Button
                                        size="lg"
                                        type="warning"
                                        buttonText={this.state.selectedOption.value === "user" ? "Register" : "Next"}
                                        variation="primary"
                                        style={{ margin: 0, marginTop: 20, marginBottom: 20 }}
                                        onClick={() => this.onNext()}
                                    />
                                </div>
                            </Col>
                            <Col className="banner-container">
                                <img src={Images.banner_login} alt="user-login.png" />
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    }

}

export default (JoinPartnerStepOneContainer);