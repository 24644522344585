import React from 'react';
import { connect } from 'react-redux';
import { 
    ProfileContainer, 
    UsersContainer,
    LogisticPartnerContainer,
    FleetCategoriesContainer,
    MaterialCategoriesContainer,
    OfficerContainer,
    DeliveryBoysContainer,
    ConsignmentsContainer,
    RefundsContainer,
    InvoiceContainer
} from 'container';
import { Tools } from 'common';

class DashboardAdminContainer extends React.PureComponent {
// test commit 

    state = {
        selectedOption: null
    }

    render() {
        const { user, activeMenu, history } = this.props;
        
        if(
            activeMenu === "user" || 
            Tools.getParamFromURL(history, "screen") === "user" || 
            Tools.getParamFromURL(history, "child") === "user"
        ) {
            return (
                <UsersContainer 
                    {...this.props} 
                />
            )
        }
        else if(
            activeMenu === "partner" || 
            Tools.getParamFromURL(history, "screen") === "partner" || 
            Tools.getParamFromURL(history, "child") === "partner"
        ) {
            return (
                <LogisticPartnerContainer 
                    {...this.props}
                />
            )
        }
        else if(activeMenu === "fleet_categories" || Tools.getParamFromURL(history, "screen") === "fleet_categories" || Tools.getParamFromURL(history, "child") === "fleet_categories") {
            return (
                <FleetCategoriesContainer 
                    {...this.props}
                />
            )
        }
        else if(activeMenu === "material_categories" || Tools.getParamFromURL(history, "screen") === "material_categories" || Tools.getParamFromURL(history, "child") === "material_categories") {
            return (
                <MaterialCategoriesContainer
                    {...this.props}
                />
            )
        }
        else if(activeMenu === "back_officer" || Tools.getParamFromURL(history, "screen") === "back_officer" || Tools.getParamFromURL(history, "child") === "back_officer") {
            if(user.role === "admin") {
                return (
                    <OfficerContainer 
                        {...this.props}
                    />
                )
            }
            else {
                return <ProfileContainer {...this.props} />
            }
        }
        else if(activeMenu === "delivery_boy" || Tools.getParamFromURL(history, "screen") === "delivery_boy" || Tools.getParamFromURL(history, "child") === "delivery_boy") {
            return (
                <DeliveryBoysContainer 
                    {...this.props} 
                />
            )
        }
        else if(activeMenu === "consignment" || Tools.getParamFromURL(history, "screen") === "consignment" || Tools.getParamFromURL(history, "child") === "consignment") {
            return (
                <ConsignmentsContainer 
                    {...this.props}
                />
            )
        }
        else if(activeMenu === "refund" || Tools.getParamFromURL(history, "screen") === "refund" || Tools.getParamFromURL(history, "child") === "refund") {
            return (
                <RefundsContainer 
                    {...this.props}
                />
            )
        }
        else if(activeMenu === "profile" || Tools.getParamFromURL(history, "screen") === "profile" || Tools.getParamFromURL(history, "child") === "profile") {
            return <ProfileContainer {...this.props} />
        }
        else if(activeMenu === "invoice" || Tools.getParamFromURL(history, "screen") === "invoice" || Tools.getParamFromURL(history, "child") === "invoice") {
            return <InvoiceContainer {...this.props} />
        }
        else {
            return <ProfileContainer {...this.props} />
        }
    }

}

const mapsStateToProps = ({ authenticated, navigation }) => {
    return {
        user: authenticated.userInfo,
        activeMenu: navigation.activeMenu
    }
}

export default connect(mapsStateToProps)(DashboardAdminContainer);