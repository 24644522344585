import React from 'react';
// import { HomeContainer } from 'container';
import HomeContainer from 'container/Home';

class Home extends React.PureComponent {
    
    render() {
        return(
            <HomeContainer
                {...this.props}
            />
        )
    }

}


export default (Home);