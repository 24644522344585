import { MaterialCategoriesAPI } from "services"

const types = {
    FETCH_MATERIAL_CATEGORIES_SUCCESS: "FETCH_MATERIAL_CATEGORIES_SUCCESS",
    FETCH_MATERIAL_CATEGORIES_FAILURE: "FETCH_MATERIAL_CATEGORIES_FAILURE",
    CLEAR_MATERIAL_CATEGORIES: "CLEAR_MATERIAL_CATEGORIES",
    DELETE_MATERIAL_CATEGORIES_SUCCESS: "DELETE_MATERIAL_CATEGORIES_SUCCESS",
    DELETE_MATERIAL_CATEGORIES_FAILURE: "DELETE_MATERIAL_CATEGORIES_FAILURE",
    CREATE_MATERIAL_CATEGORIES_SUCCESS: "CREATE_MATERIAL_CATEGORIES_SUCCESS",
    CREATE_MATERIAL_CATEGORIES_FAILURE: "CREATE_MATERIAL_CATEGORIES_FAILURE",
    UPDATE_MATERIAL_CATEGORIES_SUCCESS: "UPDATE_MATERIAL_CATEGORIES_SUCCESS",
    UPDATE_MATERIAL_CATEGORIES_FAILURE: "UPDATE_MATERIAL_CATEGORIES_FAILURE"
}

export const actions = {
    fetchMaterialCategories: async(
        dispatch,
        token
    ) => {
        var json = await MaterialCategoriesAPI.fetchCategories(token)
        if(json.status_code === 500) {
            dispatch(actions.fetchMaterialCategoriesFailure(json.message))
        }
        else {
            dispatch(actions.fetchMaterialCategoriesSuccess(json))
        }
    },
    fetchMaterialCategoriesFailure: (error) => ({
        type: types.FETCH_MATERIAL_CATEGORIES_FAILURE,
        error
    }),
    fetchMaterialCategoriesSuccess: (items) => ({
        type: types.FETCH_MATERIAL_CATEGORIES_SUCCESS,
        items
    }),
    deleteMaterialCategories: async(
        dispatch,
        token,
        id
    ) => {
        var json = await MaterialCategoriesAPI.deleteCategories(token, id)
        if(json.status_code === 500) {
            dispatch(actions.deleteMaterialCategoriesFailure(json.message))
        }
        else {
            dispatch(actions.deleteMaterialCategoriesSuccess(json))
        }
        return json
    },
    deleteMaterialCategoriesFailure: (error) => ({
        type: types.DELETE_MATERIAL_CATEGORIES_FAILURE,
        error
    }),
    deleteMaterialCategoriesSuccess: (items) => ({
        type: types.DELETE_MATERIAL_CATEGORIES_SUCCESS,
        items
    }),
    createMaterialCategories: async(
        dispatch,
        token,
        body
    ) => {
        var json = await MaterialCategoriesAPI.createMaterialCategories(token, body)
        if(json.status_code === 500) {
            dispatch(actions.createMaterialCategoriesFailure(json.message))
        }
        else {
            dispatch(actions.createMaterialCategoriesSuccess(json))
        }
        return json
    },
    createMaterialCategoriesFailure: (error) => ({
        type: types.CREATE_MATERIAL_CATEGORIES_FAILURE,
        error
    }),
    createMaterialCategoriesSuccess: (items) => ({
        type: types.CREATE_MATERIAL_CATEGORIES_SUCCESS,
        items
    }),
    updateMaterialCategories: async(
        dispatch,
        token,
        category_id,
        body
    ) => {
        var json = await MaterialCategoriesAPI.updateMaterialCategory(token, category_id, body)
        if(json.status_code === 500) {
            dispatch(actions.updateMaterialCategoriesFailure(json.message))
        }
        else {
            dispatch(actions.updateMaterialCategoriesSuccess(json))
        }
        return json
    },
    updateMaterialCategoriesFailure: (error) => ({
        type: types.UPDATE_MATERIAL_CATEGORIES_FAILURE,
        error
    }),
    updateMaterialCategoriesSuccess: (items) => ({
        type: types.UPDATE_MATERIAL_CATEGORIES_SUCCESS,
        items
    }),
}

const initialState = {
    lists: [],
    total: 0,
    error: null
}

export const reducer = (state = initialState, action) => {

    const { items, error } = action;

    var data = []
    var selectedMaterial = null

    switch (action.type) {
        case types.FETCH_MATERIAL_CATEGORIES_FAILURE: {
            return Object.assign({}, state, {
                error: error
            })
        }
        case types.FETCH_MATERIAL_CATEGORIES_SUCCESS: {
            return Object.assign({}, state, {
                error: null,
                lists: items.data.data,
                total: items.data.data.length
            })
        }
        case types.CLEAR_MATERIAL_CATEGORIES: {
            return Object.assign({}, state, {
                error: null,
                lists: [],
                total: 0
            })
        }
        case types.DELETE_MATERIAL_CATEGORIES_FAILURE: {
            return Object.assign({}, state, {
                error: error
            })
        }
        case types.DELETE_MATERIAL_CATEGORIES_SUCCESS: {
            selectedMaterial = state.lists.find((item) => parseFloat(item.id) === parseFloat(items.data.data))
            data = []
            state.lists.map((item) => {
                if(parseFloat(item.id) !== parseFloat(selectedMaterial.id)) {
                    data.push(item)
                }
                return true
            })
            data.sort((a, b) => (a.id > b.id) ? 1 : -1);

            return Object.assign({}, state, {
                error: null,
                lists: data,
                total: data.length
            })
        }
        case types.CREATE_MATERIAL_CATEGORIES_FAILURE: {
            return Object.assign({}, state, {
                error: error
            })
        }
        case types.CREATE_MATERIAL_CATEGORIES_SUCCESS: {
            return Object.assign({}, state, {
                error: null,
                lists: items.data.data,
                total: items.data.data.length
            })
        }
        case types.UPDATE_MATERIAL_CATEGORIES_FAILURE: {
            return Object.assign({}, state, {
                error: error
            })
        }
        case types.UPDATE_MATERIAL_CATEGORIES_SUCCESS: {
            return Object.assign({}, state, {
                error: null,
                lists: items.data.data,
                total: items.data.data.length
            })
        }
        default:
            return state;
    }
}