import React from 'react';
import { Accordion, Button } from 'react-bootstrap'
import "./sidenav.scss";
import "./accordion.scss";
import { withRouter } from 'react-router-dom';
class Sidenav extends React.PureComponent {

    constructor(props) {
        super(props);

        props.menu.map((item) => {
            this[`accordion${item.id}`] = React.createRef();
            return true;
        });
    }

    componentDidMount() {
        const { menu } = this.props;
        var selectedMenu = menu.filter((item) => item.items !== undefined);
        selectedMenu.map((item) => {
            this.closeCollapse(this[`accordion${item.id}`]);
            return true;
        })
    }

    state = {
        activeKey: null
    }

    handleClick = (ref, selectedMenu) => {
        const { menu, onChange } = this.props;
        const { innerWidth } = window;

        menu.map((item) => {
            if(item.id.toString() !== selectedMenu.id.toString()) {
                this[`accordion${item.id}`].current.classList.remove("active");
            }
            return true;
        })

        var currentClasses = [...ref.current.classList]; 
        if(currentClasses.includes("active")) {
            if(innerWidth > 768) {
                ref.current.classList.remove("active")
            }
        }
        else {
            ref.current.classList.add("active");
        }
        
        if(!selectedMenu.items || selectedMenu.items.length === 0) {
            onChange(selectedMenu)
        }
    }

    closeCollapse = (ref) => {
        const { innerWidth } = window;
        if(innerWidth < 768) {
            var classList = ref.current.classList;
            classList = classList.value;
            if(classList.includes("active")) {
                ref.current.click();
            }
        }
    }

    render() {

        const { menu, onChange } = this.props;
        
        var params = this.props.history.location.search;
        var search = new URLSearchParams(params);

        return(
            <div className="sidenav">
                
                <Accordion 
                    className="accordion" 
                    defaultActiveKey={search.get("screen")} 
                    ref={(ref) => this.tryRef = ref}
                >
                
                {menu.map((item, index) => {

                    var isActive = false;
                    if(item.key === search.get("screen")) {
                        isActive = true;
                    }
                        
                    return (
                            <div className={`accordion__container`} key={index}>
                                <Accordion.Toggle 
                                    as={Button} 
                                    eventKey={item.key} 
                                    className={`accordion__header ${isActive ? "active" : ""}`} 
                                    onClick={() => this.handleClick(this[`accordion${item.id}`], item)}
                                    ref={this[`accordion${item.id}`]}
                                >
                                    <div className="accordion__header__label">
                                        <img alt="icon" src={isActive ? item.icon_active : item.icon} className="sidenav__container__menu__icon" />
                                        <p className="sidenav__container__menu__title">{item.title}</p>
                                    </div>

                                    {item.items && item.items.length > 0 && (
                                        <div alt="icon" className="arrow"></div>
                                    )}

                                </Accordion.Toggle>
                                
                                    {item.items && item.items.length > 0 && (
                                        <div className="accordion__collapse__wrapper">
                                            {item.items.map((child, child_index) => {
                                                var childActive = false;
                                                if(child.key === search.get("child")) {
                                                    childActive = true;
                                                }

                                                return (
                                                    <Accordion.Collapse 
                                                        key={child_index} 
                                                        eventKey={item.key} 
                                                        className={`accordion__collapse ${child_index === 0 ? "accordion__collapse__first" : ""}`} 
                                                        onClick={() => this.closeCollapse(this[`accordion${item.id}`])}
                                                    >
                                                        <button className={`sidenav__container__menu ${childActive ? `active` : ""}`} onClick={() => onChange(item, child)}>
                                                            <p className="sidenav__container__menu__title">{child.title}</p>
                                                        </button>
                                                    </Accordion.Collapse>
                                                )
                                            })}
                                        </div>
                                    )}

                            </div>
                    )

                })}

                </Accordion>
            </div>
        )
    }

}

export default (withRouter(withRouter(Sidenav)));
