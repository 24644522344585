import React from "react";
import "./Tracking.scss";
import track from "./track.module.scss"
import { connect } from "react-redux";
import moment from "moment";
import { navigate } from "common";

class navTracking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: "profile", // default selected tab
      data: {},
      trackhistory: {},
      consignment: {},
      consignmentDetails: {},
    };
  }

  async componentDidMount() {
    await this.initializeComponent();
  }

  componentDidUpdate(prevProps, prevState) {
    const oldId = new URLSearchParams(prevProps.location.search).get("id");
    const newId = new URLSearchParams(this.props.location.search).get("id");
    // console.log("old ids", oldId, "new", newId);
    if (oldId !== newId) {
      this.initializeComponent();
    }
  }

  initializeComponent = async () => {
    var anonymousData = localStorage.getItem("@anonymousData");

    if (anonymousData) {
      anonymousData = JSON.parse(anonymousData);
      // console.log("track-data", anonymousData)
      const token = anonymousData.user.stsTokenManager.accessToken;
      this.setState({
        data: {
          ...anonymousData,
          token: token,
        },
      });
      this.fetchData(token);
      this.fiteredDetails();
    }
  };


  fetchData = async (token) => {
    const {
      clearTrackingHistories,
      fetchTrackingHistories,
      toastPending,
      consignments,
    } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    await clearTrackingHistories();
    const trackhistory = await fetchTrackingHistories(token, id);
    if (trackhistory.status_code === 200) {
      if (trackhistory && Object.keys(trackhistory.consignment).length > 0) {
        this.setState({
          trackhistory,
          consignment: trackhistory.consignment,
        });
      } else {
        toastPending({ message: "Wrong Consignment Number", toastType: 'error' });
      }
    } else {
      toastPending({ message: trackhistory.message, toastType: trackhistory.status_code });
    }
    // console.log("tracking", trackhistory);
  };

  fiteredDetails = () => {
    const Id = new URLSearchParams(window.location.search).get("id");
    const filteredData = this.props.consignments.filter((consignment) =>
      consignment.id.toString() === Id
    );
    // console.log("filtered", filteredData);
    if (filteredData.length > 0) {
      this.setState({
        consignmentDetails: filteredData,
      });
    }
  }

  handleTabClick = (tab) => {
    this.setState({
      selectedTab: tab,
    });
  };

  render() {
    const { selectedTab, consignment, consignmentDetails } = this.state;
    const { trackings } = this.props;
    // const status = trackings.length > 0 ? trackings[0].status : "No tracking data";
    const status =
      consignmentDetails.length > 0
        ? consignmentDetails[0].status
        : "No tracking data";
    // console.log("consignmentDetails", consignmentDetails);
    // console.log("status", status);
    // console.log("consignment", consignment);
    // console.log("====>>", trackings);
    return (
      <div className="tracking">
        {/* <div className="container px-1 px-md-4 py-5 mx-auto"> */}
        <div clasName={track.card} style={{ paddingTop: 50 }}>
          <div className="row d-flex justify-content-between px-3 top mb-4">
            {/* <div className="d-flex">
              <h5>
                ORDER{" "}
                <span clasNames="text-primary font-weight-bold">#Y34XDHR</span>
              </h5>
            </div> */}
            {/* <div className="d-flex flex-column text-sm-right">
              <p class="mb-0">
                Expected Arrival <span>01/12/19</span>
              </p>
            </div> */}
          </div>
          {/* <!-- Add class 'active' to progress --> */}
          <div className=" row ticks d-flex justify-content-center">
            <div className="col-12">
              <ul id="progressbar" class="text-center">
                <li className={`step0 ${status >= 141 ? "active" : ""}`}></li>
                <li className={`step0 ${status >= 142 ? "active" : ""}`}></li>
                <li className={`step0 ${status >= 143 ? "active" : ""}`}></li>
                <li className={`step0 ${status === 145 ? "active" : ""}`}></li>
              </ul>
            </div>
          </div>
          <div className="row ticks d-flex justify-content-between top">
            <div className="col d-flex icon-content">
              <div className="row">
                <img className="icon" src="https://i.imgur.com/9nnc9Et.png" />
                <div className="d-flex flex-column">
                  <p className="font-weight-bold">
                    Order
                    <br />
                    Scheduled
                  </p>
                </div>
              </div>
            </div>
            <div className="col d-flex icon-content">
              <div className="row">
                <img className="icon" src="https://i.imgur.com/u1AzR7w.png" />
                <div className="d-flex flex-column">
                  <p className="font-weight-bold">
                    Shipment
                    <br />
                    Assigned
                  </p>
                </div>
              </div>
            </div>
            <div className="col d-flex icon-content">
              <div className="row">
                <img className="icon" src="https://i.imgur.com/TkPm63y.png" />
                <div className="d-flex flex-column">
                  <p className="font-weight-bold">
                    Shipment
                    <br />
                    In Transit
                  </p>
                </div>
              </div>
            </div>
            <div className="col d-flex icon-content">
              <div className="row">
                <img className="icon" src="https://i.imgur.com/HdsziHP.png" />
                <div className="d-flex flex-column">
                  <p className="font-weight-bold">
                    Shipment
                    <br />
                    Delivered
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}

        <div className="details">
          <h3 className="details__header">Tracking Details</h3>

          <div className={`${track.card} text-center`}>
            <ul
              className="nav nav-tabs card-header-tabs mt-50 "
              id="myTab"
              role="tablist"
            >
              <li className={`nav-item `}>
                <a
                  className={`nav-link ${
                    selectedTab === "home" ? "active show" : ""
                  }`}
                  id="home-tab"
                  data-toggle="tab"
                  href="#home"
                  role="tab"
                  aria-controls="home"
                  aria-selected="false"
                  onClick={() => this.handleTabClick("home")}
                >
                  Shipment Details
                </a>
              </li>
              <li className={`nav-item `}>
                <a
                  className={`nav-link ${
                    selectedTab === "profile" ? "active show" : ""
                  }`}
                  id="profile-tab"
                  data-toggle="tab"
                  href="#profile"
                  role="tab"
                  aria-controls="profile"
                  // aria-selected="true"
                  onClick={() => this.handleTabClick("profile")}
                >
                  Status and Scan
                </a>
              </li>
            </ul>

            <div className="tab-content card-body" id="myTabContent">
              <div
                className={`tab-pane fade in ${
                  selectedTab === "home" ? "active show" : ""
                }`}
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <table className="shipingtable card-text table table-bordered">
                  <tbody>
                    {Object.keys(consignment).length > 0 ? (
                      <>
                        <tr>
                          <td className="bg-success-track">Consignment No</td>
                          <td className="">{consignment.id}</td>
                        </tr>
                        <tr>
                          <td className="bg-success-track">Pickup Date</td>
                          <td className="bg-success-track">
                            {moment(consignment.pickup_date).format(
                              "DD MMM YYYY"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td className="bg-success-track">Consignee Name</td>
                          <td className="bg-success-track">
                            {consignment.delivery_name}
                          </td>
                        </tr>
                        <tr>
                          <td className="bg-success-track">Pickup City</td>
                          <td>{consignment.pickup_cities}</td>
                        </tr>
                        <tr>
                          <td className="bg-success-track">Delivery City</td>
                          <td className="bg-success-track">
                            {consignment.delivery_cities}
                          </td>
                        </tr>
                        <tr>
                          <td className="bg-success-track">Material Detail</td>
                          <td>{consignment.material_detail}</td>
                        </tr>
                      </>
                    ) : (
                      <tr>
                        <td
                          className="bg-success-track"
                          colSpan="3"
                          style={{ textAlign: "center" }}
                        >
                          No Shipments details available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div
                className={`tab-pane fade in ${
                  selectedTab === "profile" ? "active show" : ""
                }`}
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <table className="shipingtable table table-bordered">
                  <thead className="card-title">
                    <tr className="table-secondary">
                      <td className="bg-success-track">
                        <span>Date</span>
                      </td>
                      <td className="bg-success-track">
                        <span>Activity</span>
                      </td>
                      <td className="bg-success-track">
                        <span>Tracking Details</span>
                      </td>
                    </tr>
                  </thead>
                  <tbody className="card-text">
                    {trackings.length > 0 ? (
                      trackings.map(function (item, index) {
                        return (
                          <tr key={index}>
                            <td>
                              {moment(item.tracking_event_time).format(
                                "DD MMM YYYY"
                              )}
                            </td>
                            <td>{item.remarks} </td>
                            <td>{item.location}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="3" style={{ textAlign: "center" }}>
                          No Tracking data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapsStatetoProps = ({ tracking, consignment }) => {
  return {
    trackings: tracking.histories,
    consignments: consignment.lists,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/TrackingRedux");

  return {
    ...ownProps,
    ...stateProps,
    clearTrackingHistories: () =>
      dispatch({ type: "CLEAR_TRACKING_HISTORIES" }),
    fetchTrackingHistories: async (token, consignment_id) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await actions.fetchTrackingHistories(
        dispatch,
        token,
        consignment_id
      );
      dispatch({ type: "FETCH_FINISH" });
      // console.log("=>> GOEL", json);
      return json;
    },
    toastPending: (body) => {
      dispatch({ type: "TOAST_PENDING", ...body });
    },
  };
};
export default connect(mapsStatetoProps, undefined, mergeProps)(navTracking);
