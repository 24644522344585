import { Constant } from "common";
import { FirebaseAPI } from "./FirebaseAPI";

export const DeliveryBoysAPI = {

    fetchDeliveryBoy(token) {
        try {
            return fetch(`${Constant.API_URL}/delivery-boy`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(async(response) => {
                if(response.ok) {
                    return response.json()
                }
                var error_message = await response.json().then((json) => {
                    if(typeof(json.message) === "object") {
                        for (const index in json.message) {
                            return json.message[index];
                        }
                    }
                    else {
                        return json; 
                    }
                })
                throw new Error(JSON.stringify(error_message));
            })
            .then((json) => {
                return { status_code: 200, ...json };
            }).catch((error) => {
                return { status_code: 500, ...error };
            });
        }
        catch(error) {
            return { status_code: 500, ...error };
        }
    },

    fetchPartnersDeliveryBoy(token, partner_id) {
        try {
            return fetch(`${Constant.API_URL}/delivery-boy/${partner_id}`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(async(response) => {
                if(response.ok) {
                    return response.json()
                }
                var error_message = await response.json().then((json) => {
                    if(typeof(json.message) === "object") {
                        for (const index in json.message) {
                            return json.message[index];
                        }
                    }
                    else {
                        return json; 
                    }
                })
                throw new Error(JSON.stringify(error_message));
            })
            .then((json) => {
                return { status_code: 200, ...json };
            }).catch((error) => {
                return { status_code: 500, ...error };
            });
        }
        catch(error) {
            return { status_code: 500, ...error };
        }
    },

    async createDeliveryBoy(token, body) {
        try {
            var registerFirebase = await this.registerFirebase(body);
            if(registerFirebase.status === 200) {
                body = {
                    ...body,
                    uuid: registerFirebase.user.uid
                }
            }
            else if(registerFirebase.status === 401) {
                var getUser = await FirebaseAPI.callFunctions("getUser", { phone: body.phone });
                body= {
                    ...body,
                    uuid: getUser.user.uid
                }
            }

            return fetch(`${Constant.API_URL}/user/register?type=user&role=delivery_boy`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(body)
            }).then(async(response) => {
                const res = await response.json();
                if(response.ok) {
                    return res;
                }
                var error_message = await response.json().then((json) => {
                    if(typeof(json.message) === "object") {
                        for (const index in json.message) {
                            return json.message[index];
                        }
                    }
                    else {
                        return json; 
                    }
                })
                throw new Error(JSON.stringify(error_message))
            })
            .then((json) => {
                return { status_code: 200, ...json };
            }).catch((error) => {
                const data = error
                if(Object.entries(data).length > 0)
                {
                    return { status_code: 500, ...error };
                }
                else
                {
                    return { status_code: 500, message: "The phone number is already in use by another account." };
                }
            });
        }
        catch(error) {
            const data = error
                if(Object.entries(data).length > 0)
                {
                    return { status_code: 500, ...error };
                }
                else
                {
                    return { status_code: 500, message: "The phone number is already in use by another account." };
                }
        }
    },

    async registerFirebase(body) {
        var results = null;
        var check_params = { phone: body.phone }
        var check_phone = await FirebaseAPI.callFunctions("isRegisteredAccount", check_params);
        if(check_phone.status_code === 200) {
            results = {
                status: 200,
                user: check_phone.user
            }
        }
        else {
            results = {
                status: check_phone.status_code
            }
        }
    
        return results;
    },

    toggleActivate(token, body) {
        try {
            return fetch(`${Constant.API_URL}/user/activate`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(body)
            }).then(async(response) => {
                if(response.ok) {
                    return response.json();
                }
                var error_message = await response.json().then((json) => {
                    if(typeof(json.message) === "object") {
                        for (const index in json.message) {
                            return json.message[index];
                        }
                    }
                    else {
                        return json; 
                    }
                })
                throw new Error(JSON.stringify(error_message))
            })
            .then((json) => {
                return { status_code: 200, ...json };
            }).catch((error) => {
                return { status_code: 500, ...error };
            });
        }
        catch(error) {
            return { status_code: 500, ...error };
        }
    },

    deleteDeliveryBoy: (token, id) => {
        try {
            return fetch(`${Constant.API_URL}/user/${id}?role=delivery_boy`, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }).then(async(response) => {
                if(response.ok) {
                    return response.json();
                }
                var error_message = await response.json().then((json) => {
                    if(typeof(json.message) === "object") {
                        for (const index in json.message) {
                            return json.message[index];
                        }
                    }
                    else {
                        return json; 
                    }
                })
                throw new Error(JSON.stringify(error_message))
            })
            .then((json) => {
                return { status_code: 200, ...json };
            }).catch((error) => {
                return { status_code: 500, ...error };
            });
        }
        catch(error) {
            return { status_code: 500, ...error };
        }
    },

}