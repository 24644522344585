import React from 'react';
import { connect } from 'react-redux';
import { 
    AuctionsContainer,
    DeliveryBoysContainer, 
    FleetsContainer, 
    ProfileContainer, 
    ShipmentsContainer
} from 'container';
import { Tools } from 'common';

class DashboardPartnerContainer extends React.PureComponent {

    render() {

        var { activeMenu, history } = this.props;

        if((activeMenu === "fleet" || Tools.getParamFromURL(history, "screen") === "fleet" || Tools.getParamFromURL(history, "child") === "fleet") && Tools.isProfileCompleted()) {
            return (
                <FleetsContainer 
                    {...this.props}
                />
            )
        }
        else if((activeMenu === "worker" || Tools.getParamFromURL(history, "screen") === "worker" || Tools.getParamFromURL(history, "child") === "worker") && Tools.isProfileCompleted()) {
            return(
                <DeliveryBoysContainer 
                    {...this.props} 
                />
            )
        }
        else if((activeMenu === "auction" || Tools.getParamFromURL(history, "screen") === "auction" || Tools.getParamFromURL(history, "child") === "auction") && Tools.isProfileCompleted()) {
            return(
                <AuctionsContainer 
                    {...this.props}
                />
            )
        }
        else if(activeMenu === "profile" || Tools.getParamFromURL(history, "screen") === "profile" || Tools.getParamFromURL(history, "child") === "profile") {
            return(
                <ProfileContainer 
                    {...this.props}
                />
            )
        }
        else if(activeMenu === "activity" || Tools.getParamFromURL(history, "screen") === "activity" || Tools.getParamFromURL(history, "child") === "activity") {
            return (
                <ShipmentsContainer 
                    {...this.props} 
                />
            )
        }
        else {
            return(
                <ProfileContainer 
                    {...this.props}
                />
            )
        }
    }

}


const mapsStateToProps = ({ authenticated, navigation }) => {
    return{
        token: authenticated.token,
        activeMenu: navigation.activeMenu
    } 
}

export default connect(mapsStateToProps)(DashboardPartnerContainer);