import Images from './Images';

const Constant = {
    
    API_URL: process.env.REACT_APP_API_URL,
    GOOGLE_PLACE_API_KEY: process.env.REACT_APP_GOOGLE_PLACE_API_KEY,
    GOOGLE_OAUTH_KEY: process.env.REACT_APP_GOOGLE_OAUTH_KEY,
    FACEBOOK_APP_ID: process.env.REACT_APP_FACEBOOK_APP_ID,
    GA_ID: process.env.REACT_APP_GA_MEASURE_ID,
    GST:process.env.REACT_APP_GST_PRICE,
    FIREBASE: {
        apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
        authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_FIREBASE_APP_ID,
        measurementId: process.env.REACT_APP_MEASUREMENT_ID,
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
        region: process.env.REACT_APP_FIREBASE_REGION
    },
    DASHBORD_MENU: {
        USER: [
            { id: "0", title: "my profile", icon_active: Images.dashboard_active, icon: Images.dashboard, link: "#profile", key: "profile" },
            { id: "1", title: "consignment", icon_active: Images.cubic_primary, icon: Images.cubic, link: "#consignment", key: "consignment" },
            { id: "2", title: "auction", icon_active: Images.auction_active, icon: Images.auction, link: "#auction", key: "auction" },
            { id: "3", title: "tracking", icon_active: Images.calendar_active, icon: Images.calendar, link: "#activity", key: "activity" },
            { id: "4", title: "invoice", icon_active: Images.calendar_active, icon: Images.calendar, link: "#invoice", key: "invoice" }
        ],
        PARTNER: [
            { id: "1", title: "my profile", icon_active: Images.dashboard_active, icon: Images.dashboard, link: "#profile", key: "profile" },
            { id: "3", title: "my worker", icon_active: Images.cubic_primary, icon: Images.cubic, link: "#worker", key: "worker" },
            { id: "4", title: "my fleet", icon_active: Images.cubic_primary, icon: Images.cubic, link: "#fleet", key: "fleet" },
            { id: "2", title: "auction", icon_active: Images.cubic_primary, icon: Images.cubic, link: "#auction", key: "auction" },
            { id: "5", title: "tracking", icon_active: Images.calendar_active, icon: Images.calendar, link: "#activity", key: "activity" }
        ],
        ADMIN: [
            { id: "0", title: "my profile", icon_active: Images.dashboard_active, icon: Images.dashboard, link: "#profile", key: "profile" },
            { id: "1", title: "master data", icon_active: Images.cubic_primary, icon: Images.cubic, link: "#master_data", key: "master_data", items: [
                { id: "1.1", title: "user", icon_active: Images.cubic_primary, icon: Images.cubic, link: "#user", key: "user", parent: "master_data" },
                { id: "1.2", title: "logistic partner", icon_active: Images.cubic_primary, icon: Images.cubic, link: "#logistic_partner", key: "partner", parent: "master_data" },
                { id: "1.3", title: "fleet categories", icon_active: Images.cubic_primary, icon: Images.cubic, link: "#fleet_categories", key: "fleet_categories", parent: "master_data" },
                { id: "1.4", title: "material categories", icon_active: Images.cubic_primary, icon: Images.cubic, link: "#material_categories", key: "material_categories", parent: "master_data" },
                { id: "1.5", title: "back officer", icon_active: Images.cubic_primary, icon: Images.cubic, link: "#back_officer", key: "back_officer", parent: "master_data" },
                { id: "1.6", title: "delivery boy", icon_active: Images.cubic_primary, icon: Images.cubic, link: "#delivery_boy", key: "delivery_boy", parent: "master_data" }
            ] },
            { id: "2", title: "consignment", icon_active: Images.cubic_primary, icon: Images.cubic, link: "#consignment", key: "consignment" },
            { id: "3", title: "refund", icon_active: Images.cubic_primary, icon: Images.cubic, link: "#refund", key: "refund" },
            { id: "4", title: "invoice", icon_active: Images.calendar_active, icon: Images.calendar, link: "#invoice", key: "invoice" }
        ],
        WORKER: [
            { id: "0", title: "my profile", icon_active: Images.dashboard_active, icon: Images.dashboard, link: "#profile", key: "profile" },
            { id: "1", title: "my task", icon_active: Images.calendar_active, icon: Images.calendar, link: "#task", key: "task" }
        ],
        OFFICER: [
            { id: "0", title: "my profile", icon_active: Images.dashboard_active, icon: Images.dashboard, link: "#profile", key: "profile" },
            { id: "1", title: "master data", icon_active: Images.cubic_primary, icon: Images.cubic, link: "#master_data", key: "master_data", items: [
                { id: "1.1", title: "user", icon_active: Images.cubic_primary, icon: Images.cubic, link: "#user", key: "user", parent: "master_data" },
                { id: "1.2", title: "logistic partner", icon_active: Images.cubic_primary, icon: Images.cubic, link: "#logistic_partner", key: "partner", parent: "master_data" },
                { id: "1.3", title: "fleet categories", icon_active: Images.cubic_primary, icon: Images.cubic, link: "#fleet_categories", key: "fleet_categories", parent: "master_data" },
                { id: "1.4", title: "material categories", icon_active: Images.cubic_primary, icon: Images.cubic, link: "#material_categories", key: "material_categories", parent: "master_data" },
                { id: "1.5", title: "delivery boy", icon_active: Images.cubic_primary, icon: Images.cubic, link: "#delivery_boy", key: "delivery_boy", parent: "master_data" }
            ] },
            { id: "2", title: "consignment", icon_active: Images.cubic_primary, icon: Images.cubic, link: "#consignment", key: "consignment" }
        ]
    },

    COUNTRY_CODE: {
        options: [
            { 
                label: "+91", 
                value: "+91", 
                mask: /^((?!(0))[0-9]{0,10})$/,
                length: 10
            },
            // { 
            //     label: "+65", 
            //     value: "+65",
            //     mask: /^((?!(0))[0-9]{0,8})$/,
            //     length: 8
            // }
        ],
        default: {
            label: "+91", 
            value: "+91", 
            mask: /^((?!(0))[0-9]{0,10})$/,
            length: 10
        }
    },

    Postal_Code: {
        postalCodeLength: "6",
    },
  
    DEFAULT_GOOGLE_PLACE_COUNTRIES_CODE: [
        // "IN"
        "ID"
    ],
    NAVBAR_MENU: [
        { title: "Home", link: "/#home" },
        { title: "About", link: "/#aboutus" },
        { title: "Mission", link: "/#mission" },
        // { title: "Contact", link: "/#contact" },
        // { title: "Download App", link: "/#download" }
    ]
}

export default (Constant);