import { DeliveryBoysAPI } from "services"

const types = {
    FETCH_DELIVERY_BOY_SUCCESS: "FETCH_DELIVERY_BOY_SUCCESS",
    FETCH_DELIVERY_BOY_FAILURE: "FETCH_DELIVERY_BOY_FAILURE",
    CLEAR_DELIVERY_BOY: "CLEAR_DELIVERY_BOY",
    CREATE_DELIVERY_BOY_SUCCESS: "CREATE_DELIVERY_BOY_SUCCESS",
    CREATE_DELIVERY_BOY_FAILURE: "CREATE_DELIVERY_BOY_FAILURE",
    TOGGLE_ACTIVATE_FAILURE: "TOGGLE_ACTIVATE_FAILURE",
    TOGGLE_ACTIVATE_SUCCESS: "TOGGLE_ACTIVATE_SUCCESS",
    DELETE_SUCCESS: "DELETE_SUCCESS",
    DELETE_FAILURE: "DELETE_FAILURE"
}

export const actions = {
    fetchDeliveryBoy: async(
        dispatch,
        token
    ) => {
        var json = await DeliveryBoysAPI.fetchDeliveryBoy(token)
        if(json.status_code === 500) {
            dispatch(actions.fetchDeliveryBoyFailure(json.message))
        }
        else {
            dispatch(actions.fetchDeliveryBoySuccess(json))
        }
        return json
    },
    fetchPartnersDeliveryBoy: async(
        dispatch,
        token,
        partner_id
    ) => {
        var json = await DeliveryBoysAPI.fetchPartnersDeliveryBoy(token, partner_id)
        if(json.status_code === 500) {
            dispatch(actions.fetchDeliveryBoyFailure(json.message))
        }
        else {
            dispatch(actions.fetchDeliveryBoySuccess(json))
        }
        return json
    },
    fetchDeliveryBoyFailure: (error) => ({
        type: types.FETCH_DELIVERY_BOY_FAILURE,
        error
    }),
    fetchDeliveryBoySuccess: (items) => ({
        type: types.FETCH_DELIVERY_BOY_SUCCESS,
        items
    }),
    createDeliveryBoy: async(
        dispatch,
        token,
        body
    ) => {
        var json = await DeliveryBoysAPI.createDeliveryBoy(token, body)
        if(json.status_code === 500) {
            dispatch(actions.createDeliveryBoyFailure(json.message))
        }
        else {
            if(json.status_code === 200)
            {
            dispatch(actions.createDeliveryBoySuccess(json))
            }
            else
            {
                const newjson = json;
                newjson.status_code = 500
                dispatch(actions.createDeliveryBoySuccess(newjson))
            }
        }
        return json
    },
    createDeliveryBoyFailure: (error) => ({
        type: types.CREATE_DELIVERY_BOY_FAILURE,
        error
    }),
    createDeliveryBoySuccess: (items) => ({
        type: types.CREATE_DELIVERY_BOY_SUCCESS,
        items
    }),
    toggleActivate: async(
        dispatch,
        token,
        body
    ) => {
        var json = await DeliveryBoysAPI.toggleActivate(token, body);
        if(json.status_code === 500) {
            dispatch(actions.toggleActivateFailure(json.message))
        }
        else {
            dispatch(actions.toggleActivateSuccess(json, body))
        }
        return json
    },
    toggleActivateFailure: (error) => ({
        type: types.TOGGLE_ACTIVATE_FAILURE,
        error
    }),
    toggleActivateSuccess: (items, param) => ({
        type: types.TOGGLE_ACTIVATE_SUCCESS,
        items,
        param
    }),
    deleteDeliveryBoy: async(
        dispatch,
        token,
        user_id
    ) => {
        var json = await DeliveryBoysAPI.deleteDeliveryBoy(token, user_id);
        if(json.status_code === 500) {
            dispatch(actions.deleteDeliveryBoyFailure(json.message))
        }
        else {
            dispatch(actions.deleteDeliveryBoySuccess(json))
        }
        return json
    },
    deleteDeliveryBoyFailure: (error) => ({
        type: types.DELETE_FAILURE,
        error
    }),
    deleteDeliveryBoySuccess: (items) => ({
        type: types.DELETE_SUCCESS,
        items
    }),
}

const initialState = {
    lists: [],
    total: 0,
    error: null
}

export const reducer = (state = initialState, action) => {

    const { items, error, param } = action;
    
    var data = []

    switch (action.type) {
        case types.FETCH_DELIVERY_BOY_FAILURE: {
            return Object.assign({}, state, {
                error: error
            })
        }
        case types.FETCH_DELIVERY_BOY_SUCCESS: {
            return Object.assign({}, state, {
                error: null,
                lists: items.data.data,
                total: items.data.data.length
            })
        }
        case types.CLEAR_DELIVERY_BOY: {
            return Object.assign({}, state, {
                error: null,
                lists: [],
                total: 0
            })
        }
        case types.CREATE_DELIVERY_BOY_FAILURE: {
            return Object.assign({}, state, {
                error: error
            })
        }
        case types.CREATE_DELIVERY_BOY_SUCCESS: {
            return Object.assign({}, state, {
                error: null,
                lists: items.data.data,
                total: 1
            })
        }
        case types.TOGGLE_ACTIVATE_FAILURE: {
            return Object.assign({}, state, {
                error: error
            })
        }
        case types.TOGGLE_ACTIVATE_SUCCESS: {
            var selectedUser = state.lists.find((item) => parseFloat(item.user_id) === parseFloat(items.data.id))
            if(param.type === "activate") {
                selectedUser.is_active = 1;
            }
            else {
                selectedUser.is_active = 0;
            }

            data = [
                selectedUser
            ];
            state.lists.map((item) => {
                if(parseFloat(item.id) !== parseFloat(selectedUser.id)) {
                    data.push(item);
                }
                return true;
            });
            data.sort((a, b) => (a.id > b.id) ? 1 : -1);

            return Object.assign({}, state, {
                error: null,
                lists: data,
                total: data.length
            })
        }
        case types.DELETE_FAILURE: {
            return Object.assign({}, state, {
                error: error
            })
        }
        case types.DELETE_SUCCESS: {
            state.lists.map((item) => {
                if(parseFloat(item.user_id) !== parseFloat(items.data.id)) {
                    data.push(item);
                }
                return true;
            });
            data.sort((a, b) => (a.id > b.id) ? 1 : -1);

            return Object.assign({}, state, {
                error: null,
                lists: data,
                total: data.length
            })
        }
        default:
            return state;
    }
}
