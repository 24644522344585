import React from 'react';


class ButtonGroup extends React.PureComponent {

    render() {
        return(
            <div 
                className={`button__group__${this.props.position} ${this.props.className}`}
                style={this.props.style}
            >
                {this.props.children}
            </div>
        )
    }

}

export default (ButtonGroup);