import React from "react";
import RegisterStepOne from "./step-one";
import RegisterStepThree from "./step-three";
import RegisterStepTwo from "./step-two";
import { connect } from "react-redux";
import { Tools, navigate } from "common";
import moment from "moment";

class RegisterContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      errors: [],
    };
  }

  onNext = () => {
    var data = localStorage.getItem("@registrationData");
    data = JSON.parse(data);

    if (data.accountType.value === "user") {
      data = {
        ...data,
      };
      this.handleManualRegister(data, "user");
    } else {
      if (data.account_type.value === "personal") {
        if (this.state.step === 1) {
          this.setState({ step: 2 });
        } else {
          if (this.props.isResubmit) {
            this.handlePartnerResubmitRegister(data);
          } else {
            this.handlePartnerRegister(data);
          }
        }
      } else {
        if (this.state.step === 1) {
          this.setState({ step: 3 });
        } else {
          if (this.props.isResubmit) {
            this.handlePartnerResubmitRegister(data);
          } else {
            this.handlePartnerRegister(data);
          }
        }
      }
    }
  };

  onPrevious = () => {
    this.setState({
      step: 1,
    });
  };

  handleManualRegister = async (params, role) => {
    const { register, history, toastPending } = this.props;
    var json = await register(role, params);
    if (json.status_code === 200) {
      params = {
        ...params,
        registrationTime: moment(),
      };
      localStorage.setItem("@verificationData", JSON.stringify(params));
      navigate(history, "/verification");
    }
    toastPending({ message: json.message, toastType: json.status_code });
  };

  handleSocialRegister = async (params) => {
    const { socialRegister, toastPending, fetchAuthenticatedUser } = this.props;
    var json = await socialRegister(params.provider, params);
    if (json.status_code === 200) {
      await fetchAuthenticatedUser(json.token, {
        role: "user",
        provider: params.provider,
      });
      navigate(this.props.history, "/dashboard", "replace");
    }
    toastPending({ message: json.message, toastType: json.status_code });
  };

  handlePartnerRegister = async (params) => {
    var data = await Tools.generateRegistrationData(params);
    data = {
      ...data,
    };
    this.handleManualRegister(data, "partner");
  };

  handlePartnerResubmitRegister = async (params) => {
    // console.log("params", params);
    const { token, user, resubmitProfile, history, toastPending } = this.props;
    var data = await Tools.generateRegistrationData(params, true);
    // console.log("resubmit" , data)
    data = {
      ...data,
      id: user.id,
      type: "resubmit",
      role: "partner",
    };
    var json = await resubmitProfile(token, data);
    // console.log(json)
    if (json.status_code === 200) {
      navigate(history, "/dashboard?status=unconfirmed", "replace");
    }
    toastPending({ message: json.message, toastType: json.status_code });
  };

  render() {
    const { step } = this.state;

    if (step === 1) {
      return (
        <RegisterStepOne
          onNext={() => this.onNext()}
          onSocialRegister={(params) => this.handleSocialRegister(params)}
          {...this.props}
        />
      );
    } else if (step === 2) {
      return (
        <RegisterStepTwo
          onNext={() => this.onNext()}
          onPrevious={this.onPrevious}
          {...this.props}
        />
      );
    } else if (step === 3) {
      return (
        <RegisterStepThree
          onNext={() => this.onNext()}
          onPrevious={this.onPrevious}
          {...this.props}
        />
      );
    }
  }
}

const mapsStateToProps = ({ authenticated }) => {
  return {
    token: authenticated.token,
    user: authenticated.userInfo,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/AuthenticatedRedux");

  return {
    ...ownProps,
    ...stateProps,
    register: async (role, body) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await actions.register(dispatch, role, body);
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    socialRegister: async (provider, body) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await actions.socialRegister(dispatch, provider, body);
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    resubmitProfile: async (token, body) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await actions.updateProfile(dispatch, token, body);
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    fetchAuthenticatedUser: async (token, body) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await actions.fetchAuthenticatedUser(dispatch, token, body);
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    fetchPending: () => dispatch({ type: "FETCH_PENDING" }),
    fetchFinish: () => dispatch({ type: "FETCH_FINISH" }),
    toastPending: (body) => {
      dispatch({ type: "TOAST_PENDING", ...body });
    },
  };
};

export default connect(
  mapsStateToProps,
  undefined,
  mergeProps
)(RegisterContainer);
