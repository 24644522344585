import React from "react";
import { Col, Row } from "react-bootstrap";
// import jwt_decode from "jwt-decode";
import { Input, Button, SocialAuthentication } from "components";
import LoginWithMobileNumberContainer from "./mobile-number";
import { connect } from "react-redux";
import { Languages, Tools, navigate, Images } from "common";
import { FirebaseAPI } from "services";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
class LoginContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      send: false,
      valid: false,
      data: {
        email: "",
        password: "",
      },
      emailError: false,
      passwordError: false,
    };
  }

  componentDidMount() {
    const { history } = this.props;
    this.props.fetchFinish();

    const role = Tools.getParamFromURL(history, "role");

    if (role === "user") {
      this.setState({
        selectedOption: { value: "user", label: "Saytum User" },
      });
    } else if (role === "logistic_partner") {
      this.setState({
        selectedOption: { value: "partner", label: "Saytum Service Provider" },
      });
    } else if (role === "admin") {
      this.setState({
        selectedOption: { value: "admin", label: "Saytum Admin" },
      });
    } else {
      this.setState({
        selectedOption: { value: "user", label: "Saytum User" },
      });
    }
  }

  onChange = (target, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [target]: value,
      },
      send: false,
    });
  };

  onBlurValidationHandler = (target) => {
    if (target === "email") {
      if (!Tools.isEmailValid(this.state.data.email)) {
        this.setState({
          ...this.state,
          emailError: true,
        });
      }
    } else {
      if (!Tools.isPasswordValid(this.state.data.password)) {
        this.setState({
          ...this.state,
          passwordError: true,
        });
      }
    }
  };

  handleManualLogin = async () => {
    var valid = await this.validation();

    if (valid) {
      const { login, history, toastPending } = this.props;

      var role = Tools.getParamFromURL(history, "role");
      var data = {
        ...this.state.data,
        provider: "saytum",
        role: role === "logistic_partner" ? "partner" : role,
      };

      var json = await login(data);
      if (json.status_code === 200) {
        if (role === "partner") {
          const { is_confirmed, notes } = json.partner;
          if (!is_confirmed) {
            navigate(history, "/dashboard?status=unconfirmed", "replace");
          } else if (!is_confirmed && !Tools.isNotEmpty(notes)) {
            navigate(history, "/dashboard?status=rejected", "replace");
          } else {
            navigate(history, "/dashboard", "replace");
          }
        } else {
          navigate(history, "/dashboard", "replace");
        }

        var name =
          role === "logistic_partner"
            ? json.partner.name
            : json.firstname + " " + json.lastname;
        toastPending({
          toastType: json.status_code,
          message: Languages.login.success(name),
        });
      } else {
        if (json.code === "auth/user-not-verified") {
          this.setState({
            isShowVerification: true,
          });
        }
        toastPending({ toastType: json.status_code, message: json.message });
      }
    }
  };

  handleSocialLogin = async (params) => {
    const { login, history, toastPending } = this.props;

    params = {
      ...params,
      role: Tools.getParamFromURL(history, "role"),
    };
    var check_account = await FirebaseAPI.callFunctions(
      "isRegisteredAccount",
      params
    );

    // params.idToken = params.accessToken;

    if (check_account.status_code === 200) {
      // const obj = {
      //   ...check_account.user,
      //   token: check_account.user.idToken,
      // };
      var json = await login(params);
      console.log("json22", json);
      if (json.status_code === 200) {
        navigate(history, "/dashboard", "replace");
        var name = json.firstname + " " + json.lastname;
        toastPending({
          toastType: json.status_code,
          message: Languages.login.success(name),
        });
      } else {
        toastPending({
          toastType: json.status_code || 500,
          message: json.message || "Something Went Wrong !!!",
        });
      }
    } else {
      var message = check_account.errorInfo.message;
      toastPending({ toastType: check_account.status_code, message: message });
    }
  };

  handleFacebookLogin = async (params) => {
    var data = {
      email: params.email,
      password: "",
      provider: "facebook",
      accessToken: params.accessToken,
    };
    this.handleSocialLogin(data);
  };

  handleGoogleLogin = async (params) => {
    // console.log("params", params);
    // const resDecode = jwt_decode(params.credential);
    // console.log("paramsdecode" , resDecode)
    var data = {
      email: params.credDecode.email,
      password: "",
      provider: "google",
      accessToken: params.cred,
    };
    this.handleSocialLogin(data);
  };

  handleRequestNewCode = async () => {
    const { requestNewCode, toastPending } = this.props;

    var json = await requestNewCode(this.state.data);
    toastPending({ message: json.message, toastType: json.status_code });
    if (json.status_code === 200) {
      this.setState({ isShowVerification: false });
    }
  };

  validation = () => {
    const { email, password } = this.state.data;

    var valid = Tools.isEmailValid(email) && Tools.isPasswordValid(password);
    let emailError = false;
    let passwordError = false;

    if (!valid) {
      if (!Tools.isEmailValid(email)) {
        emailError = true;
      }
      if (!Tools.isPasswordValid(password)) {
        passwordError = true;
      }
      this.setState({
        ...this.state,
        emailError: emailError,
        passwordError: passwordError,
      });
    }

    this.setState({
      valid: valid,
      send: true,
    });

    return valid;
  };

  render() {
    const { data } = this.state;
    var role = Tools.getParamFromURL(this.props.history, "role");
    if (role === "delivery_boy") {
      return <LoginWithMobileNumberContainer {...this.props} />;
    } else if (
      role === "user" ||
      role === "admin" ||
      role === "logistic_partner"
    ) {
      return (
        <div className="container">
          <div className="container__auth">
            <Row>
              <Col>
                <div className="container__auth__header">
                  <h1 className="main-header-text">
                    Login into{" "}
                    <span className="text__capitalize">
                      {role ? role.replace("_", " ") : ""}
                    </span>{" "}
                    account
                  </h1>
                  <h4 className="secondary-header-text">
                    Provide your credintials to login into your account.
                  </h4>
                </div>
                <div className="container__auth__body">
                  {this.state.isShowVerification && (
                    <div className="container__auth__body__unverified">
                      <div className="floating-navigation danger with-button">
                        <div className="flex">
                          <p className="floating-navigation__label">
                            Your account is not verified
                          </p>
                        </div>
                        <Button
                          buttonText="Request Verification"
                          className="floating-navigation__btn"
                          type="danger"
                          size="sm"
                          onClick={() => this.handleRequestNewCode()}
                        />
                      </div>
                    </div>
                  )}

                  {role === "user" && (
                    <SocialAuthentication
                      activity="login"
                      handleActivity={(provider, data) => {
                        if (provider === "google") {
                          this.handleGoogleLogin(data);
                        } else {
                          this.handleFacebookLogin(data);
                        }
                      }}
                    />
                  )}

                  <Input
                    type="email"
                    label="Email Address"
                    iconPrefix={Images.form_email}
                    placeholder="Enter your email address"
                    style={{ margin: 0, marginBottom: 25 }}
                    value={data.email}
                    onChange={(e) => {
                      this.setState({ ...this.state, emailError: false });
                      this.onChange("email", e.target.value);
                    }}
                    onBlur={(e) => this.onBlurValidationHandler("email")}
                    caption={
                      this.state.emailError && "Provide correct email format"
                    }
                    isError={
                      (this.state.send && !this.state.valid) ||
                      this.state.emailError
                    }
                    isRequired={true}
                  />
                  <Input
                    type={!this.state.showPassword ? "password" : "text"}
                    label="Password"
                    iconPrefix={Images.password}
                    iconSuffix={
                      !this.state.showPassword ? Images.eye_close : Images.eye
                    }
                    clickableIcon={["suffix"]}
                    onIconClick={(position) => {
                      if (position === "suffix") {
                        this.setState({
                          showPassword: !this.state.showPassword,
                        });
                      }
                    }}
                    placeholder="Example:- Saytum@123"
                    style={{ margin: 0, marginBottom: 25 }}
                    value={data.password}
                    onChange={(e) => {
                      this.setState({ ...this.state, passwordError: false });
                      this.onChange("password", e.target.value);
                    }}
                    onBlur={(e) => this.onBlurValidationHandler("password")}
                    caption={
                      this.state.passwordError &&
                      "Invalid Password"
                    }
                    isError={
                      (this.state.send && !this.state.valid) ||
                      this.state.passwordError
                    }
                    isRequired={true}
                  />
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <a
                      href="/forgot-password"
                      className="hyperlink"
                      style={{ marginBottom: 30, width: "fit-content" }}
                    >
                      Forgot password?
                    </a>
                  </div>
                  <Button
                    size="lg"
                    type="warning"
                    buttonText="Login"
                    variation="primary"
                    style={{ margin: 0, marginTop: 20, marginBottom: 20 }}
                    onClick={() => this.handleManualLogin()}
                  />

                  <p className="hyperlink__text text-center">
                    Do you have an account?{" "}
                    <a
                      href={`/register?role=${role}`}
                      className="hyperlink text-warning"
                    >
                      Register
                    </a>
                  </p>


                </div>
              </Col>
              <Col className="banner-container">
                <img src={Images.banner_login} alt="user-login.png" />
              </Col>
            </Row>
          </div>
        </div>
      );
    }else{
      return <Redirect to="/login?role=user" />;
    }
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/AuthenticatedRedux");

  return {
    ...ownProps,
    ...stateProps,
    fetchPending: () => dispatch({ type: "FETCH_PENDING" }),
    fetchFinish: () => dispatch({ type: "FETCH_FINISH" }),
    toastPending: (body) => {
      dispatch({ type: "TOAST_PENDING", ...body });
    },
    login: async (body) => {
      dispatch({ type: "FETCH_PENDING" });
      var login = await actions.login(dispatch, body);
      dispatch({ type: "FETCH_FINISH" });
      return login;
    },
    toastPending: (body) => {
      dispatch({ type: "TOAST_PENDING", ...body });
    },
    requestNewCode: async (body) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await actions.requestNewCode(dispatch, body);
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
  };
};

export default connect(undefined, undefined, mergeProps)(LoginContainer);
