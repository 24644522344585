import React from 'react';
import { JoinPartnerContainer } from 'container';

class JoinPartner extends React.PureComponent {
   
    render() {
        return(
            <JoinPartnerContainer 
                {...this.props}
            />
        )
    }

}

export default (JoinPartner);