import React from "react";
import Geocode from "react-geocode";
import { persistStore } from "redux-persist";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store from "store/configureStore";
import "./styles.scss";
import Constant from "common/Constant";
import AppNavigation from "navigation";
import firebase from "firebase";
import { GoogleOAuthProvider } from "@react-oauth/google";

const app = firebase.initializeApp(Constant.FIREBASE);
const appCheck = app.appCheck();

appCheck.activate(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY, true);

class App extends React.PureComponent {
  componentDidMount() {
    Geocode.setApiKey(Constant.GOOGLE_PLACE_API_KEY);
  }

  render() {
    const persistor = persistStore(store);

    return (
      <GoogleOAuthProvider clientId={Constant.GOOGLE_OAUTH_KEY}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <AppNavigation />
          </PersistGate>
        </Provider>
      </GoogleOAuthProvider>
    );
  }
}

export default App;
