import React from 'react';
import { Button, FormBody, FormWrapper } from 'components';
import { connect } from 'react-redux';
import { navigate, Images } from 'common';


class UnconfirmedPartnerContainer extends React.PureComponent {

    handleUserLogout = async() => {
        const {
            logout,
            token,
            toastPending
        } = this.props

        var json = await logout(token)
        if(json.status_code === 200) {
            navigate(this.props.history, "/", "replace");
        }
        toastPending({ message: json.message, toastType: json.status_code })
    }

    render() {

        const { innerWidth } = window;

        return (
            <div className="container" style={innerWidth > 768 ? { marginTop: 100 } : {}}>

                <FormWrapper>
                    <FormBody>
                        <img src={Images.success_plane} alt="success" className="image__verification" />
                        <h1 className="text__verification__header">Thank you for joining us</h1>
                        <h3 className="text__verification__body" style={{ marginBottom: 30 }}>
                        Your profile verification is in progress. You will be notified via SMS and registered email once your profile is verified. Have a nice day!
                        </h3>
                        <Button
                            buttonText="signout"
                            size="lg"
                            type="danger"
                            style={{ margin: 0 }}
                            onClick={() => this.handleUserLogout()}
                        />
                    </FormBody>
                </FormWrapper>

            </div>
        )
    }

}

const mapsStateToProps  = ({ authenticated }) => {
    return {
        user: authenticated.userInfo,
        token: authenticated.token
    }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { actions } = require("redux/AuthenticatedRedux");

    return {
      ...ownProps,
      ...stateProps,
      logout: async(token) => {
        dispatch({ type: "FETCH_PENDING" });
        var logout = await actions.logout(
            dispatch,
            token
        )
        dispatch({ type: "FETCH_FINISH" });
        return logout
      },
      toastPending: (body) => {
          dispatch({ type: "TOAST_PENDING", ...body })
      }
    };
}


export default connect(
    mapsStateToProps, 
    undefined,
    mergeProps
)(UnconfirmedPartnerContainer);