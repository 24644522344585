import { Modal as BootstrapModal } from 'react-bootstrap';
import React from 'react';
import "./modal.scss";

class Modal extends React.PureComponent {

    render() {
        return (
            <>
                <BootstrapModal className={`modal__medium ${this.props.className}`} size="large" show={this.props.isVisible}
                    aria-labelledby="edit-name-modal" ref={this.props.reference} backdrop="static"
                    centered>
                    
                    {this.props.children}

                </BootstrapModal>
            </>
        )
    }

}

export default (Modal);