import Tools from "common/Tools";
import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import "./autocomplete.scss";

class InputAutoComplete extends React.PureComponent {
  render() {
    const { clickableIcon, onIconClick, countries, isRequired } = this.props;
    var country = null;
    if (countries) {
      country = Tools.getCountryDetail(countries);
      country = country.iso2;
    }

    return (
      <div className={`input`} style={this.props.style}>
        <label
          className={`input__label ${
            this.props.isError ? "input__label__error" : undefined
          }`}
        >
          {this.props.label}
          {isRequired && <span className="input__required">*</span>}
        </label>

        <PlacesAutocomplete
          value={this.props.value}
          onChange={(value) => this.props.onChange(value)}
          onBlur={(e) => this.props.onBlur(e)}
          onSelect={(value) => this.props.onChange(value)}
          searchOptions={{
            componentRestrictions: { country: country ? country : null },
          }}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps }) => (
            <div className="input__autocomplete">
              {this.props.iconPrefix &&
                (clickableIcon && clickableIcon.includes("prefix") ? (
                  <button
                    className="input__icon input__icon__button input__icon__button__prefix input__icon__prefix"
                    onClick={() => onIconClick("prefix")}
                  >
                    <img src={this.props.iconPrefix} alt="form-icon-prefix" />
                  </button>
                ) : (
                  <img
                    src={this.props.iconPrefix}
                    alt="form-icon-prefix"
                    className="input__icon input__icon__prefix"
                  />
                ))}
              {this.props.iconSuffix &&
                (clickableIcon && clickableIcon.includes("suffix") ? (
                  <button
                    className="input__icon input__icon__button input__icon__button__suffix input__icon__suffix"
                    onClick={() => onIconClick("suffix")}
                  >
                    <img src={this.props.iconSuffix} alt="form-icon-suffix" />
                  </button>
                ) : (
                  <img
                    src={this.props.iconSuffix}
                    alt="form-icon-suffix"
                    className="input__icon input__icon__suffix"
                  />
                ))}
              <input
                onBlur={(e) => this.props.onBlur(e)}
                type="text"
                {...getInputProps({
                  placeholder: "Ex: Surat",
                  className: `input__control ${
                    this.props.isError ? "input__control__error" : undefined
                  } ${
                    this.props.type === "password"
                      ? "input__control__password"
                      : undefined
                  }`,
                  ...this.props.inputProps,
                })}
              />
              <div className="input__autocomplete__suggestion">
                {suggestions.map((suggestion) => {
                  return (
                    <div
                      key={Math.random().toString()}
                      {...getSuggestionItemProps(
                        suggestion,
                        "input__autocomplete__suggestion__item"
                      )}
                      className="input__autocomplete__suggestion__item"
                    >
                      <span className="input__autocomplete__suggestion__item__text">
                        {suggestion.description}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>

        {this.props.caption && (
          <label
            className={`input__caption ${
              this.props.isError ? "input__caption__error" : undefined
            }`}
          >
            {this.props.caption}
          </label>
        )}
      </div>
    );
  }
}

export default InputAutoComplete;
