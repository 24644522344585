import { EditConsignmentContainer } from "container";

import { connect } from "react-redux";

import React from "react";
import { useEffect } from "react";
// import { useCallback } from "react";

const EditConsignment = (props) => {

  
  // eslint-disable-next-line
  useEffect(() => {
    const { token } = props;
    props.fetchCountries(token);
    props.fetchStates(token);
    props.fetchCities(token);
  // eslint-disable-next-line
  }, []);

  return <EditConsignmentContainer {...props} />;
};

const mapsStatetoProps = ({ authenticated, locations, consignment }) => {
  return {
    token: authenticated.token,
    cities: locations.cities,
    states: locations.states,
    countries: locations.countries,
    local_consignment: consignment.local_data,
    consignment: consignment.lists,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions: location_actions } = require("redux/LocationRedux");

  return {
    ...ownProps,
    ...stateProps,
    fetchCountries: async (token) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await location_actions.fetchCountries(dispatch, token);
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    fetchStates: async (token, country_id) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await location_actions.fetchStates(
        dispatch,
        token,
        country_id
      );
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    fetchCities: async (token, state_code) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await location_actions.fetchCities(dispatch, token, state_code);
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
  };
};

export default connect(
  mapsStatetoProps,
  undefined,
  mergeProps
)(EditConsignment);
