import { Tools, navigate } from 'common';
import React from 'react';
import { connect } from 'react-redux';
import JoinPartnerStepOneContainer from './step-one';
import RegisterStepThree from './step-three';
import JoinPartnerStepTwoContainer from './step-two';

class JoinPartnerContainer extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            errors: [],
            finished: false
        }
    }

    UNSAFE_componentWillMount() {
        const {
            history
        } = this.props

        if(Tools.getParamFromURL(history, "resubmit") === "yes") {
            this.initialResubmitData()
        }
        else {
            this.initialData()
        }
    }

    initialData = async() => {
        const { user } = this.props;
        var data = await {
            email: user.email,
            mobile_prefix: Tools.isNotEmpty(user.mobile_prefix) ? { value: user.mobile_prefix, label: user.mobile_prefix } : null,
            mobile_number: user.mobile_number,
            accountType: { value: "partner", label: "Saytum Service Provider" }
        }
        await localStorage.setItem("@registrationData", JSON.stringify(data));
        this.setState({ finished: true })
    }

    initialResubmitData = async() => {
        const { partner } = this.props.user;
        var data = {
            accountType: { value: "partner", label: "Saytum Service Provider" },
            account_type: partner.is_company ? { value: "company", label: "Company"  } : { value: "personal", label: "Personal" },
            name: partner.name,
            email: partner.email,
            password: null,
            address: partner.address,
            mobile_prefix: { value: partner.mobile_prefix, label: partner.mobile_prefix },
            mobile_number: partner.mobile_number,
            document_type: Tools.isNotEmpty(partner.driving_licence_number) ?
                                { value: "license", label: "Driving License" }
                            : Tools.isNotEmpty(partner.passport_number) ?
                                { value: "passport", label: "Passport" }
                            :
                                { value: "aadhaar", label: "Aadhaar" }, 
            document_number: Tools.isNotEmpty(partner.driving_licence_number) ?
                                partner.driving_licence_number
                            : Tools.isNotEmpty(partner.passport_number) ?
                                partner.passport_number
                            :
                                partner.national_identity_number,
            document_image : Tools.isNotEmpty(partner.driving_licence_number) ?
                                { file: partner.driving_licence_image_url, name: partner.driving_licence_image, value: partner.driving_licence_image_url }
                            : Tools.isNotEmpty(partner.passport_number) ?
                                { file: partner.passport_image_url, name: partner.passport_image, value: partner.passport_image_url }
                            :
                                { file: partner.national_identity_image_url, name: partner.national_identity_image, value: partner.national_identity_image_url },
            pan_number: partner.pan_number,
            company_registration_number: partner.company_registration_number,
            gst_number: partner.gst_number
        }

        await localStorage.setItem("@registrationData", JSON.stringify(data));
        this.setState({ finished: true })
    }

    onPrevious = () => {
        this.setState({
            step: 1    
        })
    }

    onNext = () => {
        var data = localStorage.getItem("@registrationData");
        data = JSON.parse(data);
        
        if(data.account_type.value === "personal") {
            if(this.state.step === 1) {
                this.setState({ step: 2 })
            }
            else {
                this.handlePartnerRegister(data);
            }
        }
        else {
            if(this.state.step === 1) {
                this.setState({ step: 3 })
            }
            else {
                this.handlePartnerRegister(data);
            }
        }
    }

    handlePartnerRegister = async(params) => {
        const { history } = this.props;
        var data = await Tools.generateRegistrationData(params, true);

        if(Tools.getParamFromURL(history, "resubmit") === "yes") {
            data = {
                ...data,
                type: "resubmit",
                is_join_partner: true
            }
            this.handleResubmitProfile(data);
        }
        else {
            this.handleJoinPartner(data);
        }
    }
    
    handleJoinPartner = async(params) => {
        const {
            toastPending,
            joinPartner,
            token,
            user
        } = this.props
        var json = await joinPartner(token, user.id, params)
        
        toastPending({ message: json.message, toastType: json.status_code })

        if(json.status_code === 200) {
            localStorage.removeItem("@registrationData");
            navigate(this.props.history, "/dashboard");
        }
    }

    handleResubmitProfile = async(params) => {
        const {
            toastPending,
            resubmitRegistration,
            token,
            user
        } = this.props
        params = {
            ...params,
            role: "user"
        }
        var json = await resubmitRegistration(token, user.id, params)
        toastPending({ message: json.message, toastType: json.status_code })

        if(json.status_code === 200) {
            localStorage.removeItem("@registrationData");
            navigate(this.props.history, "/dashboard");
        }           
    }

    render() {
        const { step, finished } = this.state;
        if(finished) {
            if(step === 1) {
                return (
                    <JoinPartnerStepOneContainer 
                        {...this.props} 
                        type="join-partner" 
                        onNext={() => this.onNext()}
                    />
                )
            }
            else if(step === 2) {
                return (
                    <JoinPartnerStepTwoContainer 
                        {...this.props} 
                        type="join-partner" 
                        onNext={() => this.onNext()}
                        onPrevious={() => this.onPrevious()}
                    />
                )
            }
            else if(step === 3) {
                return (
                    <RegisterStepThree 
                        {...this.props} 
                        type="join-partner" 
                        onNext={() => this.onNext()}
                        onPrevious={() => this.onPrevious()}
                    />
                )
            }
        }
        else {
            return <></>
        }
    }

}

const mapsPropsToState = ({ authenticated }) => {
    return {
        user: authenticated.userInfo,
        token: authenticated.token
    }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { actions } = require("redux/AuthenticatedRedux");

    return {
      ...ownProps,
      ...stateProps,
      joinPartner: async(
          token,
          user_id,
          body
      ) => {
        dispatch({ type: "FETCH_PENDING" })
        var json = await actions.joinPartner(dispatch, token, user_id, body)
        dispatch({ type: "FETCH_FINISH" })
        return json
      },
      resubmitRegistration: async(
        token,
        user_id,
        body
      ) => {
        dispatch({ type: "FETCH_PENDING" })
        var json = await actions.resubmitRegistration(dispatch, token, user_id, body)
        dispatch({ type: "FETCH_FINISH" })
        return json
      },
      toastPending: (body) => {
        dispatch({ type: "TOAST_PENDING", ...body })
      }
    };
}

export default connect(
    mapsPropsToState,
    undefined,
    mergeProps
)(JoinPartnerContainer);