import React from 'react';
import { Button, Table, Confirmation, Filter } from 'components';
import { navigate, Images } from 'common';
import { connect } from 'react-redux';
import * as Moment from 'moment';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

const filterOptions = [
    {
        type: "date",
        key: "created_date",
        label: "Date"
    }
];
class MaterialCategoriesContainer extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            confirmation: {
                isVisible: false,
                header: "",
                description: "",
                onCancel: null,
                onConfirm: null
            },
            loaded: false
        }
    }

    async componentDidMount() {
        const {
            token,
            clearMaterialCategories,
            fetchMaterialCategories
        } = this.props
        clearMaterialCategories()
        await fetchMaterialCategories(token)
        this.setState({ loaded: true })
    }

    openConfirmation = (headerText, descriptionText, onCancel, onConfirm) => {
        this.setState({
            confirmation: {
                isVisible: true,
                header: headerText,
                description: descriptionText,
                onCancel: onCancel,
                onConfirm: onConfirm
            }
        })
    }

    handleDelete = async(params) => {
        this.onCancelConfirmation();
        const {
            token,
            deleteMaterialCategories,
            toastPending
        } = this.props
        var json = await deleteMaterialCategories(token, params.id)
        toastPending({ message: json.message, toastType: json.status_code })
    }

    onCancelConfirmation = () => {
        this.setState({
            confirmation: {
                isVisible: false
            }
        })
    }

    onSearch = (keyword) => {
        const { categories } = this.props;
        var filteredData = categories.filter((item) => item.name.toLowerCase().includes(keyword.toLowerCase()));
        this.setState({
            keyword: keyword,
            filteredData: filteredData
        });
    }

    onFilter = async(filters) => {
        var filterDate = await this.filterDate(filters);
        var filteredData = filterDate;

        this.setState({
            isFiltering: false,
            keyword: "filter",
            filteredData: filteredData
        })
    }

    filterDate = (filters) => {
        const { categories } = this.props;
        var isFilterDate = filters.filter((item) => item.target === "created_date");
        if(isFilterDate.length > 0) {
            var filteredData = [];
            var date_from = filters.find((item) => item.target === "created_date" && item.key === "date_from")    
            var date_to = filters.find((item) => item.target === "created_date" && item.key === "date_to");
            if(date_to) {
                var range = moment.range(date_from.value, date_to.value);
                
                filteredData = categories.filter((item) => {
                    if(moment(item.created_at).within(range)) {
                        return item;
                    }
                    return false;
                });
            }
            else {
                filteredData = categories.filter((item) => {
                    var created_at = moment(item.created_at).format("YYYY-MM-DD");
                    var filterDate = moment(date_from.value).format("YYYY-MM-DD");

                    if(moment(created_at).isSame(filterDate)) {
                        return item;
                    }
                    return false;
                });
            }
            return filteredData;
        }
        
        return categories;
    }

    render() {

        const { categories, history } = this.props;
        const { confirmation, keyword, filteredData } = this.state;

        var data = keyword ? filteredData : categories;

        const columns = [
            {
                Header: 'No',
                id: 'no',
                accessor: (props, index) => (
                    <div className="table__body__row__column__text-style">{index + 1}</div>
                )
            },
            {
                Header: 'Name',
                id: 'name',
                accessor: props => (
                    <div className="table__body__row__column__text-style">{props.name}</div>
                )
            },
            {
                Header: 'Created Date',
                accessor: 'created_at',
                Cell: props => {
                    return (
                        <div className="table__body__row__column__text-style">{moment(props.value).format("DD MMM YYYY")}</div>
                    ) 
                    
                },
            },
            {
                Header: 'Actions',
                id: "id",
                accessor: props => {
                    return(
                        <DropdownButton
                            className="table__body__row__column__dropdown"
                            key="up"
                            drop="up"
                            variant="secondary"
                            title={<i className="material-icons">more_horiz</i>}
                        >
                            <Dropdown.Item eventKey="1" onClick={() => navigate(history, "/material-categories/edit/" + props.id + "", "push")} className="dropdown-item__edit">Edit</Dropdown.Item>
                            <Dropdown.Item eventKey="2" 
                                onClick={() => this.openConfirmation(
                                    "Are you sure",
                                    "Are you sure to delete this data",
                                    () => this.onCancelConfirmation(),
                                    () => this.handleDelete(props)
                                )} className="dropdown-item__delete"
                            >
                                Delete
                            </Dropdown.Item>
                        </DropdownButton>
                    )
                }
            }
        ];

        return(
            <div className="dashboard">

                <Confirmation
                    isVisible={confirmation.isVisible}
                    header={confirmation.header}
                    description={confirmation.description}
                    confirmationText="Yes"
                    onCancel={confirmation.onCancel}
                    onConfirm={confirmation.onConfirm}
                />

                <div className="dashboard__header">
                    <div className="flex flex__sm">
                        <h1 className="header" style={{ marginRight: 20 }}>Material Categories</h1>
                        <Filter 
                            filterOptions={filterOptions}
                            withFilter
                            isFilterVisible={this.state.isFiltering}
                            onCancel={() => this.setState({ isFiltering: false })}
                            onFilter={() => this.setState({ isFiltering: !this.state.isFiltering })}
                            onApplyFilter={(e) => this.onFilter(e)}
                            onSearch={(e) => this.onSearch(e)}
                        />
                    </div>
                    <div className="flex flex__sm">
                        <Button
                            icon={Images.plus}
                            iconPosition="before-text"
                            buttonText="Add Material Categories"
                            type="dashboard"
                            size="sm"
                            style={{ marginRight: 0 }}
                            onClick={() => {
                                navigate(this.props.history, "/material-categories/create");
                            }}
                        />      
                    </div>
                </div>
                <div className="dashboard__body">
                    {this.state.loaded && (
                        <Table 
                            columns={columns}
                            data={data}
                        />
                    )}
                </div>
            </div>
        )
    }

}

const mapsStateToProps = ({ authenticated, material_categories }) => {
    return {
        token: authenticated.token,
        categories: material_categories.lists
    }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { actions } = require("redux/MaterialCategoriesRedux");

    return {
      ...ownProps,
      ...stateProps,
      fetchMaterialCategories: async(
        token
      ) => {
        dispatch({ type: "FETCH_PENDING" })
        var json = await actions.fetchMaterialCategories(dispatch, token)
        dispatch({ type: "FETCH_FINISH" })
        return json
      },
      clearMaterialCategories: () => dispatch({ type: "CLEAR_MATERIAL_CATEGORIES" }),
      deleteMaterialCategories: async(
        token,
        id
      ) => {
        dispatch({ type: "FETCH_PENDING" })
        var json = await actions.deleteMaterialCategories(dispatch, token, id)
        dispatch({ type: "FETCH_FINISH" })
        return json
      },
      toastPending: (body) => {
        dispatch({ type: "TOAST_PENDING", ...body })
      }
    };
}

export default connect(
    mapsStateToProps, 
    undefined,
    mergeProps
)(MaterialCategoriesContainer);