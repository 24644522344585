import { Images } from 'common';
import React from 'react';
import AuctionBiddersContainer from './Bidder';

class AuctionBidsContainer extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            bids_data: [],
        }
    }

    render() {

        const { auction } = this.props

        return (
            <>
                
                {auction.total_bids > 0 ? (
                    auction.auction_status === 111 ?
                        auction.status === 103 ? (
                            <div className="details__empty">
                                <img src={Images.empty} alt="empty" />
                                <p className="text text__bold text__uppercase">Pending auction payment. Verify on auction payment below</p>
                            </div>
                        ) : (
                        <AuctionBiddersContainer
                            title="Auction Bidder"
                            auction={auction}
                            {...this.props}
                        />
                    ) : (
                        <div className="details__empty">
                            <img src={Images.empty} alt="empty" />
                            <p className="text text__bold text__uppercase">This auction has completed. Track you shipment process in the Tracking menu</p>
                        </div>
                    )
                ) : ( 
                    <div className="details__empty">
                        <img src={Images.empty} alt="empty" />
                        <p className="text text__bold text__uppercase">This auction does not have bidder yet</p>
                    </div>
                )}
                
            </>
        )
    }

}

export default (AuctionBidsContainer)