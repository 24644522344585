import React from 'react';
import { 
    Button as ButtonComponent, 
    Input, 
    Confirmation 
} from 'components'; 
import { Constant, Images, navigate } from 'common';
import { connect } from 'react-redux';
import AuctionBidderDetailContainer from './Detail';
import "./styles.scss";
import moment from 'moment';

class AuctionBiddersContainer extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            keyword: null,
            filteredData: [],
            detail: {
                isVisible: false,
                selectedBid: {}
            },
            confirmation: {}
        }
    }

    componentDidMount = async() => {
        const { 
            auction,
            token,
            clearAuctionsBidders,
            fetchAuctionsBidders
        } = this.props;
        clearAuctionsBidders()
        fetchAuctionsBidders(token, auction.auction_id)
    }

    renderActiveStar = (length) => {
        var temp = [];
        for (let index = 0; index < length; index++) {
            temp.push(
                <img src={Images.active_star} alt={`star${index}`} key={`star${index}`} />
            );
        }
        return temp;
    }

    renderInactiveStar = (length) => {
        var temp = [];
        for (let index = 0; index < length; index++) {
            temp.push(
                <img src={Images.inactive_star} alt={`star${index}`} key={`star${index}`} />
            );
        }
        return temp;
    }

    onSearch = (keyword) => {
        const { bidders } = this.props;
        var filteredData = bidders.filter((item) => item.partner.name.toLowerCase().includes(keyword.toLowerCase()))    
        
        this.setState({
            keyword: keyword,
            filteredData: filteredData
        });
    }

    onOpenDetail = (bid) => {
        this.setState({
            detail: {
                ...this.state.detail,
                selectedBid: bid
            }
        }, () => {
            this.setState({
                detail: {
                    ...this.state.detail,
                    isVisible: true
                }
            });
        })
    }

    onCloseDetail = () => {
        this.setState({
            detail: {
                ...this.state.detail,
                isVisible: false
            }
        })
    }

    openConfirmation = (headerText, descriptionText, onCancel, onConfirm) => {
        this.setState({
            confirmation: {
                isVisible: true,
                header: headerText,
                description: descriptionText,
                onCancel: onCancel,
                onConfirm: onConfirm
            }
        })
    }

    onCancelConfirmation = () => {
        this.setState({
            confirmation: {
                isVisible: false
            }
        })
    }

    onAccept = async(params) => {
        let newParams = {...params};
        // console.log("newParams", newParams);
        newParams.bid_price = params.bid_price + (params.bid_price*Constant.GST)/100;
        const { token, toastPending } = this.props;
        this.onCancelConfirmation()
        this.onCloseDetail()
        var acceptData = { bid_id: newParams.id, auction_id: newParams.auction_id, bidder_id: newParams.bidder_id, consignment_id: newParams.consignment_id}
        var res = await this.getPaymentDetail(token, acceptData.bid_id, acceptData.auction_id, acceptData.consignment_id)
        if(res.status_code === 200) {
            if(res.payment_status === 152){
                toastPending({message:res.message, toastType: res.status_code})
            }else{
                await this.checkout(newParams, acceptData)
            }
        }else{
            toastPending({message: res.message , toastType: res.status_code})
        }
    }

    async getPaymentDetail(token, 
        bid_id , consignment_id) {
      const {
          fetchPaymentDetail,
          clearPaymentDetail,
          bidders
      } = this.props;
    //   console.log("object", bidders);
    //   console.log("bidder", bidders[0].consignment_id);
      clearPaymentDetail()
      var json = await fetchPaymentDetail(token, bid_id , bidders[0].consignment_id)
    //   console.log("payment_details" , json);
    //   console.log("consignment_id" , bidders[0].consignment_id);
    //   console.log("bid_id" , bid_id);
    //   console.log("auction_id" , auction_id);
      return json
  }

    checkout = async(data, acceptData) => {
        // console.log('===',data);
        const {
            token,
            createOrder,
            auction,
            payment_detail,
            verifySelf,
            toastPending,
            fetchOrderDetail
        } = this.props;
        // console.log('===', payment_detail.order_id);
        // console.log("payment  details" , payment_detail);
        // console.log("auction", auction);
        // console.log("checkout_acceptdata" , acceptData);
        if(!payment_detail.order_id) { // order_id doesn't exist
            var params = {
                amount: data.bid_price,
                currency: "INR",
                receipt: null,
                bid_id: data.id,
                notes: {
                    consignment_id: acceptData.consignment_id,
                    consignment_name: auction.order_name,
                    pickup_name: auction.pickup_name,
                    pickup_address_two: auction.pickup_address_two,
                    pickup_mobile_number: auction.pickup_mobile_prefix + auction.pickup_mobile_number,
                    pickup_date: moment(auction.pickup_date).format('YYYY-MM-DD hh:mm:ss'),
                    pickup_time: auction.pickup_time, 
                    delivery_name: auction.delivery_name,
                    delivery_address_two: auction.delivery_address_two,
                    delivery_mobile_number: auction.delivery_mobile_prefix + auction.delivery_mobile_number,
                    material_detail: auction.material_detail,
                    weight: auction.weight.toString(),
                    material_price: auction.material_price.toString(),
                    logistic_partner_id: data.partner.id,
                    logistic_partner_name: data.partner.name
                },
                partial_payment: false
            }
            // console.log('createOrder',params);
            var json = await createOrder(token, params)
            // console.log("create_order" , json);
            if(json.status_code === 200) {
                this.onPayNow(json.data, acceptData.bid_id, acceptData)
                await this.getPaymentDetail(token, acceptData.bid_id, auction.id, acceptData.consignment_id);
            }
        } else if(payment_detail.payment_status === 151) { // payment status created
            var orderData = await fetchOrderDetail(token, payment_detail.order_id)
            // console.log("order_detail", orderData);
            if(orderData.status_code === 200) {
                this.onPayNow(orderData.data, acceptData.bid_id, acceptData)
            } else {
                toastPending({ message: orderData.message, toastType: orderData.status_code })
            }
        } else { // payment status failed
            var result = await verifySelf(token, payment_detail.order_id, auction.id)
            if(result.status_code === 500) {
                await this.getPaymentDetail(token, acceptData.bid_id, auction.id, acceptData.consignment_id)
            }
            toastPending({ message: result.message, toastType: result.status_code })
        }
    }

    onPayNow = (data, bid_id, acceptData) => {
        this.onCancelConfirmation()
        const { 
            token,
            auction,
            verifyPayments,
            toastPending,
            acceptBidAuction,
            updatePayments,
            generateInvoice,
            history,
            updateConsignment
        } = this.props;
        var options = {
          key: process.env.REACT_APP_RAZORPAY_KEY_ID,
          amount: data.amount.toString(),
          currency: data.currency,
          name: "Saytum",
          description: "",
          image: require("images/logo.png"),
          order_id: data.id,
          config: {
            display: {
              blocks:{
                banks:{
                    name:"All payment methods",
                    instruments:[
                        {method:"upi"},
                        {method:"card"},
                    ]
                }
              },
              sequence: ['block.banks'],
              preferences: {
                show_default_blocks: false,
              },
            },
          },
          handler: async function (response) {
            var json = await verifyPayments(token, {
              bid_id: bid_id,
              consignment_id: acceptData.consignment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
            });
            if(json.status_code === 200){
                console.log("verifyPayments successful, generating invoice...");
                var invoiceResponse = await generateInvoice(token , {
                    bid_id: bid_id,
                    consignment_id: acceptData.consignment_id,
                    razorpay_order_id: response.razorpay_order_id,
                    razorpay_payment_id: response.razorpay_payment_id
                })
            // if (json.status_code === 500) {
            //   // update status
            //   await updatePayments(token, data[1].id, {
            //     consigment_id: auction.id,
            //     payment_status: 154,
            //   });
            //   var res = await updateConsignment(token, auction.id, {
            //     status: 103,
            //   });
            //   if (res.status_code === 200) {
            //     navigate(history, "/dashboard?screen=auction");
            //   }
            //   toastPending({
            //     message: json.message,
            //     toastType: json.status_code,
            //   });
            // } else {
            //   // accept bid
            // console.log("Invoice generated successfully:", invoiceResponse);
              var result = await acceptBidAuction(token, acceptData);
            //   if (result.status_code === 200) {
                // console.log("Bid accepted:", result);
                navigate(history, "/dashboard?screen=auction");

                toastPending({
                    message: json.message,
                    toastType: json.status_code,
                  })
              }else {
              toastPending({
                message: json.message,
                toastType: json.status_code,
              });
            }
          },
          prefill: {
            name: data.notes.pickup_name,
            email: "",
            contact: data.notes.pickup_mobile_number,
          },
          notes: {
            address: data.notes.pickup_address_two,
          },
          theme: {
            color: "#3399cc",
          },
        };
        var payment = new window.Razorpay(options)
        payment.open()
        // payment.on('payment.failed', async function (response){
        //     await updatePayments(token, data[1].id, {
        //         consigment_id: acceptData.consignment_id,
        //         payment_status: 154
        //     })
        // })
    }

    render() {
        const { title, bidders } = this.props;
        const { keyword, filteredData, confirmation } = this.state;
        
        // var max_star = 5;
        var selectedData = keyword ? filteredData : bidders;

        return (
            <div className="bidder">
                <h4 className="details__item__header">{title}</h4>

                <Input
                    type="text"
                    inputClassName={"bidder__search"}
                    style={{ marginLeft: 0, marginRight: 0, marginTop: 20 }}
                    iconPrefix={Images.search}
                    iconPrefixClassName={"bidder__search__icon-prefix"}
                    onChange={(e) => this.onSearch(e.target.value)}
                    placeholder="Search..."
                />

                {/* {keyword && selectedData.length === 0 && (
                    <div className="details__empty" style={{ marginTop: 40 }}>
                        <img src={Images.empty} alt="empty" />
                        <p className="text text__bold text__uppercase">No search results for <i>{keyword}</i></p>
                    </div>
                )} */}

                {selectedData.length > 0 && (
                    <div>
                        {selectedData.map((item, index) => {
                            // var active_star = parseFloat(3);
                            // var inactive_star = parseFloat(max_star) - parseFloat(active_star);

                            return (
                                <div className="bidder__list" key={index}>
                                    <div className="bidder__item">
                                        <div className="flex">
                                            <p className="bidder__list__detail__item__company">{item.partner.name}</p>
                                            {/* <div className="bidder__list__detail__item__star">
                                                {this.renderActiveStar(active_star)}
                                                
                                                {inactive_star > 0 && (
                                                    this.renderInactiveStar(inactive_star)
                                                )}
                                            </div> */}
                                        </div>
                                        <div className="flex__space-between bidder__list__detail">
                                            <div>
                                                <p className="text bidder__list__detail__item__delivery">Delivery in {item.delivery_days}</p>
                                                <p className="text bidder__list__detail__item__budget">INR {item.bid_price}</p>
                                                <p className="text bidder__list__detail__item__delivery">GST({Constant.GST}%) {(item.bid_price * Constant.GST)/100 }</p>
                                            </div>
                                            
                                            {item.bid_status < 124 ? (
                                                <div className="flex bidder__list__detail__item__btn-group">
                                                    <ButtonComponent
                                                        buttonText="See Detail"
                                                        type="success"
                                                        size="sm"
                                                        variation="outline"
                                                        className="bidder__list__detail__item__btn"
                                                        onClick={() => this.onOpenDetail(item)}
                                                    />
                                                    <ButtonComponent
                                                        buttonText="Accept"
                                                        type="success"
                                                        size="sm"
                                                        className="bidder__list__detail__item__btn"
                                                        onClick={() => this.openConfirmation(
                                                            "Are you sure",
                                                            "Are you sure to accept this bid?",
                                                            () => this.onCancelConfirmation(),
                                                            () => this.onAccept(item)
                                                        )}
                                                    />
                                                </div>
                                            ) : (
                                                <span className='bidder__status'>Withdrawn</span>
                                            )}

                                        </div>
                                    </div>
                                    
                                </div>
                            )
                        })}
                    </div>
                )}

                {this.state.detail.isVisible && (
                    <AuctionBidderDetailContainer
                        isVisible={this.state.detail.isVisible}
                        onClose={() => this.onCloseDetail()}
                        data={this.state.detail.selectedBid}
                        onAccept={(params) => this.openConfirmation(
                            "Are you sure",
                            "Are you sure to accept this bid?",
                            () => this.onCancelConfirmation(),
                            () => this.onAccept(params)
                        )}
                    />
                )}

                <Confirmation
                    isVisible={confirmation.isVisible}
                    header={confirmation.header}
                    description={confirmation.description}
                    confirmationText="Yes"
                    onCancel={confirmation.onCancel}
                    onConfirm={confirmation.onConfirm}
                />
                
            </div>
        )
    }

}

const mapsStateToProps = ({ 
    authenticated, 
    consignment, 
    auction 
}) => {
    return {
        token: authenticated.token,
        partner: authenticated.userInfo.partner,
        status: consignment.status,
        bidders: auction.bid_lists,
        bid_total: auction.bid_total,
        payment_detail: auction.payment_detail
    }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { actions } = require("redux/AuctionRedux");
    const {actions: invoice} = require("redux/InvoiceRedux")
    const { actions: consignment_actions } = require("redux/ConsignmentRedux");
    
    return {
      ...ownProps,
      ...stateProps,
      fetchAuctionsBidders: async(
        token,
        auction_id
      ) => {
        dispatch({ type: "FETCH_PENDING" })
        var json = await actions.fetchAuctionsBidders(dispatch, token, auction_id)
        dispatch({ type: "FETCH_FINISH" })
        return json
      },
      acceptBidAuction: async(
        token,
        body
      ) => {
        dispatch({ type: "FETCH_PENDING" })
        var json = await actions.acceptBidAuction(dispatch, token, body)
        dispatch({ type: "FETCH_FINISH" })
        return json
      },
      clearAuctionsBidders: () => dispatch({ type: "CLEAR_AUCTIONS_BIDDERS" }),
      toastPending: (body) => {
        dispatch({ type: "TOAST_PENDING", ...body })
      },
      fetchOrderDetail: async(
        token,
        order_id
      ) => {
       dispatch({ type: "FETCH_PENDING" })
       var json = await actions.fetchOrderDetail(dispatch, token, order_id)
        dispatch({ type: "FETCH_FINISH" })
        return json
      },
      createOrder: async(
        token,
        body
      ) => {
        dispatch({ type: "FETCH_PENDING" })
        var json = await actions.createOrderAuction(dispatch, token, body)
        dispatch({ type: "FETCH_FINISH" })
        return json
      },
      fetchPaymentDetail: async(
        token,
        bid_id,
        consignment_id
      ) => {
        dispatch({ type: "FETCH_PENDING" })
        var json = await actions.fetchPaymentsDetail(dispatch, token, bid_id, consignment_id)
        dispatch({ type: "FETCH_FINISH" })
        return json
      },
      clearPaymentDetail: () => dispatch({ type: "CLEAR_PAYMENT_DETAIL" }),
      updatePayments: async(
        token,
        payment_id,
        body
      ) => {
        dispatch({ type: "FETCH_PENDING" })
        var json = await actions.updatePayments(dispatch, token, payment_id, body)
        dispatch({ type: "FETCH_FINISH" })
        return json
      },
      verifyPayments: async(
        token,
        body
      ) => {
        dispatch({ type: "FETCH_PENDING" })
        var json = await actions.verifyAuctionPayments(dispatch, token, body)
        dispatch({ type: "FETCH_FINISH" })
        return json
      },
      verifySelf: async(
        token,
        order_id,
        consignment_id
      ) => {
        dispatch({ type: "FETCH_PENDING" })
        var json = await actions.verifyAuctionPaymentSelf(dispatch, token, order_id, consignment_id)
        dispatch({ type: "FETCH_FINISH" })
        return json
      },
      generateInvoice: async (token , body) => {
        dispatch({ type: "FETCH_PENDING" });
        var json = await invoice.generateInvoice(dispatch, token, body);
        dispatch({ type: "FETCH_FINISH" });
        return json;
      },
      updateConsignment: async(
        token,
        consigment_id,
        body
      ) => {
        dispatch({ type: "FETCH_PENDING" })
        var json = await consignment_actions.updateConsignmentStatus(dispatch, token, consigment_id, body)
        dispatch({ type: "FETCH_FINISH" })
        // console.log(json)
        return json
      }
    };
}

export default connect(
    mapsStateToProps,
    undefined,
    mergeProps
)(AuctionBiddersContainer);
