import React from "react";
import { Input } from "components";
import { Tools, Constant, Languages, Images } from "common";
import { withRouter } from "react-router-dom";
import firebase from "firebase";
import { connect } from "react-redux";
import "./styles.scss";
import VerificationModal from "./verification";
import { FirebaseAPI } from "services/FirebaseAPI";

class VerifyMobileNumber extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      send: false,
      valid: false,
      data: null,
      phoneError: false,
    };
  }

  async componentDidMount() {
    const { user, type } = this.props;
    // console.log("type", type)
    var data = null;
    if (
      type === "resubmit" ||
      type === "edit-profile"
    ) {
      data = {
        ...user,
        mobile_prefix: user.mobile_prefix
          ? Constant.COUNTRY_CODE.options.find(
              (item) => item.value === user.mobile_prefix
            )
          : null,
      };
    }else if(type === "join-partner"){
      data = {}
    } else {
      var storage = localStorage.getItem("@registrationData");
      storage = JSON.parse(storage);
      data = {
        ...storage,
        mobile_prefix: Constant.COUNTRY_CODE.default};
    }

    this.setState(
      {
        data: {
          mobile_number: data ? data.mobile_number : null,
          mobile_prefix: data ? data.mobile_prefix : null,
          isUseDefaultMobileNumber: data
            ? data.mobile_number
              ? true
              : false
            : false,
        },
      },
      () => {
        if (type === "edit-profile" || type === "join-partner") {
          this.setState({
            data: {
              ...this.state.data,
              mobile_number_verified: true,
              mobile_prefix: Constant.COUNTRY_CODE.default
            },
          });
        }
        if (this.props.isVerified) {
          this.setState({
            data: {
              ...this.state.data,
              mobile_number_verified: true,
            },
          });
        }
      }
    );
  }

  onBlurValidationHandler = () => {
    if (
      !Tools.isNotEmpty(this.state.data.mobile_prefix) ||
      !Tools.isNotEmpty(this.state.data.mobile_number)
    ) {
      this.setState({
        ...this.state,
        phoneError: true,
      });
    } else {
      this.setState({
        ...this.state,
        phoneError: false,
      });
    }
  };

  onChange = (target, value) => {
    this.setState(
      {
        data: {
          ...this.state.data,
          [target]: value,
          mobile_number_verified: false,
        },
        send: false,
      },
      () => {
        this.toggleInvalidate(target, value);

        if (target === "mobile_prefix") {
          this.setState({
            data: {
              ...this.state.data,
              mobile_number: null,
            },
          });
        }
      }
    );
  };

  toggleInvalidate = async (target, value) => {
    const { user, type } = this.props;
    var data = null;
    if (
      type === "resubmit" ||
      type === "edit-profile" ||
      type === "join-partner"
    ) {
      data = user;
      data.mobile_prefix = user.mobile_prefix
        ? { label: user.mobile_prefix, value: user.mobile_prefix }
        : null;
    } else {
      data = await localStorage.getItem("@registrationData");
      data = JSON.parse(data);
    }

    if (target === "mobile_prefix") {
      var currentPrefix =
        data && data.mobile_prefix ? data.mobile_prefix.value.toString() : "";
      if (currentPrefix === value.value.toString()) {
        this.setState(
          {
            data: {
              ...this.state.data,
              mobile_number_verified: true,
            },
          },
          () => {
            this.props.isChanged(false);
          }
        );
      } else {
        this.props.isChanged(true);
        this.setState({
          data: {
            ...this.state.data,
            isUseDefaultMobileNumber: false,
          },
        });
      }
    }
    if (target === "mobile_number") {
      var currentNumber =
        data && data.mobile_number
          ? data.mobile_number.replace(/ /g, "").toString()
          : "";
      if (currentNumber === value.replace(/ /g, "").toString()) {
        this.setState(
          {
            data: {
              ...this.state.data,
              mobile_number_verified: true,
            },
          },
          () => {
            this.props.isChanged(false);
          }
        );
      } else {
        this.props.isChanged(true);
        this.setState({
          data: {
            ...this.state.data,
            isUseDefaultMobileNumber: false,
          },
        });
      }
    }
  };

  validation = () => {
    const { mobile_number, mobile_prefix } = this.state.data;

    var valid =
      Tools.isNotEmpty(mobile_number) && Tools.isNotEmpty(mobile_prefix);

    this.setState({
      valid: valid,
      send: true,
    });

    return valid;
  };

  handleRequestVerification = async () => {
    var valid = await this.validation();

    if (valid) {
      var data = {
        ...this.state.data,
        provider: "saytum",
      };

      const { type } = this.props;
      if (type === "edit-profile" || type === "join-partner") {
        this.handleSaytumRequestCode();
      } else {
        var is_account_unique = await this.isAccountUnique();
        if (is_account_unique) {
          this.onRequestVerification(data);
        }
      }
    } else {
      this.props.SET_NOTIFICATION({
        isVisible: true,
        message: "Mobile number is empty",
        type: "error",
      });
    }
  };

  handleVerifyOTP = (data) => {
    this.setState(
      {
        showVerification: false,
      },
      () => {
        const { type } = this.props;
        data = {
          ...data,
          ...this.state.data,
        };

        if (type === "edit-profile" || type === "join-partner") {
          this.handleSaytumVerifyCode(data);
        } else {
          this.verifyMobileNumber(data);
        }
      }
    );
  };

  handleSaytumRequestCode = async () => {
    const { sendOTP, toastPending, token, fetchFinish, fetchPending } =
      this.props;

    fetchPending();
    var number =
      this.state.data.mobile_prefix.value +
      this.state.data.mobile_number.replace(/ /g, "");
    var param = { phone: number };
    var is_unique_account = await FirebaseAPI.callFunctions(
      "isUniqueAccount",
      param
    );
    if (is_unique_account.status_code === 200) {
      var otp_param = {
        mobile_number: number,
        purpose: "update_profile",
        type: "sms",
      };
      var json = await sendOTP(token, otp_param);
      if (json.status_code === 200) {
        this.setState({
          showVerification: true,
        });
      }
      toastPending({ message: json.message, toastType: json.status_code });
    } else {
      toastPending({
        message: is_unique_account.message,
        toastType: is_unique_account.status_code,
      });
    }
    fetchFinish();
  };

  handleSaytumVerifyCode = async (data) => {
    const { verifyOTP, toastPending, onVerified, token } = this.props;

    var number =
      data.mobile_prefix.value + data.mobile_number.replace(/ /g, "");
    var param = {
      mobile_number: number,
      verification_code: data.verification_code,
      type: "sms",
    };
    var json = await verifyOTP(token, param);
    if (json.status_code === 200) {
      this.setState({
        data: { ...this.state.data, mobile_number_verified: true },
      });
      onVerified(data);
    } else {
      this.setState({ showVerification: true });
    }
    toastPending({ message: json.message, toastType: json.status_code });
  };

  onRequestVerification = async () => {
    const { mobile_prefix, mobile_number } = this.state.data;
    const { email } = this.props;

    if (this.state.verified) {
      await this.setState({ verified: false });
    }

    if (Tools.isNotEmpty(mobile_prefix) && Tools.isNotEmpty(mobile_number)) {
      var number = mobile_prefix.value + mobile_number.replace(/ /g, "");
      firebase.auth().languageCode = "en";

      var is_registered = false;
      var check_params = { phone: number, email: email };
      var check_mobile_number = await FirebaseAPI.callFunctions(
        "isRegisteredAccount",
        check_params
      );

      if (check_mobile_number.status_code !== 401) {
        if (check_mobile_number.status_code === 200) {
          is_registered = true;
        }

        let verify = new firebase.auth.RecaptchaVerifier("recaptcha-container");
        await firebase
          .auth()
          .signInWithPhoneNumber(number, verify)
          .then((result) => {
            const { verificationId } = result;
            this.setState(
              {
                showVerification: true,
              },
              () => {
                this.setState({
                  data: {
                    ...this.state.data,
                    verificationId: verificationId,
                  },
                  verified: true,
                  is_registered,
                });
              }
            );
          })
          .catch((err) => {
            this.props.toastPending({ message: err.message, toastType: 500 });
          });
      } else {
        this.props.toastPending({
          message: check_mobile_number.message,
          toastType: 500,
        });
      }
    } else {
      this.setState({ send: true, valid: false });
    }
  };

  isAccountUnique = async () => {
    const { fetchPending, fetchFinish, toastPending } = this.props;
    fetchPending();
    const { mobile_prefix, mobile_number } = this.state.data;
    var number = mobile_prefix.value + mobile_number.replace(/ /g, "");
    var param = { phone: number, role: "partner" };
    var is_unique_account = await FirebaseAPI.callFunctions(
      "isUniqueAccount",
      param
    );

    if (is_unique_account.status_code === 200) {
      fetchFinish();
      return true;
    }

    var message = is_unique_account.message;
    if (is_unique_account.status === "app/account-registered") {
      message = Languages.registration.credintial_registered("Mobile Number");
    }
    toastPending({
      message: message,
      toastType: is_unique_account.status_code,
    });
    fetchFinish();
    return false;
  };

  verifyMobileNumber = async (data) => {
    const { fetchPending, fetchFinish, toastPending } = this.props;

    fetchPending();
    var firebaseAuth = await FirebaseAPI.signInWithMobileNumber(data);
    if (firebaseAuth.status === 200) {
      this.setState({
        data: { ...this.state.data, mobile_number_verified: true },
      });
      if (!this.state.is_registered) {
        await FirebaseAPI.deleteUser();
      }
      toastPending({
        message: Languages.verification.success("Mobile number"),
        toastType: 200,
      });
      this.props.onVerified(data);
    } else {
      this.setState({ showVerification: true });

      toastPending({ message: firebaseAuth.message, toastType: 500 });
    }

    fetchFinish();
  };

  render() {
    const { data, verified } = this.state;
    const { type, isEmailRequired, email } = this.props;
    var inputProps = { ...this.props.inputProps };
    var selectProps = { ...this.props.inputProps };
    // console.log("mobile" , data)
    if (this.props.isResubmit) {
      inputProps = {
        ...inputProps,
        readOnly: true,
      };
      selectProps = {
        ...selectProps,
        isDisabled: true,
      };
    }

    return (
      <>
        {this.state.showVerification && (
          <VerificationModal
            isVisible={this.state.showVerification}
            onVerified={(data) => this.handleVerifyOTP(data)}
            type="mobile_number"
            onCancel={() =>
              this.setState({ verified: false, showVerification: false })
            }
            onRequestNewCode={() =>
              this.setState({ showVerification: false, verified: false }, () =>
                this.handleRequestVerification()
              )
            }
          />
        )}

        <Input
          type="phone"
          phoneCodes={Constant.COUNTRY_CODE.options}
          onSelect={(e) => this.onChange("mobile_prefix", e)}
          onChange={(e) => {
            this.setState({
              ...this.state,
              phoneError: false,
            });
            this.onChange("mobile_number", e.target.value);
          }}
          value={data ? data.mobile_number : ""}
          selectedPrefix={data ? data.mobile_prefix : null}
          iconPrefix={Images.form_phone}
          iconSuffix={Images.qrcode}
          clickableIcon={["suffix"]}
          clickableIconLabel="Verify"
          onIconClick={(position) => {
            if (position === "suffix") {
              if (isEmailRequired) {
                if (Tools.isEmailValid(email)) {
                  this.handleRequestVerification();
                }
              } else {
                this.handleRequestVerification();
              }
            }
          }}
          clickableIconClassName={`button__clickable-icon ${
            isEmailRequired && !Tools.isEmailValid(email) ? `disabled` : ``
          }`}
          style={{ margin: 0, marginBottom: 25 }}
          label="Mobile Number"
          caption={
            data &&
            !data.isUseDefaultMobileNumber &&
            (this.state.phoneError
              ? this.props.caption
                ? this.props.caption
                : type !== "edit-profile" && "This field is required"
              : !data.mobile_number_verified
              ? "Mobile number need to verified"
              : null)
          }
          isError={this.props.isError || this.state.phoneError}
          selectProps={selectProps}
          inputProps={inputProps}
          isRequired={true}
        />

        {!verified && <div id="recaptcha-container"></div>}
      </>
    );
  }
}

const mapsStateToProps = ({ authenticated }) => {
  return {
    user: authenticated.userInfo,
    token: authenticated.token,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/AuthenticatedRedux");

  return {
    ...ownProps,
    ...stateProps,
    fetchPending: () => dispatch({ type: "FETCH_PENDING" }),
    fetchFinish: () => dispatch({ type: "FETCH_FINISH" }),
    sendOTP: async (token, body) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await actions.sendOTP(dispatch, token, body);
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    verifyOTP: async (token, body) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await actions.verifyOTP(dispatch, token, body);
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    toastPending: (body) => {
      dispatch({ type: "TOAST_PENDING", ...body });
    },
  };
};

export default connect(
  mapsStateToProps,
  undefined,
  mergeProps
)(withRouter(VerifyMobileNumber));
