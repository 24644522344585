import { Modal } from 'react-bootstrap';
import React from 'react';
import "./confirmation.scss";
import images from 'common/Images';
import { Button, ButtonGroup } from 'components';


class Confirmation extends React.PureComponent {

    render() {
        return(
            <>
                <Modal className="confirmation" show={this.props.isVisible}
                    aria-labelledby="edit-name-modal" backdrop="static"
                    centered
                    backdropClassName="confirmation__backdrop"
                >
                    <Modal.Body className="confirmation__body">
                        <button className="confirmation__body__closer" onClick={this.props.onCancel}>
                            <img src={images.close} alt="confirmation-closer" />    
                        </button>
                        <img src={images.confirmation} alt="confirmation" className="confirmation__body__icon" />
                        <h2 className="confirmation__body__header">{this.props.header}</h2>
                        <p className="confirmation__body__description">{this.props.description}</p>
                            <ButtonGroup position={this.props.buttonPosition}>
                                <Button 
                                    type="default"
                                    variation="primary"
                                    buttonText={this.props.cancelText || "Cancel"}
                                    size="sm"
                                    onClick={this.props.onCancel}
                                />
                                <Button 
                                    type="danger"
                                    variation="primary"
                                    buttonText={this.props.confirmationText}
                                    size="sm"
                                    onClick={this.props.onConfirm}
                                />
                            </ButtonGroup>
                    </Modal.Body>
                </Modal>
            </>
        )
    }

}

Confirmation.defaultProps = {
    buttonPosition: "center"
}

export default (Confirmation);