import { 
    AuctionsAPI, 
    ConsignmentsAPI, 
    TrackingAPI 
} from "services";

const types = {
    FETCH_TRACKING_SUCCESS: "FETCH_TRACKING_SUCCESS",
    FETCH_TRACKING_FAILURE: "FETCH_TRACKING_FAILURE",
    FETCH_TRACKING_HISTORY_SUCCESS: "FETCH_TRACKING_HISTORY_SUCCESS",
    FETCH_TRACKING_HISTORY_FAILURE: "FETCH_TRACKING_HISTORY_FAILURE",
    CLEAR_TRACKING: "CLEAR_TRACKING",
    CLEAR_TRACKING_HISTORIES: "CLEAR_TRACKING_HISTORIES",
    UPDATE_TRACKING_LOCATION_SUCCESS: "UPDATE_TRACKING_LOCATION_SUCCESS",
    UPDATE_TRACKING_LOCATION_FAILURE: "UPDATE_TRACKING_LOCATION_FAILURE",
    MARK_AS_DELIVERED_SUCCESS: "MARK_AS_DELIVERED_SUCCESS",
    MARK_AS_DELIVERED_FAILURE: "MARK_AS_DELIVERED_FAILURE"
}


export const actions = {
    fetchTrackings: async (
        dispatch,
        token,
        role,
        user_id
    ) => {
        var json = {}
        if(role === "user") {
            json = await ConsignmentsAPI.fetchUsersConsignments(token, user_id)
        }
        else {
            json = await ConsignmentsAPI.fetchConsignments(token)
        }
        
        if(json.status_code === 200) {
            var consignments = json.consignments.filter((item) => parseFloat(item.status) >= 142);
            if(role === "user") {
                json = await AuctionsAPI.fetchUsersAuctions(token, user_id);
            }
            else if(role === "partner") {
                json = await TrackingAPI.fetchTrackings(token, user_id)
            }
            else if(role === "delivery_boy") {
                json = await TrackingAPI.fetchDeliveryBoysTasks(token, user_id)
            }
            else {
                json = await AuctionsAPI.fetchAuctions()
            }
            if(json.status_code === 200) {
                var auctions = json.auctions;
                // if(role !== "delivery_boy") {
                //     auctions = json.auctions.filter((item) => parseFloat(item.auction_status) === 112);
                // }
                var data = []
                auctions.map((item) => {
                    var c_id = role === "delivery_boy" ? item.order_number : item.consignment_id
                    consignments.map((c_item) => {
                        if(parseFloat(c_id) === parseFloat(c_item.id)) {
                            data.push({
                                ...c_item,
                                auction_id: role === "user" ? item.id : item.auction_id,
                                auction_created_date: item.created_at,
                                auction_status: role === "delivery_boy" ? item.status : item.auction_status,
                                bid_status: item.bid_status,
                                bid_price: item.bid_price
                            })
                        }
                        return true;
                    })
                    return true;
                });
                dispatch(actions.fetchTrackingsSuccess(data))
            }
            else {
                dispatch(actions.fetchTrackingsFailure(json.message))
            }
        }
        else {
            dispatch(actions.fetchTrackingsFailure(json.message))
        }
        return json;
    },
    fetchTrackingsSuccess: (items) => ({
        type: types.FETCH_TRACKING_SUCCESS,
        items
    }),
    fetchTrackingsFailure: (error) => ({
        type: types.FETCH_TRACKING_FAILURE,
        error
    }),
    fetchTrackingHistories: async(
        dispatch,
        token,
        consignment_id
    ) => {
        var json = await TrackingAPI.fetchTrackingHistories(token, consignment_id)
        if(json.status_code === 500) {
            dispatch(actions.fetchTrackingHistoriesFailure(json.message))
        }
        else {
            dispatch(actions.fetchTrackingHistoriesSuccess(json))
        }
        return json
    },
    fetchTrackingHistoriesFailure: (error) => ({
        type: types.FETCH_TRACKING_HISTORY_FAILURE,
        error
    }),
    fetchTrackingHistoriesSuccess: (items) => ({
        type: types.FETCH_TRACKING_HISTORY_SUCCESS,
        items
    }),
    updateTrackingLocations: async (
        dispatch,
        token,
        body
    ) => {
        var json = await TrackingAPI.updateTrackingLocations(token, body)
        if(json.status_code === 500) {
            dispatch(actions.updateTrackingLocationsFailure(json.message))
        }
        else {
            dispatch(actions.updateTrackingLocationsSuccess(json))
        }
        return json
    },
    updateTrackingLocationsFailure: (error) => ({
        type: types.UPDATE_TRACKING_LOCATION_FAILURE,
        error
    }),
    updateTrackingLocationsSuccess: (items) => ({
        type: types.UPDATE_TRACKING_LOCATION_SUCCESS,
        items
    }),
    markAsDelivered: async (
        dispatch,
        token,
        body
    ) => {
        var json = await TrackingAPI.markAsDelivered(token, body)
        if(json.status_code === 500) {
            dispatch(actions.markAsDeliveredFailure(json.message))
        }
        else {
            dispatch(actions.markAsDeliveredSuccess(json, body))
        }
        return json
    },
    markAsDeliveredFailure: (error) => ({
        type: types.MARK_AS_DELIVERED_FAILURE,
        error
    }),
    markAsDeliveredSuccess: (items, param) => ({
        type: types.MARK_AS_DELIVERED_SUCCESS,
        items,
        param
    }),
};

const initialState = {
    lists: [],
    total: 0,
    bidder: null,
    histories: [],
    total_histories: 0,
    error: null
}

export const reducer = (state = initialState, action) => {

    const { items, error, param } = action;

    switch (action.type) {
        case  types.FETCH_TRACKING_SUCCESS: {
            return Object.assign({}, state, {
                error: null,
                lists: items,
                total: items.length
            })
        }
        case types.FETCH_TRACKING_FAILURE: {
            return Object.assign({}, state, {
                error: error
            })
        }
        case types.CLEAR_TRACKING: {
            return Object.assign({}, state, {
                error: null,
                lists: [],
                total: 0
            })
        }
        case  types.FETCH_TRACKING_HISTORY_SUCCESS: {
            return Object.assign({}, state, {
                error: null,
                bidder: items.bidder,
                histories: items.tracking,
                total_histories: items.tracking.length
            })
        }
        case types.FETCH_TRACKING_HISTORY_FAILURE: {
            return Object.assign({}, state, {
                error: error
            })
        }
        case types.CLEAR_TRACKING_HISTORIES: {
            return Object.assign({}, state, {
                error: null,
                bidder: null,
                histories: [],
                total_histories: 0
            })
        }
        case  types.UPDATE_TRACKING_LOCATION_SUCCESS: {
            return Object.assign({}, state, {
                error: null
            })
        }
        case types.UPDATE_TRACKING_LOCATION_FAILURE: {
            return Object.assign({}, state, {
                error: error
            })
        }
        case  types.MARK_AS_DELIVERED_SUCCESS: {
            var selectedConsignment = state.lists.find((item) => parseFloat(item.id) === parseFloat(param.consignment_id))
            selectedConsignment.status = 145;

            var data = [
                selectedConsignment
            ];
            state.lists.map((item) => {
                if(parseFloat(item.id) !== parseFloat(selectedConsignment.id)) {
                    data.push(item);
                }
                return true;
            });
            data.sort((a, b) => (a.id > b.id) ? 1 : -1);

            return Object.assign({}, state, {
                error: null,
                lists: data,
                total: data.length
            })
        }
        case types.MARK_AS_DELIVERED_FAILURE: {
            return Object.assign({}, state, {
                error: error
            })
        }
        default:
            return state;
    }
}
