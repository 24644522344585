import React from 'react';
import { CreateDeliveryBoyContainer } from 'container';

class CreateDeliveryBoy extends React.PureComponent {

    render() {
        return(
            <CreateDeliveryBoyContainer
                {...this.props}
            />
        )
    }

}

export default (CreateDeliveryBoy);