import React from "react";
import "./styles.scss";
import { connect } from "react-redux"

class ShipmentCourierContainer extends React.PureComponent {

    render() {

        const {
            bidder
        } = this.props;

        var courier = bidder ? bidder : {};
        
        return (
            <div className="shipment__courier">
                
                <div className="shipment__courier__image" style={{ backgroundImage: `url(${courier.image})` }}></div>
                <div>
                    <p className="shipment__courier__name">{courier.bidder_name}</p>
                    <p className="shipment__courier__mobile-number">{courier.mobile_number}</p>
                </div>

            </div>
        )
    }

}

const mapsStateToProps = ({ tracking }) => {
    return {
        bidder: tracking.bidder
    }
}

export default connect(mapsStateToProps)(ShipmentCourierContainer);