import React from 'react';
import { LoginContainer } from 'container';
import { Tools } from 'common';
import { Redirect } from 'react-router-dom';
class Login extends React.Component {

    render() {

        var params = this.props.history.location.search;
        var search = new URLSearchParams(params);
        if(!Tools.isNotEmpty(search.get("role"))) {
            return <Redirect to="/login?role=user" />
        }

        return(
            <>

                <LoginContainer 
                    {...this.props}
                />
            </>
        )
    }

}

export default (Login);