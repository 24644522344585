import React from 'react';
import Modal from 'components/Modal';
import { Detail, DetailContent } from 'components';
import { Col, Row } from 'react-bootstrap';
import images from 'common/Images';
import Tools from 'common/Tools';
import { FirebaseAPI } from 'services/FirebaseAPI';
import ContentLoader from 'components/Loader/content';

class SpecificPartnerDetail extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            image: "",
            loading: true
        }
    }

    async componentDidUpdate() {
        const { data } = this.props;
        var image = ""
        
        if(data.partner) {
            if(Tools.isNotEmpty(data.partner.national_identity_number)) {
                image = await FirebaseAPI.getStorageFile(data.partner.national_identity_image);
            }
            else if(Tools.isNotEmpty(data.partner.passport_number)) {
                image = await FirebaseAPI.getStorageFile(data.partner.passport_image);
            }
            else if(Tools.isNotEmpty(data.partner.driving_licence_number)) {
                image = await FirebaseAPI.getStorageFile(data.partner.driving_licence_image);
            }
            this.setState({ loading: false })
        }

        this.setState({ image })
    }

    render() {

        const { data, isVisible } = this.props;
        const { innerWidth } = window;

        return(
            <Modal
                reference={(ref) => this.rejection_modal = ref}
                isVisible={isVisible}
                className="modal__full modal__form modal__detail medium"
            >
                <button className={`button__link modal__detail__closer`} onClick={() => this.props.onClose()}>
                    <img src={images.close} alt="close" />
                </button>

                <Detail
                    style={{ width: '100%', margin: 0 }}
                >
                    <DetailContent
                        style={{ width: '100%', borderWidth: 0 }}
                        header="Logistic Partner Detail"
                    >
                        {this.state.loading ? (
                            <ContentLoader 
                                isVisible={this.state.loading}
                            />
                        ) : (
                            <>
                                <div className="details__item" style={innerWidth > 768 ? { paddingTop: 30 } : { paddingTop: 10 }}>
                                    <h4 className="details__item__header">Profile Detail</h4>
                                    <div className="details__item__group">
                                        <p className="label">Name</p>
                                        <p className="text">{data.partner ? data.partner.name : ""}</p>
                                    </div>
                                    <div className="details__item__group">
                                        <p className="label">Address</p>
                                        <p className="text">{data.partner ? data.partner.address : "-"}</p>
                                    </div>
                                    <Row>
                                        <Col>
                                            <div className="details__item__group">
                                                <p className="label">Mobile Number</p>
                                                <p className="text">{data.partner ? data.partner.mobile_prefix + " " + data.partner.mobile_number : ""}</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="details__item__group">
                                                <p className="label">Email Address</p>
                                                <p className="text">{data.partner ? data.partner.email : ""}</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="details__item" style={innerWidth > 768 ? { paddingTop: 30 } : { paddingTop: 10 } }>
                                    <h4 className="details__item__header">Document Detail</h4>

                                    {data.partner && (
                                        data.partner.is_company ? (
                                            <>
                                                <Row>
                                                    <Col>
                                                        <div className="details__item__group">
                                                            <p className="label">Company Registration Number</p>
                                                            <p className="text">{data.partner.company_registration_number || "-"}</p>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="details__item__group">
                                                            <p className="label">GST Number</p>
                                                            <p className="text">{data.partner.gst_number || "-"}</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </>
                                        ) : (
                                            <>
                                                <div className="details__item__group">
                                                    <p className="label">Document Type</p>
                                                    
                                                    {Tools.isNotEmpty(data.partner.national_identity_number) && (
                                                        <p className="text">National Identity</p>
                                                    )}
                                                    {Tools.isNotEmpty(data.partner.passport_number) && (
                                                        <p className="text">Passport</p>
                                                    )}
                                                    {Tools.isNotEmpty(data.partner.driving_licence_number) && (
                                                        <p className="text">Driving Licence</p>
                                                    )}

                                                </div>
                                                <Row>
                                                    <Col>
                                                        <div className="details__item__group">
                                                            <p className="label">Document Number</p>

                                                            {Tools.isNotEmpty(data.partner.national_identity_number) && (
                                                                <p className="text">{data.partner.national_identity_number}</p>
                                                            )}
                                                            {Tools.isNotEmpty(data.partner.passport_number) && (
                                                                <p className="text">{data.partner.passport_number}</p>
                                                            )}
                                                            {Tools.isNotEmpty(data.partner.driving_licence_number) && (
                                                                <p className="text">{data.partner.driving_licence_number}</p>
                                                            )}

                                                        </div>
                                                    </Col>
                                                    {/* <Col>
                                                        <div className="details__item__group">
                                                            <p className="label">Pan Number</p>
                                                            <p className="text">{data.partner.pan_number || ""}</p>
                                                        </div>
                                                    </Col> */}
                                                </Row>
                                                <div>
                                                    <p className="label" style={{ marginBottom: 3 }}>Document Image</p>
                                                    <img src={this.state.image} alt="document" className="details__item__image" />
                                                </div>
                                            </>
                                        )
                                    )}
                                    
                                </div>
                            </>
                        )}
                    </DetailContent>
                </Detail>
                
            </Modal>
        )
    }

}

export default (SpecificPartnerDetail);