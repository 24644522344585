import { Tools, Notifications } from "common";
import { AuthenticationAPI } from "services";
import firebase from "firebase";

const types = {
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",
  REQUEST_NEW_CODE_FAILURE: "REQUEST_NEW_CODE_FAILURE",
  REQUEST_NEW_CODE_SUCCESS: "REQUEST_NEW_CODE_SUCCESS",
  SEND_RESET_PASSWORD_LINK_SUCCESS: "SEND_RESET_PASSWORD_LINK_SUCCESS",
  SEND_RESET_PASSWORD_LINK_FAILURE: "SEND_RESET_PASSWORD_LINK_FAILURE",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  SEND_OTP_SUCCESS: "SEND_OTP_SUCCESS",
  SEND_OTP_FAILURE: "SEND_OTP_FAILURE",
  VERIFY_OTP_FAILURE: "VERIFY_OTP_FAILURE",
  VERIFY_OTP_SUCCESS: "VERIFY_OTP_SUCCESS",
  UPDATE_PROFILE_SUCCESS: "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_FAILURE: "UPDATE_PROFILE_FAILURE",
  FETCH_AUTHENTICATED_USER_SUCCESS: "FETCH_AUTHENTICATED_USER_SUCCESS",
  FETCH_AUTHENTICATED_USER_FAILURE: "FETCH_AUTHENTICATED_USER_FAILURE",
  JOIN_PARTNER_FAILURE: "JOIN_PARTNER_FAILURE",
  JOIN_PARTNER_SUCCESS: "JOIN_PARTNER_SUCCESS",
  RESUBMIT_REGISTRATION_FAILURE: "RESUBMIT_REGISTRATION_FAILURE",
  RESUBMIT_REGISTRATION_SUCCESS: "RESUBMIT_REGISTRATION_SUCCESS",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_FAILURE: "REGISTER_FAILURE",
  SOCIAL_REGISTER_SUCCESS: "SOCIAL_REGISTER_SUCCESS",
  SOCIAL_REGISTER_FAILURE: "SOCIAL_REGISTER_FAILURE",
};

export const actions = {
  login: async (dispatch, body) => {
    var json = {};
    if (body.provider === "saytum") {
      if (body.role === "delivery_boy") {
        json = await AuthenticationAPI.phoneLogin(body);
      } else {
        json = await AuthenticationAPI.login(body);
      }
    } 
    // else if (body.provider === "google" || body.provider === "facebook") {
    //   json = await AuthenticationAPI.socailLogin(body);
    // }
    else {
        
        json = await AuthenticationAPI.socialLogin(body);
    }

    if (Tools.isNotEmpty(json)) {
      // console.log(json);
      if (json.status_code === 500) {
        dispatch(actions.loginFailure(json.message));
      } else {
        dispatch(actions.loginSuccess(json));
      }
    } else {
      dispatch(actions.loginFailure("Failed to fetch data from server"));
    }

    return json;
  },
  loginFailure: (error) => ({
    type: "LOGIN_FAILURE",
    error,
  }),
  loginSuccess: (items) => ({
    type: "LOGIN_SUCCESS",
    items,
  }),
  logout: async (dispatch, token, isForce = false) => {
    var json = await AuthenticationAPI.logout(token);
    if (isForce) {
      dispatch(actions.logoutSuccess(json));
    } else {
      if (json.status_code === 500) {
        dispatch(actions.logoutFailure(json.message));
      } else {
        dispatch(actions.logoutSuccess(json));
      }
    }

    return json;
  },
  logoutFailure: (error) => ({
    type: "LOGOUT_FAILURE",
    error,
  }),
  logoutSuccess: () => ({
    type: "LOGOUT_SUCCESS",
  }),
  requestNewCode: async (dispatch, body) => {
    var json = await AuthenticationAPI.requestNewCode(body);

    if (Tools.isNotEmpty(json)) {
      if (json.status_code === 500) {
        dispatch(actions.requestNewCodeFailure(json.message));
      } else {
        dispatch(actions.requestNewCodeSuccess(json));
      }
    } else {
      dispatch(
        actions.requestNewCodeFailure("Failed to fetch data from server")
      );
    }

    return json;
  },
  requestNewCodeFailure: (error) => ({
    type: "REQUEST_NEW_CODE_FAILURE",
    error,
  }),
  requestNewCodeSuccess: (items) => ({
    type: "REQUEST_NEW_CODE_SUCCESS",
    items,
  }),
  sendResetPasswordLink: async (dispatch, email) => {
    var json = await firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return {
          status_code: 200,
          message: Notifications.ResetPasswordLinkSentSuccess,
        };
      })
      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          return {
            status_code: 500,
            message: Notifications.EmailNotRegistered,
          };
        }
        return { status_code: 500, ...error };
      });

    if (json.status_code === 500) {
      dispatch(actions.sendResetPasswordLinkFailure(json.message));
    } else {
      dispatch(actions.sendResetPasswordLinkSuccess(json));
    }
    return json;
  },
  sendResetPasswordLinkFailure: (error) => ({
    type: "R",
    error,
  }),
  sendResetPasswordLinkSuccess: (items) => ({
    type: "SEND_RESET_PASSWORD_LINK_SUCCESS",
    items,
  }),
  resetPassword: async (dispatch, body) => {
    var json = await firebase
      .auth()
      .verifyPasswordResetCode(body.oobCode)
      .then(() => {
        return firebase
          .auth()
          .confirmPasswordReset(body.oobCode, body.password)
          .then(async () => {
            return {
              status_code: 200,
              message: Notifications.ResetPasswordSuccess,
            };
          })
          .catch((error) => {
            return { status_code: 500, ...error };
          });
      })
      .catch((error) => {
        return { status_code: 500, ...error };
      });

    if (json.status_code === 500) {
      dispatch(actions.resetPasswordFailure(json.message));
    } else {
      dispatch(actions.resetPasswordSuccess(json));
    }
    return json;
  },
  resetPasswordFailure: (error) => ({
    type: "RESET_PASSWORD_FAILURE",
    error,
  }),
  resetPasswordSuccess: (items) => ({
    type: "RESET_PASSWORD_SUCCESS",
    items,
  }),
  sendOTP: async (dispatch, token, body) => {
    var json = await AuthenticationAPI.sendOTP(token, body);
    if (json.status_code === 500) {
      dispatch(actions.sendOTPFailure(json.message));
    } else {
      dispatch(actions.sendOTPSuccess(json));
    }
    return json;
  },
  sendOTPFailure: (error) => ({
    type: types.SEND_OTP_FAILURE,
    error,
  }),
  sendOTPSuccess: (items) => ({
    type: types.SEND_OTP_SUCCESS,
    items,
  }),
  verifyOTP: async (dispatch, token, body) => {
    var json = await AuthenticationAPI.verifyOTP(token, body);
    if (json.status_code === 500) {
      dispatch(actions.verifyOTPFailure(json.message));
    } else {
      dispatch(actions.verifyOTPSuccess(json));
    }
    return json;
  },
  verifyOTPFailure: (error) => ({
    type: types.VERIFY_OTP_FAILURE,
    error,
  }),
  verifyOTPSuccess: (items) => ({
    type: types.VERIFY_OTP_SUCCESS,
    items,
  }),
  updateProfile: async (dispatch, token, body) => {
    var json = await AuthenticationAPI.updateProfile(token, body);
    if (json.status_code === 500) {
      dispatch(actions.updateProfileFailure(json.message));
    } else {
      dispatch(actions.updateProfileSuccess(json));
    }
    return json;
  },
  updateProfileFailure: (error) => ({
    type: types.UPDATE_PROFILE_FAILURE,
    error,
  }),
  updateProfileSuccess: (items) => ({
    type: types.UPDATE_PROFILE_SUCCESS,
    items,
  }),
  fetchAuthenticatedUser: async (dispatch, token, body) => {
    var json = await AuthenticationAPI.fetchAuthenticatedUser(token, body);
    if (json.status_code === 500) {
      dispatch(actions.fetchAuthenticatedUserFailure(json.message));
    } else {
      dispatch(actions.fetchAuthenticatedUserSuccess(json));
    }
    return json;
  },
  fetchAuthenticatedUserFailure: (error) => ({
    type: types.FETCH_AUTHENTICATED_USER_FAILURE,
    error,
  }),
  fetchAuthenticatedUserSuccess: (items) => ({
    type: types.FETCH_AUTHENTICATED_USER_SUCCESS,
    items,
  }),
  joinPartner: async (dispatch, token, user_id, body) => {
    var json = await AuthenticationAPI.joinPartner(token, user_id, body);
    if (json.status_code === 500) {
      dispatch(actions.joinPartnerFailure(json.message));
    } else {
      dispatch(actions.joinPartnerSuccess(json));
    }
    return json;
  },
  joinPartnerFailure: (error) => ({
    type: types.JOIN_PARTNER_FAILURE,
    error,
  }),
  joinPartnerSuccess: (items) => ({
    type: types.JOIN_PARTNER_SUCCESS,
    items,
  }),
  resubmitRegistration: async (dispatch, token, user_id, body) => {
    var json = await AuthenticationAPI.resubmitRegistration(
      token,
      user_id,
      body
    );
    if (json.status_code === 500) {
      dispatch(actions.resubmitRegistrationFailure(json.message));
    } else {
      dispatch(actions.resubmitRegistrationSuccess(json));
    }
    return json;
  },
  resubmitRegistrationFailure: (error) => ({
    type: types.RESUBMIT_REGISTRATION_FAILURE,
    error,
  }),
  resubmitRegistrationSuccess: (items) => ({
    type: types.RESUBMIT_REGISTRATION_SUCCESS,
    items,
  }),
  register: async (dispatch, role, body) => {
    var json = await AuthenticationAPI.register(role, body);
    if (json.status_code === 500) {
      dispatch(actions.registerFailure(json.message));
    } else {
      dispatch(actions.registerSuccess(json));
    }
    return json;
  },
  registerFailure: (error) => ({
    type: types.REGISTER_FAILURE,
    error,
  }),
  registerSuccess: (items) => ({
    type: types.REGISTER_SUCCESS,
    items,
  }),
  socialRegister: async (dispatch, provider, body) => {
    var json = await AuthenticationAPI.socialRegister(provider, body);
    if (json.status_code === 500) {
      dispatch(actions.socialRegisterFailure(json.message));
    } else {
      dispatch(actions.socialRegisterSuccess(json));
    }
    return json;
  },
  socialRegisterFailure: (error) => ({
    type: types.SOCIAL_REGISTER_FAILURE,
    error,
  }),
  socialRegisterSuccess: (items) => ({
    type: types.SOCIAL_REGISTER_SUCCESS,
    items,
  }),
};

const initialState = {
  token: null,
  userInfo: {},
  error: null,
};

export const reducer = (state = initialState, action) => {
  const { items, error } = action;

  // console.log("items", items);

  switch (action.type) {
    case types.LOGIN_FAILURE: {
      return Object.assign({}, state, {
        error: error,
      });
    }
    case types.LOGIN_SUCCESS: {
      return Object.assign({}, state, {
        ...state,
        token: items.token,
        userInfo: items,
      });
    }
    case types.LOGOUT_FAILURE: {
      return Object.assign({}, state, {
        error: error,
      });
    }
    case types.LOGOUT_SUCCESS: {
      return Object.assign({}, state, {
        ...state,
        token: null,
        userInfo: {},
      });
    }
    case types.REQUEST_NEW_CODE_FAILURE: {
      return Object.assign({}, state, {
        error: error,
      });
    }
    case types.REQUEST_NEW_CODE_SUCCESS: {
      return Object.assign({}, state, {
        ...state,
        token: null,
        userInfo: {},
      });
    }
    case types.SEND_RESET_PASSWORD_LINK_FAILURE: {
      return Object.assign({}, state, {
        error: error,
      });
    }
    case types.SEND_RESET_PASSWORD_LINK_SUCCESS: {
      return Object.assign({}, state, {
        ...state,
        token: null,
        userInfo: {},
      });
    }
    case types.RESET_PASSWORD_FAILURE: {
      return Object.assign({}, state, {
        error: error,
      });
    }
    case types.RESET_PASSWORD_SUCCESS: {
      return Object.assign({}, state, {
        ...state,
        token: null,
        userInfo: {},
      });
    }
    case types.SEND_OTP_FAILURE: {
      return Object.assign({}, state, {
        error: error,
      });
    }
    case types.SEND_OTP_SUCCESS: {
      return Object.assign({}, state, {
        error: null,
      });
    }
    case types.VERIFY_OTP_FAILURE: {
      return Object.assign({}, state, {
        error: error,
      });
    }
    case types.VERIFY_OTP_SUCCESS: {
      return Object.assign({}, state, {
        error: null,
      });
    }
    case types.UPDATE_PROFILE_FAILURE: {
      return Object.assign({}, state, {
        error: error,
      });
    }
    case types.UPDATE_PROFILE_SUCCESS: {
      return Object.assign({}, state, {
        error: null,
        userInfo: items.data[0],
      });
    }
    case types.FETCH_AUTHENTICATED_USER_FAILURE: {
      return Object.assign({}, state, {
        error: error,
      });
    }
    case types.FETCH_AUTHENTICATED_USER_SUCCESS: {
      return Object.assign({}, state, {
        error: null,
        userInfo: items,
      });
    }
    case types.JOIN_PARTNER_FAILURE: {
      return Object.assign({}, state, {
        error: error,
      });
    }
    case types.JOIN_PARTNER_SUCCESS: {
      return Object.assign({}, state, {
        error: null,
        userInfo: items.data[0],
      });
    }
    case types.RESUBMIT_REGISTRATION_FAILURE: {
      return Object.assign({}, state, {
        error: error,
      });
    }
    case types.RESUBMIT_REGISTRATION_SUCCESS: {
      return Object.assign({}, state, {
        error: null,
        userInfo: items.data[0],
      });
    }
    case types.REGISTER_FAILURE: {
      return Object.assign({}, state, {
        error: error,
      });
    }
    case types.REGISTER_SUCCESS: {
      return Object.assign({}, state, {
        error: null,
      });
    }
    case types.SOCIAL_REGISTER_FAILURE: {
      return Object.assign({}, state, {
        error: error,
      });
    }
    case types.SOCIAL_REGISTER_SUCCESS: {
      return Object.assign({}, state, {
        error: null,
        token: items.token,
      });
    }
    default:
      return state;
  }
};
