import React from "react";
import { connect } from "react-redux";
import { Button, FormBody, FormWrapper, Input } from "components";
import { Tools, navigate } from "common";

class CreateRefundsContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      speed: "optimum",
      idError: false,
      receiptError: false,
      numberError: false,
    };
  }

  onChange = (target, value) => {
    this.setState({
      send: false,
      [target]: value,
    });
  };

  validation = async () => {
    const { payment_id, amount, speed, receipt } = this.state;
    var valid =
      Tools.isNotEmpty(payment_id) &&
      Tools.isNotEmpty(amount) &&
      Tools.isNotEmpty(speed) &&
      Tools.isNotEmpty(receipt);

    this.setState({
      valid: valid,
      send: true,
    });

    return valid;
  };

  onBlurValidation = (target) => {
    if (target === "id") {
      if (!Tools.isNotEmpty(this.state.payment_id)) {
        this.setState({
          ...this.state,
          idError: true,
        });
      } else {
        this.setState({
          ...this.state,
          idError: false,
        });
      }
    }
    if (target === "number") {
      if (!Tools.isNotEmpty(this.state.amount)) {
        this.setState({
          ...this.state,
          numberError: true,
        });
      } else {
        this.setState({
          ...this.state,
          numberError: false,
        });
      }
    }
    if (target === "receipt") {
      if (!Tools.isNotEmpty(this.state.receipt)) {
        this.setState({
          ...this.state,
          receiptError: true,
        });
      } else {
        this.setState({
          ...this.state,
          receiptError: false,
        });
      }
    }
  };

  onAdd = async () => {
    this.setState({
      send: true,
    });

    var valid = await this.validation();

    if (valid) {
      const { createRefund, history, token, toastPending } = this.props;
      const { amount, payment_id, receipt, speed } = this.state;
      var json = await createRefund(token, {
        amount: amount,
        payment_id,
        receipt,
        speed,
      });
      if (json.status_code === 200) {
        navigate(history, "/dashboard?screen=refund");
      }
      toastPending({ message: json.message, toastType: json.status_code });
    }
  };

  render() {
    const { innerWidth } = window;

    return (
      <div
        className="container"
        style={
          innerWidth > 768
            ? { marginTop: 50, marginBottom: 50 }
            : { marginTop: 15, marginBottom: 15 }
        }
      >
        <FormWrapper>
          <FormBody>
            <h1
              className="text__verification__header"
              style={{ marginBottom: 0 }}
            >
              Add Refund
            </h1>
            <h3
              className="text__verification__body"
              style={{ marginBottom: 30 }}
            >
              Create new users refund
            </h3>
            <Input
              type={"text"}
              label="Payment ID"
              placeholder="Please type here"
              style={{ margin: 0, marginBottom: 30 }}
              caption={this.state.idError && "This field is required"}
              value={this.state.payment_id}
              onBlur={() => this.onBlurValidation("id")}
              isError={
                (this.state.send && !this.state.valid) || this.state.idError
              }
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  idError: false,
                });
                this.onChange("payment_id", e.target.value);
              }}
              isRequired={true}
            />
            <Input
              type={"number"}
              min="0"
              label="Amount"
              placeholder="Please type here"
              style={{ margin: 0, marginBottom: 30 }}
              caption={this.state.numberError && "This field is required"}
              value={this.state.amount}
              onBlur={() => this.onBlurValidation("number")}
              isError={
                (this.state.send && !this.state.valid) || this.state.numberError
              }
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  numberError: false,
                });
                this.onChange("amount", e.target.value);
              }}
              isRequired={true}
            />
            <Input
              type={"text"}
              label="Receipt"
              placeholder="Please type here"
              style={{ margin: 0, marginBottom: 30 }}
              caption={this.state.receiptError && "This field is required"}
              value={this.state.receipt}
              onBlur={() => this.onBlurValidation("receipt")}
              isError={
                (this.state.send && !this.state.valid) ||
                this.state.receiptError
              }
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  receiptError: false,
                });
                this.onChange("receipt", e.target.value);
              }}
              isRequired={true}
            />
            <Button
              buttonText="Create Refund"
              size="lg"
              type="warning"
              style={{ margin: 0 }}
              onClick={() => this.onAdd()}
            />
          </FormBody>
        </FormWrapper>
      </div>
    );
  }
}

const mapsStateToProps = ({ authenticated }) => {
  return {
    token: authenticated.token,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/PaymentRedux");

  return {
    ...ownProps,
    ...stateProps,
    createRefund: async (token, body) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await actions.createRefund(dispatch, token, body);
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    toastPending: (body) => {
      dispatch({ type: "TOAST_PENDING", ...body });
    },
  };
};

export default connect(
  mapsStateToProps,
  undefined,
  mergeProps
)(CreateRefundsContainer);
