
import greeting from 'images/landing-page-greeting.png';
import mission from 'images/landing-page-mission.png';
import download from 'images/landing-page-download.png';
import address from 'images/icon/landing-map.svg';
import phone from 'images/icon/landing-call.svg';
import email from 'images/icon/landing-email.svg';
import logo_white from 'images/icon/logo-white.svg';
import form_email from 'images/icon/email.svg';
import password from 'images/icon/key.svg';
import eye from 'images/icon/eye.svg';
import eye_close from 'images/icon/eye-close.svg';
import user from 'images/icon/user.svg';
import form_edit from 'images/icon/edit.svg';
import active_personal_tab from 'images/icon/active-personal-tab.png';
import inactive_personal_tab from 'images/icon/inactive-personal-tab.png';
import active_company_tab from 'images/icon/active-company-tab.png';
import inactive_company_tab from 'images/icon/inactive-company-tab.png';
import location from 'images/icon/pin.svg';
import qrcode from 'images/icon/qrcode.svg';
import identity_card from 'images/aadhaar.png';
import passport from 'images/passport.png';
import licence from 'images/license.png';
import arrow_left from 'images/icon/arrow-left.svg';
import dashboard from 'images/icon/dashboard.svg';
import dashboard_active from 'images/icon/dashboard-primary.svg';
import job from 'images/icon/jobs.svg';
import cubic from 'images/icon/cubic.svg';
import cubic_primary from 'images/icon/cubic-primary.svg';
import draft_green from 'images/icon/draft-green.svg';
import shopping_cart from 'images/icon/shopping-cart.svg';
import auction_active from 'images/icon/auction-active.svg';
import auction from 'images/icon/auction.svg';
import close from 'images/icon/close.svg';
import success_plane from 'images/icon/success-plane.svg';
import warning from 'images/icon/warn.svg';
import send from 'images/icon/send-white.svg';
import upload_error from 'images/icon/upload-danger.png';
import upload from 'images/icon/upload.png';
import plus from 'images/icon/plus-sign.svg';
import bell from 'images/icon/bell.svg';
import whatsapp from 'images/icon/whatsapp.png';
import call_home from 'images/icon/call-home.png';
import google from 'images/icon/google-icon.png';
import facebook from 'images/icon/facebook-icon.png';
import confirmation from 'images/image/confirmation.png';
import banner_login from 'images/user-login.png';
import form_map from 'images/icon/form-map.svg';
import form_phone from 'images/icon/form-phone.svg';
import arrow_left_grey from 'images/icon/arrow-left-grey.svg';
import down_arrow from 'images/icon/down-arrow.png';
import up_arrow from 'images/icon/up-arrow.png';
import datepicker from 'images/icon/datepicker.svg';
import activate from 'images/icon/activate.png';
import empty from 'images/image/empty.png';
import active_star from 'images/icon/active-star.png';
import inactive_star from 'images/icon/inactive-star.png';
import search from 'images/icon/search.png';
import user_profile from 'images/image/user.png';
import arrow_right from 'images/icon/arrow-right.svg';
import next_arrow from 'images/icon/next-arrow.png';
import profile from 'images/image/user.png';
import filter from 'images/icon/filter.svg';
import calendar from "images/icon/calendar.svg";
import calendar_active from "images/icon/calendar-primary.svg";
import camera from "images/icon/camera.png";
import close_dark from "images/icon/close.png";
import rotate from "images/icon/rotate.png";
import avatar from "images/programmer.png";
import refresh from "images/icon/refresh.png";
import android_google from "images/icon/android.png";

const images = {
    greeting,
    mission,
    download,
    address,
    phone,
    email,
    logo_white,
    form_email,
    password,
    eye,
    eye_close,
    user,
    form_edit,
    active_personal_tab,
    active_company_tab,
    inactive_personal_tab,
    inactive_company_tab,
    location,
    qrcode,
    identity_card,
    passport,
    licence,
    arrow_left,
    dashboard,
    dashboard_active,
    job,
    cubic,
    cubic_primary,
    draft_green,
    shopping_cart,
    auction,
    auction_active,
    close,
    success_plane,
    warning,
    send,
    upload,
    upload_error,
    plus,
    bell,
    whatsapp,
    call_home,
    google,
    facebook,
    confirmation,
    banner_login,
    form_map,
    form_phone,
    arrow_left_grey,
    down_arrow,
    up_arrow,
    datepicker,
    activate,
    empty,
    active_star,
    inactive_star,
    search,
    user_profile,
    arrow_right,
    next_arrow,
    profile,
    filter,
    calendar,
    calendar_active,
    camera,
    close_dark,
    rotate,
    avatar,
    refresh,
    android_google
}

export default images;