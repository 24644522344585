import { navigate, Notifications, Tools } from 'common';
import React from 'react';
import { connect } from 'react-redux';
import { FirebaseAPI } from 'services/FirebaseAPI';

class Verification extends React.PureComponent {

    componentDidMount() {
        const { history } = this.props
        var params = this.props.history.location.search;

        if(Tools.getParamFromURL(history, "mode") === "verifyEmail") {
            var oobCode = Tools.getParamFromURL(history, "oobCode");
            this.verifyEmail(oobCode)
        }
        else {
            navigate(history, "/reset-password" + params, "replace");
        }
    }

    verifyEmail = async(oobCode) => {
        const {
            fetchPending,
            fetchFinish,
            toastPending
        } = this.props
        fetchPending()
        await FirebaseAPI.verifyEmail(oobCode).then(async(response) => {
            
            localStorage.removeItem("@verificationData");

            if(response.fetch_status === "success") {
                toastPending({ message: Notifications.AccountVerifiedSuccess, toastType: 200 })
                fetchFinish()
                navigate(this.props.history, `/`, "replace");
            }
            else {
                var message = response.message;
                if(response.code === "auth/invalid-action-code") {
                    message = Notifications.VerificationLinkExpired;
                }
                toastPending({ message: message, toastType: 500 })
                fetchFinish()
                navigate(this.props.history, "/", "replace")
            }
        }).catch((error) => {
            toastPending({ message: error.message, toastType: 500 })
            fetchFinish()
            navigate(this.props.history, "/", "replace")
        });
    }

    render() {
        return(
            <div className="container container__center" style={{ marginTop: 30, marginBottom: 24 }}>
                <h1>Verifying your account...</h1>
            </div>
        )
    }

}


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;;

    return {
      ...ownProps,
      ...stateProps,
      fetchPending: () => dispatch({ type: "FETCH_PENDING" }),
      fetchFinish: () => dispatch({ type: "FETCH_FINISH" }),
      toastPending: (body) => {
        dispatch({ type: "TOAST_PENDING", ...body })
      }
    };
}

export default connect(
    undefined, 
    undefined,
    mergeProps
)(Verification);