import React from "react";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import {
  Button,
  ButtonGroup,
  FormWrapper,
  FormHeader,
  FormBody,
  Input,
  FormGroup,
} from "components";
import { Tools, Constant } from "common";

var validation = {
  delivery_name: "required",
  delivery_mobile_number: "required",
  delivery_mobile_prefix: "required",
  delivery_address_one: "required",
  delivery_address_two: "required",
  delivery_countries: "required",
  delivery_states: "required",
  delivery_cities: "required",
  delivery_postal_code: "required",
};

class CreateConsignmentStepTwo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      states: [],
      cities: [],
      data: {
        delivery_mobile_prefix: Constant.COUNTRY_CODE.default,
        Postal_Code: Constant.Postal_Code.postalCodeLength,
      },
      errors: [],
      nameError: false,
      nameErrorMessage: "",
      mobileError: false,
      countryError: false,
      stateError: false,
      cityError: false,
      postalCodeError: false,
      pickupPostalCodeErrorMessage: "",
      addressOneError: false,
      addressTwoError: false,
    };
  }

  async UNSAFE_componentWillMount() {
    await this.getCountries();
    this.initialData();
  }

  getCountries = async () => {
    const { fetchCountries, token } = this.props;
    fetchCountries(token);
  };

  initialData = async () => {
    var data = localStorage.getItem("@createConsignment");
    if (data) {
      data = JSON.parse(data);

      var state_options = [];
      var city_options = [];

      if (Tools.isNotEmpty(data.delivery_countries)) {
        const { countries, fetchStates, token } = this.props;
        var selectedCountry = countries.find(
          (item) => item.id === data.delivery_countries.id
        );
        var states = await fetchStates(token, selectedCountry.id);
        states.data.data.map((item) => {
          state_options.push({
            label: item.name,
            value: item.name,
            code: item.code,
          });
          return true;
        });
      }
      if (Tools.isNotEmpty(data.delivery_states)) {
        const { states, fetchCities, token } = this.props;
        var selectedState = states.find(
          (item) => item.code === data.delivery_states.code
        );
        var cities = await fetchCities(token, selectedState.code);
        cities.data.data.map((item) => {
          city_options.push({
            label: item.name,
            value: item.name,
            code: item.code,
          });
          return true;
        });
      }

      this.setState({
        data: {
          ...this.state.data,
          ...data,
        },
        states: state_options,
        cities: city_options,
      });
    }
  };

  onChange = (target, value) => {
    this.setState(
      {
        send: false,
        data: {
          ...this.state.data,
          [target]: value,
          country_changed: false,
          state_changed: false,
        },
      },
      async () => {
        var data = [];
        var json = null;

        if (target === "delivery_countries") {
          const { fetchStates, token } = this.props;
          json = await fetchStates(token, value.code);
          data = [];
          json.data.data.filter((item) => {
            data.push({
              label: item.name,
              value: item.name,
              code: item.code,
            });
            return true;
          });
          this.setState({
            states: data,
            country_changed: true,
            data: { ...this.state.data, delivery_states: null },
          });
        } else if (target === "delivery_states") {
          const { fetchCities, token } = this.props;
          json = await fetchCities(token, value.code);
          data = [];
          json.data.data.filter((item) => {
            data.push({
              label: item.name,
              value: item.name,
              code: item.code,
            });
            return true;
          });
          this.setState({
            cities: data,
            state_changed: true,
            country_changed: false,
            data: { ...this.state.data, delivery_cities: null },
          });
        } else if (target === "delivery_cities") {
          this.setState({ state_changed: false });
        }
        // else if (target === "delivery_mobile_number") {
        //   if( this.state.data.delivery_mobile_number.length <= 10 )
        //   {
        //     this.setState({
        //       ...this.state,
        //       mobileError: true,
        //     });
        //   }
        // }
      }
    );
  };

  validation = async () => {
    var validate = {};
    validate = await Tools.validation(validation, this.state.data);

    this.setState({
      valid: validate.valid,
      errors: validate.errors,
    });

    return validate.valid;
  };

  onNext = async () => {
    const valid = await this.validation();
    this.setState({
      send: true,
    });

    if (valid) {
      const { data } = this.state;
      var consignment = localStorage.getItem("@createConsignment");
      if (consignment) {
        consignment = JSON.parse(consignment);
        consignment = {
          ...consignment,
          ...data,
        };
      } else {
        consignment = {
          ...data,
        };
      }
      this.saveLocally(consignment);
      localStorage.setItem("@createConsignment", JSON.stringify(consignment));
      this.props.onNext();
    }
  };

  saveLocally = (local) => {
    const { local_consignment, setLocalConsingment } = this.props;
    var local_consignments = local_consignment || [];

    const { data } = this.state;
    var selected_consignments = local_consignments.find(
      (item) => item.id === local.id
    );

    if (selected_consignments) {
      selected_consignments = {
        ...selected_consignments,
        ...data,
      };
      var index = local_consignments
        .map(function (e) {
          return e.id;
        })
        .indexOf(local.id);

      local_consignments[index] = selected_consignments;

      setLocalConsingment(local_consignments);
    }

    return true;
  };

  onBlurValidation = (target) => {
    if (target === "name") {
      if (!Tools.isNotEmpty(this.state.data.delivery_name)) {
        this.setState({
          ...this.state,
          nameError: true,
          nameErrorMessage: "This field is required"
        });
      } else if (!Tools.isNameValid(this.state.data.delivery_name)) {
        this.setState({
          ...this.state,
          nameError: true,
          nameErrorMessage: "Special characters and Numbers are not allowed",
        });
      } else {
        this.setState({
          ...this.state,
          nameError: false,
        });
      }
    }
    if (target === "mobile") {
      const countryCode = this.state.data.delivery_mobile_prefix;
      // console.log("countryCode",countryCode)
      const mobileNumber = this.state.data.delivery_mobile_number;
      // console.log("what", mobileNumber);
      // console.log("countryLenght", countryCode.length);
      if (
        !Tools.isNotEmpty(this.state.data.delivery_mobile_number) ||
        !Tools.isNotEmpty(this.state.data.delivery_mobile_prefix)
      ) {
        this.setState({
          ...this.state,
          mobileError: true,
        });
      } else if (mobileNumber.length !== countryCode.length) {
        this.setState({
          ...this.state,
          mobileError: true,
        });
      } else {
        this.setState({
          ...this.state,
          mobileError: false,
        });
      }
    }
    if (target === "country") {
      if (!Tools.isNotEmpty(this.state.data.delivery_countries)) {
        this.setState({
          ...this.state,
          countryError: true,
        });
      } else {
        this.setState({
          ...this.state,
          countryError: false,
        });
      }
    }
    if (target === "state") {
      if (!Tools.isNotEmpty(this.state.data.delivery_states)) {
        this.setState({
          ...this.state,
          stateError: true,
        });
      } else {
        this.setState({
          ...this.state,
          stateError: false,
        });
      }
    }
    if (target === "city") {
      if (!Tools.isNotEmpty(this.state.data.delivery_cities)) {
        this.setState({
          ...this.state,
          cityError: true,
        });
      } else {
        this.setState({
          ...this.state,
          cityError: false,
        });
      }
    }
    if (target === "postalCode") {
      const postalLength = this.state.data.delivery_postal_code;
      // console.log("deliverycode" , postalLength);
      // console.log("length", postalLength.length);
      const postal_code = this.state.data.Postal_Code;
      // console.log("code", postal_code);
      if (!Tools.isNotEmpty(this.state.data.delivery_postal_code)) {
        this.setState({
          ...this.state,
          postalCodeError: true,
          pickupPostalCodeErrorMessage: "This is a required field",
        });
      } else if (
        postalLength.length < postal_code ||
        postalLength.length > postal_code
      ) {
        this.setState({
          ...this.state,
          postalCodeError: true,
          pickupPostalCodeErrorMessage: "Invalid Postal Code",
        });
      } else {
        this.setState({
          ...this.state,
          postalCodeError: false,
        });
      }
    }
    if (target === "addressOne") {
      if (!Tools.isNotEmpty(this.state.data.delivery_address_one)) {
        this.setState({
          ...this.state,
          addressOneError: true,
        });
      } else {
        this.setState({
          ...this.state,
          addressOneError: false,
        });
      }
    }
    if (target === "addressTwo") {
      if (!Tools.isNotEmpty(this.state.data.delivery_address_two)) {
        this.setState({
          ...this.state,
          addressTwoError: true,
        });
      } else {
        this.setState({
          ...this.state,
          addressTwoError: false,
        });
      }
    }
  };

  render() {
    const { data, states, cities } = this.state;
    const { countries, onPrevious } = this.props;
    const { innerWidth } = window;

    var country_datas = [];
    countries.map((item) => {
      country_datas.push({ label: item.name, value: item.name, id: item.id });
      return true;
    });

    return (
      <div
        style={
          innerWidth > 768
            ? { marginTop: 50, marginBottom: 50 }
            : { marginTop: 15, marginBottom: 20 }
        }
      >
        <FormWrapper>
          <FormHeader title="delivery" />
          <FormBody>
            <Input
              type="text"
              label="Receiver Name"
              placeholder="Please type here"
              style={{ marginBottom: 20 }}
              value={data.delivery_name}
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  nameError: false,
                });
                this.onChange("delivery_name", e.target.value);
              }}
              onBlur={() => this.onBlurValidation("name")}
              caption={this.state.nameError && this.state.nameErrorMessage}
              isError={
                (this.state.send &&
                  !this.state.valid &&
                  this.state.errors.includes("delivery_name")) ||
                this.state.nameError
              }
              isRequired={true}
            />
            <Input
              type="phone"
              label="Mobile Number"
              phoneCodes={Constant.COUNTRY_CODE.options}
              placeholder="Please type here"
              style={{ marginBottom: 20 }}
              onSelect={(e) => this.onChange("delivery_mobile_prefix", e)}
              selectedPrefix={data.delivery_mobile_prefix}
              value={data.delivery_mobile_number}
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  mobileError: false,
                });
                this.onChange("delivery_mobile_number", e.target.value);
              }}
              onBlur={() => this.onBlurValidation("mobile")}
              caption={this.state.mobileError && "Please enter valid mobile number "}
              isError={
                (this.state.send &&
                  !this.state.valid &&
                  (this.state.errors.includes("delivery_mobile_prefix") ||
                    this.state.errors.includes("delivery_mobile_number"))) ||
                this.state.mobileError
              }
              isRequired={true}
            />
            <FormGroup>
              <Col lg={6} md={6} sm={6} xs={6}>
                <Input
                  type="select"
                  label="Country"
                  options={country_datas}
                  placeholder="Please type here"
                  style={{ marginBottom: 20 }}
                  value={data.delivery_countries}
                  onChange={(e) => {
                    this.setState({
                      ...this.state,
                      countryError: false,
                    });
                    this.onChange("delivery_countries", e);
                  }}
                  // onBlur={() => this.onBlurValidation("country")}
                  caption={this.state.countryError && "This field is required"}
                  isError={
                    (this.state.send &&
                      !this.state.valid &&
                      this.state.errors.includes("delivery_countries")) ||
                    this.state.countryError
                  }
                  isRequired={true}
                />
              </Col>
              <Col lg={6} md={6} sm={6} xs={6}>
                <Input
                  type="select"
                  label="State"
                  options={states}
                  placeholder="Please type here"
                  style={{ marginBottom: 20 }}
                  value={this.state.country_changed ? {} : data.delivery_states}
                  onChange={(e) => {
                    this.setState({
                      ...this.state,
                      stateError: false,
                    });
                    this.onChange("delivery_states", e);
                  }}
                  // onBlur={() => this.onBlurValidation("state")}
                  caption={this.stateError && "This field is required"}
                  isError={
                    (this.state.send &&
                      !this.state.valid &&
                      this.state.errors.includes("delivery_states")) ||
                    this.state.stateError
                  }
                  isRequired={true}
                />
              </Col>
            </FormGroup>
            <FormGroup>
              <Col lg={6} md={6} sm={6} xs={6}>
                <Input
                  type="select"
                  label="City"
                  options={cities}
                  placeholder="Please type here"
                  style={{ marginBottom: 20 }}
                  value={this.state.state_changed ? {} : data.delivery_cities}
                  onChange={(e) => {
                    this.setState({
                      ...this.state,
                      cityError: false,
                    });
                    this.onChange("delivery_cities", e);
                  }}
                  // onBlur={() => this.onBlurValidation("city")}
                  caption={this.state.cityError && "This field is required"}
                  isError={
                    (this.state.send &&
                      !this.state.valid &&
                      this.state.errors.includes("delivery_cities")) ||
                    this.state.cityError
                  }
                  isRequired={true}
                />
              </Col>
              <Col lg={6} md={6} sm={6} xs={6}>
                <Input
                  type="number"
                  min="1"
                  label="Postal Code"
                  placeholder="Please type here"
                  style={{ marginBottom: 20 }}
                  value={data.delivery_postal_code}
                  onChange={(e) => {
                    this.setState({
                      ...this.state,
                      postalCodeError: false,
                    });
                    this.onChange("delivery_postal_code", e.target.value);
                  }}
                  onBlur={() => this.onBlurValidation("postalCode")}
                  caption={
                    this.state.postalCodeError &&
                    this.state.pickupPostalCodeErrorMessage
                  }
                  isError={
                    (this.state.send &&
                      !this.state.valid &&
                      this.state.errors.includes("delivery_postal_code")) ||
                    this.state.postalCodeError
                  }
                  isRequired={true}
                />
              </Col>
            </FormGroup>

            <Input
              type="autocomplete"
              label="Address One"
              placeholder="Please type here"
              style={{ marginBottom: 20 }}
              value={data.delivery_address_one}
              countries={
                data.delivery_countries ? data.delivery_countries.value : null
              }
              inputProps={{
                readOnly: data.delivery_countries ? false : true,
              }}
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  addressOneError: false,
                });
                this.onChange("delivery_address_one", e);
              }}
              onBlur={() => this.onBlurValidation("addressOne")}
              caption={this.state.addressOneError && "This field is required"}
              isError={
                (this.state.send &&
                  !this.state.valid &&
                  this.state.errors.includes("delivery_address_one")) ||
                this.state.addressOneError
              }
              isRequired={true}
            />
            <Input
              type="text"
              label="Address Two"
              placeholder="Please type here"
              style={{ marginBottom: 20 }}
              value={data.delivery_address_two}
              inputProps={{
                readOnly: data.delivery_countries ? false : true,
              }}
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  addressTwoError: false,
                });
                this.onChange("delivery_address_two", e.target.value);
              }}
              onBlur={() => this.onBlurValidation("addressTwo")}
              caption={this.state.addressTwoError && "This field is required"}
              isError={
                (this.state.send &&
                  !this.state.valid &&
                  this.state.errors.includes("delivery_address_two")) ||
                this.state.addressTwoError
              }
              isRequired={true}
            />

            <ButtonGroup position="right">
              <Button
                buttonText="Back"
                type="default"
                size="sm"
                onClick={onPrevious}
              />
              <Button
                buttonText="Save"
                type="success"
                size="sm"
                onClick={() => this.onNext()}
              />
            </ButtonGroup>
          </FormBody>
        </FormWrapper>
      </div>
    );
  }
}

const mapsStatetoProps = ({ authenticated, locations, consignment }) => {
  return {
    token: authenticated.token,
    cities: locations.cities,
    states: locations.states,
    countries: locations.countries,
    local_consignment: consignment.local_data,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions: location_actions } = require("redux/LocationRedux");

  return {
    ...ownProps,
    ...stateProps,
    fetchCountries: async (token) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await location_actions.fetchCountries(dispatch, token);
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    fetchStates: async (token, country_id) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await location_actions.fetchStates(
        dispatch,
        token,
        country_id
      );
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    fetchCities: async (token, state_code) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await location_actions.fetchCities(dispatch, token, state_code);
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    toastPending: (body) => {
      dispatch({ type: "TOAST_PENDING", ...body });
    },
    setLocalConsingment: (items) =>
      dispatch({ type: "SET_LOCAL_CONSIGNMENT", items }),
  };
};

export default connect(
  mapsStatetoProps,
  undefined,
  mergeProps
)(CreateConsignmentStepTwo);
