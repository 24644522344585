import React from 'react';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import "./toast.scss";

class Toast extends React.PureComponent {

    render() {

        const { type, message } = this.props;
        var toastId = type;

        if(type === 200) {
            toast.success(message, {
                toastId: toastId,
                onClose: () => {
                    this.props.TOAST_FINISH()
                }
            });
        }
        if(type === 500) {
            toast.error(message, {
                toastId: toastId,
                onClose: () => {
                    this.props.TOAST_FINISH()
                }
            });
        }
        
        return(
            <ToastContainer 
                draggable={false}
                position="top-right"
                autoClose={this.props.duration}
                toastClassName="toast__container"
                bodyClassName="toast__container__body"     
            />
        )
    }

}

const mapsDispatchToState = (dispatch) => {
    return {
        TOAST_FINISH: () => dispatch({ type: 'TOAST_FINISH' })
    }
}

export default connect(null, mapsDispatchToState)(Toast);