import { FleetCategoriesAPI } from "services"

const types = {
    FETCH_FLEET_CATEGORIES_SUCCESS: "FETCH_FLEET_CATEGORIES_SUCCESS",
    FETCH_FLEET_CATEGORIES_FAILURE: "FETCH_FLEET_CATEGORIES_FAILURE",
    CLEAR_FLEET_CATEGORIES: "CLEAR_FLEET_CATEGORIES",
    DELETE_FLEET_CATEGORIES_SUCCESS: "DELETE_FLEET_CATEGORIES_SUCCESS",
    DELETE_FLEET_CATEGORIES_FAILURE: "DELETE_FLEET_CATEGORIES_FAILURE",
    CREATE_FLEET_CATEGORIES_SUCCESS: "CREATE_FLEET_CATEGORIES_SUCCESS",
    CREATE_FLEET_CATEGORIES_FAILURE: "CREATE_FLEET_CATEGORIES_FAILURE",
    UPDATE_FLEET_CATEGORIES_SUCCESS: "UPDATE_FLEET_CATEGORIES_SUCCESS",
    UPDATE_FLEET_CATEGORIES_FAILURE: "UPDATE_FLEET_CATEGORIES_FAILURE"
}

export const actions = {
    fetchFleetCategories: async(
        dispatch,
        token
    ) => {
        var json = await FleetCategoriesAPI.fetchCategories(token)
        if(json.status_code === 500) {
            dispatch(actions.fetchFleetCategoriesFailure(json.message))
        }
        else {
            dispatch(actions.fetchFleetCategoriesSuccess(json))
        }
    },
    fetchFleetCategoriesFailure: (error) => ({
        type: types.FETCH_FLEET_CATEGORIES_FAILURE,
        error
    }),
    fetchFleetCategoriesSuccess: (items) => ({
        type: types.FETCH_FLEET_CATEGORIES_SUCCESS,
        items
    }),
    deleteFleetCategories: async(
        dispatch,
        token,
        id
    ) => {
        var json = await FleetCategoriesAPI.deleteCategories(token, id)
        if(json.status_code === 500) {
            dispatch(actions.deleteFleetCategoriesFailure(json.message))
        }
        else {
            dispatch(actions.deleteFleetCategoriesSuccess(json))
        }
        return json
    },
    deleteFleetCategoriesFailure: (error) => ({
        type: types.DELETE_FLEET_CATEGORIES_FAILURE,
        error
    }),
    deleteFleetCategoriesSuccess: (items) => ({
        type: types.DELETE_FLEET_CATEGORIES_SUCCESS,
        items
    }),
    createFleetCategories: async(
        dispatch,
        token,
        body
    ) => {
        var json = await FleetCategoriesAPI.createFleetCategories(token, body)
        if(json.status_code === 500) {
            dispatch(actions.createFleetCategoriesFailure(json.message))
        }
        else {
            dispatch(actions.createFleetCategoriesSuccess(json))
        }
        return json
    },
    createFleetCategoriesFailure: (error) => ({
        type: types.CREATE_FLEET_CATEGORIES_FAILURE,
        error
    }),
    createFleetCategoriesSuccess: (items) => ({
        type: types.CREATE_FLEET_CATEGORIES_SUCCESS,
        items
    }),
    updateFleetCategories: async(
        dispatch,
        token,
        category_id,
        body
    ) => {
        var json = await FleetCategoriesAPI.updateFleetCategory(token, category_id, body)
        if(json.status_code === 500) {
            dispatch(actions.updateFleetCategoriesFailure(json.message))
        }
        else {
            dispatch(actions.updateFleetCategoriesSuccess(json))
        }
        return json
    },
    updateFleetCategoriesFailure: (error) => ({
        type: types.UPDATE_FLEET_CATEGORIES_FAILURE,
        error
    }),
    updateFleetCategoriesSuccess: (items) => ({
        type: types.UPDATE_FLEET_CATEGORIES_SUCCESS,
        items
    }),
}

const initialState = {
    lists: [],
    total: 0,
    error: null
}

export const reducer = (state = initialState, action) => {

    const { items, error } = action;

    var data = []
    var selectedFleet = null

    switch (action.type) {
        case types.FETCH_FLEET_CATEGORIES_FAILURE: {
            return Object.assign({}, state, {
                error: error
            })
        }
        case types.FETCH_FLEET_CATEGORIES_SUCCESS: {
            return Object.assign({}, state, {
                error: null,
                lists: items.data.data,
                total: items.data.data.length
            })
        }
        case types.CLEAR_FLEET_CATEGORIES: {
            return Object.assign({}, state, {
                error: null,
                lists: [],
                total: 0
            })
        }
        case types.DELETE_FLEET_CATEGORIES_FAILURE: {
            return Object.assign({}, state, {
                error: error
            })
        }
        case types.DELETE_FLEET_CATEGORIES_SUCCESS: {
            selectedFleet = state.lists.find((item) => parseFloat(item.id) === parseFloat(items.data.data))
            data = []
            state.lists.map((item) => {
                if(parseFloat(item.id) !== parseFloat(selectedFleet.id)) {
                    data.push(item)
                }
                return true
            })
            data.sort((a, b) => (a.id > b.id) ? 1 : -1);

            return Object.assign({}, state, {
                error: null,
                lists: data,
                total: data.length
            })
        }
        case types.CREATE_FLEET_CATEGORIES_FAILURE: {
            return Object.assign({}, state, {
                error: error
            })
        }
        case types.CREATE_FLEET_CATEGORIES_SUCCESS: {
            return Object.assign({}, state, {
                error: null,
                lists: items.data.data,
                total: items.data.data.length
            })
        }
        case types.UPDATE_FLEET_CATEGORIES_FAILURE: {
            return Object.assign({}, state, {
                error: error
            })
        }
        case types.UPDATE_FLEET_CATEGORIES_SUCCESS: {
            return Object.assign({}, state, {
                error: null,
                lists: items.data.data,
                total: items.data.data.length
            })
        }
        default:
            return state;
    }
}
