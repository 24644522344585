import { navigate } from 'common/Tools';
import { Button } from 'components';
import React from 'react';
import { withRouter } from 'react-router-dom';


class ProfileNotCompleteHeader extends React.PureComponent {

    render() {

        return (
            <div className="floating-navigation danger with-button">
                <div className="flex">
                    <p className="floating-navigation__label">Complete your profile to continue!</p>
                </div>
                <Button
                    buttonText="Edit Profile"
                    className="floating-navigation__btn"
                    type="danger"
                    size="sm"
                    onClick={() => navigate(this.props.history, "/profile/edit")}
                />
            </div>
        )
    }

}

export default withRouter(ProfileNotCompleteHeader);