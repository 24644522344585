import React from "react";
import DatePicker from "react-datepicker";
import "./datepicker.scss";

class InputDatePicker extends React.PureComponent {
  render() {
    const { isRequired, selectedTime } = this.props;
    const currentDate = new Date();
    const tomorrow = new Date(currentDate);
    tomorrow.setDate(currentDate.getDate() + 1);
    const defaultDate = selectedTime === "future" && !this.props.value ? tomorrow : this.props.value;
    
    return (
      <div className="input" style={this.props.style}>
        <label
          className={`input__label ${
            this.props.isError ? "input__label__error" : undefined
          }`}
        >
          {this.props.label}
          {isRequired && <span className="input__required">*</span>}
        </label>

        {this.props.iconPrefix && (
          <img
            src={this.props.iconPrefix}
            alt="form-icon-prefix"
            className="input__icon input__icon__prefix"
          />
        )}

        {this.props.iconSuffix && (
          <img
            src={this.props.iconSuffix}
            alt="form-icon-suffix"
            className={`input__icon input__icon__suffix ${this.props.iconSuffixClassName}`}
          />
        )}

        {this.props.iconPrefix && this.props.iconSuffix ? (
          <DatePicker
            className={`input__picker__control input__picker__control__with-icon__prefix  input__picker__control__with-icon__suffix ${
              this.props.isError ? "input__picker__control__error" : undefined
            }`}
            wrapperClassName="input__picker"
            popperClassName="input__picker__popper"
            calendarClassName="input__picker__calendar"
            allowSameDay
            dateFormat="dd MMMM yyyy HH:mm"
            timeIntervals={30}
            timeFormat="HH:mm"
            minTime={selectedTime === "same_day" ? new Date() : new Date().setHours(0, 0)}
            maxTime={selectedTime === "same_day" ? new Date(currentDate.setHours(23, 59)) : new Date().setHours(23, 59)}
            minDate={selectedTime === "same_day" ? new Date() : selectedTime === "future" ? tomorrow : null}
            maxDate={selectedTime === "same_day" ? new Date() : null}
            placeholderText={this.props.placeholder}
            onChange={(e) => this.props.onChange(e)}
            onBlur={(e) => this.props.onBlur(e)}
            selected={defaultDate}
            {...this.props.pickerProps}
          />
        ) : this.props.iconPrefix ? (
          <DatePicker
            className={`input__picker__control input__picker__control__with-icon__prefix ${
              this.props.isError ? "input__picker__control__error" : undefined
            }`}
            wrapperClassName="input__picker"
            popperClassName="input__picker__popper"
            calendarClassName="input__picker__calendar"
            allowSameDay
            dateFormat="dd MMMM yyyy HH:mm"
            timeIntervals={30}
            timeFormat="HH:mm"
            minTime={selectedTime === "same_day" ? new Date() : new Date().setHours(0, 0)}
            maxTime={selectedTime === "same_day" ? new Date(currentDate.setHours(23, 59)) : new Date().setHours(23, 59)}
            minDate={selectedTime === "same_day" ? new Date() : selectedTime === "future" ? tomorrow : null}
            maxDate={selectedTime === "same_day" ? new Date() : null}
            placeholderText={this.props.placeholder}
            onChange={(e) => this.props.onChange(e)}
            onBlur={(e) => this.props.onBlur(e)}
            selected={defaultDate}
            {...this.props.pickerProps}
          />
        ) : (
          <DatePicker
            className={`input__picker__control input__picker__control__with-icon__suffix ${
              this.props.isError ? "input__picker__control__error" : undefined
            }`}
            wrapperClassName="input__picker"
            popperClassName="input__picker__popper"
            calendarClassName="input__picker__calendar"
            allowSameDay
            dateFormat="dd MMMM yyyy HH:mm"
            timeIntervals={30}
            timeFormat="HH:mm"
            minTime={selectedTime === "same_day" ? new Date() : new Date().setHours(0, 0)}
            maxTime={selectedTime === "same_day" ? new Date(currentDate.setHours(23, 59)) : new Date().setHours(23, 59)}
            minDate={selectedTime === "same_day" ? new Date() : selectedTime === "future" ? tomorrow : null}
            maxDate={selectedTime === "same_day" ? new Date() : null}
            placeholderText={this.props.placeholder}
            onChange={(e) => this.props.onChange(e)}
            onBlur={(e) => this.props.onBlur(e)}
            selected={defaultDate}
            {...this.props.pickerProps}
          />
        )}

        {this.props.caption && (
          <label
            className={`input__caption ${
              this.props.isError ? "input__caption__error" : undefined
            }`}
          >
            {this.props.caption}
          </label>
        )}
      </div>
    );
  }
}

export default InputDatePicker;
