import React from 'react';
import CreateConsignmentStepOne from './Create/step-one';
import CreateConsignmentStepTwo from './Create/step-two';
import CreateConsignmentStepThree from './Create/step-three';
import CreateConsignmentStepFour from './Create/step-four';
import CreateConsignmentStepFive from './Create/step-five';
import { connect } from 'react-redux';
import moment from 'moment';
import { navigate, Notifications, Tools } from 'common';

class CreateConsignmentContainer extends React.PureComponent {

    state = {
        step: 1,
        data: {}
    }

    componentDidMount() {
        localStorage.removeItem("@createConsignment");
    }

    onNext = async() => {
        this.setState({
            step: this.state.step + 1
        });
    }

    onDraft = async() => {
        const data = await this.generateData();
        const {
            createConsignment,
            token,
            history,
            toastPending
        } = this.props
        var json = await createConsignment(token, data)
        if(json.status_code === 200) {
            await this.removeFromLocal();
            await localStorage.removeItem("@createConsignment");
            navigate(history, "/dashboard?screen=consignment");
        }
        toastPending({ message: json.message, toastType: json.status_code })
    }

    onCreate = async() => {
        const data = await this.generateData();
        const {
            publishConsignment,
            createConsignment,
            token,
            history,
            user,
            toastPending
        } = this.props
        var json = await createConsignment(token, data)
        if(json.status_code === 200) {
            const params = {
                ...data,
                status: 102,
                id: json.id
            }
            json = await publishConsignment(token, user.id, params)
            if(json.status_code === 200) {
                json.message = Notifications.ConsignmentPublished
                await this.removeFromLocal();
                await localStorage.removeItem("@createConsignment");
                navigate(history, "/dashboard?screen=consignment");
            }
            toastPending({ message: json.message, toastType: json.status_code })
        }
    }

    generateData = async() => {
        const { 
            user,
            fetchPending,
            fetchFinish 
        } = this.props;
        fetchPending()
        var data = localStorage.getItem("@createConsignment");
        data = JSON.parse(data);

        const pickup_address = data.pickup_address_one + "," + data.pickup_postal_code;
        const delivery_address = data.delivery_address_one + "," + data.delivery_postal_code;
        const pickup_coord = await Tools.reverseLocation(pickup_address);
        const delivery_coord = await Tools.reverseLocation(delivery_address);

        var response = {
            order_name: data.order_name || "",
            pickup_name: data.pickup_name || "",
            pickup_mobile_prefix: data.pickup_mobile_prefix ? data.pickup_mobile_prefix.value : "",
            pickup_mobile_number: data.pickup_mobile_number ? data.pickup_mobile_number.replace(/ /g, '') : "",
            pickup_address_one: data.pickup_address_one || "",
            pickup_address_two: data.pickup_address_two || "",
            pickup_postal_code: data.pickup_postal_code || "",
            pickup_time: data.pickup_time ? data.pickup_time.value : "",
            pickup_date: moment(data.pickup_date).format("YYYY-MM-DD HH:mm:ss") || moment().format("YYYY-MM-DD HH:mm:ss"),
            pickup_countries: data.pickup_countries ? data.pickup_countries.value : "",
            pickup_states: data.pickup_states ? data.pickup_states.value : "",
            pickup_cities: data.pickup_cities ? data.pickup_cities.value : "",
            pickup_latitude: pickup_coord.lat || "",
            pickup_longitude: pickup_coord.lng || "",
            delivery_name: data.delivery_name || "",
            delivery_mobile_prefix: data.delivery_mobile_prefix ? data.delivery_mobile_prefix.value : "",
            delivery_mobile_number: data.delivery_mobile_number ? data.delivery_mobile_number.replace(/ /g, '') : "",
            delivery_address_one: data.delivery_address_one || "",
            delivery_address_two: data.delivery_address_two || "",
            delivery_countries: data.delivery_countries ? data.delivery_countries.value : "",
            delivery_states: data.delivery_states ? data.delivery_states.value : "",
            delivery_cities: data.delivery_cities ? data.delivery_cities.value : "",
            delivery_postal_code: data.delivery_postal_code || "",
            delivery_latitude: delivery_coord.lat || "",
            delivery_longitude: delivery_coord.lng || "",
            material_category_id: data.material_category ? data.material_category.id : "",
            material_detail: data.material_detail || "",
            weight: parseFloat(data.weight) || 0,
            material_price: parseFloat(data.material_price) || 0,
            user_id: user.id,
            gst_number: data.gst_number || "",
            is_insurance: data.is_insurance || false,
            fleet_category_id: data.fleet_category ? data.fleet_category.id : ""
        }

        // if(this.state.data.material_category.label.toLowerCase() !== "envelope") {
        //     data = {
        //         ...data,
        //         fleet_category_id: this.state.data.fleet_category ? this.state.data.fleet_category.id : ""
        //     }
        // }

        fetchFinish()

        return response;
    }

    onPrevious = () => {
        this.setState({
            step: this.state.step - 1
        });
    }

    removeFromLocal = () => {
        const { 
            local_consignment,
            setLocalConsignment
        } = this.props;
        var local_consignments = local_consignment || [];
    
        var localData = localStorage.getItem("@createConsignment");
        localData = JSON.parse(localData);
        var parameter_id = localData.id;
        var selected_consignments = local_consignments.find((item) => item.id === parameter_id);
        if(selected_consignments) {
            var index = local_consignments.map(function(e) {
                return e.id;
            }).indexOf(selected_consignments.id);
    
            local_consignments.splice(index, 1);
            
            setLocalConsignment(local_consignments);
        }
        return true;
    }

    render() {
        if(this.state.step === 1) {
            return (
                <CreateConsignmentStepOne 
                    {...this.props}
                    onBack={() => this.props.history.goBack()}
                    onNext={() => this.onNext()}
                />
            )
        }
        else if(this.state.step === 2) {
            return (
                <CreateConsignmentStepTwo 
                    {...this.props}
                    onPrevious={() => this.onPrevious()}
                    onNext={() => this.onNext()}
                />
            )
        }
        else if(this.state.step === 3) {
            return (
                <CreateConsignmentStepThree 
                    {...this.props}
                    onPrevious={() => this.onPrevious()}
                    onNext={() => this.onNext()}
                />
            )
        }
        else if(this.state.step === 4) {
            return (
                <CreateConsignmentStepFour 
                    {...this.props}
                    onPrevious={() => this.onPrevious()}
                    onNext={() => this.onNext()}
                />
            )
        }
        else if(this.state.step === 5) {
            return (
                <CreateConsignmentStepFive 
                    {...this.props}
                    onDraft={() => this.onDraft()}
                    onCreate={() => this.onCreate()}
                />
            )
        }
    }

}

const mapsStateToProps = ({ authenticated, consignment }) => {
    return {
        token: authenticated.token,
        user: authenticated.userInfo,
        consignment: consignment.lists,
        local_consignment: consignment.local_data
    }
}


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { actions } = require("redux/ConsignmentRedux")

    return {
      ...ownProps,
      ...stateProps,
      createConsignment: async(
          token,
          body
      ) => {
        dispatch({ type: "FETCH_PENDING" })
        var json = await actions.createConsignment(dispatch, token, body)
        dispatch({ type: "FETCH_FINISH" })
        return json
      },
      publishConsignment: async(
        token,
        user_id,
        body
      ) => {
        dispatch({ type: "FETCH_PENDING" })
        var json = await actions.publishConsignment(dispatch, token, user_id, body)
        dispatch({ type: "FETCH_FINISH" })
        return json
      },
      toastPending: (body) => {
        dispatch({ type: "TOAST_PENDING", ...body })
      },
      fetchPending: () => dispatch({ type: "FETCH_PENDING" }),
      fetchFinish: () => dispatch({ type: "FETCH_FINISH" }),
      setLocalConsignment: (items) => dispatch({ type: "SET_LOCAL_CONSIGNMENT", items })
    };
}

export default connect(
    mapsStateToProps, 
    undefined,
    mergeProps
)(CreateConsignmentContainer);