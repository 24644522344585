import React from 'react';
import { EditProfileContainer } from 'container';

class EditProfile extends React.PureComponent {

    render() {
        return(
            <EditProfileContainer
                {...this.props}
            />
        )
    }

}

export default (EditProfile);