import { Constant, Tools, Languages, Notifications } from "common";
import firebase from "firebase";
import { FirebaseAPI } from "./FirebaseAPI";

export const AuthenticationAPI = {
  async login(body) {
    try {
      return await firebase
        .auth()
        .signInWithEmailAndPassword(body.email, body.password)
        .then(async (response) => {
          if (response.user.emailVerified) {
            return response.user.getIdToken().then(async function (idToken) {
              return fetch(
                `${Constant.API_URL}/user/me?role=${body.role}&provider=${body.provider}`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${idToken}`,
                  },
                }
              )
                .then(async (response) => {
                  if (response.ok) {
                    return response.json();
                  }
                  var error_message = await response.json().then((json) => {
                    if (typeof json.message === "object") {
                      for (const index in json.message) {
                        return json.message[index];
                      }
                    } else {
                      return json;
                    }
                  });
                  return Promise.reject(error_message);
                })
                .then((json) => {
                  if (typeof json === "object" && Tools.isNotEmpty(json)) {
                    if (json.code === 7001) {
                      FirebaseAPI.deleteUser();
                    }
                  }
                  return { status_code: 200, token: idToken, ...json };
                })
                .catch((error) => {
                  if (typeof error === "object" && Tools.isNotEmpty(error)) {
                    if (error.code === 7001) {
                      FirebaseAPI.deleteUser();
                    }
                  }
                  return { status_code: 500, ...error };
                });
            });
          } else {
            return {
              status_code: 500,
              message: Languages.account_not_verified,
              code: "auth/user-not-verified",
            };
          }
        })
        .catch((error) => {
          if (error.code === "auth/user-not-found") {
            return {
              status_code: 500,
              message: Notifications.EmailNotRegistered,
            };
          } else if (error.code === "auth/wrong-password") {
            return {
              status_code: 500,
              message: Notifications.IncorrectPassword,
            };
          }
          return { status_code: 500, ...error };
        });
    } catch (error) {
      return { status_code: 500, ...error };
    }
  },

  async socailLogin(body) {
    try {
      return fetch(
        `${Constant.API_URL}/user/me?role=${body.role}&provider=${body.provider}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${body.accessToken}`,
          },
        }
      )
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          }
          var error_message = await response.json().then((json) => {
            if (typeof json.message === "object") {
              for (const index in json.message) {
                return json.message[index];
              }
            } else {
              return json;
            }
          });
          return Promise.reject(error_message);
        })
        .then((json) => {
          if (typeof json === "object" && Tools.isNotEmpty(json)) {
            if (json.code === 7001) {
              FirebaseAPI.deleteUser();
            }
          }
          return { status_code: 200, token: body.idToken, ...json };
        })
        .catch((error) => {
          if (typeof error === "object" && Tools.isNotEmpty(error)) {
            if (error.code === 7001) {
              FirebaseAPI.deleteUser();
            }
          }
          return { status_code: 500, ...error };
        });
    } catch (error) {
      return { status_code: 500, ...error };
    }
  },

  async fetchAuthenticatedUser(token, body) {
    return fetch(
      `${Constant.API_URL}/user/me?role=${body.role}&provider=${body.provider}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        }
        var error_message = await response.json().then((json) => {
          if (typeof json.message === "object") {
            for (const index in json.message) {
              return json.message[index];
            }
          } else {
            return json;
          }
        });
        return Promise.reject(error_message);
      })
      .then((json) => {
        if (typeof json === "object" && Tools.isNotEmpty(json)) {
          if (json.code === 7001) {
            FirebaseAPI.deleteUser();
          }
        }
        return { status_code: 200, ...json };
      })
      .catch((error) => {
        if (typeof error === "object" && Tools.isNotEmpty(error)) {
          if (error.code === 7001) {
            FirebaseAPI.deleteUser();
          }
        }
        return { status_code: 500, ...error };
      });
  },

  async logout(token) {
    return fetch(`${Constant.API_URL}/user/logout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        }
        var error_message = await response.json().then((json) => {
          if (typeof json.message === "object") {
            for (const index in json.message) {
              return json.message[index];
            }
          } else {
            return json;
          }
        });
        throw new Error(JSON.stringify(error_message));
      })
      .then((json) => {
        return {
          status_code: 200,
          ...json,
          message: Notifications.LogoutSuccess,
        };
      })
      .catch((error) => {
        return { status_code: 500, ...error };
      });
  },

  async phoneLogin(body) {
    try {
      const credintial = firebase.auth.PhoneAuthProvider.credential(
        body.verificationId,
        body.verification_code
      );
      return await firebase
        .auth()
        .signInWithCredential(credintial)
        .then(async (response) => {
          return response.user.getIdToken().then(function (idToken) {
            return fetch(
              `${Constant.API_URL}/user/me?role=${body.role}&provider=${body.provider}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${idToken}`,
                },
              }
            )
              .then(async (response) => {
                if (response.ok) {
                  return response.json();
                }
                var error_message = await response.json().then((json) => {
                  if (typeof json.message === "object") {
                    for (const index in json.message) {
                      return json.message[index];
                    }
                  } else {
                    return json;
                  }
                });
                throw new Error(JSON.stringify(error_message));
              })
              .then((json) => {
                if (typeof json === "object" && Tools.isNotEmpty(json)) {
                  if (json.code === 7001) {
                    FirebaseAPI.deleteUser();
                  }
                }
                return { status_code: 200, token: idToken, ...json };
              })
              .catch((error) => {
                if (typeof error === "object" && Tools.isNotEmpty(error)) {
                  if (error.code === 7001) {
                    FirebaseAPI.deleteUser();
                  }
                }
                return { status_code: 500, ...error };
              });
          });
        })
        .catch((error) => {
          return { status_code: 500, ...error };
        });
    } catch (error) {
      return { status_code: 500, message: error.message };
    }
  },

  async socialLogin(body) {
    try {
      var credintial = null;
      if (body.provider === "google") {
        credintial = await firebase.auth.GoogleAuthProvider.credential(
          body.accessToken
        );
      } else if (body.provider === "facebook") {
        credintial = await firebase.auth.FacebookAuthProvider.credential(
          body.accessToken
        );
      }

      return await firebase
        .auth()
        .signInWithCredential(credintial)
        .then(async (response) => {
          return response.user.getIdToken().then(async function (idToken) {
            return fetch(
              `${Constant.API_URL}/user/me?role=${body.role}&provider=${body.provider}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${idToken}`,
                },
              }
            )
              .then(async (response) => {
                if (response.ok) {
                  return response.json();
                }
                var error_message = await response.json().then((json) => {
                  if (typeof json.message === "object") {
                    for (const index in json.message) {
                      return json.message[index];
                    }
                  } else {
                    return json;
                  }
                });
                throw new Error(JSON.stringify(error_message));
              })
              .then((json) => {
                if (typeof json === "object" && Tools.isNotEmpty(json)) {
                  if (json.code === 7001) {
                    FirebaseAPI.deleteUser();
                  }
                }
                return { status_code: 200, token: idToken, ...json };
              })
              .catch((error) => {
                if (typeof error === "object" && Tools.isNotEmpty(error)) {
                  if (error.code === 7001) {
                    FirebaseAPI.deleteUser();
                  }
                }
                const data = JSON.parse(error.message);
                return { status_code: 500, ...error, message: data.message };
              });
          });
        })
        .catch((error) => {
          return { status_code: 500, ...error };
        });
    } catch (error) {
      return { status_code: 500, ...error };
    }
  },

  async requestNewCode(body) {
    return await firebase
      .auth()
      .signInWithEmailAndPassword(body.email, body.password)
      .then(async (response) => {
        return firebase
          .auth()
          .currentUser.sendEmailVerification()
          .then(() => {
            return {
              status_code: 200,
              message: Languages.registration.resent_verification,
            };
          })
          .catch((err) => {
            return { status_code: 500, message: err.message };
          });
      })
      .catch((error) => {
        return { status_code: 500, ...error };
      });
  },

  async sendOTP(token, body) {
    try {
      return fetch(`${Constant.API_URL}/user/request-code`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      })
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          }
          let status_code = response.status;
          var error_message = await response.json().then((json) => {
            if (typeof json.message === "object") {
              for (const index in json.message) {
                return json.message[index];
              }
            } else {
              if(status_code === 409) {
                json.code = status_code;
              }
              return json;
            }
          });
          throw (JSON.stringify(error_message));
          // throw new Error(JSON.stringify(error_message));
        })
        .then((json) => {
          return { status_code: 200, ...json };
        })
        .catch((error) => {
          console.log('error',JSON.parse(error));
          const errorResponse = JSON.parse(error);
          return { status_code: 500, ...errorResponse };
        });
    } catch (error) {
      return { status_code: 500, ...error };
    }
  },

  async verifyOTP(token, body) {
    try {
      return fetch(`${Constant.API_URL}/user/verify-code`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      })
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          }
          var error_message = await response.json().then((json) => {
            if (typeof json.message === "object") {
              for (const index in json.message) {
                return json.message[index];
              }
            } else {
              return json;
            }
          });
          throw new Error(JSON.stringify(error_message));
        })
        .then((json) => {
          return { status_code: 200, ...json };
        })
        .catch((error) => {
          return { status_code: 500, ...error };
        });
    } catch (error) {
      return { status_code: 500, ...error };
    }
  },

  async updateProfile(token, body) {
    try {
      var url = `user/update/${body.id}`;
      if (body.role === "partner") {
        url = `partner/${body.id}`;
      }

      if (
        !Tools.isNotEmpty(body.mobile_prefix) ||
        !Tools.isNotEmpty(body.mobile_number)
      ) {
        delete body.mobile_prefix;
        delete body.mobile_number;
      }

      return fetch(`${Constant.API_URL}/${url}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      })
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          }
          var error_message = await response.json().then((json) => {
            if (typeof json.message === "object") {
              for (const index in json.message) {
                return json.message[index];
              }
            } else {
              return json;
            }
          });
          throw new Error(JSON.stringify(error_message));
        })
        .then((json) => {
          return { status_code: 200, ...json };
        })
        .catch((error) => {
          return { status_code: 500, message: error.message };
        });
    } catch (error) {
      return { status_code: 500, ...error };
    }
  },

  async joinPartner(token, user_id, body) {
    try {
      return fetch(`${Constant.API_URL}/user/join-partner/${user_id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      })
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          }
          var error_message = await response.json().then((json) => {
            if (typeof json.message === "object") {
              for (const index in json.message) {
                return json.message[index];
              }
            } else {
              return json;
            }
          });
          throw new Error(JSON.stringify(error_message));
        })
        .then((json) => {
          return { status_code: 200, ...json };
        })
        .catch((error) => {
          return { status_code: 500, ...error };
        });
    } catch (error) {
      return { status_code: 500, ...error };
    }
  },

  async resubmitRegistration(token, user_id, body) {
    try {
      return fetch(`${Constant.API_URL}/partner/${user_id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
      })
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          }
          var error_message = await response.json().then((json) => {
            if (typeof json.message === "object") {
              for (const index in json.message) {
                return json.message[index];
              }
            } else {
              return json;
            }
          });
          throw new Error(JSON.stringify(error_message));
        })
        .then((json) => {
          return { status_code: 200, ...json };
        })
        .catch((error) => {
          return { status_code: 500, ...error };
        });
    } catch (error) {
      return { status_code: 500, ...error };
    }
  },

  async register(role, body) {
    try {
      var register_firebase = await this.handleRegisterToFirebase(body);
      if (register_firebase.status === 200) {
        const { uid, photoURL, idToken } = register_firebase.user;

        var url = `${Constant.API_URL}/user/register?type=partner&role=partner`;
        if (role === "user") {
          url = `${Constant.API_URL}/user/register?type=user&role=user`;
        }

        body = {
          ...body,
          uuid: uid,
          file: photoURL,
          provider: "saytum",
        };

        return fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify(body),
        })
          .then(async (response) => {
            if (response.ok) {
              return response.json();
            }
            var error_message = await response.json().then((json) => {
              if (typeof json.message === "object") {
                for (const index in json.message) {
                  return json.message[index];
                }
              } else {
                return json;
              }
            });
            throw new Error(JSON.stringify(error_message));
          })
          .then(async (json) => {
            await FirebaseAPI.sendEmailVerification(body);
            return {
              status_code: 200,
              ...json,
              message: Notifications.RegistrationSuccess,
            };
          })
          .catch((error) => {
            if (
              error.code !== 7000 &&
              error.code !== 7001 &&
              error.code !== 7002 &&
              error.code !== 7005
            ) {
              FirebaseAPI.deleteUser();
            }
            return { status_code: 500, ...error };
          });
      } else {
        var message = register_firebase.message;
        if (register_firebase.code === "auth/email-already-in-use") {
          message = Notifications.EmailRegistered;
        }
        return { status_code: 500, message: message };
      }
    } catch (error) {
      return {
        status_code: 500,
        message: "Failed to connect to server",
        ...error,
      };
    }
  },

  async socialRegister(provider, body) {
    try {
      var firebaseAuth = await {};
      if (provider === "facebook") {
        firebaseAuth = await FirebaseAPI.signInWithFacebookCredintial(body);
      } else if (provider === "google") {
        firebaseAuth = await FirebaseAPI.signInWithGoogleCredintial(body);
      }

      if (firebaseAuth.status === 200) {
        const { token, firebaseUser } = firebaseAuth;
        body = {
          ...body,
          uuid: firebaseUser.uid,
        };

        return fetch(`${Constant.API_URL}/user/register?type=user&role=user`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(body),
        })
          .then(async (response) => {
            if (response.ok) {
              return response.json();
            }
            var error_message = await response.json().then((json) => {
              if (typeof json.message === "object") {
                for (const index in json.message) {
                  return json.message[index];
                }
              } else {
                return json;
              }
            });
            throw new Error(JSON.stringify(error_message));
          })
          .then(async (json) => {
            return { status_code: 200, token: token, ...json };
          })
          .catch((error) => {
            if (
              error.code !== 7000 &&
              error.code !== 7001 &&
              error.code !== 7002 &&
              error.code !== 7005
            ) {
              FirebaseAPI.deleteUser();
            }
            return { status_code: 500, ...error };
          });
      } else {
        FirebaseAPI.deleteUser();
        return { status_code: 500, ...firebaseAuth };
      }
    } catch (error) {
      return {
        status_code: 500,
        message: "Failed to connect to server",
        ...error,
      };
    }
  },

  async handleRegisterToFirebase(params) {
    var results = null;
    var check_params = { email: params.email };
    var check_email = await FirebaseAPI.callFunctions(
      "isRegisteredAccount",
      check_params
    );
    if (check_email.status_code !== 200) {
      var param_check_mobile = {
        phone: params.mobile_prefix + params.mobile_number,
      };
      var check_phone = await FirebaseAPI.callFunctions(
        "isRegisteredAccount",
        param_check_mobile
      );

      if (check_phone.status_code === 200) {
        const { idToken } = check_phone.user;
        var signin = await FirebaseAPI.signInWithCustomToken(idToken);
        results = {
          status: 200,
          user: {
            ...check_phone.user,
            idToken: signin.token,
          },
        };
        return {
          ...results,
          isRegistered: true,
        };
      } else {
        results = await FirebaseAPI.createUserWithEmailPassword(params);
        return {
          ...results,
          isRegistered: false,
        };
      }
    }
  },
};
