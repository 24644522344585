import { LocationAPI } from "services";

const types = {
  FETCH_COUNTRIES_FAILURE: "FETCH_COUNTRIES_FAILURE",
  FETCH_COUNTRIES_SUCCESS: "FETCH_COUNTRIES_SUCCESS",
  FETCH_STATES_FAILURE: "FETCH_STATES_FAILURE",
  FETCH_STATES_SUCCESS: "FETCH_STATES_SUCCESS",
  FETCH_CITIES_FAILURE: "FETCH_CITIES_FAILURE",
  FETCH_CITIES_SUCCESS: "FETCH_CITIES_SUCCESS",
};

export const actions = {
  fetchCountries: async (dispatch, token) => {
    var json = await LocationAPI.getCountries(token);
    if (json.status_code === 500) {
      dispatch(actions.fetchCountriesFailure(json.message));
    } else {
      console.log("countries", json);
      dispatch(actions.fetchCountriesSuccess(json));
    }
    return json;
  },
  fetchCountriesFailure: (error) => ({
    type: types.FETCH_COUNTRIES_FAILURE,
    error,
  }),
  fetchCountriesSuccess: (items) => ({
    type: types.FETCH_COUNTRIES_SUCCESS,
    items,
  }),
  fetchStates: async (dispatch, token, country_id) => {
    var json = await LocationAPI.getStates(token, country_id);
    if (json.status_code === 500) {
      dispatch(actions.fetchStatesFailure(json.message));
    } else {
      dispatch(actions.fetchStatesSuccess(json));
    }
    return json;
  },
  fetchStatesFailure: (error) => ({
    type: types.FETCH_STATES_FAILURE,
    error,
  }),
  fetchStatesSuccess: (items) => ({
    type: types.FETCH_STATES_SUCCESS,
    items,
  }),
  fetchCities: async (dispatch, token, state_code) => {
    var json = await LocationAPI.getCities(token, state_code);
    if (json.status_code === 500) {
      dispatch(actions.fetchCitiesFailure(json.message));
    } else {
      dispatch(actions.fetchCitiesSuccess(json));
    }
    return json;
  },
  fetchCitiesFailure: (error) => ({
    type: types.FETCH_CITIES_FAILURE,
    error,
  }),
  fetchCitiesSuccess: (items) => ({
    type: types.FETCH_CITIES_SUCCESS,
    items,
  }),
};

const initialState = {
  countries: [],
  cities: [],
  states: [],
  error: null,
};

export const reducer = (state = initialState, action) => {
  const { items, error } = action;

  switch (action.type) {
    case types.FETCH_COUNTRIES_FAILURE: {
      return Object.assign({}, state, {
        error: error,
        countries: [],
      });
    }
    case types.FETCH_COUNTRIES_SUCCESS: {
      return Object.assign({}, state, {
        error: null,
        countries: items.data.data,
      });
    }
    case types.FETCH_STATES_FAILURE: {
      return Object.assign({}, state, {
        error: error,
        states: [],
      });
    }
    case types.FETCH_STATES_SUCCESS: {
      return Object.assign({}, state, {
        error: null,
        states: items.data.data,
      });
    }
    case types.FETCH_CITIES_FAILURE: {
      return Object.assign({}, state, {
        error: error,
        cities: [],
      });
    }
    case types.FETCH_CITIES_SUCCESS: {
      return Object.assign({}, state, {
        error: null,
        cities: items.data.data,
      });
    }
    default:
      return state;
  }
};
