import { SaytumAPI } from './SaytumAPI';
import store from "store/configureStore";
import Tools from 'common/Tools';
import moment from 'moment';
import languages from 'common/Languages';

const Workers = {

    createConsignment: async(params) => {        
        var token = await Tools.getCurrentValidToken();

        try {
            var consignment = await SaytumAPI.authenticated_post("consignment", token, params);
            
            store.dispatch({
                type:"SET_NOTIFICATION",
                items: { isVisible: true, message: "Consignment created successfully", type: "success" }
            });

            return consignment;
        }
        catch(error) {
            store.dispatch({
                type:"SET_NOTIFICATION",
                items: { isVisible: true, message: "Internal server error", type: "error" }
            });
        }
    },

    publishConsignment: async(params) => {
        var token = await Tools.getCurrentValidToken();

        try {
            const updateParams = {
                ...params,
                status: 1
            }
            const update = await SaytumAPI.authenticated_put("consignment", token, updateParams);

            if(update.fetch_status === "success") {
                await Workers.createAuction(update);

                store.dispatch({
                    type:"SET_NOTIFICATION",
                    items: { isVisible: true, message: languages.consignment.published, type: "success" }
                });
                
                params = {
                    ...params,
                    status: 1
                }

                store.dispatch({
                    type:"UPDATE_CONSIGNMENT",
                    items: params
                });
            }
            else {
                store.dispatch({
                    type:"SET_NOTIFICATION",
                    items: { isVisible: true, message: update.message, type: "error" }
                });
            }
        }
        catch(error) {
            store.dispatch({
                type:"SET_NOTIFICATION",
                items: { isVisible: true, message: "Internal server error", type: "error" }
            });
        }
    },

    createAuction: async(params) => {
        var state = store.getState();
        const { user } = state.authenticated;

        var token = await Tools.getCurrentValidToken();
        
        const data = { consignment_id: params.id, user_id: user.id }
        const auction = await SaytumAPI.authenticated_post("auction", token, data);

        if(auction.fetch_status === "success") {
            const get_auction = await SaytumAPI.authenticated_get("auction/" + auction.auction_id, token);
            await SaytumAPI.authenticated_put(
               "auction",  
                token, 
                {
                    id: auction.auction_id,
                    expire_on: get_auction.expire_on,
                    auction_status: 4
                }
            );
        }
    },

    updateConsignment: async(params) => {
        var token = await Tools.getCurrentValidToken();
    
        try {
            var consignment = await SaytumAPI.authenticated_put("consignment", token, params);
            
            store.dispatch({
                type:"SET_NOTIFICATION",
                items: { isVisible: true, message: "Consignment saved successfully", type: "success" }
            });

            return consignment;
        }
        catch(error) {
            store.dispatch({
                type:"SET_NOTIFICATION",
                items: { isVisible: true, message: "Internal server error", type: "error" }
            });
        }
    },

    sendBid: async(auction_id, params) => {
        var token = await Tools.getCurrentValidToken();
        try {
            var bid = await SaytumAPI.authenticated_post("auction/" + auction_id + "/bid", token, params);
            if(bid.fetch_status === "success") {
                var updateParam = { id: bid.bid_id, bid_status: 9 }
                var update = await SaytumAPI.authenticated_put("auction/" + auction_id + "/bid", token, updateParam);
                
                if(update.fetch_status === "success") {
                    params = {
                        ...params,
                        bid_status: 9,
                        auction_id: auction_id,
                        created_at: moment().format("YYYY-MM-DD HH:MI:SS"),
                        is_deleted: false,
                        id: bid.bid_id
                    }

                    store.dispatch({
                        type:"ADD_BIDDER",
                        items: params
                    });

                    store.dispatch({
                        type:"SET_NOTIFICATION",
                        items: { isVisible: true, message: "Bid send successfully", type: "success" }
                    });
                }
                else {
                    store.dispatch({
                        type:"SET_NOTIFICATION",
                        items: { isVisible: true, message: "Bid failed : " + update.message, type: "error" }
                    });
                }
            }
            else {
                store.dispatch({
                    type:"SET_NOTIFICATION",
                    items: { isVisible: true, message: bid.message, type: "error" }
                });
            }
        }
        catch(err) {
            store.dispatch({
                type:"SET_NOTIFICATION",
                items: { isVisible: true, message: err.message, type: "error" }
            });
        }
    }

}

export default (Workers);