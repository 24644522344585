import { Images } from 'common';
import { Detail, DetailContent, Modal } from 'components';
import DeliveryBoysCardContainer from './DeliveryBoys';
import React from 'react';
import "./styles.scss";


class AuctionAssignTaskContainer extends React.PureComponent {

    render() {
        return(
            <Modal className="modal__assign" size="large" isVisible={this.props.isVisible}
                aria-labelledby="edit-name-modal" ref={(ref) => this.modalRef = ref} backdrop="static"
                centered
            >
                <button className={`button__link modal__closer`} onClick={() => this.props.onClose()}>
                    <img src={Images.close} alt="close" />
                </button>

                <Detail
                    style={{ width: '100%', margin: 0 }}
                    containerClassName="bid-detail__assign"
                >
                    <DetailContent
                        header="Select Your Worker"
                        description="Choose worker to pick up the goods then send them to the recipient's home"
                        style={{ width: '100%', margin: 0 }}
                    >

                        <DeliveryBoysCardContainer
                            {...this.props} 
                        />

                    </DetailContent>
                </Detail>

            </Modal>
        )
    }

}

export default (AuctionAssignTaskContainer);