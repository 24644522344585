import React from "react";
import { connect } from "react-redux";
import { Redirect, Route as Routes } from "react-router-dom";
import AppLayout from './AppLayout';

class AppRouter extends React.PureComponent {

    render() {
        
        const { 
            component: Component, 
            token, 
            computedMatch,
            mustLogin 
        } = this.props;

        return (
            <Routes
                {...computedMatch}
                render={(props) => {
                    if(mustLogin) {
                        if (token !== null) {
                            return (
                                <AppLayout>
                                    <Component {...props} />
                                </AppLayout>
                            )
                        } else {
                            return (
                                <Redirect to={{ pathname: "/login" }} />
                            )
                        }
                    }
                    else {
                        return (
                            <AppLayout>
                                <Component {...props} />
                            </AppLayout>
                        )
                    }
                }}
            />
        )
    }
}

const mapsStateToProps = ({ authenticated }) => {
    return {
        token: authenticated.token
    }
}

export default connect(mapsStateToProps)(AppRouter);
