import React from 'react';
import { CreateFleetContainer } from 'container';

class CreateFleet extends React.PureComponent {


    render() {
        return(
            <CreateFleetContainer
                {...this.props}
            />
        )
    }

}

export default (CreateFleet);