import React from 'react';
import './TandCpage.css';

function TermsAndConditions() {


    return (
    <div className="tc-container">
        <div className='tc-header'><h1>Terms & Conditions</h1></div>
      <div className="tc-content">
        <div className="tc-pnt1">
            <h3>Terms and Conditions:</h3>
            <ul>
                <li>Services Offered: Saytum will provide a digital platform to its customer to connect with each other efficiently.</li>
                <li>Payment Terms: The customers will be required to pay for the services provided by Saytum as per the agreed-upon payment terms.</li>
                <li>Liability: Saytum will not be liable for any loss, damage, or delay in the delivery of goods caused by circumstances beyond its control, including but not limited to, natural disasters, strikes, and other events of force majeure.</li>
                <li>Confidentiality: Saytum will maintain the confidentiality of all information provided by its customers, including but not limited to, the nature of goods being transported, the destination, and the value of the goods.</li>
                <li>Compliance with Laws: Saytum will comply with all applicable laws and regulations related to the transportation of goods, including but not limited to, customs regulations, export controls, and transportation safety regulations.</li>
                <li>Termination: Either party may terminate this agreement by providing written notice to the other party. Upon termination, the Saytum viding services to the customer, and any outstanding payments will become due.</li>
                <li>Dispute Resolution: Any disputes arising out of this agreement will be resolved through arbitration in accordance with the rules of the Chhattisgarh, India</li>
                <li>Governing Law: This agreement will be governed by and construed in accordance with the laws of Chhattisgarh, India in which the Saytum is incorporated.</li>
                <li>Amendments: This agreement may be amended or modified only by written agreement signed by both parties.</li>
            </ul>
        </div>

        <div className="section-divider"></div>

        <div className="tc-pnt2">
            <h3>Payments on the Service</h3>
            <ul>
                <li>Payments quoted are in INR and include Indian Goods and Service Tax, where applicable.</li>
                <li>SAYTUM has the absolute right to revise the prices of any credit packages or credit amount/limits where applicable without giving prior notice.</li>
                <li>Any application/request for service is subjected to availability of resources. In the event any order submitted is not processed, SAYTUM shall inform the User by email at the email address specified by the User</li>
                <li>SAYTUM shall process the order only after the User's Payment is cleared by the bank appointed by SAYTUM and upon approval by SAYTUM.</li>
                <li>SAYTUM reserves the right to refund to the User the equivalent sum of amount paid in the event SAYTUM for any reason is unable to process the application/request of Packages/Fees</li>
                <li>Credits/Couple/Points sold are not exchangeable, returnable nor refundable.</li>
                <li>By signing up as a Service provider with SAYTUM, you authorize SAYTUM to quote the service fee on your behalf as per the pricing rates stated on our platform.</li>
                <li>Acting as an agent between users and service providers, SAYTUM reserves the rights to claw back all payments made to service providers in the scenario where service providers failed to fulfil the committed service to user and any such misconduct.</li>
            </ul>
        </div>

        <div className="section-divider"></div>

        <div className="tc-pnt3">
            <h3>Security</h3>
            <ul>
                <li>Where appropriate, SAYTUM employs available technology to protect the security of communications made. However, SAYTUM does not accept liability for the security, authenticity, integrity or confidentiality of any transactions and other communications.</li>
                <li>Internet communications may be susceptible to interference or interception by third parties. Despite SAYTUM's best efforts, SAYTUM makes no warranties that the Website is free of infection by computer viruses or other unauthorised software.</li>
                <li>Users and Service Providers should take appropriate steps to keep information, software and equipment secure from where they are operating. These include installing a personal firewall and latest anti-virus software on your computer, and clearing your Internet browser cookies, cache and history after using any services on the Website. For more details on the essential infocomm security tips, please visit: https://www.cert-in.org.in/</li>
                <li>You further agree that the collection, use, storage and transfer of your personal data is generally subject to SAYTUM's privacy policy and personal information collection statement the latest version(s) thereof is at “www.SAYTUM.com”, and you acknowledge that you have read and understood the same prior to agreeing to these Terms and Conditions.</li>
            </ul>
        </div>

        <div className="section-divider"></div>

        <div className="tc-pnt4">
            <h3>Cancellation of order</h3>
            <ul>
                <li>Service Providers reserve the rights to cancel the order if the waiting time exceed 60 minutes starting from the indicated order pickup time chosen by the user.</li>
                <li>In such scenario, Service Providers will not be liable for any disruption to users' deliveries.</li>
                <li>In such scenario, Service Providers will not be able to claim for any waiting time charges and/or cancellation fees from users or SAYTUM.</li>
                <li>Clause 16.1 is not applicable for orders carried out within Secured Zones, Service Providers who cancel such order would be subjected to revocation of their SAYTUM account.</li>
            </ul>
        </div>

        <div className="section-divider"></div>

        <div className="tc-pnt5">
            <h3>Engagement of services outside SAYTUM platform</h3>
            <ul>
                <li>SAYTUM service providers should not engage with our customers outside of SAYTUM's platform directly/indirectly within a period of 12 months after the order is being completed.</li>
                <li>Should customer have special requirements not catered within the SAYTUM platform, Service Providers are obligated to direct customer/User to our sales team at support@saytum.com and we would be more than happy to assist them.</li>
            </ul>
        </div>

        <div className="section-divider"></div>

        <div className="tc-pnt6">
            <h3>Purchasing service</h3>
            <ul>
                <li>Drivers reserves the rights to cancel the order if the purchase order does not fulfill any of the following conditions: Material Categories are not properly stated in order detail. In case of Mismatch of Transportation Mode. Waiting time exceeds 60 minutes for picking up the goods. if the service provider is being forced to ship the extra goods which are not stated in the consignment on SAYTUM Platform.</li>
                <li>In such a scenario, drivers will not be liable for any disruption to users' deliveries.</li>
                <li>In such a scenario, drivers will not be able to claim for any waiting time charges and/or cancellation fees from users or SAYTUM.</li>
                <li>Drivers will not be able to claim for any compensation in the event of non-payment by customers.</li>
            </ul>
        </div>

        <div className="section-divider"></div>

        <div className="tc-pnt7">
            <h3>Damage Protection</h3>
            <ul>
                <li>Under the condition where good has been lost or damaged during the custody of service providers, service providers will be liable to compensate goods value declared or claimed by user (owner of goods). Saytum won't be liable for any lost or damage of goods by service providers.</li>
            </ul>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;
