import React from 'react';
import { useTable, usePagination, useSortBy } from 'react-table'
import "./table.scss";

function Table({ columns, data, perPage=10, hiddenColumns=[] }) {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      prepareRow,
      canPreviousPage,
      canNextPage,
      pageOptions,
      nextPage,
      previousPage,
      state: { pageIndex },
    } = useTable({
      columns,
      data,
      initialState: { 
        pageSize: perPage,
        hiddenColumns: hiddenColumns 
      }
    }, useSortBy, usePagination)
    
    return (
      <div className="table__wrapper">
        <table {...getTableProps()} className="table">
          <thead className="table__head">
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())} className="table__head__title">
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="table__body">
            {data.length > 0 ?
              page.map(
                (row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} className={`table__body__row ${i % 2 === 0 && `table__body__row__odd`}`}>
                      {row.cells.map(cell => {
                        return (
                          <td {...cell.getCellProps()} className="table__body__row__column">{cell.render('Cell')}</td>
                        )
                      })}
                    </tr>
                  )}
              )
            :
              <tr className="table__body__row">
                <td className="table__body__row__column center" colSpan="9"><div className="table__body__row__column__text-style">No data found</div></td>
              </tr>
            }
          </tbody>
          
        </table>

        {data.length > 0 && (
          <div className="table__pagination">
            <p className="table__pagination__label">Page {pageIndex + 1}  of {pageOptions.length}</p>
            
            {data.length > perPage && (
              <div className="table__pagination__paging">
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                  <span className="material-icons">
                    chevron_left
                  </span>
                </button>
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                  <span className="material-icons">
                    chevron_right
                  </span>
                </button>
              </div>
            )}

          </div>
        )}         
          
      </div>
    )
    
}

export default Table;