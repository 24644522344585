import React from 'react';
import { Redirect } from 'react-router-dom';
import { Tools } from "common"
import { RegisterContainer } from 'container'
class Register extends React.PureComponent {

    render() {
        
        const { history } = this.props;
        if(!Tools.isNotEmpty(Tools.getParamFromURL(history, "role"))) {
            return <Redirect to="/register?role=user" />
        }

        return(
            <RegisterContainer 
                {...this.props}
            />
        )
    }

}

export default (Register);