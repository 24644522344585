import images from "common/Images";
import Tools from "common/Tools";
import { Button, ButtonGroup, FormBody, Input, Modal } from "components";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import "./styles.scss";

var validation = {
  location_name: "required",
  description: "required",
};

class UpdateShipmentTrackingLocationContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      valid: false,
      errors: [],
      send: false,
      nameError: false,
      descError: false,
    };
  }

  onChange = (target, value) => {
    this.setState({
      send: false,
      data: {
        ...this.state.data,
        [target]: value,
      },
    });
  };

  onBlurValidation = () => {
    if (!Tools.isNotEmpty(this.state.data.location_name)) {
      this.setState({
        ...this.state,
        nameError: true,
      });
    } else {
      this.setState({
        ...this.state,
        nameError: false,
      });
    }
  };

  onUpdate = async () => {
    const valid = await this.validation();
    this.setState({
      send: true,
    });

    if (valid) {
      const {
        token,
        fetchTrackingHistories,
        updateTrackingLocation,
        match,
        toastPending,
        onClose,
        isDashboard,
        selectedConsignmentId,
      } = this.props;
      onClose();
      var consignment_id = isDashboard
        ? selectedConsignmentId
        : match.params.id;
      var { data } = this.state;
      var params = {
        consignment_id: parseFloat(consignment_id),
        tracking_event_time: moment().format("YYYY-MM-DD HH:mm:ss"),
        location: data.location_name,
        remarks: data.description,
        status: 143,
        pod: "",
        sign_attachment: "",
      };
      var json = await updateTrackingLocation(token, params);
      if (json.status_code === 200) {
        fetchTrackingHistories(token, parseFloat(consignment_id));
      }
      toastPending({ message: json.message, toastType: json.status_code });
    }
  };

  validation = async () => {
    var validate = {};
    validate = await Tools.validation(validation, this.state.data);

    this.setState({
      valid: validate.valid,
      errors: validate.errors,
    });

    return validate.valid;
  };

  render() {
    const { data } = this.state;

    return (
      <Modal
        reference={(ref) => (this.rejection_modal = ref)}
        isVisible={this.props.isVisible}
        className="modal__form"
      >
        <button
          className={`button__link modal__detail__closer`}
          onClick={() => this.props.onClose()}
        >
          <img src={images.close} alt="close" />
        </button>

        <FormBody className="modal__form__container">
          <h1 className="modal__form__header" style={{ marginBottom: 30 }}>
            Update Shipment Tracking
          </h1>

          <Input
            type="text"
            label="Location Name"
            placeholder="Please type here"
            style={{ marginBottom: 20 }}
            value={data.location_name}
            onChange={(e) => {
              this.setState({
                ...this.state,
                nameError: false,
              });
              this.onChange("location_name", e.target.value);
            }}
            onBlur={this.onBlurValidation}
            caption={this.state.nameError && "This field is required"}
            isError={
              (this.state.send &&
                !this.state.valid &&
                this.state.errors.includes("location_name")) ||
              this.state.nameError
            }
            isRequired={true}
          />
          <Input
            type="textarea"
            label="Description"
            placeholder="Please type here"
            style={{ marginBottom: 20 }}
            value={data.description}
            // onChange={(e) => this.onChange("description", e.target.value)}
            onChange={(e) => {
              this.setState({
                ...this.state,
                descError: false,
              });
              this.onChange("description", e.target.value);
            }}
            caption={this.state.descError && "This field is required"}
            isError={
              (this.state.send &&
              !this.state.valid &&
              this.state.errors.includes("description")) || this.state.descError
            }
            isRequired={true}
          />

          <ButtonGroup position="right" className="modal__form__btn-group">
            <Button
              buttonText="Cancel"
              size="sm"
              type="default"
              style={{ margin: 0 }}
              onClick={() => this.props.onClose()}
            />
            <Button
              buttonText="Update"
              size="sm"
              type="warning"
              style={{ margin: 0 }}
              onClick={() => this.onUpdate()}
            />
          </ButtonGroup>
        </FormBody>
      </Modal>
    );
  }
}

const mapsStateToProps = ({ authenticated }) => {
  return {
    token: authenticated.token,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/TrackingRedux");

  return {
    ...ownProps,
    ...stateProps,
    updateTrackingLocation: async (token, data) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await actions.updateTrackingLocations(dispatch, token, data);
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    clearTrackingHistories: () =>
      dispatch({ type: "CLEAR_TRACKING_HISTORIES" }),
    fetchTrackingHistories: async (token, consignment_id) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await actions.fetchTrackingHistories(
        dispatch,
        token,
        consignment_id
      );
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    toastPending: (body) => {
      dispatch({ type: "TOAST_PENDING", ...body });
    },
  };
};

export default connect(
  mapsStateToProps,
  undefined,
  mergeProps
)(UpdateShipmentTrackingLocationContainer);
