import React from 'react';
import "./styles.scss";

class DetailInformation extends React.PureComponent {

    render() {
        return(
            <div className="detail__information" style={this.props.style}>
                {this.props.children}
            </div>
        )
    }

}

export default (DetailInformation);