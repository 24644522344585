import React from 'react';
import { CreateRefundsContainer } from 'container';

class CreateRefund extends React.PureComponent {
    render() {
        return (
            <CreateRefundsContainer
                {...this.props}
            />
        )
    }
}

export default (CreateRefund);