import React from "react";
import Select from "react-select";
import "./select.scss";

class SelectType extends React.PureComponent {
  render() {
    const { options, isRequired } = this.props;

    return (
      <div className="input" style={this.props.style}>
        <label
          className={`input__label ${
            this.props.isError ? "input__label__error" : undefined
          }`}
        >
          {this.props.label}
          {isRequired && <span className="input__required">*</span>}
        </label>

        {this.props.iconPrefix && (
          <img
            src={this.props.iconPrefix}
            alt="form-icon-prefix"
            className="input__icon input__icon__prefix"
          />
        )}

        {this.props.iconSuffix && (
          <img
            src={this.props.iconSuffix}
            alt="form-icon-suffix"
            className="input__icon input__icon__suffix"
          />
        )}

        {!this.props.isError ? (
          <>
            {this.props.iconPrefix && this.props.iconSuffix ? (
              <Select
                className="input__select input__select__icon__prefix input__select__icon__suffix"
                classNamePrefix={`input__select`}
                options={options}
                onChange={(e) => this.props.onChange(e)}
                onBlur={(e) => this.props.onBlur(e)}
                value={this.props.value}
                ref={this.props.ref}
                {...this.props.selectProps}
              />
            ) : (
              <>
                {this.props.iconPrefix && (
                  <Select
                    className="input__select input__select__icon__prefix"
                    classNamePrefix={`input__select`}
                    options={options}
                    onChange={(e) => this.props.onChange(e)}
                    onBlur={(e) => this.props.onBlur(e)}
                    value={this.props.value}
                    ref={this.props.ref}
                    {...this.props.selectProps}
                  />
                )}

                {this.props.iconSuffix && (
                  <Select
                    className="input__select input__select__icon__suffix"
                    classNamePrefix={`input__select`}
                    options={options}
                    onChange={(e) => this.props.onChange(e)}
                    onBlur={(e) => this.props.onBlur(e)}
                    value={this.props.value}
                    ref={this.props.ref}
                    {...this.props.selectProps}
                  />
                )}

                {!this.props.iconSuffix && !this.props.iconPrefix && (
                  <Select
                    className="input__select"
                    classNamePrefix={`input__select`}
                    options={options}
                    onChange={(e) => this.props.onChange(e)}
                    onBlur={(e) => this.props.onBlur(e)}
                    value={this.props.value}
                    ref={this.props.ref}
                    {...this.props.selectProps}
                  />
                )}
              </>
            )}
          </>
        ) : (
          <>
            {this.props.iconPrefix && this.props.iconSuffix ? (
              <Select
                className="input__select input__select__icon__prefix input__select__icon__suffix"
                classNamePrefix={`input__select__error`}
                options={options}
                onChange={(e) => this.props.onChange(e)}
                onBlur={(e) => this.props.onBlur(e)}
                value={this.props.value}
                ref={this.props.ref}
                {...this.props.selectProps}
              />
            ) : (
              <>
                {this.props.iconPrefix && (
                  <Select
                    className="input__select input__select__icon__prefix"
                    classNamePrefix={`input__select__error`}
                    options={options}
                    onChange={(e) => this.props.onChange(e)}
                    onBlur={(e) => this.props.onBlur(e)}
                    value={this.props.value}
                    ref={this.props.ref}
                    {...this.props.selectProps}
                  />
                )}

                {this.props.iconSuffix && (
                  <Select
                    className="input__select input__select__icon__suffix"
                    classNamePrefix={`input__select__error`}
                    options={options}
                    onChange={(e) => this.props.onChange(e)}
                    onBlur={(e) => this.props.onBlur(e)}
                    value={this.props.value}
                    ref={this.props.ref}
                    {...this.props.selectProps}
                  />
                )}

                {!this.props.iconSuffix && !this.props.iconPrefix && (
                  <Select
                    className="input__select"
                    classNamePrefix={`input__select__error`}
                    options={options}
                    onChange={(e) => this.props.onChange(e)}
                    onBlur={(e) => this.props.onBlur(e)}
                    value={this.props.value}
                    ref={this.props.ref}
                    {...this.props.selectProps}
                  />
                )}
              </>
            )}
          </>
        )}

        {this.props.caption && (
          <label
            className={`input__caption ${
              this.props.isError ? "input__caption__error" : undefined
            }`}
          >
            {this.props.caption}
          </label>
        )}
      </div>
    );
  }
}

export default SelectType;
