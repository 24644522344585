import React from "react";
import {
  Button,
  FormBody,
  FormWrapper,
  Input,
  ImageUploader,
} from "components";
import { Tools, Images, navigate } from "common";
import { connect } from "react-redux";

class CreateFleetCategoriesContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      nameError: false,
      imageError: false,
    };
  }

  onChange = (target, value) => {
    this.setState({
      send: false,
      data: {
        ...this.state.data,
        [target]: value,
      },
    });
  };

  onBlurValidation = (target) => {
    if (target === "name") {
      if (!Tools.isNotEmpty(this.state.data.name)) {
        this.setState({
          ...this.state,
          nameError: true,
        });
      } else {
        this.setState({
          ...this.state,
          nameError: false,
        });
      }
    }
    if (target === "image") {
      if (!Tools.isNotEmpty(this.state.data.image)) {
        this.setState({
          ...this.state,
          imageError: true,
        });
      } else {
        this.setState({
          ...this.state,
          imageError: false,
        });
      }
    }
  };

  onImageChange = (event) => {
    const reader = new FileReader();
    var file = event.target.files[0];

    let local = this;
    reader.onload = function (event) {
      local.setState({
        data: {
          ...local.state.data,
          image: {
            file: event.target.result,
            name: file.name,
            value: event.target.result,
          },
        },
      });
    };

    reader.readAsDataURL(file);
  };

  validation = async () => {
    const { name, image } = this.state.data;
    var valid = Tools.isNotEmpty(name) && Tools.isNotEmpty(image);

    this.setState({
      valid: valid,
      send: true,
    });

    return valid;
  };

  onAdd = async () => {
    this.setState({
      send: true,
    });

    var valid = await this.validation();

    if (valid) {
      const { createFleetCategories, token, history, toastPending } =
        this.props;
      var params = {
        name: this.state.data.name,
        file: this.state.data.image.value,
      };
      var json = await createFleetCategories(token, params);
      if (json.status_code === 200) {
        navigate(
          history,
          "/dashboard?screen=master_data&child=fleet_categories"
        );
      }
      toastPending({ message: json.message, toastType: json.status_code });
    }
  };

  render() {
    const { data } = this.state;
    const { innerWidth } = window;

    return (
      <div
        className="container"
        style={
          innerWidth > 768
            ? { marginTop: 50, marginBottom: 50 }
            : { marginTop: 15, marginBottom: 15 }
        }
      >
        <FormWrapper>
          <FormBody>
            <h1
              className="text__verification__header"
              style={{ marginBottom: 0 }}
            >
              Add Fleet Categories
            </h1>
            <h3
              className="text__verification__body"
              style={{ marginBottom: 30 }}
            >
              Create your new fleet categories master
            </h3>
            <Input
              type={"text"}
              iconPrefix={Images.user}
              label="Name"
              placeholder="Please type here"
              style={{ margin: 0, marginBottom: 30 }}
              value={data.name}
              caption={this.state.nameError && "This field is required"}
              isError={
                (this.state.send && !this.state.valid) || this.state.nameError
              }
              onBlur={() => this.onBlurValidation("name")}
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  nameError: false,
                });
                this.onChange("name", e.target.value);
              }}
              isRequired={true}
            />
            <ImageUploader
              value={data.image ?? {}}
              label="Image"
              style={{ margin: 0, marginBottom: 25 }}
              onChange={(e) => {
                this.setState({
                  ...this.state,
                  imageError: false,
                });
                this.onImageChange(e);
              }}
              caption={this.state.imageError && "This field is required"}
              onBlur={() => this.onBlurValidation("image")}
              isError={
                (this.state.send && !this.state.valid) || this.state.imageError
              }
              isRequired={true}
            />
            <Button
              buttonText="Create Categories"
              size="lg"
              type="warning"
              style={{ margin: 0 }}
              onClick={() => this.onAdd()}
            />
          </FormBody>
        </FormWrapper>
      </div>
    );
  }
}

const mapsStateToProps = ({ authenticated }) => {
  return {
    token: authenticated.token,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/FleetCategoriesRedux");

  return {
    ...ownProps,
    ...stateProps,
    createFleetCategories: async (token, body) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await actions.createFleetCategories(dispatch, token, body);
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    toastPending: (body) => {
      dispatch({ type: "TOAST_PENDING", ...body });
    },
  };
};

export default connect(
  mapsStateToProps,
  undefined,
  mergeProps
)(CreateFleetCategoriesContainer);
