import { Footer, Navbar, Sidenav } from "components";
import React from "react";
import { connect } from "react-redux";
import ErrorBoundary from "./error-boundaries";
import jwt_decode from "jwt-decode";
import { Constant, Tools, navigate } from "common";
import { Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { Loader, Toast } from "components";

class AppLayout extends React.PureComponent {
  componentDidMount() {
    const { error, fetchFinish, resetError } = this.props;

    // fetchFinish()
    if (error.hasError) {
      fetchFinish();
      resetError();
    }

    this.handleAutoLogout();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.location.pathname.includes("/consignment/create") ||
      prevProps.location.pathname.includes("/consignment/edit")
    ) {
      localStorage.removeItem("@consignmentData");
    }
  }

  handleAutoLogout() {
    const { token } = this.props;
    if (Tools.isNotEmpty(token)) {
      var decoded_token = jwt_decode(token);
      let currentDate = new Date();
      if (decoded_token.exp * 1000 < currentDate.getTime()) {
        this.handleUserLogout();
      }
    }
  }

  handleUserLogout = async () => {
    const { logout, token, toastPending } = this.props;

    var json = await logout(token, true);
    if (json.status_code === 200) {
      navigate(this.props.history, "/", "replace");
    }
    toastPending({ message: json.message, toastType: json.status_code });
  };

  onChangeMenu = (selectedMenu, child) => {
    if (child === undefined) {
      this.props.history.replace({
        pathname: "dashboard",
        search: "?screen=" + selectedMenu.key,
      });
      this.props.setNavigation(selectedMenu.key);
    } else {
      this.props.history.replace({
        pathname: "dashboard",
        search: "?screen=" + child.parent + "&child=" + child.key,
      });
      this.props.setNavigation(child.key);
    }
    var page = window.location.pathname + "" + window.location.search;
    localStorage.setItem("@activePage", page);
  };

  removeUnusedCache = async () => {
    console.log("work");
    // localStorage.removeItem('@consignmentData')
    // localStorage.removeItem('@registrationData')
  };

  render() {
    const { token, isFetching, toast } = this.props;

    const { user } = this.props;

    var menu = [];
    if (user.role === "user") {
      menu = Constant.DASHBORD_MENU.USER;
    } else if (user.role === "partner") {
      menu = Constant.DASHBORD_MENU.PARTNER;
    } else if (user.role === "delivery_boy") {
      menu = Constant.DASHBORD_MENU.WORKER;
    } else if (user.role === "officer") {
      menu = Constant.DASHBORD_MENU.OFFICER;
    } else {
      menu = Constant.DASHBORD_MENU.ADMIN;
    }

    var params = this.props.history.location.search;
    var location = this.props.history.location.pathname;
    var search = new URLSearchParams(params);
    const isAndriodParam = search.get('from') == 'android' ? true: false;
    // window.addEventListener("beforeunload", this.removeUnusedCache());

    return (
      <>
        {isFetching && <Loader isVisible={isFetching} />}

        {toast.showToast && (
          <Toast
            isVisible={toast.showToast}
            message={toast.toast}
            type={toast.toastType}
            duration={3000}
          />
        )}

        {!isAndriodParam && <Navbar />}
        

        <div className="app-content">
          <ErrorBoundary>
            {token !== null ? (
              location !== "/dashboard" || search.get("status") !== null ? (
                this.props.children
              ) : (
                <div className="container__dashboard">
                  <Row>
                    <Col xl={2} lg={3}>
                      <Sidenav
                        menu={menu}
                        onChange={(selectedMenu, child) =>
                          this.onChangeMenu(selectedMenu, child)
                        }
                      />
                    </Col>
                    <Col>{this.props.children}</Col>
                  </Row>
                </div>
              )
            ) : (
              this.props.children
            )}
          </ErrorBoundary>
        </div>

        {!isAndriodParam && <Footer />}
      </>
    );
  }
}

const mapsStateToProps = ({ authenticated, app }) => {
  return {
    error: app.error,
    token: authenticated.token,
    error_auth: authenticated.error,

    isFetching: app.isFetching,
    toast: app,
    user: authenticated.userInfo,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/AuthenticatedRedux");

  return {
    ...ownProps,
    ...stateProps,
    logout: async (token, isForce) => {
      dispatch({ type: "FETCH_PENDING" });
      var logout = await actions.logout(dispatch, token, isForce);
      dispatch({ type: "FETCH_FINISH" });
      return logout;
    },
    toastPending: (body) => {
      dispatch({ type: "TOAST_PENDING", ...body });
    },
    toastFinish: () => dispatch({ type: "TOAST_FINISH" }),
    fetchFinish: () => dispatch({ type: "FETCH_FINISH" }),
    resetError: () => dispatch({ type: "RESET_ERROR" }),
    setNavigation: (menu) => dispatch({ type: "SET_NAVIGATION", items: menu }),
  };
};

export default connect(
  mapsStateToProps,
  undefined,
  mergeProps
)(withRouter(AppLayout));
