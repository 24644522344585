import React from "react";
import "./checkbox.scss";

class InputCheckbox extends React.PureComponent {
  render() {
    const { value, onChange, label, info } = this.props;

    return (
      <div
        className={`input__checkbox__wrapper ${this.props.wrapperClassName}`}
        style={this.props.style}
      >
        <div className={`input__checkbox ${this.props.className}`}>
          <div
            style={
              this.props.size === "sm"
                ? { marginRight: 25 }
                : { marginRight: 15 }
            }
          >
            <input
              type="checkbox"
              className={`input__checkbox__control ${this.props.controlClassName}`}
              value={value}
              checked={value}
              onChange={(e) => onChange(e.target.checked)}
              onBlur={(e) => this.props.onBlur(e)}
              {...this.props.inputProps}
            />
            <span
              className={`input__checkbox__custom-control ${this.props.controlClassName}`}
            ></span>
          </div>
          <div>
            <p
              className={`input__checkbox__label ${this.props.labelClassName}`}
            >
              {label}
            </p>
            <p className="input__checkbox__information">{info}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default InputCheckbox;
