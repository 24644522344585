import images from "common/Images";
import Tools from "common/Tools";
import { Button, ButtonGroup, FormBody, Input, Modal } from "components";
import React from "react";
import { FaAngry, FaGrinHearts, FaMeh, FaSmile} from "react-icons/fa";
import "./styles.scss";

var validation = {
  description: "required",
};

class ReviewDeliveryContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      valid: false,
      errors: [],
      send: false,
      descriptionError: false,
    };
  }

  onChange = (target, value) => {
    this.setState({
      send: false,
      data: {
        ...this.state.data,
        [target]: value,
      },
    });
  };

  onReview = async () => {
    const valid = await this.validation();
    this.setState({
      send: true,
    });

    if (valid) {
      var { data } = this.state;
      this.props.onReview(data);
    }
  };

  validation = async () => {
    var validate = {};
    validate = await Tools.validation(validation, this.state.data);

    this.setState({
      valid: validate.valid,
      errors: validate.errors,
    });

    return validate.valid;
  };

  onBlurValidation = () => {
    if (!Tools.isNotEmpty(this.state.data.description)) {
      this.setState({
        ...this.state,
        descriptionError: true,
      });
    } else {
      this.setState({
        ...this.state,
        descriptionError: false,
      });
    }
  };

  render() {
    const { data } = this.state;

    var options = [
      {
        name: "amazing",
        icon: (
          <FaGrinHearts
            className={`emojies__icon ${
              data.satisfaction === "amazing" ? "active" : ""
            }`}
          />
        ),
      },
      {
        name: "smile",
        icon: (
          <FaSmile
            className={`emojies__icon ${
              data.satisfaction === "smile" ? "active" : ""
            }`}
          />
        ),
      },
      {
        name: "neutral",
        icon: (
          <FaMeh
            className={`emojies__icon ${
              data.satisfaction === "neutral" ? "active" : ""
            }`}
          />
        ),
      },
      {
        name: "angry",
        icon: (
          <FaAngry
            className={`emojies__icon ${
              data.satisfaction === "angry" ? "active" : ""
            }`}
          />
        ),
      },
    ];

    return (
      <Modal
        reference={(ref) => (this.rejection_modal = ref)}
        isVisible={this.props.isVisible}
        className="modal__form"
      >
        <button
          className={`button__link modal__detail__closer`}
          onClick={() => this.props.onClose()}
        >
          <img src={images.close} alt="close" />
        </button>

        <FormBody className="modal__form__container">
          <h1 className="modal__form__header">Review Delivery</h1>
          <h3 className="text__verification__body" style={{ marginBottom: 30 }}>
            Write your rating about our service here
          </h3>

          <Input
            type="textarea"
            label="Description"
            placeholder="Please type here"
            style={{ marginBottom: 20 }}
            value={data.description}
            onChange={(e) => {
              this.setState({
                ...this.state,
                descriptionError: false,
              });
              this.onChange("description", e.target.value);
            }}
            onBlur={this.onBlurValidation}
            caption={this.state.descriptionError && "This field is required"}
            isError={
              (this.state.send &&
                !this.state.valid &&
                this.state.errors.includes("description")) ||
              this.state.descriptionError
            }
            isRequired={true}
          />

          <Input
            type="emoji"
            options={options}
            style={{ marginBottom: 30, marginTop: 40 }}
            value={data.satisfaction}
            onChange={(e) => this.onChange("satisfaction", e.name)}
          />

          <ButtonGroup position="right" className="modal__form__btn-group">
            <Button
              buttonText="Cancel"
              size="sm"
              type="default"
              style={{ margin: 0 }}
              onClick={() => this.props.onClose()}
            />
            <Button
              buttonText="Save"
              size="sm"
              type="warning"
              style={{ margin: 0 }}
              onClick={() => this.onReview()}
            />
          </ButtonGroup>
        </FormBody>
      </Modal>
    );
  }
}

export default ReviewDeliveryContainer;
