import { Constant } from "common";

export const ConsignmentsAPI = {

    fetchConsignments: (token) => {
        try {
            return fetch(`${Constant.API_URL}/consignments`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(async(response) => {
                if(response.ok) {
                    return response.json()
                }
                var error_message = await response.json().then((json) => {
                    if(typeof(json.message) === "object") {
                        for (const index in json.message) {
                            return json.message[index];
                        }
                    }
                    else {
                        return json; 
                    }
                })
                throw new Error(JSON.stringify(error_message));
            })
            .then((json) => {
                return { status_code: 200, ...json };
            }).catch((error) => {
                return { status_code: 500, ...error };
            });
        }
        catch(error) {
            return { status_code: 500, ...error };
        }
    },

    fetchUsersConsignments: (token, user_id) => {
        try {
            return fetch(`${Constant.API_URL}/user/${user_id}/consignment`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(async(response) => {
                if(response.ok) {
                    return response.json()
                }
                var error_message = await response.json().then((json) => {
                    if(typeof(json.message) === "object") {
                        for (const index in json.message) {
                            return json.message[index];
                        }
                    }
                    else {
                        return json; 
                    }
                })
                throw new Error(JSON.stringify(error_message));
            })
            .then((json) => {
                return { status_code: 200, ...json };
            }).catch((error) => {
                return { status_code: 500, ...error };
            });
        }
        catch(error) {
            return { status_code: 500, ...error };
        }
    },

    fetchConsigmentStatus: (token) => {
        try {
            return fetch(`${Constant.API_URL}/consignment/status`, {
                method: "GET",
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(async(response) => {
                if(response.ok) {
                    return response.json()
                }
                var error_message = await response.json().then((json) => {
                    if(typeof(json.message) === "object") {
                        for (const index in json.message) {
                            return json.message[index];
                        }
                    }
                    else {
                        return json; 
                    }
                })
                throw new Error(JSON.stringify(error_message));
            })
            .then((json) => {
                return { status_code: 200, ...json };
            }).catch((error) => {
                return { status_code: 500, ...error };
            });
        }
        catch(error) {
            return { status_code: 500, ...error };
        }
    },

    createConsignment: (token, body) => {
        try {
            var params = {
                ...body,
                material_price: body.material_price
            }
            return fetch(`${Constant.API_URL}/consignment`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(params)
            }).then(async(response) => {
                if(response.ok) {
                    return response.json();
                }
                var error_message = await response.json().then((json) => {
                    if(typeof(json.message) === "object") {
                        for (const index in json.message) {
                            return json.message[index];
                        }
                    }
                    else {
                        return json; 
                    }
                })
                throw new Error(JSON.stringify(error_message))
            })
            .then((json) => {
                return { status_code: 200, ...json };
            }).catch((error) => {
                return { status_code: 500, ...error };
            });
        }
        catch(error) {
            return { status_code: 500, ...error };
        }
    },

    updateConsignment: (token, body) => {
        try {
            return fetch(`${Constant.API_URL}/consignment`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(body)
            }).then(async(response) => {
                if(response.ok) {
                    return response.json();
                }
                var error_message = await response.json().then((json) => {
                    if(typeof(json.message) === "object") {
                        for (const index in json.message) {
                            return json.message[index];
                        }
                    }
                    else {
                        return json; 
                    }
                })
                throw new Error(JSON.stringify(error_message))
            })
            .then((json) => {
                return { status_code: 200, ...json };
            }).catch((error) => {
                return { status_code: 500, ...error };
            });
        }
        catch(error) {
            return { status_code: 500, ...error };
        }
    },

    updateConsignmentStatus: (token, consignment_id, body) => {
        try {
            return fetch(`${Constant.API_URL}/orders/update-consigment/${consignment_id}`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(body)
            }).then(async(response) => {
                if(response.ok) {
                    return response.json();
                }
                var error_message = await response.json().then((json) => {
                    if(typeof(json.message) === "object") {
                        for (const index in json.message) {
                            return json.message[index];
                        }
                    }
                    else {
                        return json; 
                    }
                })
                throw new Error(JSON.stringify(error_message))
            })
            .then((json) => {
                return { status_code: 200, ...json };
            }).catch((error) => {
                return { status_code: 500, ...error };
            });
        }
        catch(error) {
            return { status_code: 500, ...error };
        }
    },

}