import { Tools, Images } from "common";
import { Button, Confirmation, Input } from "components";
import React from "react";
import { connect } from "react-redux";
import AuctionAssignTaskContainer from "./Assign";

var validation = {
  bid_price: "required",
  delivery_days: "required",
  description: "required",
};

class CreateAuctionBidContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      estimated_delivery: [
        "same day",
        "1-2 days",
        "3-5 days",
        "1 week",
        "2 weeks",
        "3 weeks",
        "1 month",
      ],
      data: {},
      confirmation: {},
      errors: [],
      loaded: false,
      bidPriceError: false,
      deliverDayError: false,
      descriptionError: false,
    };
  }

  componentDidMount() {
    this.fetchBidders();
  }

  fetchBidders = async () => {
    const { auction, token, clearAuctionsBidders, fetchAuctionsBidders } =
      this.props;
    clearAuctionsBidders();
    await fetchAuctionsBidders(token, auction.auction_id);
    this.setState({ loaded: true });
  };

  validation = async () => {
    var validate = {};
    validate = await Tools.validation(validation, this.state.data);

    this.setState({
      valid: validate.valid,
      errors: validate.errors,
    });

    return validate.valid;
  };

  onBlurValidationHandler = (target) => {
    if (target === "bidPrice")
      if (!Tools.isNotEmpty(this.state.data.bid_price)) {
        this.setState({
          ...this.state,
          bidPriceError: true,
        });
      } else {
        this.setState({
          ...this.state,
          bidPriceError: false,
        });
      }
    else if (target === "deliveryDay") {
      if (!Tools.isNotEmpty(this.state.data.delivery_days)) {
        this.setState({
          ...this.state,
          deliverDayError: true,
        });
      } else {
        this.setState({
          ...this.state,
          deliverDayError: false,
        });
      }
    } else if (target === "description") {
      if (!Tools.isNotEmpty(this.state.data.description)) {
        this.setState({
          ...this.state,
          descriptionError: true,
        });
      } else {
        this.setState({
          ...this.state,
          descriptionError: false,
        });
      }
    }
  };

  onSend = async () => {
    var valid = await this.validation();
    this.setState({ send: true });

    if (valid) {
      const { data } = this.state;
      const { auction, partner, createBidAuction, toastPending, token } =
        this.props;
      var params = {
        bidder_id: partner.id,
        bid_price: parseFloat(data.bid_price),
        delivery_days: data.delivery_days.value,
        description: data.description,
      };
      var json = await createBidAuction(token, auction.auction_id, params);
      if (json.status_code === 200) {
        this.fetchBidders();
      }
      toastPending({ message: json.message, toastType: json.status_code });
    }
  };

  onChange = (target, value) => {
    this.setState({
      send: false,
      data: {
        ...this.state.data,
        [target]: value,
      },
    });
  };

  openConfirmation = (headerText, descriptionText, onCancel, onConfirm) => {
    this.setState({
      confirmation: {
        isVisible: true,
        header: headerText,
        description: descriptionText,
        onCancel: onCancel,
        onConfirm: onConfirm,
      },
    });
  };

  onCancelConfirmation = () => {
    this.setState({
      confirmation: {
        isVisible: false,
      },
    });
  };

  onWithdraw = async (my_bid) => {
    this.onCancelConfirmation();
    const { token, toastPending, withdrawBidAuction, auction } = this.props;
    var json = await withdrawBidAuction(token, auction.auction_id, my_bid.id);
    if (json.status_code === 200) {
      this.setState({ loaded: false });
      this.fetchBidders();
    }
    toastPending({ message: json.message, toastType: json.status_code });
  };

  render() {
    const { bidders, partner, status, bid_total, auction } = this.props;
    const { confirmation } = this.state;

    var hasBidded = false;
    var bid_status = null;
    if (bid_total > 0) {
      var my_bid = bidders.find(
        (item) => parseFloat(item.bidder_id) === parseFloat(partner.id)
      );
      if (my_bid) {
        bid_status = status.find(
          (item) =>
            parseFloat(item.status_code) === parseFloat(my_bid.bid_status)
        );
        hasBidded = true;
      }
    }

    const renderBidForm = () => {
      const { estimated_delivery, data } = this.state;

      const estimated = [];
      estimated_delivery.map((item) => {
        estimated.push({ label: item, value: item, name: "estimated_time" });
        return true;
      });

      return (
        <div>
          <h4 className="details__item__header">Send Bid</h4>
          <Input
            type="number"
            min="0"
            label="Bid Amount"
            placeholder="Please type here"
            style={{ marginLeft: 0, marginRight: 0, marginBottom: 20 }}
            caption={this.state.bidPriceError && "This field is required"}
            onBlur={() => this.onBlurValidationHandler("bidPrice")}
            value={data.bid_price}
            isError={
              (this.state.send &&
                !this.state.valid &&
                this.state.errors.includes("bid_price")) ||
              this.state.bidPriceError
            }
            onChange={(e) => {
              this.setState({
                ...this.state,
                bidPriceError: false,
              });
              this.onChange("bid_price", e.target.value);
            }}
            isRequired={true}
          />
          <Input
            type="select"
            label="Estimated Delivery Day"
            placeholder="Please type here"
            options={estimated}
            style={{ marginLeft: 0, marginRight: 0, marginBottom: 20 }}
            caption={this.deliverDayError && "This field is required"}
            // onBlur={() => this.onBlurValidationHandler("deliveryDay")}
            value={data.delivery_days}
            isError={
              (this.state.send &&
                !this.state.valid &&
                this.state.errors.includes("delivery_days")) ||
              this.state.deliverDayError
            }
            onChange={(e) => {
              this.setState({
                ...this.state,
                deliverDayError: false,
              });
              this.onChange("delivery_days", e);
            }}
            isRequired={true}
          />
          <Input
            type="textarea"
            label="Cover Letter"
            placeholder="Please type here"
            style={{ marginLeft: 0, marginRight: 0, marginBottom: 20 }}
            caption={this.state.descriptionError && "This field is required"}
            value={data.description}
            onBlur={() => this.onBlurValidationHandler("description")}
            isError={
              (this.state.send &&
                !this.state.valid &&
                this.state.errors.includes("description")) ||
              this.state.descriptionError
            }
            onChange={(e) => {
              this.setState({
                ...this.state,
                descriptionError: false,
              });
              this.onChange("description", e.target.value);
            }}
            isRequired={true}
          />
          <Button
            type="warning"
            size="lg"
            style={{ margin: 0, marginBottom: 0 }}
            buttonText="Send Bid"
            onClick={() => this.onSend()}
          />
        </div>
      );
    };

    const renderOfferedBid = (my_bid) => {
      return (
        <Button
          type="danger"
          size="lg"
          style={{ margin: 0, marginBottom: 0, marginTop: 20 }}
          buttonText="Withdraw Bid"
          onClick={() =>
            this.openConfirmation(
              "Are you sure",
              "Are you sure to withdraw your bid on this project?",
              () => this.onCancelConfirmation(),
              () => this.onWithdraw(my_bid)
            )
          }
        />
      );
    };

    const renderAcceptedBid = (my_bid) => {
      return (
        <Button
          type="warning"
          size="lg"
          style={{ margin: 0, marginBottom: 0, marginTop: 20 }}
          buttonText="Assign Job to Delivery Boy"
          onClick={() => this.setState({ openAssignModal: true })}
        />
      );
    };

    return (
      <>
        <Confirmation
          isVisible={confirmation.isVisible}
          header={confirmation.header}
          description={confirmation.description}
          confirmationText="Yes"
          onCancel={confirmation.onCancel}
          onConfirm={confirmation.onConfirm}
        />

        <AuctionAssignTaskContainer
          {...this.props}
          isVisible={this.state.openAssignModal}
          onClose={() => this.setState({ openAssignModal: false })}
        />

        {this.state.loaded &&
          (!hasBidded ? (
            renderBidForm()
          ) : auction.status < 142 ? (
            bid_status && bid_status?.status_name !== "withdraw" ? (
              <div>
                <h4 className="details__item__header">Bid Detail</h4>
                <div className="details__item__group">
                  <p className="label">Bid Amount</p>
                  <p className="text">{my_bid.bid_price}</p>
                </div>
                <div className="details__item__group">
                  <p className="label">Estimated Delivery Day</p>
                  <p className="text">{my_bid.delivery_days}</p>
                </div>
                <div className="details__item__group">
                  <p className="label">Cover Letter</p>
                  <p className="text">{my_bid.description}</p>
                </div>
                <div
                  className="details__item__group"
                  style={{ marginBottom: 0 }}
                >
                  <p className="label">Status</p>
                  <p className="text">
                    {bid_status ? bid_status?.status_name : ""}
                  </p>
                </div>

                {bid_status &&
                  bid_status?.status_name === "offered" &&
                  renderOfferedBid(my_bid)}

                {auction.status === 141 &&
                  bid_status &&
                  bid_status?.status_name === "accepted" &&
                  renderAcceptedBid(my_bid)}
              </div>
            ) : (
              <div className="details__empty">
                <img src={Images.empty} alt="empty" />
                <p className="text text__bold text__uppercase">
                  Your bid has withdrawn due to a reason
                </p>
              </div>
            )
          ) : (
            <div className="details__empty">
              <img src={Images.empty} alt="empty" />
              <p className="text text__bold text__uppercase">
                This auction completed
              </p>
            </div>
          ))}
      </>
    );
  }
}

const mapsStateToProps = ({ authenticated, consignment, auction }) => {
  return {
    token: authenticated.token,
    partner: authenticated.userInfo.partner,
    status: consignment.status,
    bidders: auction.bid_lists,
    bid_total: auction.bid_total,
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/AuctionRedux");

  return {
    ...ownProps,
    ...stateProps,
    fetchAuctionsBidders: async (token, auction_id) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await actions.fetchAuctionsBidders(
        dispatch,
        token,
        auction_id
      );
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    createBidAuction: async (token, auction_id, body) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await actions.createBidAuction(
        dispatch,
        token,
        auction_id,
        body
      );
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    withdrawBidAuction: async (token, auction_id, bid_id) => {
      dispatch({ type: "FETCH_PENDING" });
      var json = await actions.withdrawBidAuction(
        dispatch,
        token,
        auction_id,
        bid_id
      );
      dispatch({ type: "FETCH_FINISH" });
      return json;
    },
    clearAuctionsBidders: () => dispatch({ type: "CLEAR_AUCTIONS_BIDDERS" }),
    toastPending: (body) => {
      dispatch({ type: "TOAST_PENDING", ...body });
    },
  };
};

export default connect(
  mapsStateToProps,
  undefined,
  mergeProps
)(CreateAuctionBidContainer);
