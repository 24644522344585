import React from 'react';
import { Table, Confirmation, Filter, Button } from 'components';
import { connect } from 'react-redux';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import * as Moment from 'moment';
import { navigate, Images } from 'common';
import RefundDetailContainer from './Detail';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

class RefundsContainer extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            confirmation: {
                isVisible: false,
                header: "",
                description: "",
                onCancel: null,
                onConfirm: null
            },
            loaded: false
        }
    }

    async componentDidMount() {
        const {
            fetchRefunds,
            token,
        } = this.props
        await fetchRefunds(token)
        
        this.setState({
            loaded: true
        })
    }

    onSearch = (keyword) => {
        const { refunds } = this.props;
        var filteredData = refunds[0].filter((item) => item.refund_id.toLowerCase().includes(keyword.toLowerCase()));
        this.setState({
            keyword: keyword,
            filteredData: filteredData
        });
    }

    onFilter = async(filters) => {
        var filterDate = await this.filterDate(filters);
        var finalData = filterDate;
        var filteredData = finalData;

        this.setState({
            isFiltering: false,
            keyword: "filter",
            filteredData: filteredData
        })
    }

    filterDate = (filters) => {
        const { refunds } = this.props;
        var isFilterDate = filters.filter((item) => item.target === "created_date");
        if(isFilterDate.length > 0) {
            var filteredData = [];
            var date_from = filters.find((item) => item.target === "created_date" && item.key === "date_from")    
            var date_to = filters.find((item) => item.target === "created_date" && item.key === "date_to");
            if(date_to) {
                var range = moment.range(date_from.value, date_to.value);
                
                filteredData = refunds[0].filter((item) => {
                    if(moment(item.created_at).within(range)) {
                        return item;
                    }
                    return false;
                });
            }
            else {
                filteredData = refunds[0].filter((item) => {
                    var created_at = moment(item.created_at).format("YYYY-MM-DD");
                    var filterDate = moment(date_from.value).format("YYYY-MM-DD");

                    if(moment(created_at).isSame(filterDate)) {
                        return item;
                    }
                    return false;
                });
            }
            return filteredData;
        }
        
        return refunds;
    }

    render() {

        const {
            refunds
        } = this.props;
        const { confirmation, filteredData, keyword } = this.state;

        const columns = [
            {
                Header: 'No',
                id: 'no',
                accessor: (props, index) => (
                    <div className="table__body__row__column__text-style">{index + 1}</div>
                )
            },
            {
                Header: 'Refund ID',
                id: 'refund_id',
                accessor: (props, index) => (
                    <div className="table__body__row__column__text-style">{props.refund_id}</div>
                )
            },
            {
                Header: 'Payment ID',
                id: 'payment_id',
                accessor: props => (
                    <div className="table__body__row__column__text-style">{props.payment_id}</div>
                )
            },
            {
                Header: 'Amount',
                id: 'amount',
                accessor: props => (
                    <div className="table__body__row__column__text-style">{props.amount} INR</div>
                )
            },
            {
                Header: 'Created Date',
                id: 'created_at',
                accessor: props => (
                    <div className="table__body__row__column__text-style">{moment(props.created_at).format('DD MMM YYYY')}</div>
                ),
            },
            {
                Header: 'Batch ID',
                id: 'batch_id',
                accessor: props => (
                    <div className="table__body__row__column__text-style">{null}</div>
                )
            },
            {
                Header: 'Status',
                id: 'status',
                accessor: props => {
                    return (
                        <div className={`table__body__row__column__btn-style table__body__row__column__btn-style__primary`}>{props.status === 155 ? "Pending" : props.status === 156 ? "Processed" : "Failed"}</div>
                    ) 
                    
                },
            },
            {
                Header: 'Actions',
                id: "id",
                accessor: props => {
                    return(
                        <DropdownButton
                            className="table__body__row__column__dropdown center"
                            key="up"
                            drop="up"
                            variant="secondary"
                            title={<i className="material-icons">more_horiz</i>}
                        >
                            <Dropdown.Item eventKey="1" 
                                onClick={() => this.setState({ selectedData: props }, () => this.setState({ openDetail: true }))} 
                                className="dropdown-item__detail"
                            >
                                See Detail
                            </Dropdown.Item>

                        </DropdownButton>
                    )
                }
            }
        ];

        const data = keyword ? filteredData : refunds[0];
        
        const filterOptions = [
            {
                type: "date",
                key: "created_date",
                label: "Date"
            },
        ];

        return(
            <div className="dashboard">

                <Confirmation
                    isVisible={confirmation.isVisible}
                    header={confirmation.header}
                    description={confirmation.description}
                    confirmationText="Yes"
                    onCancel={confirmation.onCancel}
                    onConfirm={confirmation.onConfirm}
                />

                <RefundDetailContainer
                    isVisible={this.state.openDetail}
                    data={this.state.selectedData}
                    onClose={() => this.setState({ openDetail: false })}
                />

                <div className="dashboard__header">
                    <div className="flex flex__sm">
                        <h1 className="header" style={{ marginRight: 20 }}>Refund</h1>
                        <Filter 
                            filterOptions={filterOptions}
                            withFilter
                            isFilterVisible={this.state.isFiltering}
                            onCancel={() => this.setState({ isFiltering: false })}
                            onFilter={() => this.setState({ isFiltering: !this.state.isFiltering })}
                            onApplyFilter={(e) => this.onFilter(e)}
                            onSearch={(e) => this.onSearch(e)}
                        />
                    </div>
                    <div className="flex flex__sm">   
                        <Button
                            icon={Images.plus}
                            iconPosition="before-text"
                            buttonText="Add Refund"
                            type="dashboard"
                            size="sm"
                            style={{ marginRight: 0 }}
                            onClick={() => {
                                navigate(this.props.history, "/refund/create");
                            }}
                        /> 
                    </div>

                </div>
                <div className="dashboard__body">
                    {this.state.loaded && (
                        <Table 
                            columns={columns}
                            data={data}
                        />
                    )}
                </div>
            </div>
        )
    }

}

const mapsStateToProps = ({ 
    authenticated,
    users,
    payment
}) => {
    return {
        token: authenticated.token,
        userInfo: authenticated.userInfo,
        users: users.lists,
        refunds: payment.refund_lists
    }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { dispatch } = dispatchProps;
    const { actions } = require("redux/PaymentRedux");

    return {
      ...ownProps,
      ...stateProps,
      fetchRefunds: async(
        token
      ) => {
        dispatch({ type: "FETCH_PENDING" })
        var json = await actions.fetchRefund(dispatch, token)
        dispatch({ type: "FETCH_FINISH" })
        return json
      },
      clearRefunds: () => dispatch({ type: "CLEAR_REFUND" }),
      toastPending: (body) => {
        dispatch({ type: "TOAST_PENDING", ...body })
      },
    };
}

export default connect(
    mapsStateToProps, 
    undefined,
    mergeProps
)(RefundsContainer);