import React from "react";
import { Col, Row } from "react-bootstrap";
import images from "common/Images";
import { Input, Button } from "components";
import firebase from "firebase";
import LoginWithMobileNumberVerificationContainer from "./mobile-verification";
import { connect } from "react-redux";
import { FirebaseAPI } from "services";
import { Languages, Tools, navigate, Constant, Notifications } from "common";

class LoginWithMobileNumberContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      send: false,
      valid: false,
      data: {
        mobile_prefix : Constant.COUNTRY_CODE.default
      },
    };
  }

  onChange = (target, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [target]: value,
      },
      send: false,
    });
  };

  handleManualLogin = async () => {
    var valid = await this.validation();

    if (valid) {
      var data = {
        ...this.state.data,
        provider: "saytum",
      };

      var is_active = await this.isAccountActive();
      if (is_active) {
        this.onRequestVerification(data);
      }
    }
  };

  isAccountActive = async () => {
    const { fetchPending, fetchFinish, toastPending, history } = this.props;

    fetchPending();
    const { mobile_prefix, mobile_number } = this.state.data;
    var number = mobile_prefix.value + mobile_number.replace(/ /g, "");
    var param = { phone: number, role: Tools.getParamFromURL(history, "role") };
    var is_account_active = await FirebaseAPI.callFunctions(
      "isAccountActive",
      param
    );
    var message = is_account_active.message;

    if (is_account_active.status_code === 200) {
      fetchFinish();
      return true;
    } else if (is_account_active.status_code === 500) {
      message = is_account_active.errorInfo.message;
      if (is_account_active.errorInfo.code === "auth/user-not-found") {
        message = Notifications.UserNotFound;
      }
    }

    fetchFinish();
    toastPending({ message: message, toastType: 500 });

    return false;
  };

  onRequestVerification = async (data) => {
    if (this.state.verified) {
      await this.setState({ verified: false });
    }

    const { mobile_prefix, mobile_number } = data;
    var number = mobile_prefix.value + mobile_number.replace(/ /g, "");
    firebase.auth().languageCode = "en";
    let verify = new firebase.auth.RecaptchaVerifier("recaptcha-container");
    await firebase
      .auth()
      .signInWithPhoneNumber(number, verify)
      .then((result) => {
        const { verificationId } = result;
        this.setState(
          {
            showVerification: true,
          },
          () => {
            this.setState({
              data: {
                ...this.state.data,
                verificationId: verificationId,
              },
              verified: true,
              error: false,
            });
          }
        );
      })
      .catch((err) => {
        this.setState({ verified: true });
        this.props.toastPending({ message: err.message, toastType: 500 });
      });
  };

  onBlueValidationHandler = () => {
    if (
      !Tools.isNotEmpty(this.state.data.mobile_prefix) ||
      !Tools.isNotEmpty(this.state.data.mobile_number)
    ) {
      this.setState({
        ...this.state,
        error: true,
      });
    } else {
      this.setState({
        ...this.state,
        error: false,
      });
    }
  };

  validation = () => {
    const { mobile_number, mobile_prefix } = this.state.data;

    var valid =
      Tools.isNotEmpty(mobile_number) && Tools.isNotEmpty(mobile_prefix);

    this.setState({
      valid: valid,
      send: true,
    });

    return valid;
  };

  onLogin = async (data) => {
    const { history, login, toastPending } = this.props;
    this.setState(
      {
        showVerification: false,
      },
      async () => {
        data = {
          ...data,
          ...this.state.data,
          provider: "saytum",
          role: Tools.getParamFromURL(history, "role"),
        };

        var json = await login(data);
        if (json.status_code === 200) {
          navigate(this.props.history, "/dashboard", "replace");
          var account = json.delivery_boy.find(
            (item) => item.is_deleted === false
          );
          var name = account.name;
          toastPending({
            toastType: json.status_code,
            message: Languages.login.success(name),
          });
        } else {
          // alert(22)
          console.log("json", json)
          toastPending({ toastType: json.status_code, message: json.status_code === 500 ? "Incorrect Verification Code" : json.message });
        }
      }
    );
  };

  render() {
    const { data, verified } = this.state;
    var params = this.props.history.location.search;
    var search = new URLSearchParams(params);

    return (
      <div className="container">
        <div className="container__auth">
          <LoginWithMobileNumberVerificationContainer
            isVisible={this.state.showVerification}
            onVerified={(data) => this.onLogin(data)}
            onCancel={() =>
              this.setState({ showVerification: false, verified: false })
            }
            onRequestNewCode={() =>
              this.setState({ showVerification: false, verified: false }, () =>
                this.handleManualLogin()
              )
            }
          />

          <Row>
            <Col>
              <div className="container__auth__header">
                <h1 className="main-header-text">
                  Login into{" "}
                  <span className="text__capitalize">
                    {search.get("role").replace("_", " ")}
                  </span>{" "}
                  account
                </h1>
                <h4 className="secondary-header-text">
                  Provide your credintials to login into your account.
                </h4>
              </div>
              <div className="container__auth__body">
                <Input
                  type="phone"
                  label="Mobile Number"
                  phoneCodes={Constant.COUNTRY_CODE.options}
                  iconPrefix={images.form_phone}
                  placeholder="Enter your Mobile Number"
                  style={{ margin: 0, marginBottom: 20 }}
                  onSelect={(e) => this.onChange("mobile_prefix", e)}
                  onChange={(e) => {
                    this.setState({
                      ...this.state,
                      error: false,
                    });
                    this.onChange("mobile_number", e.target.value);
                  }}
                  onBlur={() => this.onBlueValidationHandler()}
                  value={data.mobile_number}
                  selectedPrefix={data.mobile_prefix}
                  caption={this.state.error && "This field is required"}
                  isError={
                    (this.state.send && !this.state.valid) || this.state.error
                  }
                  isRequired={true}
                />

                {/* <div style={{ marginBottom: 30 }}>
                                    <a href="/login" className="hyperlink text-left">Login with email address</a>
                                </div> */}

                {!verified && <div id="recaptcha-container"></div>}

                <Button
                  size="lg"
                  type="warning"
                  buttonText="Login"
                  variation="primary"
                  style={{ margin: 0, marginTop: 20, marginBottom: 20 }}
                  onClick={() => this.handleManualLogin()}
                />
              </div>
            </Col>
            <Col className="banner-container">
              <img src={images.banner_login} alt="user-login.png" />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { dispatch } = dispatchProps;
  const { actions } = require("redux/AuthenticatedRedux");

  return {
    ...ownProps,
    ...stateProps,
    login: async (body) => {
      dispatch({ type: "FETCH_PENDING" });
      var login = await actions.login(dispatch, body);
      dispatch({ type: "FETCH_FINISH" });
      return login;
    },
    fetchPending: () => dispatch({ type: "FETCH_PENDING" }),
    fetchFinish: () => dispatch({ type: "FETCH_FINISH" }),
    toastPending: (body) => {
      dispatch({ type: "TOAST_PENDING", ...body });
    },
  };
};

export default connect(
  undefined,
  undefined,
  mergeProps
)(LoginWithMobileNumberContainer);
