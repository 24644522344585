import React from "react";
import FAQ from './FAQ';

const nxtarrow = <i class="fa-solid fa-right-long" style={{color: "#000000"}}></i>;

const items = [
  {
    title:(
        <p>
            What services does Saytum offers?
        </p>
    ),
    content:( 
        <p>
            Saytum offers a digital marketplace that connects users and vendors within the logistics and courier industry. Our platform is designed to facilitate seamless interactions and transactions between businesses, individuals, and logistics service providers.
        </p>
    )
  },

  {
    title:(
        <p>
            How can I initiate a consignment order with Saytum?
        </p>
    ),
    content:(
        <p>
          Initiating a consignment order with Saytum is straightforward. You can leverage our user-friendly platform to select the perfect logistics provider tailored to your specific needs and submit your consignment request seamlessly. Follow as below :-<br/><br/>
          Login as a User {nxtarrow} Consignment {nxtarrow} Add Consignment {nxtarrow} Pickup Details {nxtarrow} Delivery Details {nxtarrow} Material Details {nxtarrow} Declare Good Value {nxtarrow} Place Order
        </p>
      )
  },

  {
    title:(
        <p>
            How can I conveniently track my consignment with Saytum's services
        </p>
    ),
    content:(
        <p>
          Saytum provides a robust tracking system to keep you informed about the real-time progress of your consignment. This feature ensures that you can monitor the status of your shipments and guarantee timely deliveries. Follow as below :-<br/><br/>
          Login as a User {nxtarrow} Tracking {nxtarrow} Actions {nxtarrow} See Details
        </p>
      )
  },

  {
    title:(
        <p>
            What is the best way to get in touch with Saytum's customer support for assistance or inquiries?
        </p>
    ),
    content:(
        <p>
          To reach Saytum's dedicated customer support team, you can utilize various communication channels, such as email, phone within our platform. We are here to provide assistance and resolve any questions or concerns you may have.
        </p>
      )
  },

  {
    title:(
        <p>
            What transportation modes does Saytum support for deliveries?
        </p>
    ),
    content:(
        <p>
          Saytum typically supports a broad spectrum of transportation modes, including surface transportation (by road), by Airfreight, by Train. Our flexibility allows you to choose the mode that aligns with your business's unique requirements.
        </p>
      )
  },
  {
    title:(
        <p>
            In the event that my consignment is not delivered as expected, who should I contact for resolution?
        </p>
    ),
    content:( 
        <p>
            If you encounter any issues with the delivery of your consignment, please reach out to Saytum's customer support. We are committed to addressing any concerns promptly and ensuring that your consignment reaches its destination successfully.
        </p>
    )
  },

  {
    title:(
        <p>
            If a service provider is temporarily unreachable, how can I establish contact with them?
        </p>
    ),
    content:(
        <p>
          In the event that you experience difficulty reaching a service provider, Saytum can assist you in re-establishing contact. Please notify our customer support team, and we will facilitate communication or help address the issue.
        </p>
      )
  },

  {
    title:(
        <p>
            What categories of materials are supported for transportation through Saytum's platform?
        </p>
    ),
    content:(
        <p>
          Saytum typically supports a wide array of material categories, but the specific categories may vary based on the logistics providers in our network. User shall endeavour to ensure that it’s consignments do not contain any items, which are hazardous or inflammable or prohibited for carriage by road by any Regional Transport Authority, Government or other Statutory body.
        </p>
      )
  },

  {
    title:(
        <p>
            How long does it usually take to get approval for a partner (service provider) account with Saytum?
        </p>
    ),
    content:(
        <p>
          The approval timeline for a partner or service provider account on Saytum may vary depending on specific criteria. Please reach out to Saytum directly to discuss the approval process and expected timeline based on your unique circumstances.
        </p>
      )
  },

  {
    title:(
        <p>
            Can service providers also serve as delivery providers within the Saytum network?
        </p>
    ),
    content:(
        <p>
          Yes, service providers within the Saytum network often play a role in the delivery process. Many logistics companies offer comprehensive services that include transportation and final delivery. You can discuss specific arrangements with the service provider you are interested in partnering with.
        </p>
      )
  },
 
];

const Faq = () =>{
    return(
        <>
            <FAQ items={items} />
        </>
    )
}

export default Faq;
