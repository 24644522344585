/** @format */
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";

import reducers from "redux/index";
import { EnsureAccountIsComplete } from "store/middleware";

const middleware = [
  thunk,
  EnsureAccountIsComplete
  // more middleware
];

// const store = createStore(reducers, {}, applyMiddleware(...middleware));

const configureStore = () => {
  let store = null;
  
  store = compose(applyMiddleware(...middleware))(createStore)(reducers);

  return store;
};

export default configureStore();
