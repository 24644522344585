import images from "common/Images";
import Tools, { navigate } from "common/Tools";
import { Button } from "components";
import React from "react";
import { withRouter } from "react-router-dom";
import ProfileNotCompleteHeader from "./header-profile-not-complete";

class ProfileHeader extends React.PureComponent {
  render() {
    const { user } = this.props;

    console.log("user", user);

    if (!Tools.isProfileCompleted()) {
      return <ProfileNotCompleteHeader />;
    }
    return (
      <>
      </>
    );
    // return (
      // user.role === "user" &&
      // (!user.partner ? (
      //   <div className="floating-navigation">
      //     <div className="flex">
      //       <img
      //         src={images.job}
      //         alt="job"
      //         className="floating-navigation__icon"
      //       />
      //       <p className="floating-navigation__label">
      //         Join our logistic partner team!
      //       </p>
      //     </div>
      //     <Button
      //       buttonText="Join Now"
      //       className="floating-navigation__btn"
      //       type="success"
      //       size="sm"
      //       onClick={() => navigate(this.props.history, "/join-partner")}
      //     />
      //   </div>
      // ) : (
        // <>
        //   {/** Confirmed */}
        //   {/* {user.partner.is_confirmed && (
        //                     <div className="floating-navigation">
        //                         <div className="flex">
        //                             <img src={images.job} alt="job" className="floating-navigation__icon" />
        //                             <p className="floating-navigation__label">Switch to Logistic Partner Account!</p>
        //                         </div>
        //                         <Button
        //                             buttonText="Switch Now"
        //                             className="floating-navigation__btn"
        //                             type="success"
        //                             size="sm"
        //                             onClick={() => navigate(this.props.history, "/join-partner")}
        //                         />
        //                     </div>   
        //                 )} */}

        //   {/** Rejected */}
        //   {!user.partner.is_confirmed &&
        //     Tools.isNotEmpty(user.partner.notes) && (
        //       <div className="floating-navigation danger with-button">
        //         <div className="flex">
        //           <img
        //             src={images.job}
        //             alt="job"
        //             className="floating-navigation__icon"
        //           />
        //           <p className="floating-navigation__label">
        //             Your logistic partner registration is rejected due to some
        //             issue!
        //           </p>
        //         </div>
        //         <Button
        //           buttonText="Resubmit Registration"
        //           className="floating-navigation__btn"
        //           type="danger"
        //           size="sm"
        //           onClick={() =>
        //             navigate(this.props.history, "/join-partner?resubmit=yes")
        //           }
        //         />
        //       </div>
        //     )}

        //   {/** In Review */}
        //   {!user.partner.is_confirmed &&
        //     !Tools.isNotEmpty(user.partner.notes) && (
        //       <div className="floating-navigation danger">
        //         <div className="flex">
        //           <p
        //             className="floating-navigation__label text__center"
        //             style={{ margin: 0 }}
        //           >
        //             Your logistic partner registration is in verification
        //             progress!
        //           </p>
        //         </div>
        //       </div>
        //     )}
        // </>
      // ))
    // );
  }
}

export default withRouter(ProfileHeader);
