import images from "common/Images";
import { Button, ButtonGroup, FormBody, Modal } from "components";
import React from "react";
import SignatureCanvas from 'react-signature-canvas'
import "./styles.scss";


class SignaturePad extends React.PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            is_empty: false
        }
    }

    clear = () => {
        this.signaturePad.clear()
    }

    onSign = () => {
        if(!this.signaturePad.isEmpty()) {
            this.setState({
                is_empty: false
            }, () => {
                var data = this.signaturePad.toDataURL();
                this.props.onSign(data);
            })
        }
        else {
            this.setState({
                is_empty: true
            })
        }
    }

    render() {
        return(
            <Modal
                reference={(ref) => this.rejection_modal = ref}
                isVisible={this.props.isVisible}
                className="modal__form signature__modal"
            >
                
                <button 
                    className={`button__link modal__detail__closer`} 
                    onClick={() => this.props.onClose()}
                >
                    <img src={images.close} alt="close" />
                </button>

                <FormBody
                    className="modal__form__container"
                >
                    <h1 className="modal__form__header">Signature Pad</h1>
                    <h3 className="text__verification__body" style={{ marginBottom: 30 }}>Please provide your signature here</h3>

                    <div className="signature__wrapper">
                        <div className="signature__container">

                            <SignatureCanvas 
                                penColor={"black"}
                                canvasProps={{
                                    width: 500, 
                                    height: 250, 
                                    className: 'sigCanvas'
                                }} 
                                ref={(ref) => this.signaturePad = ref}
                            />

                            <Button
                                buttonText="Clear"
                                size="sm"
                                type="danger"
                                className="signature__clear__btn"
                                onClick={() => this.clear()}
                            />

                        </div>

                        {this.state.is_empty && (
                            <p className="signature__caption signature__caption__error">Please fill your signature</p>
                        )}
                        
                    </div>

                    <ButtonGroup 
                        position="right" 
                        className="modal__form__btn-group"
                    >
                        <Button
                            buttonText="Cancel"
                            size="sm"
                            type="default"
                            style={{ margin: 0 }}
                            onClick={() => this.props.onClose()}
                        />
                        <Button
                            buttonText="Save"
                            size="sm"
                            type="warning"
                            style={{ margin: 0 }}
                            onClick={() => this.onSign()}
                        />
                    </ButtonGroup>
                </FormBody>

            </Modal>
        )
    }

}

export default (SignaturePad);